import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto, VotechaChartDto, ChartItemDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GaugeChartModule } from 'angular-gauge-chart'
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'gauge-chart',
    templateUrl: './gauge-chart.component.html',
    styleUrls: ['./gauge-chart.component.css'],
    animations: [appModuleAnimation()]
})
export class GaugeChartComponent extends AppComponentBase implements OnInit {

    @Input() poll: PollListDto; 
    @Input() inputIsUserLoggedIn: boolean = false;

    public chartActive: boolean = false;
    public saving: boolean = false;

    public canvasWidth = 300;
    public needleValue = 50;
    public centralLabel = '';
    public name = '';
    public bottomLabel = '';
    public options = {
        hasNeedle: true,
        needleColor: 'gray',
        needleStartValue: 0,
        arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)"],
        arcDelimiters: [30, 70],
    };

    chartData: VotechaChartDto;
    politicalItems: ChartItemDto[] = [];
    answerId: number;
    activeRatingTabIndex: number = 0;
    public thisYear: number = 0;

    constructor(
        injector: Injector,
        private _router: Router,

    ) {
        super(injector);
    }

    ngOnInit() {
        this.chartActive = false;
        this.needleValue = this.poll.chartData.gaugeValue;
        this.bottomLabel = this.poll.chartData.gaugeValue.toString();
    }

    goToVoteForm(id: string): void {        
        var country = "norway";
        if (this.inputIsUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country, id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }
}
