import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  animations: [appModuleAnimation()]
})
export class HelpComponent extends AppComponentBase implements OnInit {
    page: PageOutput;
    cookieLangValue: string;
    isUserLoggedIn: boolean = false;

    constructor(
        injector: Injector,
        private _pageService: PageServiceProxy,
        private _router: Router,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.page = new PageOutput();
        this.getContent();
  }

  getContent(): void {
      this._pageService.getPage("Help", this.cookieLangValue).subscribe((result) => {                              
          this.page.preamble = result.preamble;
          this.page.content = result.content;
          window.scrollTo(0, 0);           
      });
    }

    GoToRegistration(): void {
        this._router.navigate(['account/register']);
    }

    GoToLogin(): void {
        this._router.navigate(['account/login']);
    }  

    goToCreateVotecha(): void {
        var id: string = "00000000-0000-0000-0000-000000000000";
        this._router.navigate(['app/main/user-survey/create-poll-wizard/', id]);
    }
}
