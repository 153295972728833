
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VotechaVoteServiceProxy, GetUserVoteEdit, UserVoteInput } from '@shared/service-proxies/service-proxies';
import { PollServiceProxy, PollListDto, VotechaChartDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';

@Component({
    selector: 'template-vote-survey',
    templateUrl: './template-vote-survey.component.html',
    animations: [appModuleAnimation()]
})
export class TemplateVoteSurveyComponent extends AppComponentBase implements OnInit, OnChanges {

    @Input() surveyId_input: string;
    @Input() pollInput: PollListDto;
    @Input() voted_input: boolean;
    @Output() answerClicked: EventEmitter<true> = new EventEmitter<true>();

    public vote: UserVoteInput;
    public survey: PollListDto;
    public chartData: VotechaChartDto;
    public showArticles: boolean = false;
    public voteSaved: boolean = false;
    public active: boolean = false;
    public saving: boolean = false;
    public dataIsReady: boolean = false;
    public hasVoted: boolean = false;
    public activeTabIndex: number = 0;
    public tabIndex: number = 0;
    public surveyId: string = "";



    constructor(
        injector: Injector,
        private _voteService: VotechaVoteServiceProxy,
        private _pollService: PollServiceProxy,
        private _router: Router
    ) {
        super(injector);
    }

    ngOnInit() {
        registerLocaleData(localeNorwegian);
        this.survey = new PollListDto();
        this.survey = this.pollInput;
        this.surveyId = this.survey.id;
        this.hasVoted = this.survey.hasVoted;
        this.getSurveyData();
    }
    ngOnChanges() {
        this.ngOnInit();
    }

    //getSurvey() {
    //    this._pollService.getSurvey(this.surveyId).subscribe((result) => {
    //        this.survey = result;
    //        this.getSurveyData();
    //    });
    //}

    getSurveyData(): void {
        //this.getChartData();
        this.vote = new UserVoteInput();
        this.activeTabIndex = 0;
        this.vote.areaType = this.survey.areaType;
        this.vote.countryId = this.survey.countryId;
        this.vote.categoryColor = this.survey.colorCode;
        this.vote.categoryId = this.survey.categoryId;
        this.vote.categoryName = this.survey.categoryName;
        this.vote.subCategoryName = this.survey.subCategoryName;
        this.vote.displayName = this.survey.displayName;
        this.vote.displayQuestion = this.survey.displayQuestion;
        this.vote.imgUrl = this.survey.imgUrl;
        this.vote.languageId = this.survey.userLanguageId;
        this.vote.feedbackType = this.survey.feedbackTypeId;
        this.vote.locationName = this.survey.areaName;
        if (this.hasVoted) {
            this._voteService.getVotechaVoteForEdit(this.surveyId).subscribe(result => {
                this.vote.id = result.id;
                this.vote.pollId = result.pollId;
                this.vote.answer = result.answer;
                this.vote.answerId = result.answerId;
                this.vote.multiAnswers = result.multiAnswers;                           
                this.active = true;
            });
        }
        else {
            this.vote.id = '00000000-0000-0000-0000-000000000000';
            this.vote.pollId = this.survey.id;
            this.active = true;
        }

    }



  

    onChildAnswerClicked(answerId: number): void {
        this.vote.answerId = answerId;
        var answer = this.getAnswer(answerId);
        this.onVoteChange(answer);
        this.hasVoted = true;
        this.answerClicked.emit();
    }

    onChildMultiAnswerClicked(isMultiAnswerClicked: boolean): void {
        this.hasVoted = isMultiAnswerClicked;
    }

    onVoteChange(answer:string): void {
        this.saving = true;
        this.vote.answer = answer;
        this._voteService.submitVotechaVote(this.vote).pipe(
            finalize(() => this.saving = false))
            .subscribe((result) => {
                this.vote.id = result.id;
                this.voteSaved = true;
                this.hasVoted = true;
                this.answerClicked.emit();
            });
    }

 
   

    SurveyArticles(): void {
        this._router.navigate(['app/main/articles', this.survey.id]);
    }

    getAnswer(answerId: number): string {
        var answer = "";
        for (var i = 0; i < this.survey.surveyItems.length; i++) {
            if (this.survey.surveyItems[i].itemValue === answerId) {
                answer = this.survey.surveyItems[i].displayText;
            }
            return answer; 
        }
    }

    onImportanceRankingChange(): void {
        this.saving = true;
        this._voteService.submitVotechaVote(this.vote).pipe(
            finalize(() => this.saving = false))
            .subscribe(() => {
                this.notify.info(this.l('ThanksForYourRanking'));
                this.voteSaved = true;
            });
    }
}
