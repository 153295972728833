
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, Injector, Output, EventEmitter, ElementRef } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { ProfileServiceProxy, CurrentUserProfileEditDto, DefaultTimezoneScope } from "@shared/service-proxies/service-proxies";
import { AppSessionService } from '@shared/common/session/app-session.service'
import { AppTimezoneScope } from '@shared/AppEnums';
import { MySiteServiceProxy, UserProfileDto, UserPointOutput } from '@shared/service-proxies/service-proxies';
import { CountryServiceProxy, CountryListDto, IListResultDtoOfCountrySelectionDto } from '@shared/service-proxies/service-proxies';
import { AreaServiceProxy, AreaSelectionDto } from '@shared/service-proxies/service-proxies';
import { CityServiceProxy, CitySelectionDto } from '@shared/service-proxies/service-proxies';
import { SubAreaServiceProxy, SubAreaSelectionDto } from '@shared/service-proxies/service-proxies';
import { UserSettingsServiceProxy} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html',
    animations: [appModuleAnimation()]
})
export class MySettingsModalComponent extends AppComponentBase {

   @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    countries: CountryListDto[] = [];
    areas: AreaSelectionDto[] = [];
    subAreas: SubAreaSelectionDto[] = [];
    cities: CitySelectionDto[] = [];
    citiesCache: CitySelectionDto[] = [];

    activeTabIndex: number = 0;
    tabIndex: number = 0;

    public active: boolean = false;
    public saving: boolean = false;
    public forDeleting: boolean = false;
    public useAlias: number = 0;
    public userGender: number;
    public userName: string;

    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: DefaultTimezoneScope = AppTimezoneScope.User;
    private _initialTimezone: string = undefined;

    constructor(
        injector: Injector,
        private _authService: AppAuthService,
        private _profileService: ProfileServiceProxy,
        private _appSessionService: AppSessionService,
        private _mySiteService: MySiteServiceProxy,
        private _countryService: CountryServiceProxy,
        private _areaService: AreaServiceProxy,
        private _subAreaService: SubAreaServiceProxy,
        private _cityService: CityServiceProxy,
        private _userSettingsService: UserSettingsServiceProxy,
        private _router: Router
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.activeTabIndex = 0;
            this.user = result;    
            this.userGender = result.gender;
            this.userName = result.name + ' ' + result.surname;
            this.useAlias = 0;
            if (this.user.useAlias) {
                this.useAlias = 1;
            }

            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName != AppConsts.userManagement.defaultAdminUserName;
            this.getCountries();
            if (this.user.countryId > 0) {
                this.getAreas(this.user.countryId, false);
                this.getCities(this.user.countryId);
                if (this.user.areaId > 0) {
                    this.getSubAreas(this.user.areaId);                    
                }
            }
           
            this.modal.show();
        });
    }

   

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.user.useAlias = false;
        if (this.useAlias === 1) {
            this.user.useAlias = true;
        }
              

        this._profileService.updateCurrentUserProfile(this.user).pipe(
            finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this._appSessionService.user.name = this.user.name;
                this._appSessionService.user.surname = this.user.surname;
                this._appSessionService.user.userName = this.user.emailAddress;
                this._appSessionService.user.emailAddress = this.user.emailAddress;
                this._appSessionService.user.countryId = this.user.countryId;
                this._appSessionService.user.areaId = this.user.areaId;
                this._appSessionService.user.subAreaId = this.user.subAreaId;
                this._appSessionService.user.cityId = this.user.cityId;       
                this._appSessionService.user.isParticipatingVotechaGame = this.user.isParticipatingVotechaGame;

                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).done(() => {
                        window.location.reload();
                    });
                }
            });
    }

    deleteProfile(): void {
        //this.message.confirm(
        //    this.l('AreYouSureToDeleteYourAccount'), this.l('AreYouSure'),
        //    isConfirmed => {
        //        if (isConfirmed) {
        //            var isDeleted = false;
        //             this._mySiteService.deleteUserProfile().subscribe((result) => {
        //                 isDeleted = result;
        //                 if (isDeleted) {                             
        //                     this._authService.logout();
        //                     this.close();
        //                 }
        //            });
                   
        //            //this._authService.deleteMe();
        //        }
        //    }
        //);

        var isDeleted = false;
        this._mySiteService.deleteUserProfile().subscribe((result) => {
            isDeleted = result;
            if (isDeleted) {
                this._authService.logout();
                this.close();
            }
        });
    }

    getCountries(): void {
        var includeCities = false;
        this._countryService.getCountries("").subscribe((result) => {
            this.countries = result.items;
        });
    }

    getAreas(countryId: number, includeSubAreas: boolean): void {
        this._areaService.getSurveyAreas(countryId, includeSubAreas).subscribe((result) => {
            this.areas = result.items;
        });
    }

    getSubAreas(areaId: number): void {
        this._subAreaService.getSurveySubAreas(areaId).subscribe((result) => {
            this.subAreas = result.items;
        });
    }
    getCities(countryId: number): void {
        this._cityService.getSurveyCities(countryId).subscribe((result) => {
            this.cities = result.items;
            this.citiesCache = result.items;
        });
    }

    onCountryDDLChange(): void {
        this.getListValues();
    }

    onAreaDDLChange(): void {
        this.getSubAreas(this.user.areaId);
        this.filterCities();
    }

    getListValues(): void {
        if (this.user.countryId > 0) {
            this.getAreas(this.user.countryId, true);
            this.getCities(this.user.countryId);
        }
        else {
            this.areas = [];
            this.cities = [];
        }
    }

    handleDate(myDate: Date) {
        let dateString: string = myDate.toString();
        let days: number = parseInt(dateString.substring(8, 10));
        let months: number = parseInt(dateString.substring(5, 7));
        let years: number = parseInt(dateString.substring(0, 5));
        let goodDate: Date = new Date(years + "/" + months + "/" + days);
        return goodDate;
        //this.date = goodDate.toISOString().substring(0, 10);
    }
    filterCities(): void {
        this.cities = this.citiesCache;
        if (this.cities.length > 0) {
            if (this.cities[0].areaId > 0) {
                var filterList = [];
                for (var i = 0; i < this.cities.length; i++) {
                    if (this.cities[i].areaId == this.user.areaId) {
                        var item = this.cities[i];
                        filterList.push(item);
                    }
                }

                this.cities = filterList;
            }
        }
    }

    setActiveTab(index: number) {
        this.activeTabIndex = index;        
    }

   
}
