import { Component, ViewChild, Injector, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';

import * as _ from 'lodash';

@Component({
    selector: 'termsModal',
    templateUrl: './terms-modal.component.html'
})
export class TermsModalComponent extends AppComponentBase {

   @ViewChild('modal', { static: true }) modal: ModalDirective;

    public active: boolean = false;

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.modal.show();
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }

  
}
