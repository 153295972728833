<div [@routerTransition] style="margin-top: 70px; margin-bottom: 3%;" [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">
    <div class="container">
        <div class="wow fadeInLeft c-bg-white well-front-page animated">
            <div class="well well-lg c-bg-white" style="border-color:#ffffff;border-top-left-radius:15px;border-top-right-radius:15px;box-shadow:none">
                <div class="container">
                    <div class="c-content-title-1">
                        <h1 class="c-font-uppercase c-font-bold font-dark font-media-h1">{{l('Possibilities')}}</h1>
                        <div class="c-line-left c-theme-bg"></div>
                    </div>
                    <div class="col-sm-9 col-md-6 col-lg-6">
                        <div>
                            <h2 class="font-media-h2 votecha-blue bold"><i class="icon-briefcase votecha-blue"></i> {{l('Investors')}}</h2>
                            <p class="font-media-h3">
                                {{l('InvestorInfo1')}} <br />
                                {{l('InvestorInfo2')}}
                            </p>
                        </div>
                    </div>

                    <div [ngClass]="{'col-sm-3 col-md-3 col-lg-3':isUserLoggedIn, 'col-md-4 col-lg-4':!isUserLoggedIn}">
                        <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/misc/tech_148263428.png" style="visibility: visible; animation-name: fadeInUp; opacity: 1;">
                    </div>
                    <div class="margin-top-20 col-xs-12 col-sm-11 col-md-10 col-lg-10">
                        <h2 class="font-media-h2 votecha-blue bold"><i class="icon-user votecha-blue"></i> {{l('Recruitment')}}</h2>
                        <div class="font-media-h3 margin-bottom-10">
                            {{l('RecruitmentInfo1')}} <br />
                            {{l('RecruitmentInfo2')}}
                        </div>
                        <ul class="font-media-h4">
                            <li class="margin-bottom-10">{{l('RecruitmentInfoSale')}}</li>
                            <li class="margin-bottom-10">{{l('RecruitmentInfoManagement')}}</li>
                            <li class="margin-bottom-10">{{l('RecruitmentInfoProject')}}</li>
                            <li class="margin-bottom-10">{{l('RecruitmentInfoDesigners')}}</li>
                            <li class="margin-bottom-10">{{l('RecruitmentInfoAppDevelopers')}}</li>
                            <li>{{l('RecruitmentInfoWebDevelopers')}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

