import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PetitionSigningServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto, SchemaSigningInput } from '@shared/service-proxies/service-proxies';
import { CountryServiceProxy, CountryListDto, IListResultDtoOfCountrySelectionDto } from '@shared/service-proxies/service-proxies';
import { AreaServiceProxy, AreaSelectionDto } from '@shared/service-proxies/service-proxies';
import { CityServiceProxy, CitySelectionDto } from '@shared/service-proxies/service-proxies';
import { SubAreaServiceProxy, SubAreaSelectionDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';

@Component({
    selector: 'petition-registration',
    templateUrl: './petition-registration.component.html',
  animations: [appModuleAnimation()]
})
export class PetitionRegistrationComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;    
    @Input() screenWidth: number = 0;
    @Output() isSigned: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    emailSigning: SchemaSigningInput;
    countries: CountryListDto[] = [];
    areas: AreaSelectionDto[] = [];
    subAreas: SubAreaSelectionDto[] = [];

    active: boolean = false;
    saving: boolean = false;
    isPetitionSigned: boolean = false;
    displayCode: number = 0;
    cookieLangValue: string;
    private cookieSigningValue: string;
    private cookiePollId: string;

    constructor(
        injector: Injector,
        private _petitionSigningService: PetitionSigningServiceProxy,
        private _countryService: CountryServiceProxy,
        private _areaService: AreaServiceProxy,
        private _subAreaService: SubAreaServiceProxy

    ) {
        super(injector);
    }

    ngOnInit() {
        this.isPetitionSigned = false;
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.emailSigning = new SchemaSigningInput();
        this.emailSigning.pollId = this.survey.id;
        this.emailSigning.countryId = this.survey.countryId;
        this.emailSigning.countryName = this.survey.countryName;        
        this.cookiePollId = "VotechaPetitionSigned-" + this.survey.id;        
        this.cookieSigningValue = abp.utils.getCookieValue(this.cookiePollId);
        //if (this.cookieSigningValue !== null && this.cookieSigningValue !== undefined) {
        //    this.isPetitionSigned = true;
        //}
        this.getCountries();
    }

    getCountries(): void {
        var includeCities = false;
        this._countryService.getCountries("").subscribe((result) => {
            this.countries = result.items;
            if (this.emailSigning.countryId > 0) {
                this.getAreas(this.emailSigning.countryId);
                this.emailSigning.areaId = 0;
            }
        });
    }

    getAreas(countryId: number): void {
        this._areaService.getSurveyAreas(countryId, false).subscribe((result) => {
            this.areas = result.items;
            this.emailSigning.subAreaId = 0;
        });
    }

    getSubAreas(areaId: number): void {
        this._subAreaService.getSurveySubAreas(areaId).subscribe((result) => {
            this.subAreas = result.items;
        });
    }

    onCountryDDLChange(): void {
        this.getAreas(this.emailSigning.countryId);
    }

    onAreaDDLChange(): void {
        this.getSubAreas(this.emailSigning.areaId);
    }

    save(): void {
        this.emailSigning.fullName = this.emailSigning.name + " " + this.emailSigning.surName;
        this.emailSigning.areaName = VotechaHelper.getAreaName(this.emailSigning.areaId, this.areas);
        this.emailSigning.subAreaName = VotechaHelper.getSubAreaName(this.emailSigning.subAreaId, this.subAreas);
        if (this.displayCode === 0) {
            this.displayCode = 2;
        }
        this.emailSigning.showFullName = this.displayCode === 1 ? true : false;
        this.emailSigning.isAnonymous = this.displayCode === 3 ? true : false;
        this._petitionSigningService.submitEmailSignature(this.emailSigning).subscribe((result) => {
            if (result) {
                this.isPetitionSigned = true;
                this.isSigned.emit(true);                
                abp.utils.setCookieValue(
                    this.cookiePollId,
                    this.emailSigning.email.toString(),
                    new Date(new Date().getTime() + 50 * 365 * 86400000), //5 year
                    abp.appPath
                );
                this.clear();
            }
        });       
    }

    clear(): void {
        this.emailSigning = new SchemaSigningInput();
        this.emailSigning.name = "";
        this.emailSigning.fullName = "";
        this.emailSigning.email = "";
        this.emailSigning.gender = 0;
        this.emailSigning.areaId = 0;
        this.emailSigning.subAreaId = 0;
        this.displayCode = 0;
    }

  }
