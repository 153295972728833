<!--<div class="margin-top2pr margin-bottom2pr" [ngClass]="{'container':!isUserLoggedIn && screenWidth > 767 || screenWidth > 1740 }">
    <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                [adSlot]="3318695926"
                [adFormat]="'auto'"
                [display]="'block'"
                [fullWidthResponsive]="true">

    </ng-adsense>
</div>-->
    <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                [adSlot]="5156723871"
                [adFormat]="'auto'"
                [display]="'block'"
                [fullWidthResponsive]="true" *ngIf="!isMeninger24">

    </ng-adsense>
<div *ngIf="active" [@routerTransition]>
    <main *ngIf="screenWidth > 767" class="margin-bottom4pr">
        <div [ngClass]="{'container':!isUserLoggedIn || screenWidth > 1740 }">
            <div [ngClass]="{'col-lg-10 col-md-11 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-11':isUserLoggedIn && screenWidth > 1740, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}">
                <div class="margin-bottom-5">
                    <div class="portlet box shadow" [ngStyle]="{'background-color': categoryColor}">
                        <header class="portlet-title margin-left10p">
                            <div class="caption">
                                <div *ngIf="survey.surveyTypeId === 0" class="bold uppercase font-media-h3 font-white">{{l('Petition')}} <i class="fa fa-bullhorn margin-left-5"></i></div>
                                <h1><span class="caption-subject bold uppercase font-white font-media-h1"> <span *ngIf="survey.surveyTypeId > 0">{{survey.displayName}}</span><span *ngIf="survey.surveyTypeId === 0">{{survey.displayQuestion}}</span></span></h1>
                                <span class="font-white font-media-h3" style="padding-right:20px">{{ survey.creationTimeString}}</span>
                                <span class="btn" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.categoryName}}</span>
                                <span *ngIf="survey.subCategoryName !== null" class="btn margin-left-20" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.subCategoryName}}</span>
                            </div>
                            <div class="actions margin-top-30 margin-right-20">
                                <div><button class="btn-no-style font-white" (click)="close()" [attr.aria-label]="strClose"><i class="fa fa-2x fa-close"></i></button></div>
                            </div>
                        </header>
                        <div class="portlet-body light">
                            <div class="portlet light">
                                <div class="portlet-body">
                                    <div class="col-md-12 col-sm-12 margin-bottom-15" *ngIf="screenWidth < 1200">
                                        <img *ngIf="survey.imgUrl !== null" id="photo" style="width: 100%; max-width: 600px; margin-left: auto; margin-right: auto" alt="{{survey.alternateText}}" class="img-responsive shadow rounded-corners margin-bottom2pr" [src]="survey.imgUrl" />
                                    </div>
                                    <div class="col-lg-7 col-md-12 col-sm-12">
                                        <div *ngIf="survey.surveyTypeId === 0">
                                            <div class="container-max-width" *ngIf="!showRegisterPanel">
                                                <p [innerHTML]="shortDescription" class="article-text bold">
                                                </p>
                                                <p *ngIf="showContentDescription" [innerHTML]="contentDescription"></p>
                                                <div class="margin-bottom-5 text-center" *ngIf="validContentDescription">
                                                    <button type="button" id="btnShowAllContent" (click)="toggleContent()" [ngClass]="{'btn btn-circle default':!toggleShortConent,'btn-no-style': toggleShortConent }"><span *ngIf="!toggleShortConent">{{l("ReadMore")}}</span><span *ngIf="toggleShortConent"><i class="fa fa-arrow-circle-up"></i></span></button>
                                                </div>
                                            </div>
                                            <div *ngIf="showRegisterPanel">
                                                <div *ngIf="isUserLoggedIn">
                                                    <div class="form-group">
                                                        <label class="font-media-h4 font-italic font-purple">{{l("DisplayName")}}</label>
                                                        <div class="md-radio-list">
                                                            <div class="md-radio font-dark">
                                                                <input id="display_fullname" name="display_fullname" class="md-radiobtn" [(ngModel)]="displayCode" [value]=1 type="radio">
                                                                <label for="display_fullname">
                                                                    <span class="inc"></span>
                                                                    <span class="check"></span>
                                                                    <span class="box"></span>
                                                                    {{l("DisplayFullName")}}
                                                                </label>
                                                            </div>
                                                            <div class="md-radio font-dark">
                                                                <input id="display_forname" name="display_forname" class="md-radiobtn" [(ngModel)]="displayCode" [value]=2 type="radio">
                                                                <label for="display_forname">
                                                                    <span class="inc"></span>
                                                                    <span class="check"></span>
                                                                    <span class="box"></span>
                                                                    {{l("DisplayForName")}}
                                                                </label>
                                                            </div>
                                                            <div class="md-radio font-dark">
                                                                <input id="display_anonymous" name="display_anonymous" class="md-radiobtn" [(ngModel)]="displayCode" [value]=3 type="radio">
                                                                <label for="display_anonymous">
                                                                    <span class="inc"></span>
                                                                    <span class="check"></span>
                                                                    <span class="box"></span>
                                                                    {{l("DisplayAnonymous")}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="margin-top2pr" *ngIf="displayCode < 3">
                                                        <label class="font-media-h4 font-italic font-purple">{{l("PersonalPetitionMessageInfo")}}</label>
                                                        <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                                            <input class="form-control" type="text" name="petitionMessage" [ngClass]="{'edited':petitionMessage}" [(ngModel)]="petitionMessage" maxlength="150">
                                                            <label>{{l("PersonalPetitionMessage")}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="text-center margin-top4pr margin-bottom-40" *ngIf="!hasVoted">

                                                        <button type="button" (click)="signPetition()" class="btn btn-lg btn-circle" [ngClass]="{'btn-default':!isUserLoggedIn || displayCode === 0, 'btn-success margin-top-30':isUserLoggedIn && displayCode !== 0 }" [disabled]="!isUserLoggedIn || displayCode === 0">{{l("Sign")}}</button>
                                                    </div>
                                                    <div class="margin-top-20 font-dark bold text-center font-media-h2" *ngIf="hasVoted">
                                                        {{l("SigningRegistered")}}! <span class="font-purple"><i class="fa fa-thumbs-up"></i></span>
                                                    </div>
                                                </div>
                                                <petition-registration *ngIf="!isUserLoggedIn" [survey]="survey" (isSigned)='onSigningAdded($event)' [screenWidth]="screenWidth"></petition-registration>
                                            </div>
                                        </div>
                                        <div *ngIf="survey.surveyTypeId > 0">
                                            <div class="margin-left2pr margin-bottom-20 container-max-width">
                                                <p [innerHTML]="survey.displayDescription" class="article-text">
                                                </p>
                                            </div>
                                            <div *ngIf="survey.descriptionSources && survey.descriptionSources.length > 0">
                                                <hr />
                                                <div class="margin-top4pr">
                                                    <span class="font-media-h3" [ngStyle]="{'color': categoryColor}">{{l('Sources')}}</span>
                                                    <ul>
                                                        <li *ngFor="let source of survey.descriptionSources; let i=index">
                                                            <a *ngIf="source.sourceUrl !== null && source.sourceUrl !== ''" title="{{source.sourceName}}" href="{{source.sourceUrl}}" target="_blank">
                                                                {{source.sourceName}}
                                                            </a>
                                                            <span *ngIf="source.sourceUrl === null || source.sourceUrl === ''">{{source.sourceName}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-12 col-sm-12" *ngIf="survey.surveyTypeId === 0 || screenWidth >= 1200">
                                        <div *ngIf="screenWidth >= 1200">
                                            <img *ngIf="survey.imgUrl !== null" id="photo" style="width: 100%" alt="{{survey.alternateText}}" class="img-responsive shadow rounded-corners margin-bottom2pr" [src]="survey.imgUrl" />
                                        </div>
                                        <div *ngIf="survey.surveyTypeId === 0" class="margin-top-40 margin-bottom-20 text-center nowrap">
                                            <h3 class="font-media-h1 font-dark bold margin-bottom2pr">{{l("Signings")}}</h3>
                                            <div class="flex-container">
                                                <span class="fa-stack">
                                                    <span class="fa fa-heart font-purple fa-stack-6x"></span>
                                                    <strong class="fa-stack-1x heart-value font-white">
                                                        {{survey.petitionSignings}}
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="survey.surveyTypeId === 0 && latestSignings.length > 0" class="font-dark bold text-center" style="margin-top: 60px">

                                            <table class="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th style="width:40px"> # </th>
                                                        <th class="font-media-h3 bold font-purple"> {{l('LatestFive')}}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let signing of latestSignings; let i=index">
                                                        <td align="left"> {{i + 1}} </td>
                                                        <td align="left"><span *ngIf="!signing.isAnonymous">{{ signing.displayName }}</span>  <span *ngIf="signing.isAnonymous && signing.gender === 1">{{l('Man')}}</span><span *ngIf="signing.isAnonymous && signing.gender === 2">{{l('Woman')}}</span> ({{ getAge(signing.birthYear )}})<span *ngIf="signing.subAreaName !== null && signing.subAreaName !== ''">, {{ signing.subAreaName }} </span> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row"></div>
                                </div>
                            </div>
                            <div class="margin-top-30">
                                <!--Start accordion-->
                                <div class="panel-group accordion" id="accordionDashboard">
                                    <div class="panel panel-default" *ngIf="survey.surveyTypeId === 0">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle font-white bold" (click)="togglePanelBackground()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Background" aria-expanded="true">
                                                    <i class="fa fa-heart" aria-hidden="true"></i> <i class="fa fa-heart-o margin-left-5" aria-hidden="true"></i> <span class="margin-left-10"> {{l('Signings')}}</span>
                                                    <i *ngIf="!pnlExpandBackground" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                    <i *ngIf="pnlExpandBackground" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_Background" class="panel-collapse collapse" aria-expanded="false" style="">
                                            <div class="panel-body bg-grey-steel">
                                                <div class="portlet light" *ngIf="survey.surveyTypeId === 0 && isUserLoggedIn && hasVoted">
                                                    <div class="margin-top-20 font-white  bold">
                                                        <h3 class="font-media-h2 bold">{{l("RegretSigning")}}</h3>
                                                        <div class="form-group">
                                                            <label class="font-dark">{{l("RegretSigningInfo")}}</label>
                                                        </div>
                                                        <div class="margin-top-30">
                                                            <button type="button" (click)="undoSigning()" class="btn btn-circle red btn-outline">{{l("UndoSigning")}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="portlet light" *ngIf="survey.surveyTypeId === 0">
                                                    <div class="portlet-body">
                                                        <h2 class="margin-top-20 font-media-h2 bold font-purple">
                                                            {{l("AllSignings")}}
                                                        </h2>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div *ngFor="let signing of allSignings; let i = index" (deferLoad)="signing.show = true">
                                                                <div class="template-margin" [ngClass]="{'col-lg-4 col-md-4 col-sm-5 col-xs-12':screenWidth < 1500, 'col-lg-3':screenWidth >= 1500}" *ngIf="signing.show">
                                                                    <signing-template [signing]="signing"></signing-template>
                                                                </div>
                                                                <div [ngClass]="{'row  hidden-sm hidden-xs':((i+1) % 3 === 0)}" *ngIf="screenWidth < 1500 && i > 0"></div>
                                                                <div [ngClass]="{'row hidden-md hidden-sm hidden-xs':((i+1) % 4 === 0)}" *ngIf="screenWidth >= 1500 && i > 0"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="container">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default" *ngIf="showPoliticalPosisions">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle font-white bold" (click)="togglePanelPoliticalPosisions()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_PoliticalPosisions" aria-expanded="true">
                                                    <i class="icon-badge" aria-hidden="true"></i> <span class="margin-left-30">{{l('PoliticalPosision')}} </span>
                                                    <i *ngIf="!pnlExpandPoliticalPosisions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                    <i *ngIf="pnlExpandPoliticalPosisions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_PoliticalPosisions" class="panel-collapse collapse" aria-expanded="false" style="">
                                            <div class="panel-body bg-white">
                                                <political-posisions [survey]="survey" [screenWidth]="screenWidth"></political-posisions>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}" (click)="togglePanelArticles()">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle collapsed font-white bold" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Articles" aria-expanded="false">
                                                    <i class="icon-book-open" aria-hidden="true"></i><i class="icon-film margin-left-5" aria-hidden="true"></i>
                                                    <span class="margin-left-10">{{l('RelatedArticles')}}</span><span style="padding-left: 7px">({{ articlesCount }})</span> <i *ngIf="!pnlExpandArticles" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandArticles" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_Articles" class="panel-collapse collapse" aria-expanded="false">
                                            <div class="panel-body bg-grey-steel">
                                                <related-articles [isUserLoggedIn]='isUserLoggedIn' [survey]="survey" [screenWidth]='screenWidth' (articlesCountEmitter)="setArticlesCountEmitter($event)"></related-articles>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default" *ngIf="survey.useProsAndCons">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelProsCons()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_3" aria-expanded="false">
                                                    <i class="fa fa-search-plus" aria-hidden="true"></i><i class="fa fa-search-minus margin-left-5" aria-hidden="true"></i>
                                                    <span class="margin-left-15">
                                                        <span *ngIf="survey.forAndAgainstTextId === 0">{{l('ProsAndCons')}}</span>
                                                        <span *ngIf="survey.forAndAgainstTextId > 0">{{l('ArgumenterForAndAgainst')}}</span>
                                                        <span style="padding-left: 7px">({{ prosConsCount }})</span>
                                                    </span>
                                                    <i *ngIf="!pnlExpandProsCons" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                    <i *ngIf="pnlExpandProsCons" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_3" class="panel-collapse collapse" aria-expanded="false">
                                            <div class="panel-body bg-grey-steel">
                                                <pros-cons [isUserLoggedIn]='isUserLoggedIn' [survey]="survey" [screenWidth]="screenWidth" (prosConsCountEmitter)="setProsConsCountEmitter($event)"></pros-cons>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default" *ngIf="survey.surveyTypeId > 0 || isUserLoggedIn">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelPoll()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_survey" aria-expanded="false">
                                                    <i class="fa fa-line-chart" aria-hidden="true" *ngIf="survey.surveyTypeId > 0"></i><i class="fa fa-info" aria-hidden="true" *ngIf="survey.surveyTypeId === 0"></i>
                                                    <span *ngIf="survey.surveyTypeId > 0" class="margin-left-28"> {{l('TheSurvey')}}</span><span *ngIf="survey.surveyTypeId === 0" class="margin-left-36"> {{l('Metadata')}}</span> <i *ngIf="!pnlExpandPoll" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandPoll" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_survey" class="panel-collapse collapse" [ngClass]="{'in':survey.surveyTypeId > 0 }" aria-expanded="true">
                                            <div class="panel-body bg-grey-steel">
                                                <div *ngIf="survey.surveyTypeId > 0 && isUserLoggedIn && !vote.isAnonymous">
                                                    <div class="text-center">{{l('Anonymization')}}</div>
                                                    <div class="text-center margin-top-10"><button class="btn-no-style btn-no-border" (click)="toggleAnonymousControls()"><i class="fa fa-2x" [ngClass]="{'fa-arrow-circle-down':!showAnonymousControls, 'fa-arrow-circle-up':showAnonymousControls }"></i></button></div>
                                                    <div class="form-group" *ngIf="showAnonymousControls">
                                                        <div class="alert alert-success font-dark">
                                                            <p><span class="bold">{{l('PartiallyAnonymous')}}: </span> {{l('PartiallyAnonymousInfo')}}</p>
                                                        </div>
                                                        <div class="alert alert-info font-dark">
                                                            <p><span class="bold">{{l('TotallyAnonymous')}}: </span> {{l('TotallyAnonymous1')}} <br />{{l('TotallyAnonymous2')}}</p>
                                                        </div>
                                                        <div class="md-radio-inline">
                                                            <div class="md-radio font-green">
                                                                <input id="anonymousTypePartially" name="anonymousTypePartially" class="md-radiobtn" [(ngModel)]="survey.isAnonymous" [value]=false type="radio">
                                                                <label for="anonymousTypePartially">
                                                                    <span class="inc"></span>
                                                                    <span class="check"></span>
                                                                    <span class="box"></span>
                                                                    {{l("PartiallyAnonymous")}}
                                                                </label>
                                                            </div>
                                                            <div class="md-radio font-blue">
                                                                <input id="anonymousTypeTotally" name="anonymousTypeTotally" class="md-radiobtn" [(ngModel)]="survey.isAnonymous" [value]=true type="radio">
                                                                <label for="anonymousTypeTotally">
                                                                    <span class="inc"></span>
                                                                    <span class="check"></span>
                                                                    <span class="box"></span>
                                                                    {{l("TotallyAnonymous")}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <poll-voting *ngIf="survey.surveyTypeId > 0" [survey]="survey" [screenWidth]="screenWidth" [isUserLoggedIn]="isUserLoggedIn" [hasVoted]="hasVoted" [vote]="vote" [gradientExpr]="gradientExpr" [surveyContainerHeight]="surveyContainerHeight" (hasSigned)="signing()" (hasUnsigned)="unSigning()" (hasRanked)="onImportanceRankingChange(vote)" (isVoted)="onVoteChange(vote)">
                                                </poll-voting>
                                                <div class="portlet light" *ngIf="survey.surveyTypeId > 0">
                                                    <div class="portlet-title">
                                                        <i class="fa fa-area-chart" [ngStyle]="{'color': categoryColor}"></i>
                                                        <span class="caption-subject bold uppercase" [ngStyle]="{'color': categoryColor}"> {{l("Statistics")}} </span>
                                                        <div class="bar-lock-container" *ngIf="survey.participants > 0">
                                                            <div *ngIf="!survey.unlocked" style="filter: brightness(130%)"><span class="key-bar" [ngStyle]="{'width': survey.unlockPercentage !== '0 %'? survey.unlockPercentage : '0px'}"><i class="fa fa-key pull-right key-icon"></i></span><span class="pull-right"><i class="fa fa-lock"></i></span></div>
                                                            <div *ngIf="survey.unlocked" style="filter: brightness(130%)"><div class="key-bar-full"><span class="pull-right"><i class="fa fa-unlock"></i></span></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="portlet-body">
                                                        <div *ngIf="chartData !== null && ((isUserLoggedIn &&  hasVoted) || survey.unlocked)">
                                                            <dashboard-rating-bars [chartDataFromParent]="chartData" [surveyTypeId]="survey.surveyTypeId"></dashboard-rating-bars>
                                                        </div>
                                                        <div *ngIf="anonymousChartData !== null && !isUserLoggedIn && anonymousVoteSaved && survey.surveyTypeId > 0 && !survey.unlocked">
                                                            <anonymous-voting-charts [chartDataFromParent]="anonymousChartData" [surveyTypeId]="survey.surveyTypeId"></anonymous-voting-charts>
                                                        </div>
                                                    </div>
                                                </div>

                                                <meta-data [survey]="survey" [isUserLoggedIn]='isUserLoggedIn' [screenWidth]="screenWidth"></meta-data>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                            <h2 class="panel-title">
                                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelRelated()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_RelatedSurveys" aria-expanded="false">
                                                    <i class="fa fa-files-o" aria-hidden="true"></i>
                                                    <span class="margin-left-36">{{l('RelatedSurveys')}}</span> <i *ngIf="!pnlExpandRelated" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandRelated" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapse_RelatedSurveys" class="panel-collapse collapse" aria-expanded="false">
                                            <div class="portlet-body bg-white">
                                                <nav *ngIf="isUserLoggedIn" class="navbar">
                                                    <div class="container-fluid">
                                                        <div class="col-lg-12 col-md-12 col-sm-12  margin-top-15 margin-bottom-15">
                                                            <a href="javascript:;" class="btn btn-circle btn-md grey-mint" (click)="surveyForm()">
                                                                <i class="fa fa-pencil-square-o"></i> {{l('CreateARelatedSurvey')}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </nav>

                                                <div *ngFor="let relatedPoll of relatedSurveys; let i=index;">
                                                    <div class="template-margin col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                        <survey-template [inputSurvey]='relatedPoll' [inputCountry]='relatedPoll.countryName' [inputIsUserLoggedIn]='isUserLoggedIn'></survey-template>
                                                    </div>
                                                    <div [ngClass]="{'row': ((i+1) % 2 === 0)}"></div>
                                                </div>
                                                <div class="font-dark font-italic font-media-h4" *ngIf="!isUserLoggedIn || relatedSurveys.length === 0">
                                                    <span *ngIf="relatedSurveys.length === 0" class="bold">{{l('NoRelatedSurveys')}}<br /></span>
                                                    <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreateSurvey')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="margin-top-15 margin-bottom-15 margin-left-15 shadow">
                            <button class="btn-no-style font-white" (click)="close()" [attr.aria-label]="strClose"><i class="fa fa-2x fa-close"></i></button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </main>

    <main *ngIf="screenWidth < 768">
        <div [ngStyle]="{'background-color': categoryColor}">
            <header class="panel-title" style="padding:10px">
                <div class="margin-bottom2pr">
                    <span class="actions pull-right font-white">
                        <button class="btn-no-style font-white" (click)="close()" [attr.aria-label]="strClose"><i class="fa fa-2x fa-close"></i></button>
                    </span>
                    <div *ngIf="survey.surveyTypeId === 0" class="bold uppercase font-sm font-white">{{l('Petition')}} <i class="fa fa-bullhorn margin-left-5"></i></div>
                    <h1 class="caption-subject bold uppercase font-white" [ngClass]="{'font-media-h3':survey.surveyTypeId > 0, 'font-media-h2':survey.surveyTypeId === 0 }">
                        <span *ngIf="survey.surveyTypeId > 0">{{survey.displayName}}</span><span *ngIf="survey.surveyTypeId === 0">{{survey.displayQuestion}}</span>
                    </h1>
                    <span class="font-white font-media-h3">{{ survey.creationTimeString}}</span>
                </div>
                <div class="margin-top-15 margin-bottom-15 font-white">
                    <span class="btn" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.categoryName}}</span>
                    <span *ngIf="survey.subCategoryName !== null" class="btn margin-left-20" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.subCategoryName}}</span>
                </div>
                <div>
                    <img *ngIf="survey.imgUrl !== null" id="photo" style="width: 100%" alt="{{survey.alternateText}}" class="img-responsive shadow rounded-corners border-white margin-bottom2pr" [src]="survey.imgUrl" />
                    <div class="portlet light">
                        <div class="portlet-body">
                            <div *ngIf="survey.surveyTypeId === 0">
                                <div class="container-max-width" *ngIf="!showRegisterPanel">
                                    <p [innerHTML]="shortDescription" class="article-text bold">
                                    </p>
                                    <p *ngIf="showContentDescription" [innerHTML]="contentDescription"></p>
                                    <div class="margin-bottom-5 text-center" *ngIf="validContentDescription">
                                        <button type="button" id="btnShowAllContent" (click)="toggleContent()" [ngClass]="{'btn btn-circle default':!toggleShortConent,'btn-no-style': toggleShortConent }"><span *ngIf="!toggleShortConent">{{l("ReadMore")}}</span><span *ngIf="toggleShortConent"><i class="fa fa-arrow-circle-up"></i></span></button>
                                    </div>
                                </div>
                                <div *ngIf="showRegisterPanel">
                                    <div *ngIf="isUserLoggedIn">
                                        <div class="text-center margin-top4pr margin-bottom-40" *ngIf="isUserLoggedIn && !hasVoted">
                                            <button type="button" (click)="signPetition()" class="btn btn-lg btn-circle" [ngClass]="{'btn-default':!isUserLoggedIn, 'btn-success margin-top-30':isUserLoggedIn}" [disabled]="!isUserLoggedIn">{{l("Sign")}}</button>
                                        </div>
                                        <div class="margin-top-20 font-dark bold text-center font-media-h2" *ngIf="hasVoted">
                                            {{l("SigningRegistered")}}! <span class="font-purple"><i class="fa fa-thumbs-up"></i></span>
                                        </div>
                                    </div>
                                    <petition-registration *ngIf="!isUserLoggedIn" [survey]="survey" (isSigned)='onSigningAdded($event)' [screenWidth]="screenWidth"></petition-registration>
                                </div>
                            </div>
                            <div *ngIf="survey.surveyTypeId > 0">
                                <div class="container-max-width">
                                    <p [innerHTML]="survey.displayDescription" class="article-text">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="margin-top-40 margin-bottom-20 font-media-h1 font-white text-center nowrap" *ngIf="survey.surveyTypeId === 0 && !showRegisterPanel">
                        <h3 class="font-media-h1 font-white bold">{{l("Signings")}}</h3>

                        <!-- Create an icon wrapped by the fa-stack class -->
                        <div class="flex-container">
                            <span class="fa-stack">
                                <!-- The icon that will wrap the number -->
                                <span class="fa fa-heart font-purple fa-stack-6x"></span>
                                <!-- a strong element with the custom content, in this case a number -->
                                <strong class="fa-stack-1x heart-value font-white">
                                    {{survey.petitionSignings}}
                                </strong>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="survey.surveyTypeId === 0 && latestSignings.length > 0" class="font-dark bold text-center bg-white" style="margin-top: 60px">

                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th class="font-media-h3 font-purple bold" style="width:40px"> # </th>
                                    <th class="font-media-h3 font-purple bold"> {{l('LatestFive')}}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let signing of latestSignings; let i=index">
                                    <td align="left"> {{i + 1}} </td>
                                    <td align="left"><span *ngIf="!signing.isAnonymous">{{ signing.displayName }}</span>  <span *ngIf="signing.isAnonymous && signing.gender === 1">{{l('Man')}}</span><span *ngIf="signing.isAnonymous && signing.gender === 2">{{l('Woman')}}</span> ({{ getAge(signing.birthYear )}})<span *ngIf="signing.subAreaName !== null && signing.subAreaName !== ''">, {{ signing.subAreaName }} </span> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </header>

            <div class="margin-top-30" *ngIf="!showRegisterPanel">
                <div class="panel-group accordion" id="accordionDashboard">
                    <div class="panel panel-default" *ngIf="survey.surveyTypeId === 0">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle font-white bold" (click)="togglePanelBackground()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Background" aria-expanded="true">
                                    <i class="fa fa-heart" aria-hidden="true"></i> <i class="fa fa-heart-o margin-left-5" aria-hidden="true"></i>
                                    <span class="margin-left-15"> {{l('Signings')}}</span>
                                    <i *ngIf="!pnlExpandBackground" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandBackground" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_Background" class="panel-collapse collapse" aria-expanded="false" style="">
                            <div class="portlet light" *ngIf="survey.surveyTypeId === 0">
                                <div class="portlet-body" style="padding:20px">
                                    <div class="container">
                                        <div *ngFor="let signing of allSignings; let i = index" (deferLoad)="signing.show = true">
                                            <div class="template-margin col-sm-5 col-xs-12" *ngIf="signing.show">
                                                <signing-template [signing]="signing"></signing-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default" *ngIf="showPoliticalPosisions">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle font-white bold" (click)="togglePanelPoliticalPosisions()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_PoliticalPosisions" aria-expanded="true">
                                    <i class="icon-badge" aria-hidden="true"></i> <span class="margin-left-40">{{l('PoliticalPosision')}} </span>
                                    <i *ngIf="!pnlExpandPoliticalPosisions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                    <i *ngIf="pnlExpandPoliticalPosisions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_PoliticalPosisions" class="panel-collapse collapse" aria-expanded="false" style="">
                            <div class="portlet light">
                                <div class="panel-body">
                                    <political-posisions [survey]="survey" [screenWidth]="screenWidth"></political-posisions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelArticles()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Articles" aria-expanded="false">
                                    <i class="icon-book-open" aria-hidden="true"></i><i class="icon-film margin-left-5" aria-hidden="true"></i>
                                    <span class="margin-left-15"> {{l('RelatedArticles')}}</span><span>({{ articlesCount }})</span> <i *ngIf="!pnlExpandArticles" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandArticles" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_Articles" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                            <div class="panel-body bg-grey-steel">
                                <related-articles [isUserLoggedIn]='isUserLoggedIn' [survey]="survey" (articlesCountEmitter)="setArticlesCountEmitter($event)"></related-articles>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default" *ngIf="survey.useProsAndCons">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelProsCons()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_3" aria-expanded="false">
                                    <i class="fa fa-search-plus" aria-hidden="true"></i><i class="fa fa-search-minus margin-left-5" aria-hidden="true"></i>
                                    <span class="margin-left-22">
                                        <span *ngIf="survey.forAndAgainstTextId === 0">{{l('ProsAndCons')}}</span>
                                        <span *ngIf="survey.forAndAgainstTextId > 0">{{l('ArgumenterForAndAgainst')}}</span>
                                        <span style="padding-left: 7px">({{ prosConsCount }})</span>
                                    </span>
                                    <i *ngIf="!pnlExpandProsCons" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                    <i *ngIf="pnlExpandProsCons" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_3" class="panel-collapse collapse" aria-expanded="false">

                            <pros-cons [isUserLoggedIn]='isUserLoggedIn' [survey]="survey" [screenWidth]="screenWidth" (prosConsCountEmitter)="setProsConsCountEmitter($event)"></pros-cons>

                        </div>
                    </div>
                    <div class="panel panel-default" *ngIf="survey.surveyTypeId > 0 || isUserLoggedIn">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelPoll()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_survey" aria-expanded="false">
                                    <i class="fa fa-line-chart" aria-hidden="true" *ngIf="survey.surveyTypeId > 0"></i><i class="fa fa-info" aria-hidden="true" *ngIf="survey.surveyTypeId === 0"></i>
                                    <span class="margin-left-32" *ngIf="survey.surveyTypeId > 0"> {{l('TheSurvey')}}</span>
                                    <span class="margin-left-32" *ngIf="survey.surveyTypeId === 0"> {{l('Metadata')}}</span>
                                    <i *ngIf="!pnlExpandPoll" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                    <i *ngIf="pnlExpandPoll" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_survey" class="panel-collapse collapse" [ngClass]="{'in':survey.surveyTypeId > 0 }" aria-expanded="true">
                            <div *ngIf="survey.surveyTypeId > 0 && isUserLoggedIn && !vote.isAnonymous">
                                <div class="text-center">{{l('Anonymization')}}</div>
                                <div class="text-center margin-top-10"><button class="btn-no-style btn-no-border" (click)="toggleAnonymousControls()"><i class="fa fa-2x" [ngClass]="{'fa-arrow-circle-down':!showAnonymousControls, 'fa-arrow-circle-up':showAnonymousControls }"></i></button></div>
                                <div class="form-group" *ngIf="showAnonymousControls">
                                    <div class="alert alert-success font-dark">
                                        <p><span class="bold">{{l('PartiallyAnonymous')}}: </span> {{l('PartiallyAnonymousInfo')}}</p>
                                    </div>
                                    <div class="alert alert-info font-dark">
                                        <p><span class="bold">{{l('TotallyAnonymous')}}: </span> {{l('TotallyAnonymous1')}} <br />{{l('TotallyAnonymous2')}}</p>
                                    </div>
                                    <div class="md-radio-inline">
                                        <div class="md-radio font-green">
                                            <input id="anonymousTypePartially" name="anonymousTypePartially" class="md-radiobtn" [(ngModel)]="survey.isAnonymous" [value]=false type="radio">
                                            <label for="anonymousTypePartially">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box"></span>
                                                {{l("PartiallyAnonymous")}}
                                            </label>
                                        </div>
                                        <div class="md-radio font-blue">
                                            <input id="anonymousTypeTotally" name="anonymousTypeTotally" class="md-radiobtn" [(ngModel)]="survey.isAnonymous" [value]=true type="radio">
                                            <label for="anonymousTypeTotally">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box"></span>
                                                {{l("TotallyAnonymous")}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="survey.surveyTypeId > 0">
                                <poll-voting [survey]="survey" [screenWidth]="screenWidth" [isUserLoggedIn]="isUserLoggedIn" [hasVoted]="hasVoted" [vote]="vote" [gradientExpr]="gradientExpr" [surveyContainerHeight]="surveyContainerHeight" (hasSigned)="signing()" (hasUnsigned)="unSigning()" (hasRanked)="onImportanceRankingChange(vote)" (isVoted)="onVoteChange(vote)">
                                </poll-voting>
                                <div class="portlet light">
                                    <div class="portlet-title">
                                        <i class="fa fa-area-chart" [ngStyle]="{'color': categoryColor}"></i>
                                        <span class="caption-subject bold uppercase" [ngStyle]="{'color': categoryColor}"> {{l("Statistics")}}</span>
                                        <div class="bar-lock-container" *ngIf="survey.participants > 0">
                                            <div *ngIf="!survey.unlocked" style="filter: brightness(130%)"><span class="key-bar" [ngStyle]="{'width': survey.unlockPercentage !== '0 %'? survey.unlockPercentage : '0px'}"><i class="fa fa-key pull-right key-icon"></i></span><span class="pull-right"><i class="fa fa-lock"></i></span></div>
                                            <div *ngIf="survey.unlocked" style="filter: brightness(130%)"><div class="key-bar-full"><span class="pull-right"><i class="fa fa-unlock"></i></span></div></div>
                                        </div>
                                    </div>
                                    <div class="portlet-body">
                                        <div *ngIf="chartData !== null && ((isUserLoggedIn &&  hasVoted) || survey.unlocked)">
                                            <dashboard-rating-bars [chartDataFromParent]="chartData" [surveyTypeId]="survey.surveyTypeId">
                                            </dashboard-rating-bars>
                                        </div>
                                        <div *ngIf="anonymousChartData !== null && !isUserLoggedIn && anonymousVoteSaved && survey.surveyTypeId > 0 && !survey.unlocked">
                                            <anonymous-voting-charts [chartDataFromParent]="anonymousChartData" [surveyTypeId]="survey.surveyTypeId"></anonymous-voting-charts>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <meta-data [survey]="survey" [isUserLoggedIn]='isUserLoggedIn' [screenWidth]="screenWidth"></meta-data>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                            <h2 class="panel-title">
                                <a class="accordion-toggle collapsed font-white bold" (click)="togglePanelRelated()" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_RelatedSurveys" aria-expanded="false">
                                    <i class="fa fa-files-o" aria-hidden="true"></i>
                                    <span class="margin-left-32"> {{l('RelatedSurveys')}}</span> <i *ngIf="!pnlExpandRelated" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i><i *ngIf="pnlExpandRelated" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i>
                                </a>
                            </h2>
                        </div>
                        <div id="collapse_RelatedSurveys" class="panel-collapse collapse" aria-expanded="false">
                            <div class="portlet-body bg-white">
                                <nav *ngIf="isUserLoggedIn" class="navbar">
                                    <div class="container-fluid">
                                        <div class="col-lg-6 col-md-12 margin-top-20 margin-bottom-15">
                                            <a href="javascript:;" class="btn btn-circle btn-md grey-mint" (click)="surveyForm()">
                                                <i class="fa fa-pencil-square-o"></i> {{l('CreateARelatedSurvey')}}
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                                <div class="container">
                                    <div class="row">
                                        <div *ngFor="let relatedPoll of relatedSurveys; let i=index;">
                                            <div class="template-margin col-sm-6 col-xs-12">
                                                <survey-template [inputSurvey]='relatedPoll' [inputCountry]='relatedPoll.countryName' [inputIsUserLoggedIn]='isUserLoggedIn'></survey-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert font-dark" *ngIf="!isUserLoggedIn || relatedSurveys.length === 0">
                                    <span *ngIf="relatedSurveys.length === 0" class="font-media-h3 bold">{{l('NoRelatedSurveys')}}<br /></span>
                                    <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreateSurvey')}}</span>
                                    <span *ngIf="!isUserLoggedIn">{{l('LoginCreateARelatedSurvey')}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="container shadow" style="height:40px">
                <button type="button" [ngStyle]="{'background-color': tagColor}" class="btn btn-xs font-white" (click)="close()"><i class="fa fa-close"></i> {{l("Close")}}</button>
            </div>
        </div>
    </main>

    <div class="note note-success col-lg-7 col-md-12 col-sm-12 margin-top-20" style="max-width:700px" *ngIf="!survey.name">
        {{l('ThisSurveyIsNotAvailable')}}
    </div>

</div>
<div [ngClass]="{'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}" *ngIf="!active" style="margin-top:25%;margin-bottom:15%">
    <div class="col-sm-offset-5 col-xs-offset-5" [ngClass]="{'col-lg-offset-4 col-md-offset-4':isUserLoggedIn, 'col-lg-offset-5 col-md-offset-5':!isUserLoggedIn}">
        <i class="fa fa-spinner fa-spin font-white" style="font-size:94px;"></i>
    </div>
</div>
<shareModal #shareModal></shareModal>
<div class="footer" *ngIf="showFooter">
    <div [ngClass]="{'margin-top-40':screenWidth > 767, 'margin-top-20':  screenWidth < 768}">

        <div *ngIf="survey.surveyTypeId === 0">
            <button type="button" *ngIf="!hasVoted" class="btn btn-lg" [ngClass]="{'red':showRegisterPanel, 'purple':!showRegisterPanel}" (click)="toggleRegisterPanel()"><span *ngIf="!showRegisterPanel"><i class="fa fa-pencil"></i> {{l('SignPetition')}}</span><span *ngIf="showRegisterPanel">{{l('Cancel')}}</span></button>

            <div class="font-white bold text-center" [ngClass]="{'font-media-h1':screenWidth > 767, 'font-media-h2':  screenWidth < 768}" *ngIf="hasVoted">
                {{l("ThanksForYourSigning")}}! <span class="font-purple"><i class="fa fa-heart"></i></span>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked) && survey.surveyTypeId !== 0">
            <div class="pull-right margin-right-20">
                <div><button class="btn-no-style font-yellow-warning" (click)="closeFooter()" [attr.aria-label]="strClose"><i class="fa fa-2x fa-close"></i></button></div>
            </div>
            <div class="font-yellow-warning text-center font-media-h2 uppercase bold">
                {{l("LoginToVote")}}!
            </div>
        </div>
    </div>
</div>

