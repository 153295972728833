import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { HelpComponent } from 'public/help/help.component';
import { AboutComponent } from 'public/about/about.component';
import { ContactComponent } from 'public/contact/contact.component';
import { TermsComponent } from 'public/terms/terms.component';
import { PrivacyComponent } from 'public/privacy/privacy.component';
import { CookiesComponent } from 'public/cookies/cookies.component';
import { SiteComponent } from 'public/site/site.component';
import { PossibilitiesComponent } from 'public/possibilities/possibilities.component';

import { ArticleComponent } from "public/article/article.component";
import { CategoryPageComponent } from "public/category/category-page.component";
import { SurveyDashboardComponent } from "public/survey-dashboard/survey-dashboard.component";
import { RootModule } from '../root.module';
import { Route } from '@angular/compiler/src/core';


@NgModule({
    imports: [
        RouterModule.forChild([
            { path: '', component: SiteComponent },
            { path: 'site', component: SiteComponent },
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/site', pathMatch: 'full' }
                        ]
                    },
                    { path: 'main/about', component: AboutComponent },
                    { path: 'main/contact', component: ContactComponent },
                    { path: 'main/help', component: HelpComponent },
                    { path: 'main/terms', component: TermsComponent },
                    { path: 'main/privacy', component: PrivacyComponent },
                    { path: 'main/cookies', component: CookiesComponent },
                    { path: 'main/possibilities', component: PossibilitiesComponent },
                    { path: 'main/site', redirectTo: 'main/site/country/norway', pathMatch: 'full' },
                    { path: 'main/site/global', component: SiteComponent },
                    { path: 'main/site/:areaDef/:country', component: SiteComponent },
                    { path: 'main/site/:areaDef/:country/:area', component: SiteComponent },
                    { path: 'main/site/:areaDef/:country/:area/:subArea', component: SiteComponent },
                    { path: 'main/category/:categoryName/global', component: CategoryPageComponent },
                    { path: 'main/category/:categoryName/:areaDef/:country', component: CategoryPageComponent },
                    { path: 'main/category/:categoryName/:areaDef/:country/:area', component: CategoryPageComponent },
                    { path: 'main/category/:categoryName/:areaDef/:country/:area/:subArea', component: CategoryPageComponent },
                    { path: 'main/surveyboard/:country/:id', component: SurveyDashboardComponent },
                    { path: 'main/article/:id/:pollId/:countryId', component: ArticleComponent },

                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then(m => m.MainModule), //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: { preload: true }
                    }
                ]
            }
        ])
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
