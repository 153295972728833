
<div class="container" >
    <div class="owl-carousel">
        <div> Your Content A </div>
        <div> Your Content B</div>
        <div> Your Content C</div>
        <div> Your Content D</div>
        <div> Your Content E</div>
        <div> Your Content F</div>
        <div> Your Content G</div>
    </div>

</div>

