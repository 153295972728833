<div [@routerTransition] style="max-width: 1400px">
    <div class="portlet-title" *ngIf="!hideHeader">
        <h1 class="font-dark uppercase font-media-h1" *ngIf="!isRegistering">{{l('UserAgreement')}}</h1>
        <label class="hint uppercase font-purple" *ngIf="isRegistering">{{l('UserAgreement')}}</label>
    </div>
    <div [ngClass]="{'container margin-left-10': !hideTermsContent && screenWidth > 659}">
        <div class="hint font-dark bold" [ngClass]="{'margin-right-10': !hideTermsContent && screenWidth > 659}"  *ngIf="isRegistering">
            {{l('UserServiceAgreement')}}
        </div>
        <div class="margin-top-20">
            <div  [ngClass]="{'margin-right-10': !hideTermsContent && screenWidth > 659}" *ngIf="isRegistering">
                {{l("TermsInfo")}}
            </div>

            <div class="margin-top-20 form-group " *ngIf="hideTermsContent">
                <a href="javascript;" class="font-dark link-underline bold font-media-h4" data-toggle="modal" data-target="#termsModal" (click)="termsModal.show()">
                    {{l("Read")}} {{l("TheUserAgreement")}}
                </a>
            </div>

            <div class="form-group" *ngIf="isUserLoggedIn && showApproveButton">
                <input id="forApproval" class="md-check checkbox-lg" type="checkbox" name="ForApproval" [ngClass]="{'edited':forApproval}"
                       [(ngModel)]="forApproval">
                <label for="forApproval">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    <span style="margin-left:10px" [ngClass]="{'font-media-h3': screenWidth > 620,'font-media-h4': screenWidth > 380 && screenWidth < 621, 'font-media-h5': screenWidth < 381 }">{{l("ApproveAgreement1")}}</span>
                </label>
            </div>

            <div class="form-group" *ngIf="isUserLoggedIn && showApproveButton" [ngStyle]="{'font-size: medium': screenWidth > 380 && screenWidth < 720, 'font-size: small': screenWidth < 381}">
                <div class="form-group margin-top-20">
                    <button type="button"
                            [disabled]="!forApproval"
                            (click)="saveApproval()"
                            class="btn btn-circle btn-lg"
                            [ngClass]="{'btn-success':forApproval, 'btn-default': !forApproval}"
                            [buttonBusy]="saving"
                            [busyText]="l('SavingWithThreeDot')">
                        <i class="fa fa-save"></i>
                        <span [ngClass]="{'font-media-h4':screenWidth < 720 }">{{l("ApproveAgreement2")}}</span>
                    </button>
                </div>
            </div>
            <terms-template *ngIf="!hideTermsContent"></terms-template>
        </div>
    </div>
</div>
<termsModal #termsModal></termsModal>

