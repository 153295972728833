import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BlogArticleOutput, BlogArticleOutputStatus, MediaArticleListOutput, MediaArticleListOutputStatus} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';


@Component({
  selector: 'article-template',
    templateUrl: './article-template.component.html',
  animations: [appModuleAnimation()]
})
export class ArticleTemplateComponent extends AppComponentBase implements OnInit {
    @Input() inputArticle: BlogArticleOutput;

    public active: boolean = false;
    public saving: boolean = false;
    public article: MediaArticleListOutput;
    public blogArticle: BlogArticleOutput;
    isUserLoggedIn: boolean = false;


    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.article = new MediaArticleListOutput();
        this.article.title = this.inputArticle.title;
        this.blogArticle = this.inputArticle;
        this.article.description = this.inputArticle.preamble;
        this.article.creationTime = this.inputArticle.creationTime;
        this.article.pollId = this.inputArticle.pollId;
        this.article.author = this.inputArticle.authorName;
        this.article.published = this.inputArticle.published;
        this.article.id = this.inputArticle.id;
        this.article.imageUrl = this.inputArticle.imageUrl;
        this.article.iso = this.inputArticle.iso;
        this.article.alternateText = this.inputArticle.alternateText;
        this.article.articleUrl = "";
        this.article.status = MediaArticleListOutputStatus._2;
        if (this.inputArticle.status.toString() === 'Draft') {
            this.article.status = MediaArticleListOutputStatus._1;
        }
        if (this.inputArticle.status.toString() === 'Dismissed') {
            this.article.status = MediaArticleListOutputStatus._5;
        }
        else if (this.inputArticle.status.toString() === 'Offline') {
            this.article.status = MediaArticleListOutputStatus._6;
        }
        else if (this.inputArticle.status.toString() === 'Online') {
            this.article.status = MediaArticleListOutputStatus._4;
        }
        this.article.mediaLogo = "/assets/common/images/favicon/apple-icon-57x57.png";
        this.article.mediaUrl = "www.opinions24.net";
        this.article.mediaName = "Opinions24.net";
        this.article.isVotecha = true;
        this.active = true;
    }
    

  }
