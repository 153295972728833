
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, Injector, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollServiceProxy, PollListDto,  } from '@shared/service-proxies/service-proxies';
import { MediaPollArticleServiceProxy, MediaCompanyOutput, MediaArticleDto, MediaArticleOutput } from '@shared/service-proxies/service-proxies';
import { LanguageServiceProxy, ApplicationLanguageListDto } from '@shared/service-proxies/service-proxies';
import { PoliticalPartyServiceProxy, PoliticalPartyDto, PoliticalTagDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'addArticleLinkModal',
    templateUrl: './add-article-link-modal.component.html'
})
export class AddArticleLinkModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

   @ViewChild('modal', { static: true }) modal: ModalDirective;
   @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

    active: boolean = false;
    saving: boolean = false;
    invalidArticle: boolean = false;
    filter: string = '';
    publish: boolean = false;
    articleLanguage: number;
    poll: PollListDto = new PollListDto();
    languages: ApplicationLanguageListDto[];
    medias: MediaCompanyOutput[];
    politicalParties: PoliticalPartyDto[];
    newArticle: MediaArticleDto;
    savedArticle: MediaArticleOutput;
    categoryName: string;
    subCategoryName: string;
    newLink: string;
    webSiteUrl: string;
    constructor(
        injector: Injector,
        private _politicalPartyService: PoliticalPartyServiceProxy,
        private _languageService: LanguageServiceProxy,
        private _mediaArticleService: MediaPollArticleServiceProxy
    ) {
        super(injector);
    }

    show(poll: PollListDto): void {
        this.newLink = '';
        this.invalidArticle = false;
        this.articleLanguage = poll.userLanguageId;
        this.newArticle = new MediaArticleDto();
        this.active = true;
        this.newLink = "";
        this.webSiteUrl = "https://";
        this.getLanguages();
        this.getPoll(poll);
        this.getPoliticalParties();
    }

    getLanguages(): void {
        this._languageService.getLanguages().subscribe((result) => {
            this.languages = result.items;
        });
    }

    getPoliticalParties(): void {
        if (this.poll.categoryId === 1 && this.poll.countryId > 0) {
            this._politicalPartyService.getCountryPoliticalPartys(this.poll.countryId).subscribe((result) => {
                this.politicalParties = result.items;
            });
        }        
    }

    getPoll(pollDto: PollListDto): void {
        this.poll= pollDto;
        this.categoryName = pollDto.categoryName;
        this.subCategoryName = pollDto.subCategoryName;
        this.modal.show();
    }

    addPoliticalParties(): void {
        if (this.poll.categoryId === 1 && this.politicalParties && this.politicalParties.length > 0) {
            this.newArticle.politicalParties = [];
            for (var i = 0; i < this.politicalParties.length; i++) {
                if (this.politicalParties[i].isSelected) {
                    const selectedParty: PoliticalTagDto = new PoliticalTagDto();
                    selectedParty.id = this.politicalParties[i].id;
                    selectedParty.logo = this.politicalParties[i].imgUrl;
                    selectedParty.name = this.politicalParties[i].name;
                    selectedParty.shortName = this.politicalParties[i].shortName;
                    this.newArticle.politicalParties.push(selectedParty);
                }
            }
        }
    }

    save(): void {
        if (this.newLink !== "" && this.newLink.length > 9) {
            this.newArticle.articleUrl = this.newLink;
            this.newArticle.pollId = this.poll.id;            
            this.newArticle.countryId = this.poll.countryId;
            this.newArticle.categoryId = this.poll.categoryId;
            this.newArticle.subCategoryId = this.poll.subCategoryId;            
            this.addPoliticalParties();
            this.saving = true;
            this._mediaArticleService.addMediaUrl(this.newArticle).subscribe((result) => {
                this.savedArticle = result;
                if (this.savedArticle.isSaved) {
                    this.savedSuccessfully();
                }
                else if (this.savedArticle.status === 3) {
                    this.invalidArticle = true;
                }
                else {
                    this.invalidArticle = true;
                }
                
            });
        }
        
    }

    savedSuccessfully(): void {
        this.saving = false;
        this.notify.info(this.l('SavedSuccessfully'));
        this.close();
        this.modalSave.emit();
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }
}
