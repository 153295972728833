import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto, VotechaChartDto, ChartItemDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'welcome-section',
    templateUrl: './welcome-section.component.html',
    styleUrls: ['./welcome-section.component.css'],
    animations: [appModuleAnimation()]
})
export class WelcomeSectionComponent extends AppComponentBase implements OnInit {

    @Input() screenWidth: number = 0;
    @Input() isUserLoggedIn: boolean = false;

    constructor(
        injector: Injector,
        private _router: Router,

    ) {
        super(injector);
    }

    ngOnInit() {        
    }


    goToAboutPage(): void {
        this._router.navigate(['public/about']);
    }

    goToTourPage(): void {
        this._router.navigate(['public/tour']);
    }
}
