import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { VotechaVoteServiceProxy, PollServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'political-page',
    templateUrl: './political-page.component.html',
  animations: [appModuleAnimation()]
})
export class PoliticalPageComponent extends AppComponentBase implements OnInit {
    @Input() politicalPolls: PollListDto[];    
    @Input() screenWidth: number = 0;
    @Input() inputIsUserLoggedIn: boolean = false;
    @Input() countryName: string = "";
    @Input() pagePath: string = "";

    public active: boolean = false;
    politicalPartiesPoll: PollListDto; 
    politicalTrustPoll: PollListDto;
    govermentTrustPoll: PollListDto;

    constructor(
        injector: Injector,

    ) {
        super(injector);
    }

    ngOnInit() {                   
        this.active = true;
        this.politicalPartiesPoll = this.politicalPolls.find(p => p.name === 'Politisk meningsmåling');
        this.politicalTrustPoll = this.politicalPolls.find(p => p.id === '920ecfee-26a3-420f-a472-3c4622de52d9');
        this.govermentTrustPoll = this.politicalPolls.find(p => p.id === 'a5268088-3eeb-458a-bb4a-1e58db36f63f');
        
    }

  }
