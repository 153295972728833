import { Component, Injector, Input, Output, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UserSettingsServiceProxy, PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'terms-content',
    templateUrl: './terms-content.component.html',
    animations: [appModuleAnimation()]
})
export class TermsContentComponent extends AppComponentBase implements OnInit {
    @Input() isRegistering: boolean;
    @Input() showApproveButton: boolean;
    @Input() hideTermsContent: boolean;
    @Input() hideHeader: boolean;

    public active: boolean = false;
    public forApproval: boolean = false;
    public saving: boolean = false;
    isUserLoggedIn: boolean = false;
    public screenWidth: number;

    constructor(
        injector: Injector,
        private _router: Router,
        private _userSettingsService: UserSettingsServiceProxy,
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.active = true;
    }


    saveApproval(): void {
        this._userSettingsService.approveTerms()
            .subscribe((result) => {
                var isNewUser = result;
                this.notify.info(this.l('SavedSuccessfully'));
                if (isNewUser) {
                    this._router.navigate(['app/main/welcome']);
                }
                else {
                    this._router.navigate(['app/main/mysite']);
                }
            });
    }
}
