import { Component, Injector, Input, Output, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';


@Component({
    selector: 'terms',
    templateUrl: './terms.component.html',
    animations: [appModuleAnimation()]
})
export class TermsComponent extends AppComponentBase implements OnInit {   
    public active: boolean = false;
    public forApproval: boolean = false;
    public saving: boolean = false;
    isUserLoggedIn: boolean = false;
    public screenWidth: number;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.active = true;
    }

}
