
<div class="actions" *ngFor="let entityItem of entityItems; let i=index">

    <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%">

        <div class="btn blue-steel btn-outline btn-circle btn-sm">
            <div class="hidden-xs">
                <table>
                    <tr>
                        <td class="col-xs-1 col-sm-1">
                            <span *ngIf="survey.feedbackType.toString() === 'SingleSelection'">
                                <input type="radio"
                                       name="answer"
                                       (change)="onVoteChange(answerId)"
                                       [(ngModel)]="answerId"
                                       [value]="entityItem.itemValue">
                            </span>
                           
                                
                                <div class="md-checkbox" *ngIf="survey.feedbackType.toString() === 'MultipleChoice'">
                                    <input id="entityItemSelected{{entityItem.itemValue}}"
                                           class="md-check" type="checkbox"
                                           name="isEntityItemSelected{{entityItem.itemValue}}"
                                           (change)="saveChoice(entityItem.multiAnswer)"
                                           [ngClass]="{'edited':entityItem.multiAnswer.isSelected}"
                                           [(ngModel)]="entityItem.multiAnswer.isSelected">
                                    <label for="entityItemSelected{{entityItem.itemValue}}">
                                        <span class="inc"></span>
                                        <span class="check"></span>
                                        <span class="box"></span>

                                    </label>
                                </div>
                        </td>
                        <td>
                            <img id="imgitem" alt="" class="img-responsive" style="max-width:100px;max-height:100px" [src]="entityItem.imgUrl" />
                        </td>
                        <td style="width:70%">
                            <table class="table">
                                <tr>
                                    <td style="width:20%; border:none" align="left">
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'PoliticalParty'">{{l('PoliticalParty')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'Politician'">{{l('Politician')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'Attraction'">{{l('Attraction')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'SportClub'">{{l('SportClub')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'SportManager'">{{l('SportManager')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'SportPlayer'">{{l('SportPlayer')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'SportLeague'">{{l('SportLeague')}}</span>
                                    </td>
                                    <td style="border:none" align="left"><span class="bold">{{entityItem.name}}</span></td>
                                </tr>
                                <tr *ngIf="entityItem.entityType.toString() === 'PoliticalParty'">
                                    <td align="left">
                                        <span class="font-xs font-grey-silver">{{l('PoliticalIdeology')}}</span>
                                    </td>
                                    <td align="left">
                                        <span class="font-xs font-dark">{{entityItem.orgDef}}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="entityItem.locationName !== null && entityItem.locationName !== '' && (entityItem.entityType.toString() === 'Attraction' || entityItem.entityType.toString() === 'SportClub')">
                                    <td align="left">
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() !== 'SportClub'">{{l('Location')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'SportClub'">{{l('City')}}</span>
                                    </td>
                                    <td align="left"><span class="font-xs font-dark">{{entityItem.locationName}}</span></td>
                                </tr>
                                <tr>
                                    <td align="left">
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'PoliticalParty' || entityItem.entityType.toString() === 'Attraction' || entityItem.entityType.toString() === 'SportClub' || entityItem.entityType.toString() === 'SportLeague'">{{l('Country')}}</span>
                                        <span class="font-xs font-grey-silver" *ngIf="entityItem.entityType.toString() === 'Politician' || entityItem.entityType.toString() === 'SportManager' || entityItem.entityType.toString() === 'SportPlayer'">{{l('Nationality')}}</span>
                                    </td>
                                    <td align="left">
                                        <span *ngIf="entityItem.iso !== ''" class="flag-icon flag-icon-{{entityItem.iso | lowercase}}"></span> <span class="font-xs font-dark">{{entityItem.countryName}}</span>
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                </table>
            </div>
            <div class="hidden-lg hidden-md hidden-sm">
                <table>
                    <tr>
                        <td colspan="2" align="left">
                            <div class="margin-top-10" style="max-width:300px"><span class="bold h4">{{entityItem.name}}</span>  </div>
                        </td>                        
                    </tr>
                    <tr>
                        <td colspan="2" align="left">
                            <span *ngIf="entityItem.entityType.toString() === 'SportClub'" class="font-xs font-grey-silver">{{l('City')}}: </span><span *ngIf="entityItem.entityType.toString() === 'SportClub'" class="font-xs font-dark">{{entityItem.locationName}}</span>
                            <span *ngIf="entityItem.entityType.toString() === 'PoliticalParty'" class="font-xs font-grey-silver">{{entityItem.orgDef}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-xs-1" align="center" valign="middle">
                            <span *ngIf="survey.feedbackType.toString() === 'SingleSelection'">
                                <input type="radio"
                                       name="answerMobile"
                                       (change)="onVoteChange(answerId)"
                                       [(ngModel)]="answerId"
                                       [value]="entityItem.itemValue">
                            </span>
                            <div class="md-checkbox" *ngIf="survey.feedbackType.toString() === 'MultipleChoice'">
                                <input id="entityItemSelected{{entityItem.itemValue}}"
                                       class="md-check" type="checkbox"
                                       name="isEntityItemSelected{{entityItem.itemValue}}"
                                       (change)="saveChoice(entityItem.multiAnswer)"
                                       [ngClass]="{'edited':entityItem.multiAnswer.isSelected}"
                                       [(ngModel)]="entityItem.multiAnswer.isSelected">
                                <label for="entityItemSelected{{entityItem.itemValue}}">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span>

                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="margin-top-15"><img id="imgitemMob" alt="" class="img-responsive" style="max-width:100px;max-height:100px" [src]="entityItem.imgUrl" /></div>
                        </td>
                       
                    </tr>
                </table>

            </div>
        </div>

    </div>
</div>

