<div [@routerTransition] style="width: 100%">
    <div [ngClass]="{'container':screenWidth > 500}">
        <div  [ngClass]="{'col-lg-9 col-md-9 col-sm-9 col-xs-11': screenWidth > 500}">
            <div class="wow fadeInLeft c-bg-white well-front-page animated margin-bottom4pr" [ngClass]="{'margin-top6pr col-lg-12 col-md-12 col-sm-12 col-xs-12': screenWidth > 500}">
                <div class="well well-lg c-bg-white" style="border-color:#ffffff;box-shadow:none" [ngStyle]="{'border-top-left-radius:15px;border-top-right-radius:15px;': screenWidth > 500}">
                    <div class="row">
                        <span class="actions pull-right">
                            <button class="btn-no-style" (click)="exit()"><i class="fa fa-2x fa-close"></i></button>
                        </span>
                        <div class="c-content-title-1">
                            <h1 class="c-font-uppercase c-font-bold font-dark font-media-h1">{{l('Tour')}}</h1>
                            <div class="c-line-left c-theme-bg"></div>
                        </div>
                        <div *ngIf="pageId === 1">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('PollsAndPetitions')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>
                                <ul class="margin-top-20">
                                    <li>
                                        {{l('AccessPollsAndSurveys')}}
                                    </li>
                                </ul>
                                <div class="margin-top-20 font-grey-light">{{l('Menu')}}</div>
                                <ul class="margin-top-20">
                                    <li class="margin-top-10">
                                        {{l('CategoryNavigation')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('CategoryPageNavigation')}}
                                    </li>
                                </ul>
                                <div class="margin-top-20 font-grey-light">{{l('PollsAndPetitions')}}</div>
                                <ul class="margin-top-20">
                                    <li class="margin-top-10">
                                        {{l('DashboardPageNavigation')}}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/SitePoliticsSection.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 2">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('PollsAndPetitions')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('TopMenuAndPageStructure')}}</h3>
                                <p>
                                    {{l('PageStructureLevels')}} <br />
                                    <span class="bold">{{l('PageLevelsDescription')}} </span>
                                </p>
                                <p>
                                    {{l('PageStructureDescription')}}
                                </p>
                                <ul class="margin-top-20">
                                    <li class="margin-top-10">
                                        {{l('GlobalDescription')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('CountryDescription')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AreaDescription')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('SubAreaDescription')}}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/SiteMenu.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 3">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('PollsAndPetitions')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('TopMenuAndPageStructure')}}</h3>

                                <p>
                                    {{l('DropDownMenuDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/Areas.JPG" style="height:260px; animation-name: fadeInUp; opacity: 1;">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/categories.JPG" style="height: 260px; animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 4">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('DashboardMenu')}}</h3>

                                <p>
                                    {{l('DashboardDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/Dashboard.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 5">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('BackgroundInformation')}}</h3>

                                <p>
                                    {{l('DashboardBackgroudDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/Background.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 6">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('RelatedArticles')}}</h3>

                                <p>
                                    {{l('DashboardArticlesDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/ArticlesAndVideos.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 7">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('ProsAndCons')}}</h3>

                                <p>
                                    {{l('DashboardProsConsDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/ProsCons.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 8">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('PoliticalPosision')}}</h3>

                                <p>
                                    {{l('DashboardPoliticalDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/PoliticalPosisions.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 9">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('AllUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('SurveysAndPetitions')}}</h3>

                                <p>
                                    {{l('DashboardPollDescription')}}
                                </p>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/Survey.JPG" style="animation-name: fadeInUp; opacity: 1;">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/PetitionSigning.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 10">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('RegisteredUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('Statistics')}}</h3>
                                <ul class="margin-top-20">
                                    <li class="margin-top-10">
                                        {{l('StatisticsDescription')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('StatisticsCookiesDescription')}}
                                    </li>
                                </ul>


                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/StatisticsTotal.JPG" style="animation-name: fadeInUp; opacity: 1;">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/StatisticsGender.JPG" style="animation-name: fadeInUp; opacity: 1;">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/StatisticsAge.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div *ngIf="pageId === 11">
                            <div class="col-md-8 col-lg-8">
                                <h2 class="font-dark bold font-media-h2" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                                    {{l('Dashboard')}}
                                </h2>
                                <div class="margin-top-20">
                                    <span class="padding-right-7 font-grey-light">{{l('Access')}}:</span><span class="bold font-dark-grey">{{l('RegisteredUsers')}}</span>
                                </div>

                                <h3 class="margin-top-20 font-media-h3 bold">{{l('RegisteredUsersContent')}}</h3>

                                <ol class="margin-top-20">
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage1')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage2')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage3')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage4')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage5')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage6')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage7')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage8')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage9')}}
                                    </li>
                                    <li class="margin-top-10">
                                        {{l('AccountAdvantage10')}}
                                    </li>
                                </ol>

                            </div>
                            <div class="col-md-4 col-lg-4">
                                <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/tour/norway/Profil-dashboard.JPG" style="animation-name: fadeInUp; opacity: 1;">
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12">
                            <div class="margin-top-20 page-footer text-center">
                                <button [disabled]="!btnBackEnabled" type="button" class="btn btn-xs" [ngClass]="{'purple':btnBackEnabled, 'default':!btnBackEnabled }" (click)="back()"><i class="fa fa-arrow-left"></i> {{l("Back")}} </button>
                                <button *ngIf="pageId < 11" [disabled]="!btnForwardEnabled" type="button" class="btn btn-xs" [ngClass]="{'purple':btnForwardEnabled, 'default':!btnForwardEnabled }" (click)="forward()">{{l("Next")}} <i class="fa fa-arrow-right"></i></button>
                                <button *ngIf="pageId === 11" [disabled]="!btnForwardEnabled" type="button" class="btn btn-xs btn-success" (click)="exit()">{{l("End")}} <i class="fa fa-check"></i></button>
                            </div>
                            <div class="margin-top-20 page-footer text-center">
                                Side {{pageId}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
