<div style="margin-top:1%;margin-bottom:1%; margin-left:1%;" *ngIf="chartActive">
    <div class="row margin-bottom-10" style="margin-left:1px">
        <span><i class="fa fa-user" title="{{l('Participants')}}"></i> {{chartData.totalParticipants | number}}<span class="margin-left-5">{{l('Participants').toLowerCase()}}</span></span>
    </div>
    <div class="row">
        <div class="margin-right-10" *ngIf="surveyTypeId > 0">
            <table class="col-xs-12 col-sm-12" *ngFor="let item of totalItems; let i=index;let even = even; let odd = odd">
                <tr *ngIf="item.voteCount > 0">
                    <td align="right" class="col-md-3 col-sm-4 col-xs-5 font-xs">
                        <span class="pull-left margin-right-10"><i class="fa fa-user"></i> {{item.voteCount | number}}</span> {{item.displayText}}
                    </td>
                    <td>
                        <div class="bar-rating-container">
                            <div style="filter: brightness(130%)"
                                 [ngStyle]="{'width': item.percent !== '0 %'? item.percent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (totalItems.length > 3 && even)) && chartData.colorCode !== '')? chartData.colorCode : 'bg-blue'  }"
                                 [ngClass]="{'bg-red-soft': (i === 1 && totalItems.length < 4 ), 'bg-grey-mint': i === 2 && totalItems.length < 4, 'bg-green': totalItems.length > 3 && odd}" class="score">
                            <span *ngIf="item.percent !== '0 %'" class="no-wrap">{{item.percentExact}}</span></div>
                        </div>
                    </td>
                </tr>
            </table>
            <p class="col-xs-12 col-sm-12 font-italic margin-top-15">
                {{l('CookiePoll')}} <br />
                {{l('OfficialPoll')}}
            </p>
        </div>
    </div>
</div>


