import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { VotechaVoteServiceProxy, PollServiceProxy, UserVoteInput, UserSigningInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { bootloader } from '@angularclass/hmr';

@Component({
    selector: 'poll-voting',
    templateUrl: './poll-voting.component.html',
    styleUrls: ['./poll-voting.component.css'],
    animations: [appModuleAnimation()]
})
export class PollVotingComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;
    @Input() isUserLoggedIn: boolean = false;
    @Input() hasVoted: boolean = false;
    @Input() screenWidth: number = 0;
    @Input() vote: UserVoteInput;
    @Input() gradientExpr: string;
    @Input() surveyContainerHeight: number;
    @Output() isVoted: EventEmitter<UserVoteInput> = new EventEmitter<UserVoteInput>();
    @Output() hasRanked: EventEmitter<UserVoteInput> = new EventEmitter<UserVoteInput>();
    @Output() hasSigned: EventEmitter<Boolean> = new EventEmitter<Boolean>();
    @Output() hasUnsigned: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    public categoryColor: string = "#4b77be";
    public fbUrl: string;
    public pageUrl: string = "";
    useSmallFont: boolean = false;

    cookieLangValue: string;

    constructor(
        injector: Injector,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.categoryColor = this.survey.colorCode;
        this.pageUrl = window.location.href.toString();
        this.fbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.pageUrl);
        this.setSmallFont();
    }

    setSmallFont(): void {
        if (this.screenWidth < 420) {
            for (var i = 0; i < this.survey.surveyItems.length; i++) {
                if (this.survey.surveyItems[i].displayText.length > 30 || this.survey.surveyItems[i].itemText.length > 30) {
                    this.useSmallFont = true;
                }
            }
        }
    }

    onVoteChange(): void {
        this.isVoted.emit(this.vote);
        this.hasVoted = true;
    }

    onImportanceRankingChange(): void {
        this.hasRanked.emit(this.vote);
    }

    signPetition(): void {
        this.hasSigned.emit(true);
    }

    undoSigning(): void {
        this.hasUnsigned.emit(true);
    }
}
