import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SurveyListDto, EntitySchemaForVotingOutput, EntityItemDto } from '@shared/service-proxies/service-proxies';
import { VotechaVoteServiceProxy, MultiAnswerOutput } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'template-entity-selection',
    templateUrl: './template-entity-selection.component.html',
    animations: [appModuleAnimation()]
})
export class TemplateEntitySelectionComponent extends AppComponentBase implements OnInit, OnChanges {

    @Input() surveyInput: SurveyListDto;
    @Input() answer: number;
    @Output() answerClicked: EventEmitter<number> = new EventEmitter<number>();
    @Output() multiAnswerClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public active: boolean = false;
    public saving: boolean = false;
    entityItems: EntityItemDto[] = [];
    survey: SurveyListDto;
    answerId: number;


    constructor(
        injector: Injector,
        private _voteService: VotechaVoteServiceProxy

    ) {
        super(injector);
    }

    ngOnInit() {
        this.survey = this.surveyInput;
        this.entityItems = this.surveyInput.entitySchemaOutput.entityItems;
        this.answerId = this.answer;
    }
    ngOnChanges() {
        this.ngOnInit();
    }

    onVoteChange(answerId: number): void {
        this.answerId = answerId;
        this.answerClicked.emit(answerId);
    }

    saveChoice(multiAnswer: MultiAnswerOutput): void {
        //var multipleChoiceInput = new MultiAnswerInput();
        //multipleChoiceInput.id = multiAnswer.id;
        //multipleChoiceInput.surveyId = multiAnswer.surveyId;
        //multipleChoiceInput.itemId = multiAnswer.itemId;
        //multipleChoiceInput.answerId = multiAnswer.answerId;
        //multipleChoiceInput.isSelected = multiAnswer.isSelected;
        var isAnonymous: boolean = false;
        //if (multiAnswer.isSelected && multipleChoiceInput.answerId > 0 && multipleChoiceInput.surveyId > 0 && multipleChoiceInput.itemId > 0) {
        //    this._voteService.saveMultipleChoice(multipleChoiceInput)
        //        .subscribe((result) => {
        //            if (multiAnswer.id === 0) {
        //                multiAnswer.id = result;
        //                for (var i = 0; i < this.entityItems.length; i++) {
        //                    if (this.entityItems[i].multiAnswer.itemId === multiAnswer.itemId)
        //                        this.entityItems[i].multiAnswer.id = multiAnswer.id;
        //                }
        //            }
        //            this.multiAnswerClicked.emit(true);
        //        });
        //}
        //else if (!multiAnswer.isSelected && multipleChoiceInput.id > 0 && multipleChoiceInput.answerId > 0 && multipleChoiceInput.surveyId > 0 && multipleChoiceInput.itemId > 0)
        //{
        //    this._voteService.deleteMultipleChoice(multipleChoiceInput.id, multipleChoiceInput.surveyId, multipleChoiceInput.itemId, multipleChoiceInput.answerId, multipleChoiceInput.isSelected, isAnonymous)
        //        .subscribe(() => {

        //        });
        //}

    }


}
