<div class="page-footer-inner">
    <span class="text-muted">
        <small>
            opinions24.net<span *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName"> {{appSession.tenant.edition.displayName}}</span> <br />
            v{{appSession.application.version}} [{{releaseDate}}]
        </small>
    </span>
</div>
<div class="scroll-to-top">
    <i class="icon-arrow-up"></i>
</div>
