import { Component, Injector, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css'],
    animations: [appModuleAnimation()]
})
export class AboutComponent extends AppComponentBase implements OnInit {
    page: PageOutput;
    cookieLangValue: string;
    isUserLoggedIn: boolean = false;
    active: boolean = false;
    isMeninger24: boolean = false;
    pnlExpandWorldOfOpinions: boolean = false;
    pnlExpandHowToUse: boolean = false;
    pnlExpandGDPR: boolean = false;
    pnlExpandPollsAndPetitions: boolean = false;
    pnlExpandArticlesAndBlogs: boolean = false;
    pnlExpandStatistics: boolean = false;
    pnlExpandCompetitions: boolean = false;
    pnlExpandGoalsAndVisions: boolean = false;
    pnlExpandReasonsToUseVotecha: boolean = false;

    screenWidth: number;

    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private _pageService: PageServiceProxy
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.page = new PageOutput();
        this.pnlExpandWorldOfOpinions = true;
        this.pnlExpandHowToUse = false;
        this.pnlExpandGDPR = false;
        this.pnlExpandPollsAndPetitions = false;
        this.pnlExpandArticlesAndBlogs = false;
        this.pnlExpandStatistics = false;
        this.pnlExpandCompetitions = false;
        this.pnlExpandGoalsAndVisions = false;
        this.pnlExpandReasonsToUseVotecha = false;        
        this.getContent();
        if (AppConsts.appBaseUrl.toLowerCase().includes("localhost") || AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
            this.isMeninger24 = true;
        }

        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['section'] === 'gdpr') {
                this.pnlExpandWorldOfOpinions = false;
                this.pnlExpandGDPR = true;
                document.getElementById('gdpr').scrollIntoView({ behavior: 'smooth' });
            }
            else if (params['section'] === 'profile') {
                this.pnlExpandWorldOfOpinions = false;
                this.pnlExpandHowToUse = true;
                document.getElementById('RegisteredUsers').scrollIntoView({ behavior: 'smooth' });
            }
            else {
                window.scrollTo(0, 0);
            }
        });
    }

    togglePanelWorldOfOpinions(): void {
        this.pnlExpandWorldOfOpinions = !this.pnlExpandWorldOfOpinions;
    }    

    togglePanelExpandHowToUse(): void {
        this.pnlExpandHowToUse = !this.pnlExpandHowToUse;
    }

    togglePanelExpandGDPR(): void {
        this.pnlExpandGDPR = !this.pnlExpandGDPR;
    }

    togglePanelPollsAndPetitions(): void {
        this.pnlExpandPollsAndPetitions = !this.pnlExpandPollsAndPetitions;
    }

    togglePanelArticlesAndBlogs(): void {
        this.pnlExpandArticlesAndBlogs = !this.pnlExpandArticlesAndBlogs;
    }

    togglePanelStatistics(): void {
        this.pnlExpandStatistics = !this.pnlExpandStatistics;
    }

    togglePanelCompetitions(): void {
        this.pnlExpandCompetitions = !this.pnlExpandCompetitions;
    }

    togglePanelGoalsAndVisions(): void {
        this.pnlExpandGoalsAndVisions = !this.pnlExpandGoalsAndVisions;
    }

    togglePanelReasonsToUseVotecha(): void {
        this.pnlExpandReasonsToUseVotecha = !this.pnlExpandReasonsToUseVotecha;
    }

    getContent(): void {
        this._pageService.getPage("About", this.cookieLangValue).subscribe((result) => {      
            this.page.preamble = result.preamble;
            this.page.content = result.content;
            this.active = true;            
        });
    }

 goToGlobalSite(): void {
        this._router.navigate(['app/main/site']);
    }
  
}
