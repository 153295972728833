import { NgModule } from '@angular/core';
import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitAppServiceOldServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.CategoryServiceProxy,
        ApiServiceProxies.SubCategoryServiceProxy,
        ApiServiceProxies.IndustryServiceProxy,
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.AreaServiceProxy,
        ApiServiceProxies.SubAreaServiceProxy,
        ApiServiceProxies.CityServiceProxy,
        ApiServiceProxies.SurveyServiceProxy,
        ApiServiceProxies.UserSettingsServiceProxy,
        ApiServiceProxies.PrivateSurveyServiceProxy,
        ApiServiceProxies.TagServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.AdminCompanyServiceProxy,
        ApiServiceProxies.AdminMarketResearchServiceProxy,
        ApiServiceProxies.MarketResearchServiceProxy,
        ApiServiceProxies.UserMarketResearchServiceProxy,       
        ApiServiceProxies.PhotoServiceProxy,
        ApiServiceProxies.MySiteServiceProxy,
        ApiServiceProxies.SiteServiceProxy,
        ApiServiceProxies.RewardServiceProxy,
        ApiServiceProxies.PageServiceProxy,
        ApiServiceProxies.PageTranslationServiceProxy,
        ApiServiceProxies.AdminArticleServiceProxy,
        ApiServiceProxies.ArticleTranslationServiceProxy,
        ApiServiceProxies.CountryManagerServiceProxy,
        ApiServiceProxies.AreaManagerServiceProxy,
        ApiServiceProxies.ManagerServiceProxy,
        ApiServiceProxies.AttractionServiceProxy,
        ApiServiceProxies.PoliticalPartyServiceProxy,
        ApiServiceProxies.PoliticianServiceProxy,
        ApiServiceProxies.SportServiceProxy,
        ApiServiceProxies.EntityServiceProxy,
        ApiServiceProxies.GlossaryServiceProxy,
        ApiServiceProxies.MediaPollArticleServiceProxy,
        ApiServiceProxies.PetitionSigningServiceProxy,
        ApiServiceProxies.PollServiceProxy,
        ApiServiceProxies.VotechaVoteServiceProxy,
        ApiServiceProxies.PollTranslationServiceProxy,
        ApiServiceProxies.FrontPagePollServiceProxy,
        ApiServiceProxies.PollStatisticsServiceProxy,
        ApiServiceProxies.UserArticleServiceProxy,
        ApiServiceProxies.AnonymousPollAnswerServiceProxy
    ]
})
export class ServiceProxyModule { }
