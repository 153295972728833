import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlService } from '@shared/url.service';

@Component({
  selector: 'survey-template',
    templateUrl: './survey-template.component.html',
    styleUrls: ['./survey-template.component.css'],
  animations: [appModuleAnimation()]
})
export class SurveyTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() inputSurvey: PollListDto;
    @Input() inputCountry: string;
    @Input() inputIsUserLoggedIn: boolean = false;
    @Input() inputPageUrl: string;

    public active: boolean = false;
    public saving: boolean = false;
    public goToDashboard: string = "";

    previousUrl: Observable<string> = this.urlService.previousUrl$;
    lastPosision: Observable<string> = this.urlService.lastPosision$;
    lastPosisionStr: string = "";

    isUserLoggedIn: boolean = false;


    constructor(
        injector: Injector,
        private _router: Router,
        private urlService: UrlService,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.isUserLoggedIn = this.inputIsUserLoggedIn;        
        this.goToDashboard = this.l('GoToDashboardSite');
    }

    ngOnChanges() {

        this.isUserLoggedIn = this.inputIsUserLoggedIn;

    }


    goToVoteForm(id: string): void {
        this.urlService.setLastPosision(window.pageYOffset.toString());

        if (this.inputPageUrl !== undefined && this.inputPageUrl !== null && this.inputPageUrl !== '') {
            const url = this.inputPageUrl + '/post';
            localStorage.setItem("lastVotechaPage", JSON.stringify(url));
        }
        
        let  country = this.inputCountry
        if (country.toLowerCase() === 'norge') {
            country = "norway";
        }
        
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country , id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }

  }
