<section>
    <div class="flex-container  margin-bottom6pr">
        <carousel [arrowsOutside]="screenWidth > 767 ? true : false"
                  [autoplay]="true"
                  [dots]="false"
                  [cellsToShow]="1"
                  [cellWidth]="'100%'"
                  [loop]="true"
                  [objectFit]="'cover'"
                  [autoplayInterval]="7000" class="shadow">

            <div class="carousel-cell border-white rounded-corners bg-green">
                <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="box-center height-150">
                            <h2 class="font-white bold font-media-h2">{{l("YourOpinionOnTheFuture")}}</h2>
                        </div>
                    </div>
                </div>
                <div class="" [ngClass]="{'col-lg-6 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_644724364.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <h2 class="font-white bold font-media-h1">{{l("YourOpinionOnTheFuture")}}</h2>
                    </div>
                </div>
            </div>

            <div class="carousel-cell border-white rounded-corners bg-dark-green">
                <div class="" [ngClass]="{'col-lg-6 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_410688691.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <h2 class="font-white bold font-media-h1">{{l("StopThePlasticPollutionOfTheSea")}}</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="box-center height-150">
                            <h2 class="font-white bold font-media-h2">{{l("StopThePlasticPollutionOfTheSea")}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="carousel-cell border-white rounded-corners bg-dark-green">
        <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
            <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_592642775.webp" style="width:50%">
            <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_1104896513.webp" style="width:50%">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
            <div class="padding-20">
                <div class="box-center">
                    <h2 class="font-white bold font-media-h2">{{l("StopThePlasticPollutionOfTheSea")}}</h2>
                </div>
            </div>
        </div>
        <div class="image-container" *ngIf="screenWidth < 768">
            <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_592642775.webp" style="object-fit: cover;">
            <div class="slide-text margin-top-20 margin-bottom-20">
                <div class="bold">Oslo</div>
                <h2 class="font-white bold font-media-h1">{{l("StopThePlasticPollutionOfTheSea")}}</h2>
            </div>
        </div>
    </div>-->

            <div class="carousel-cell border-white rounded-corners bg-dark-green">
                <div class="" [ngClass]="{'col-lg-6 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_763734049.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <h2 class="font-white bold font-media-h1">{{l("YourOpinionOnClimateChange")}}</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="box-center height-150">
                            <h2 class="font-white bold font-media-h2">{{l("YourOpinionOnClimateChange")}}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell border-white rounded-corners c-bg-after-black" *ngIf="siteTypeId === 1 || siteTypeId === 2">
                <div class="" [ngClass]="{'col-lg-6 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_96862360.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <h2 class="font-white bold font-media-h1">{{l("JoinTheFightAnimalWelfare!")}}</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="box-center height-150">
                            <h2 class="font-white bold font-media-h2">{{l("JoinTheFightAnimalWelfare!")}}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-cell border-white rounded-corners bg-white" *ngIf="siteId === 2 || siteId === 4" (click)="goToVoteForm('9d45575d-b50e-4d1f-9e7b-530b54abbbda')">
                <div class="" [ngClass]="{'col-lg-6 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/t-banen.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <div class="bold margin-top-20 margin-bottom-20">Oslo</div>
                        <h2 class="font-white bold font-media-h1">Bli med i underskriftskampanjen for t-bane avganger frem til kl 03 i helgene</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="bold margin-bottom-20">Oslo</div>
                        <div class="box-center">
                            <h2 class="bold font-media-h2">Bli med i underskriftskampanjen for t-bane avganger frem til kl 03 i helgene</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-cell border-white rounded-corners c-bg-after-black" *ngIf="siteTypeId === 1 || siteTypeId === 2" (click)="goToVoteForm('7d57fc72-eb75-4caf-9826-84ddb46ba8f7')">
                <div class="" [ngClass]="{'col-lg-5 col-md-6 col-sm-6 no-padding':screenWidth > 767}">
                    <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/shutterstock_622748774.webp" style="object-fit: cover;">
                    <div class="slide-text margin-top-40 margin-bottom-20" *ngIf="screenWidth < 768">
                        <h2 class="font-white bold font-media-h1">
                        <span *ngIf="siteTypeId === 1">{{l("StopTheCigaretteButtsPollution")}}</span>
                        <span *ngIf="siteTypeId !== 1">{{l("StopTheCigaretteButtsPollutionNorway")}}</span>
                        </h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-6 no-padding" *ngIf="screenWidth > 767">
                    <div class="padding-20">
                        <div class="box-center height-150">
                            <h2 class="font-white bold font-media-h2">
                                <span *ngIf="siteTypeId === 1">{{l("StopTheCigaretteButtsPollution")}}</span>
                                <span *ngIf="siteTypeId !== 1">{{l("StopTheCigaretteButtsPollutionNorway")}}</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
</section>

