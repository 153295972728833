import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MultiAnswerOutput } from '@shared/service-proxies/service-proxies';
import { VotechaVoteServiceProxy, GetUserVoteEdit, UserVoteInput, FactorOutput, FactorInput, FactorRatingInput } from '@shared/service-proxies/service-proxies';
import { PollListDto, EntitySchemaForVotingOutput, EntityItemDto } from '@shared/service-proxies/service-proxies';
import { VotechaChartDto } from '@shared/service-proxies/service-proxies';
import { PollStatisticsServiceProxy, RelatedSurveyDto } from '@shared/service-proxies/service-proxies';
import { MediaPollArticleServiceProxy, ArticleRatingDto, MediaArticleListOutputStatus, BlogArticleOutput, MediaArticleListOutput } from '@shared/service-proxies/service-proxies';
import { PetitionSigningServiceProxy, UserSigningInput, PetitionSigningOutput } from '@shared/service-proxies/service-proxies';
import { AnonymousPollAnswerServiceProxy, AnonymousPollAnswerDto, AnonymousPollAnswerInput } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { CookieHelper } from '@shared/helpers/CookieHelper';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';
import { AbpSessionService } from 'abp-ng2-module';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';
import { Meta, Title } from '@angular/platform-browser';
import { AppConsts } from '@shared/AppConsts';
import {
    ProfileServiceProxy,
    UserServiceProxy,
    ChangeUserLanguageDto,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy
} from '@shared/service-proxies/service-proxies';
import { filter } from 'rxjs/operators';
import { UrlService } from '@shared/url.service';
import { toNumber } from 'lodash';
import { VotechaAdvantagesModalComponent } from './anonymous-vote-banner/votecha-advantages-modal.component';
import { log } from 'util';

@Component({
    selector: 'SurveyDashboard',
    templateUrl: './survey-dashboard.component.html',
    styleUrls: ['./survey-dashboard.component.css'],
    animations: [appModuleAnimation()]
})
export class SurveyDashboardComponent extends AppComponentBase implements OnInit, OnChanges {
    vote: UserVoteInput;
    survey: PollListDto;
    chartData: VotechaChartDto;
    anonymousChartData: AnonymousPollAnswerDto;
    relatedSurveys: PollListDto[] = [];
    latestSignings: PetitionSigningOutput[] = [];
    allSignings: PetitionSigningOutput[] = [];
    answeredPollIds$: Observable<string[]>;
    answeredPollIds: string[] = [];
    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;

    private cookieLangValue: string;
    private cookieVoteValue: string;
    private cookiePollId: string;
    public petitionMessage: string = "";
    pnlExpandBackground: boolean = false;
    pnlExpandPoliticalPosisions: boolean = false;
    pnlExpandArticles: boolean = false;
    pnlExpandProsCons: boolean = false;
    pnlExpandPoll: boolean = false;
    pnlExpandRelated: boolean = false;
    toggleShortConent: boolean = false;
    showRegisterPanel: boolean = false;

    public showArticles: boolean = false;
    public showProsCons: boolean = false;
    public showPoliticalPosisions: boolean = false;
    public anonymousVoteSaved: boolean = false;
    public active: boolean = false;
    public saving: boolean = false;
    public hasVoted: boolean = false;
    public isUserLoggedIn: boolean = false;
    public showContentDescription: boolean = false;
    public validContentDescription: boolean = false;
    private userHasAccount: boolean = false;
    public showFooter: boolean = false;
    public showAnonymousControls: boolean = false;

    public tabIndex: number = 0;
    public pollId: string = "";
    public activeTabIndex: number = 0;
    public displayCode: number = 0;
   
    public isMeninger24: boolean = false;
    

    url: string;
    fbUrl: string;
    public pageUrl: string = "";
    public strClose: string = "";
    public categoryColor: string = "#4b77be";
    public categoryColorName: string = "blue";
    public tagColor: string = "#4b77be";
    public areaDef: string = "";
    public country: string = "";
    public area: string = "";
    public subArea: string = "";
    public language: string = "";
    public screenWidth: number;
    public screenWidthRightPane: number;
    public articlesCount: number;
    public prosConsCount: number;

    public surveyContainerHeight: string;
    public lightCategoryColor: string;
    public gradientExpr: string = "";

    previousUrl: Observable<string> = this.urlService.previousUrl$;
    previousUrlStr: string = "";
    lastPosision: Observable<string> = this.urlService.lastPosision$;
    lastPosisionStr: string = "";
    shortDescription: string = "";
    contentDescription: string = "";

    constructor(
        injector: Injector,
        private activatedRoute: ActivatedRoute,
        private _userVoteService: VotechaVoteServiceProxy,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private _petitionSigningServiceProxy: PetitionSigningServiceProxy,
        private _abpSessionService: AbpSessionService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _anonymousPollAnswerServiceProxy: AnonymousPollAnswerServiceProxy,
        private meta: Meta,
        private title: Title,
        private _router: Router,
        private urlService: UrlService
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    getRightPaneWidth(): number {
        const width = this.screenWidth < 1201 ? this.screenWidth : this.isUserLoggedIn ? this.screenWidth * 4 / 12 : this.screenWidth * 5 / 12;
        return width;
    }
    ngOnInit() {
        this.urlService.previousUrl$.subscribe((previousUrl: string) => {
            this.previousUrlStr = previousUrl;
        });
        this.urlService.lastPosision$.subscribe((lastPosision: string) => {
            this.lastPosisionStr = lastPosision;
        });       

        this.articlesCount = 0;
        this.prosConsCount = 0;
        this.screenWidth = window.innerWidth;
        this.screenWidthRightPane = this.getRightPaneWidth();
        this.pageUrl = window.location.href.toString();
        this.fbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.pageUrl);
        
        this.chartData = new VotechaChartDto();
        this.activeTabIndex = 0;
        this.surveyContainerHeight = "360px";
        this.lightCategoryColor = "#ffffff"
        this.strClose = this.l('Close');
        this.getScreenSize();
        registerLocaleData(localeNorwegian);

        this.isUserLoggedIn = false;
        this.showProsCons = false;
        this.relatedSurveys = [];

        this.cookieLangValue = "en";
        if (abp.utils.getCookieValue("Abp.Localization.CultureName") !== undefined && abp.utils.getCookieValue("Abp.Localization.CultureName") !== null) {
            this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        }
        
        this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled == false);
        this.currentLanguage = this.localization.currentLanguage;

        if (this.cookieLangValue === null || this.cookieLangValue === undefined || this.cookieLangValue === '') {
            var userLang = navigator.language;
            this.changeLanguage(userLang);
        }

        if (!this.userHasAccount) {
            this.userHasAccount = CookieHelper.getUserSignedUpValue();
        }
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;

        if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
        //if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.cookieLangValue = "nb-NO";
            this.isMeninger24 = true;

        }

        this.activatedRoute.params.subscribe((params: Params) => {
            this.pollId = params['id'];
            this.country = "global";
            if (params['country'] !== null && params['country'] !== '') {
                this.country = params['country'];
                if (this.cookieLangValue === null || this.cookieLangValue === "" && this.country.toLowerCase() === "Norway") {
                    this.cookieLangValue = "nb-NO";
                }
            }

            this.language = this.cookieLangValue;
            if (!this.isMeninger24 && params['language'] !== undefined && params['language'] !== '') {
                this.language = params['language'];
            }
            this.survey = new PollListDto();
            this.vote = new UserVoteInput();
            this.vote.answerId = 0;
            this.vote.importanceRanking = 0;
            if (this.pollId !== "") {
                this.getSurvey();
            }
            this.cookiePollId = "VotechaPollAnswered-" + this.pollId;
        });


        window.scrollTo(0, 0);
    }

    intitPanels(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandArticles = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = false;
        if (this.survey.surveyTypeId > 0) {
            this.pnlExpandPoll = true;
        }
    }

    togglePanelBackground(): void {
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandArticles = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = false;
        this.pnlExpandBackground = !this.pnlExpandBackground;
    }

    togglePanelPoliticalPosisions(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandArticles = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = false;
        this.pnlExpandPoliticalPosisions = !this.pnlExpandPoliticalPosisions;
    }

    togglePanelArticles(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = false;
        this.pnlExpandArticles = !this.pnlExpandArticles;
    }

    togglePanelProsCons(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandArticles = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = false;
        this.pnlExpandProsCons = !this.pnlExpandProsCons;
    }

    togglePanelPoll(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandArticles = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandRelated = false;
        this.pnlExpandPoll = !this.pnlExpandPoll;
    }

    togglePanelRelated(): void {
        this.pnlExpandBackground = false;
        this.pnlExpandPoliticalPosisions = false;
        this.pnlExpandArticles = false;
        this.pnlExpandProsCons = false;
        this.pnlExpandPoll = false;
        this.pnlExpandRelated = !this.pnlExpandRelated;
    }

  
    toggleContent(): void {
        this.toggleShortConent = !this.toggleShortConent;
        if (this.toggleShortConent) {
            this.showContentDescription = true; 
        }
        else {
            this.showContentDescription = false;
        }
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    goToProfile(): void {
        this._router.navigate(['app/main/profile']);
    }

    getSurvey() {
        this._pollStatisticsService.getDashboardPoll(this.pollId, this.country, this.language).subscribe((result) => {
            this.survey = result;
            if (this.survey.isNewSocialNetworkUser) {
                this.goToProfile();
            }

            this.setShortDescription();
            if (this.survey.displayDescription?.includes("<img src")) this.survey.displayDescription = this.ScaleDownImages(this.survey.displayDescription);
            if (this.survey.displayDescription?.includes("<a href=")) this.survey.displayDescription = this.FixUrl(this.survey.displayDescription);
            this.title.setTitle(this.survey.displayName + " - Votecha " + this.l('Poll'));
            if (result.surveyTypeId === 0) this.title.setTitle(this.survey.displayQuestion + " - Votecha " + this.l('Petition'));
            this.setFaceBookMetaTags(this.survey.id, this.survey.imgUrl, this.survey.displayName, this.survey.displayQuestion);
            this.setTwitterMetaTags(this.survey.id, this.survey.imgUrl, this.survey.displayName, this.survey.displayQuestion);
            this.showProsCons = (result.surveyTypeId < 3 || result.surveyTypeId === 6);
            if (this.survey.colorCode === undefined || this.survey.colorCode === null || this.survey.colorCode === '') {
                this.survey.colorCode = this.categoryColor;
            }
            this.surveyContainerHeight = this.getContainerHeight().toString() + "px";
            this.setColors(result);
            

            this.showPoliticalPosisions = this.survey.usePoliticalPosisions;
            if (result.relatedPolls !== undefined) {
                this.relatedSurveys = result.relatedPolls.items;
                this.GetUserVotes();
            }

            this.hasVoted = result.hasVoted;
            if (this.hasVoted && this.survey.surveyTypeId > 0) {
                this.fillUserVoteInput(result.vote);
                this.active = true;
            }

            if (!this.isUserLoggedIn) {
                this.chartData = this.survey.chartData;
            }
            //else if (this.survey.surveyTypeId === 0 && !this.isUserLoggedIn) {
            //    this.cookiePollId = "VotechaPetitionSigned-" + this.survey.id;
            //    const cookieSigningValue = abp.utils.getCookieValue(this.cookiePollId);
            //    if (cookieSigningValue !== null && cookieSigningValue !== undefined) {
            //        this.isPetitionSigned = true;
            //    }
            //}

            this.getSurveyData();
            this.intitPanels();
            this.initFooter();
            this.getLatestSigning();
            this.getAllSignings();
        });
    }

    getLatestSigning() {
        if (this.survey.surveyTypeId === 0) {
            this._petitionSigningServiceProxy.getPetitionSignings(this.survey.id, this.survey.areaTypeId, this.survey.countryId, true).subscribe((result) => {
                this.latestSignings = result;
            });
        }        
    }

    getAllSignings() {
        if (this.survey.surveyTypeId === 0) {
            this._petitionSigningServiceProxy.getPetitionSignings(this.survey.id, this.survey.areaTypeId, this.survey.countryId, false).subscribe((result) => {
                this.allSignings = result;
            });
        }
    }

    setShortDescription() {
        this.shortDescription = this.survey.displayDescription;
        if (this.survey.displayDescription?.length > 220) {
            const description = this.survey.displayDescription;
            const strings = description.split('.');
            if (strings.length > 4) {
                this.shortDescription = `${strings[0]}. ${strings[1]}. ${strings[2]}. ${strings[3]}.`;
                this.contentDescription = this.survey.displayDescription.substring(this.survey.displayDescription.indexOf(strings[4]));
                this.validContentDescription = this.contentDescription && this.contentDescription.length > 200;
                if (!this.validContentDescription) {
                    this.shortDescription = this.survey.displayDescription;
                }
            }            
        }
    }

    private setColors(result: PollListDto) {

        this.categoryColor = result.colorCode;
        if (result.categoryColorName !== undefined) {
            this.categoryColorName = result.categoryColorName;
        }


        if (this.categoryColor !== null) {
            this.tagColor = VotechaHelper.shadeColor(this.categoryColor, -40);
            this.lightCategoryColor = VotechaHelper.shadeColor(this.categoryColor, 30);
            this.gradientExpr = "linear-gradient(" + this.lightCategoryColor + ", " + this.categoryColor + ")";
        }


        if (this.survey.surveyTypeId === 0) {
            this.categoryColor = "#000000";
            this.categoryColorName = "black";
            this.tagColor = this.lightCategoryColor;
        }

        


    }

    getContainerHeight(): number {
        var height: number = 440;
      
        if (this.screenWidth < 600 && this.survey.surveyTypeId === 0) height += 200;
        else if (this.screenWidth < 600 && this.survey.surveyTypeId > 0) height += 100;

        if (this.survey.surveyTypeId > 0) {
            if (this.survey.surveyType.toString() === 'Score_1_to_10') {
                height = 700;
                if (this.screenWidth < 1200) {
                    height = 1000;
                }
            }
            else if (this.survey.surveyTypeId === 32) {
                height += (this.survey.surveyItems?.length - 4) * 95;
            }
            else if (this.survey.surveyItems?.length > 4) {
                height += (this.survey.surveyItems.length - 4) * 80;
            }
        }

        if (this.isMeninger24) {
            height += 70;
        }
        return height;
    }

    async GetUserVotes(): Promise<void> {
        if (this.isUserLoggedIn) {
            this.answeredPollIds$ = this._userVoteService.getUsersVotedPollIds();
            this.answeredPollIds = await VotechaHelper.lastValueFrom(this.answeredPollIds$);
            for (var i = 0; i < this.relatedSurveys.length; i++) {
                for (var k = 0; k < this.answeredPollIds.length; k++) {
                    if (this.relatedSurveys[i].id === this.answeredPollIds[k]) {
                        this.relatedSurveys[i].hasVoted = true;
                    }
                }
            }
        }
    }

    ScaleDownImages(displayDescription: string): string {
        return displayDescription.replace("<img src", "<img class=\"img-responsive\" src");
    }

    FixUrl(displayDescription: string): string {
        displayDescription = displayDescription.replace("ref=\"www.", "ref=\"https://www.");
        var linkIndex = displayDescription.indexOf("ref=\"");
        if (!displayDescription.substring(linkIndex, linkIndex + 15).includes("https:") && !displayDescription.substring(linkIndex, linkIndex + 8).includes("http:")) {
            displayDescription = displayDescription.replace("ref=\"", "ref=\"https://");
        }
        return displayDescription;
    }

    fillUserVoteInput(userVoteEdit: GetUserVoteEdit): void {
        this.fillVotePoll();
        this.vote.id = userVoteEdit.id;
        this.vote.isAnonymous = userVoteEdit.isAnonymous;
        if (!this.vote.isAnonymous) {
            if (this.survey.feedbackTypeId === 0) {
                this.vote.answerId = userVoteEdit.answerId;
                this.vote.answer = userVoteEdit.answer;
            }
            else {
                this.vote.multiAnswers = userVoteEdit.multiAnswers;
            }
            this.vote.importanceRanking = userVoteEdit.importanceRanking;
        }
        
    }

    private fillVotePoll() {
        this.vote.pollId = this.survey.id;
        this.vote.surveyType = this.survey.surveyTypeId;
        this.vote.countryId = this.survey.countryId;
        this.vote.countryName = this.survey.countryName;
        this.vote.areaType = this.survey.areaTypeId;
        this.vote.locationName = this.survey.areaName;
        this.vote.iso = this.survey.iso;
        this.vote.areaId = this.survey.areaId;
        this.vote.areaName = this.survey.areaName;
        this.vote.subAreaId = this.survey.subAreaId;
        this.vote.subAreaName = this.survey.subAreaName;

        this.vote.displayName = this.survey.displayName;
        this.vote.displayQuestion = this.survey.displayQuestion;
        this.vote.feedbackType = this.survey.feedbackTypeId;
        this.vote.categoryColor = this.survey.colorCode;
        this.vote.categoryId = this.survey.categoryId;
        this.vote.categoryName = this.survey.categoryName;
        this.vote.subCategoryId = this.survey.subCategoryId;
        this.vote.subCategoryName = this.survey.subCategoryName;
        this.vote.languageId = this.survey.primaryLanguageId;
        this.vote.imgUrl = this.survey.imgUrl;
        if (this.survey.vote !== undefined) {
            this.vote.isAnonymous = this.survey.vote.isAnonymous;
            this.vote.answerId = this.survey.vote.answerId;
            this.vote.answer = this.survey.vote.answer;
            this.vote.importanceRanking = this.survey.vote.importanceRanking;
        }

    }

    getSurveyData(): void {
        if (this.isUserLoggedIn) {
            if (!this.hasVoted || this.survey.surveyTypeId === 0) {
                this.vote = new UserVoteInput();
                this.fillVotePoll();
                this.active = true;
            }
            if (this.hasVoted) {
                this.getChartData();
            }
        }
        else if (this.survey.anonymousVotesEnabled) {
            this.vote = new UserVoteInput();
            this.cookieVoteValue = abp.utils.getCookieValue(this.cookiePollId);
            if (this.cookieVoteValue !== null && this.cookieVoteValue !== undefined) {
                this.vote.answerId = toNumber(this.cookieVoteValue);
                this.anonymousVoteSaved = true;
                this.getAnonymousChartData();
            }
            this.active = true;
        }
        else if (this.survey.unlocked) {
            this.getChartData();
            this.active = true;
        }
        else {
            this.active = true;
        }
    }

    getChartData(): void {
        this._userVoteService.getChartData(this.survey.id, this.survey.areaTypeId, this.survey.countryId).subscribe((result) => {
            this.chartData = result;
        });
    }

    getAnonymousChartData(): void {
        this._anonymousPollAnswerServiceProxy.getAnonymousPollAnswers(this.survey.id).subscribe((result) => {
            this.anonymousChartData = result;
            this.anonymousChartData.colorCode = this.survey.colorCode;
            this.getItemLabelsForAnonymousChart();
            this.active = true;
        });
    }

    onVoteChange(vote: UserVoteInput): void {
        this.vote = vote;
        if (this.vote.pollId !== '' && this.vote.answerId > 0 && !this.survey.isAnonymous) {
            this.saving = true;            
            if (this.isUserLoggedIn) {
                this.vote.answer = this.getAnswer(this.vote.answerId);
                this.vote.participants = this.survey.participants;
                this.vote.pollTotalRatingId = this.survey.pollTotalRatingId;
                this.vote.pollUnlocked = this.survey.unlocked;
                this._userVoteService.submitVotechaVote(this.vote).pipe(
                    finalize(() => this.saving = false))
                    .subscribe((result) => {
                        this.vote.id = result.id;
                        this.notify.info(this.l('ThanksForYourVote'));
                        this.hasVoted = true;
                        this.getChartData();
                    });
            }
            else if (this.survey.anonymousVotesEnabled && !this.survey.unlocked) {
                this.saveAnonymousAnswer();
            }            
        }

    }

    saveAnonymousAnswer(): void {
        this.saving = true;
        if (this.survey.anonymousVotesEnabled && !this.isUserLoggedIn && this.vote.pollId !== '' && this.vote.answerId > 0) {
            var answer = new AnonymousPollAnswerInput();
            answer.pollId = this.survey.id;
            answer.answerId = this.vote.answerId;
            this.cookieVoteValue = abp.utils.getCookieValue(this.cookiePollId);
            if (this.cookieVoteValue !== null && this.cookieVoteValue !== undefined) {
                answer.previousAnswerId = toNumber(this.cookieVoteValue);
            }
            if (answer.previousAnswerId !== answer.answerId) {
                this._anonymousPollAnswerServiceProxy.submitAnswer(answer).pipe(
                    finalize(() => this.saving = false))
                    .subscribe((result) => {
                        this.anonymousChartData = result;
                        this.anonymousChartData.colorCode = this.survey.colorCode;
                        this.getItemLabelsForAnonymousChart();
                        abp.utils.setCookieValue(
                            this.cookiePollId,
                            answer.answerId.toString(),
                            new Date(new Date().getTime() + 50 * 365 * 86400000), //5 year
                            abp.appPath
                        );
                        this.anonymousVoteSaved = true;
                    });
            }

        }

    }

    private getItemLabelsForAnonymousChart(): void {
        for (var i = 0; i < this.anonymousChartData.answers.length; i++) {
            for (var k = 0; k < this.survey.surveyItems.length; k++) {
                if (this.anonymousChartData.answers[i].answerId === this.survey.surveyItems[k].itemValue) {
                    this.anonymousChartData.answers[i].displayText = this.survey.surveyItems[k].displayText;
                    this.anonymousChartData.answers[i].colorCode = this.survey.surveyItems[k].colorCode;
                }
            }
        }
    }

    private getAnswer(answerId: number): string {
        var answer = "";
        for (var i = 0; i < this.survey.surveyItems.length; i++) {
            if (this.survey.surveyItems[i].itemValue === answerId) {
                answer = this.survey.surveyItems[i].displayText;
                break;
            }

        }
        return answer;
    }

    onImportanceRankingChange(vote: UserVoteInput): void {
        this.saving = true;
        this.vote = vote;
        this.vote.isAnonymous = this.survey.isAnonymous;
        this._userVoteService.submitVotechaVote(this.vote).pipe(
            finalize(() => this.saving = false))
            .subscribe(() => {
                this.notify.info(this.l('ThanksForYourRanking'));
                this.getChartData();
            });

    }

    close(): void {
        const lastPage = localStorage.getItem("lastVotechaPage");
        if (lastPage) {
            window.history.back();
            //this._router.navigate([lastPage.substring(1, lastPage.indexOf('/post'))]);
        }
        else if (this.isMeninger24) {
            if (this.survey.areaType === 6 || this.survey.areaType === 7) {
                this._router.navigate(['public/site/', 'subarea', this.country, this.survey.areaName, this.survey.subAreaName]);
            }
            else if (this.survey.areaType === 4 || this.survey.areaType === 5) {
                this._router.navigate(['public/site/', 'area', this.country, this.survey.areaName]);
            }
            else {
                this._router.navigate(['public/site/', 'country', this.country]);
            }

        }
        else {
            this._router.navigate(['public/site/global']);
        }

    }



    surveyForm(): void {
        this._router.navigate(['app/main/user-survey/create-poll-wizard/', '00000000-0000-0000-0000-000000000000']);
    }


    signPetition(): void {
        var petitionSigning = new UserSigningInput();
        petitionSigning.pollId = this.survey.id;
        petitionSigning.countryId = this.survey.countryId;
        petitionSigning.countryName = this.survey.countryName;
        petitionSigning.iso = this.survey.iso;
        petitionSigning.areaId = this.survey.areaId;
        petitionSigning.areaName = this.survey.areaName;
        petitionSigning.subAreaId = this.survey.subAreaId;
        petitionSigning.subAreaName = this.survey.subAreaName;
        petitionSigning.areaName = this.survey.areaName;
        petitionSigning.areaTypeId = this.survey.areaTypeId;
        petitionSigning.areaType = this.survey.areaTypeId;
        petitionSigning.categoryColor = this.survey.colorCode;
        petitionSigning.categoryId = this.survey.categoryId;
        petitionSigning.categoryName = this.survey.categoryName;
        petitionSigning.subCategoryId = this.survey.subCategoryId;
        petitionSigning.subCategoryName = this.survey.subCategoryName;
        petitionSigning.displayQuestion = this.survey.displayQuestion;
        petitionSigning.showFullName = this.displayCode === 1 ? true : false;
        petitionSigning.isAnonymous = this.displayCode === 3 ? true : false;
        petitionSigning.petitionMessage = this.petitionMessage;
        petitionSigning.imgUrl = this.survey.imgUrl;


        this._petitionSigningServiceProxy.submitSignature(petitionSigning).pipe(
            finalize(() => this.saving = false))
            .subscribe(() => {
                this.notify.info(this.l('ThanksForYourSigning'));
                this.hasVoted = true;
                this.survey.petitionSignings++;
                this.getLatestSigning();
                this.getChartData();
            });
    }

    undoSigning(): void {
        this.message.confirm(
            this.l('AreYouSureToDeleteTheSignature', this.survey.name), this.l('AreYouSure'),
            isConfirmed => {
                if (isConfirmed && this.survey.vote?.id !== null) {
                    this._petitionSigningServiceProxy.deleteUserSignature(this.survey.vote.id)
                        .subscribe(() => {
                            this.notify.info(this.l('SuccessfullyDeleted'));
                            this.hasVoted = false;
                            this.getChartData();
                        });

                }
            }
        );
    }


    goToPetitionList(petitionId: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/petition-list/' + petitionId + '/' + this.survey.areaTypeId + '/' + this.survey.countryId]);
        }

    }

    goToVoteForm(id: string, country: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country, id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }

    changeLanguage(languageName: string): void {
        let input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        if (this.isUserLoggedIn) {
            this._profileServiceProxy.changeLanguage(input).subscribe(() => {
                abp.utils.setCookieValue(
                    "Abp.Localization.CultureName",
                    languageName,
                    new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                    abp.appPath
                );

                window.location.reload();
            });
        }
        else {
            abp.utils.setCookieValue(
                "Abp.Localization.CultureName",
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            location.reload();
        }
    }

    setActiveTab(index: number) {
        this.activeTabIndex = index;
    }

    setTwitterMetaTags(pollId: string, surveyImage: string, surveyTitle: string, surveyQuestion: string): void {
        var twitterSite = '@votecha';
        if (this.isMeninger24) twitterSite = '@Meninger24'
        this.meta.updateTag({ name: 'twitter:card', content: 'https://' + this.getSiteUrl() + '/assets/common/images/misc/tablet_1065196496.jpg' });
        this.meta.updateTag({ name: 'twitter:site', content: twitterSite });
        this.meta.addTag({ name: 'twitter:url', content: 'https://' + this.getSiteUrl() + '/public/surveyboard/' + this.country + "/" + pollId });
        this.meta.updateTag({ name: 'twitter:title', content: surveyQuestion });
        this.meta.updateTag({ name: 'twitter:description', content: this.l('ShareYourOpinions') });
        this.meta.updateTag({ name: 'twitter:image', content: surveyImage });
    }

    setFaceBookMetaTags(pollId: string, surveyImage: string, surveyTitle: string, surveyQuestion: string): void {
        this.meta.updateTag({ property: 'og:url', content: 'https://' + this.getSiteUrl() + '/public/surveyboard/' + this.country + "/" + pollId });
        this.meta.updateTag({ property: 'og:title', content: surveyQuestion });
        this.meta.updateTag({ property: 'og:description', content: this.l('ShareYourOpinions') });
        this.meta.updateTag({ property: 'og:image', content: surveyImage });
        this.meta.updateTag({ property: 'og:type', content: 'article' });
    }

    getSiteUrl(): string {
        var url: string = "www.opinions24.net";
        if (this.isMeninger24) {
            url = "www.meninger24.no";
        }
        return url;
    }

    onSigningAdded(event): void {
        this.survey.petitionSignings++;
        this.getLatestSigning();
        this.hasVoted = true;
    }

    setArticlesCountEmitter(articlesCount: number): void {
        this.articlesCount = articlesCount;
    }

    setProsConsCountEmitter(prosCount: number): void {
        this.prosConsCount = prosCount ? prosCount : 0;
    }

    signing(): void {
        this.signPetition();
    }

    unSigning(): void {
        this.undoSigning();
    }

    toggleRegisterPanel(): void {
        this.showRegisterPanel = !this.showRegisterPanel;
    }

    initFooter(): void {
        this.showFooter = false;
        if ((!this.isUserLoggedIn && (!this.survey.anonymousVotesEnabled || this.survey.unlocked) && this.survey.surveyTypeId !== 0) || this.survey.surveyTypeId === 0) {
            this.showFooter = true;
        }
    }

    closeFooter(): void {
        this.showFooter = false;
    }

    getAge(birthYear: number): number {
        return (new Date().getFullYear() - birthYear);
    }

    toggleAnonymousControls(): void {
        this.showAnonymousControls = !this.showAnonymousControls;
    }
}
