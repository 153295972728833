<div bsModal #modal="bs-modal" class="modal fade bs-modal-lg" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="active">            
                <div class="modal-header c-bg-white">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div class="caption">
                            <h1 class="modal-title">
                                <i class="icon-bar-chart font-blue-steel"></i>
                                <span class="caption-subject bold uppercase font-blue-steel">{{l("Share")}}</span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div id="fb-root"></div>
                    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v11.0"></script>

                    <h2>{{l('CopyLink')}}</h2>
                    <p class="bold">{{l('CopyLinkInfo')}}</p>
                    <p>{{l('CopyLink')}}: <span class="font-blue-steel bold">{{url}}</span></p>
                    <p><button class="btn btn-circle dark" (click)="copyUrl()"><i class="fa fa-copy"></i>{{l("CopyLink")}}</button></p>
                    <br />
                    <p>{{l('ShareOnFacebookInfo')}}</p>
                    <p>
                        <a class="btn btn-md btn-circle  btn-facebook" style="padding-left:0" [attr.href]="fbUrl" target="_blank">
                            <img class="icon-facebook" alt="facebook-logo" height="24" src="/assets/common/images/f_logo_RGB-Blue_58.png"> {{l('Share')}}
                        </a>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>                   
                </div>            
        </div>
    </div>    
</div>
