import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MediaPollArticleServiceProxy, PollStatisticsServiceProxy, ArticleRatingDto, MediaArticleListOutputStatus, BlogArticleOutput, MediaArticleListOutput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'related-articles',
    templateUrl: './related-articles.component.html',
    animations: [appModuleAnimation()]
})
export class RelatedArticlesComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;
    @Input() isUserLoggedIn: boolean = false;
    @Input() screenWidth: number;
    @Output() articlesCountEmitter = new EventEmitter<number>();

    articlesCount: number;

    public votechaArticles: BlogArticleOutput[] = [];
    public articles: MediaArticleListOutput[] = [];
    public filteredArticles: MediaArticleListOutput[] = [];
    public mediaList: MediaOptionItem[] = [];

    public iFrameWidth: string;
    public iFrameHeight: string;
    public active: boolean = false;
    public saving: boolean = false;
    public haveMultiLanguages: boolean = false;

    public sortingOrder: number;
    public userLanguageId: number;
    public siteLanguageId: number;
    public filterMediaTypeId: string;

    private cookieLangValue: string;

    constructor(
        injector: Injector,
        private _metaArticleService: MediaPollArticleServiceProxy,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private _router: Router,
        private _sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.articles = [];
        this.filterMediaTypeId = "2";
        this.sortingOrder = 1;
        this.setIFrameSize();
        this.getArticles();
    }

    setIFrameSize(): void {
        
        if (this.screenWidth > 1259) {
            this.iFrameWidth = "360px";
            this.iFrameHeight = "202px";
        }
        else if (this.screenWidth > 1153) {
            this.iFrameWidth = "320px";
            this.iFrameHeight = "180px";
        }
        else if (this.screenWidth > 990) {
            this.iFrameWidth = "235px";
            this.iFrameHeight = "132px";
        }
        else if (this.screenWidth > 849) {
            this.iFrameWidth = "280px";
            this.iFrameHeight = "157px";
        } 
        else if (this.screenWidth > 767 && this.screenWidth < 1154) {
            this.iFrameWidth = "235px";
            this.iFrameHeight = "132px";
        }       
       
        else if (this.screenWidth > 767) {
            this.iFrameWidth = "226px";
            this.iFrameHeight = "184px";
        }
        else if (this.screenWidth > 600) {
            this.iFrameWidth = "410px";
            this.iFrameHeight = "230px";
        }
       
        else if (this.screenWidth > 420) {
            this.iFrameWidth = "360px";
            this.iFrameHeight = "202px";
        }
        else {
            this.iFrameWidth = "300px";
            this.iFrameHeight = "168px";
        }
    }

    getSafeUrl(url: string): SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url)
    }

    getArticles(): void {
        if (this.survey !== undefined) {
            this.votechaArticles = [];
            this._pollStatisticsService.getPollArticles(this.survey.id, this.survey.areaTypeId, this.survey.countryId, this.survey.subCategoryId, this.cookieLangValue).subscribe((result) => {
                this.votechaArticles = result.blogArticles;
                this.articles = result.mediaArticles;
                this.userLanguageId = result.userLanguageId;
                this.siteLanguageId = result.siteLanguageId;
                this.articlesCount = this.votechaArticles.length + this.articles.length;
                this.articlesCountEmitter.emit(this.articlesCount);

                for (var i = 0; i < this.articles.length; i++) {
                    if (this.articles[i].mediaName?.toLowerCase() === 'youtube') {
                        if (this.articles[i].articleUrl.includes("watch?v=")) {
                            this.articles[i].articleUrl = this.articles[i].articleUrl.replace("watch?v=", "embed/");
                        }

                        this.filterMediaTypeId = "0";
                        this.haveMultiLanguages = true;
                    }
                }

                if (this.votechaArticles.length > 0) {
                    for (var i = 0; i < this.votechaArticles.length; i++) {
                        var article = new MediaArticleListOutput();
                        article.id = this.votechaArticles[i].id;
                        article.pollId = this.votechaArticles[i].pollId;
                        article.countryId = this.votechaArticles[i].countryId;
                        article.title = this.votechaArticles[i].displayTitle;
                        article.description = this.votechaArticles[i].displayPreamble;
                        article.mediaLogo = "/assets/common/images/favicon/apple-icon-57x57.png";
                        article.mediaUrl = "www.opinions24.net";
                        article.mediaName = "opinions24.net";
                        article.isVotecha = true;
                        article.languageId = this.votechaArticles[i].primaryLanguageId;
                        article.status = MediaArticleListOutputStatus._4;
                        article.imageUrl = this.votechaArticles[i].imageUrl;
                        article.published = this.votechaArticles[i].published;
                        article.alternateText = this.votechaArticles[i].alternateText;
                        article.author = this.votechaArticles[i].authorName;
                        this.articles.push(article);
                    }
                }

                this.filterArticles();
            });
        }

    }

    filterArticles(): void {       
        if (this.articles.length > 0) {
            this.filteredArticles = [];
            this.mediaList = [];
            var allItem = new MediaOptionItem();
            allItem.text = this.l('All')
            allItem.value = "0";
            this.mediaList.push(allItem);
            var englishItem = new MediaOptionItem();
            englishItem.text = this.l('EnglishArticles')
            englishItem.value = "1";
            this.mediaList.push(englishItem);
            var userLanguageItem = new MediaOptionItem();
            userLanguageItem.text = this.l('UserLanguageArticles')
            userLanguageItem.value = "2";
            this.mediaList.push(userLanguageItem);
            var firstArticleLangId = this.articles[0].languageId;
            for (var i = 0; i < this.articles.length; i++) {
                var optionItem = new MediaOptionItem();
                optionItem.text = this.articles[i].mediaName;
                optionItem.value = this.articles[i].mediaName;
                if (optionItem.text.includes(".no")) {
                    optionItem.text = optionItem.text.replace(".no", "");
                }
                if (!this.mediaItemAlreayExist(optionItem.text)) {                   
                    this.mediaList.push(optionItem);
                }
                if (this.articles[i].languageId !== firstArticleLangId) {
                    this.haveMultiLanguages = true;
                }
                if (this.filterMediaTypeId === '1' && this.articles[i].languageId.toString() === '1') {
                    var selectedLanguageArticle = this.articles[i];
                    this.filteredArticles.push(selectedLanguageArticle);
                }
                else if (this.filterMediaTypeId === '2' && this.articles[i].languageId.toString() === this.userLanguageId.toString()) {
                    var selectedLanguageArticle = this.articles[i];
                    this.filteredArticles.push(selectedLanguageArticle);
                }

                else if (this.filterMediaTypeId !== "0" && this.filterMediaTypeId !== "1" && this.filterMediaTypeId !== "2") {                   
                    if (this.articles[i].mediaName === this.filterMediaTypeId) {
                        var selectedLanguageArticle = this.articles[i];
                        this.filteredArticles.push(selectedLanguageArticle);
                    }                    
                }
            }
            if (this.filterMediaTypeId === '0') {
                this.filteredArticles = this.articles;
            }

            if (this.mediaList.length > 1) this.haveMultiLanguages = true;
        }
    }

    private mediaItemAlreayExist(itemText: string): boolean {
        var exist: boolean = false;
        for (var i = 0; i < this.mediaList.length; i++) {
            if (this.mediaList[i].text.toLocaleLowerCase() === itemText.toLocaleLowerCase()) {
                exist = true;
            }
        }
        return exist;
    }

    sortingOrder_click(sortingId: number): void {
        this.sortingOrder = sortingId;
        if (sortingId === 1) {
            this.filteredArticles.sort(this.GetSortOrderDesc("published"));
        }
        else if (sortingId === 2) {
            this.filteredArticles.sort(this.GetSortOrderAsc("name"));
        }
        else if (sortingId === 3) {
            this.filteredArticles.sort(this.GetSortOrderDesc("likes"));
        }
    }

    GetSortOrderAsc(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    GetSortOrderDesc(prop) {
        return function (a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }


    deleteArticle(article: MediaArticleListOutput): void {
        if (article.isVotecha) {
            this.message.confirm(
                this.l('AreYouSureToRemoveTheArticle', article.title), this.l('AreYouSure') + " " + this.l('SetToOffline'),
                isConfirmed => {
                    if (isConfirmed) {
                        this._metaArticleService.removeVotechaArticle(article.id, this.survey.id, this.survey.countryId)
                            .subscribe(() => {
                                this.notify.info(this.l('SuccessfullyDeleted'));
                                _.remove(this.filteredArticles, article);
                                _.remove(this.articles, article);
                            });
                    }
                }
            );
        }
        else {
            this.message.confirm(
                this.l('AreYouSureToDeleteTheArticle', article.title), this.l('AreYouSure'),
                isConfirmed => {
                    if (isConfirmed) {
                        this._metaArticleService.deleteArticle(article.id, this.survey.id, this.survey.countryId)
                            .subscribe(() => {
                                this.notify.info(this.l('SuccessfullyDeleted'));
                                _.remove(this.filteredArticles, article);
                                _.remove(this.articles, article);
                            });
                    }
                }
            );
        }

    }

    goToArticle(article: MediaArticleListOutput): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/article/', article.id, article.pollId, article.countryId]);
        }
        else {
            this._router.navigate(['public/article/', article.id, article.pollId, article.countryId]);
        }
    }

 


    public articleForm(): void {
        var id: string = "00000000-0000-0000-0000-000000000000";
        this._router.navigate(['app/main/user-article/create-article-wizard/', id, this.survey.id, this.survey.countryId]);
    }
}

 class MediaOptionItem {
     text: string;
     value: string;
}

