<header class="sticky-header"></header>

<div class="clearfix">
</div>
<div class="page-container" [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}">
    <div class="page-sidebar-wrapper">
        <side-bar></side-bar>
    </div>

    <div class="page-content-wrapper">
        <div class="page-content" role="main">
            <div [ngClass]="{'page-content-margin': screenWidth > 991}">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <!-- chat-bar.component here... -->

</div>
<div class="clearfix margin-top4pr">
</div>
<footer-public>
    <div class="page-footer">
        <footer></footer>
    </div>
    <chat-bar *ngIf="appSession.application.features['SignalR']"></chat-bar>
</footer-public>

