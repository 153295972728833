import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, HostListener  } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto, VotechaChartDto, ChartItemDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'tour-wizard',
    templateUrl: './tour-wizard.component.html',
    styleUrls: ['./tour-wizard.component.css'],
    animations: [appModuleAnimation()]
})
export class TourWizardComponent extends AppComponentBase implements OnInit {

    @Input() screenWidth: number = 0;

    pageId: number = 0;
    btnForwardEnabled: boolean = false;
    btnBackEnabled: boolean = false;

    constructor(
        injector: Injector,
        private _router: Router,

    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.pageId = 1
        this.btnForwardEnabled = true;
        this.btnBackEnabled = false;
    }

    forward(): void {
        if (this.pageId < 11) {
            this.pageId++
            this.btnBackEnabled = true;
        }
        else {
            this.btnForwardEnabled = false;
        }
    }

    back(): void {
        if (this.pageId > 1) {
            this.pageId--
            this.btnForwardEnabled = true;
        }
        else {
            this.btnBackEnabled = false;
        }
    }

    exit(): void {
        this._router.navigate(['']);
    }
}
