
    <div class="form-group">
        <input type="file" ng2FileSelect [uploader]="uploader" />
        <span class="help-block m-b-none">{{l("ProfilePicture_Change_Info")}}</span>
    </div>
    <img id="ImageResize"/>
    <div class="form-group form-md-line-input form-md-floating-label no-hint">
        <img id="newPhoto" alt="" class="img-responsive" [src]="photoInput.azureUri" />
    </div>
    <div>
        <button type="button" (click)="save()" class="btn btn-primary blue" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Upload")}}</span></button>
    </div>

