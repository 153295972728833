
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" *ngIf="screenWidth > 520">
        <div class="petition-portlet light" (click)="goToVoteForm(inputSurvey.id)" [ngStyle]="{'height': surveyContainerHeight, 'background-image': gradientExpr}">
            <!--<div class="survey-card-header" [ngStyle]="{'background-color': inputSurvey.colorCode ? inputSurvey.colorCode : '#4b77be'}">
        <div style="height:10px;"></div>
        <h3 [ngStyle]="{'font-size': inputSurvey.subCategoryName.length < 24 ? 'x-large' : 'medium'}">{{inputSurvey.subCategoryName}}</h3>
        <div class="pull-right" *ngIf="inputSurvey.surveyTypeId === 0">
            <i class="fa fa-bullhorn margin-right-10 font-white"></i>
        </div>
    </div>-->

            <div class="margin-top-20 margin-bottom-20">
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                    <img id="{{inputSurvey.id}}" alt="{{inputSurvey.alternateText}}" class="img-responsive rounded-corners border-white" [src]="inputSurvey.imgUrl" />
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <h3 class="margin-top2pr" [ngClass]="{'font-media-h1':screenWidth > 768, 'font-media-h2':screenWidth < 769 && screenWidth > 563,'font-media-h3':screenWidth < 564}">
                        <span class="uppercase bold" [ngStyle]="{'color': inputSurvey.colorCode ? '#fff' : '#000'}">
                            <span *ngIf="inputSurvey.displayQuestion !== null">
                                {{inputSurvey.displayQuestion}}!
                            </span>
                            <span *ngIf="inputSurvey.displayQuestion === null">{{inputSurvey.question}}!</span>
                        </span>
                    </h3>
                    <div style="margin-top:15%">
                        <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Online'">
                            <button class="btn btn-circle btn-outline  white" [ngClass]="{'btn-lg':screenWidth > 768, 'btn-md':screenWidth < 769}" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && (!isUserLoggedIn || !inputSurvey.hasVoted)">
                                <span *ngIf="isUserLoggedIn">{{l("SignPetition")}}</span><span *ngIf="!isUserLoggedIn" class="margin-right-10">{{l("Petition")}}</span> <i class="fa fa-arrow-right"></i>
                            </button>
                            <button class="btn btn-circle btn-outline shadow btn-success" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && isUserLoggedIn && inputSurvey.hasVoted">
                                <i class="fa fa-check-circle"></i> {{l("Signed")}}
                            </button>
                        </div>
                        <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Offline'">
                            <label class="label label-danger">{{l("Offline")}}</label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 margin-top4pr">
                <span *ngFor="let tag of inputSurvey.tags">
                    <span class="font-white bold margin-right-10">
                        {{tag}}
                    </span>
                </span>
                <!--<span class="pull-right">
                    <i class="margin-right-10 famfamfam-flags {{inputSurvey.iso | lowercase}}"></i>
                    <i class="fa fa-bullhorn font-white"></i>
                </span>-->
            </div>
        </div>
    </div>

    <div class="col-xs-12" *ngIf="screenWidth < 521">
        <div class="petition-portlet light" (click)="goToVoteForm(inputSurvey.id)" [ngStyle]="{'background-image': gradientExpr}">
            <img id="{{inputSurvey.id}}" alt="{{inputSurvey.alternateText}}" class="rounded-corners img-responsive border-white" [src]="inputSurvey.imgUrl" />

            <div class="margin-top-20">
                
                <div class="container">
                    <h3 class="margin-top2pr font-media-h1">
                        <span class="uppercase bold" [ngStyle]="{'color': inputSurvey.colorCode ? '#fff' : '#000'}">
                            <span *ngIf="inputSurvey.displayQuestion !== null">
                                {{inputSurvey.displayQuestion}}!
                            </span>
                            <span *ngIf="inputSurvey.displayQuestion === null">{{inputSurvey.question}}!</span>
                        </span>
                    </h3>
                    <div style="margin-top:15%">
                        <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Online'">
                            <button class="btn btn-circle btn-outline shadow font-white white" [ngClass]="{'btn-lg':screenWidth > 768, 'btn-md':screenWidth < 769}" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && (!isUserLoggedIn || !inputSurvey.hasVoted)">
                                <span *ngIf="isUserLoggedIn">{{l("SignPetition")}}</span><span *ngIf="!isUserLoggedIn" class="margin-right-10">{{l("Petition")}}</span> <i class="fa fa-arrow-right"></i>
                            </button>
                            <button class="btn btn-circle btn-outline shadow btn-success" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && isUserLoggedIn && inputSurvey.hasVoted">
                                <i class="fa fa-check-circle"></i> {{l("Signed")}}
                            </button>
                        </div>
                        <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Offline'">
                            <label class="label label-danger">{{l("Offline")}}</label>
                        </div>
                    </div>
                    <div class="col-xs-12 margin-top-30">
                        <span *ngFor="let tag of inputSurvey.tags">
                            <span class="font-white bold margin-left2pr">
                                {{tag}}
                            </span>
                        </span>                      
                    </div>
                </div>
               
            </div>
        </div>
    </div>

