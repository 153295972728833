<div [@routerTransition] style="max-width:1100px">
    <div class="row margin-bottom-5">
        <div class="col-xs-6">
            <div class="page-head">
                <div class="page-title">
                    <h1>
                        <span>{{l("Notifications")}}</span>
                    </h1>
                </div>
            </div>
        </div>
        <div class="col-xs-6 text-right">
            <button class="btn btn-default" (click)="openNotificationSettingsModal()"><i class="fa fa-cog"></i> {{l("NotificationSettings")}}</button>
            <button class="btn btn-primary blue" (click)="setAllNotificationsAsRead()"><i class="fa fa-check"></i> {{l("SetAllAsRead")}}</button>
        </div>
    </div>
    <div class="portlet light margin-bottom-0">
        <div class="portlet-title portlet-title-filter">
            <div class="inputs inputs-full-width">
                <div class="portlet-input">
                    <form>
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <select #TargetValueFilterSelectionCombobox
                                            class="form-control"
                                            [(ngModel)]="readStateFilter"
                                            name="readStateFilter"
                                            jq-plugin="selectpicker">
                                        <option value="ALL">{{l("All")}}</option>
                                        <option value="UNREAD">{{l("Unread")}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <button class="btn btn-default" style="display: block; width: 100%" (click)="getNotifications()"><i class="fa fa-refresh"></i> {{l("Refresh")}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="portlet-body">
            <div id="NotificationsTable" #table></div>
        </div>
    </div>
</div>
