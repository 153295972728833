import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChartData, ChartItemDto,EntityItemDto } from '@shared/service-proxies/service-proxies';



import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'template-rating-bars',
    templateUrl: './template-rating-bars.component.html',
  animations: [appModuleAnimation()]
})
export class TemplateRatingBarsComponent extends AppComponentBase implements OnInit,OnChanges {

    @Input() chartDataFromParent: ChartData;
   
    

    public chartActive: boolean = false;    
    public saving: boolean = false;
    chartData: ChartData;
    totalItems: ChartItemDto[] = [];
    womenItems: ChartItemDto[] = [];
    menItems: ChartItemDto[] = [];
    youthItems: ChartItemDto[] = [];
    youngAdultItems: ChartItemDto[] = [];
    middleAgedItems: ChartItemDto[] = [];
    elderlyItems: ChartItemDto[] = [];
    answerId: number;
    activeRatingTabIndex: number = 0;

    constructor(
        injector: Injector       
       
    ) {
        super(injector);
    }

    ngOnInit() {
        this.chartActive = false;
        if (this.chartDataFromParent !== null && this.chartDataFromParent !== undefined && this.chartDataFromParent.chartDataList !== undefined) {
            this.totalItems = [];
            this.womenItems = [];
            this.menItems = [];
            this.youthItems = [];
            this.youngAdultItems = [];
            this.middleAgedItems = [];
            this.elderlyItems = [];
            this.activeRatingTabIndex = 0;

            this.chartData = this.chartDataFromParent;
            if (this.chartData.chartDataList.length > 0) {
                this.totalItems = this.fillItemArrays('total');
                this.womenItems = this.fillItemArrays('women');
                this.menItems = this.fillItemArrays('men');
                this.youthItems = this.fillItemArrays('youth');
                this.youngAdultItems = this.fillItemArrays('youngAdult');
                this.middleAgedItems = this.fillItemArrays('middleAged');
                this.elderlyItems = this.fillItemArrays('elderly');
                this.chartActive = true;
            }
        }       
    }
    ngOnChanges() {
        this.ngOnInit();
    }

    fillItemArrays(arrayType: string): ChartItemDto[] {
        var items: ChartItemDto[] = [];
        for (var i = 0; i < this.chartData.chartDataList.length; i++) {
            if (arrayType === 'total' && this.chartData.chartDataList[i].totalVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'women' && this.chartData.chartDataList[i].womenVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'men' && this.chartData.chartDataList[i].menVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'youth' && this.chartData.chartDataList[i].youthVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'youngAdult' && this.chartData.chartDataList[i].youngAdultVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'middleAged' && this.chartData.chartDataList[i].middleAgedVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'elderly' && this.chartData.chartDataList[i].elderlyVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
        }

        return items;
    }

  }
