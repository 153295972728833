import { Component, OnInit, ViewChild, Injector, InjectionToken,  Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SurveyListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Observable } from 'rxjs';
import * as $ from 'jquery';
import 'owl.carousel';

@Component({
  selector: 'survey-carousel',
    templateUrl: './survey-carousel.component.html',
  animations: [appModuleAnimation()]
})
export class SurveyCarouselComponent extends AppComponentBase implements OnInit {

    //@Input() surveys: SurveyListDto[] = [];
     
    public active: boolean = false;    
    public saving: boolean = false;
    


    constructor(
        injector: Injector       
       
    ) {
        super(injector);
    }

    ngOnInit() {        
       
        $(document).ready(function () {
            (function ($) {
                var owl = $('.owl-carousel');
                owl.owlCarousel({
                    items: 3,
                    loop: true,
                    margin: 10,
                    autoplay: true,
                    autoplayTimeout: 3000,
                    autoplayHoverPause: true
                });
            })(jQuery);
        });
    }
    
   
  }
