import { Component, ViewChild, Injector, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PollStatisticsServiceProxy, VotechaVoteServiceProxy, PollListDto, SiteDto, SiteOutput, CategoryChartDto, CategoryPageDto, SubCategoryDto } from '@shared/service-proxies/service-proxies';
import { MySiteServiceProxy, UserSitesDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CookieHelper } from '@shared/helpers/CookieHelper';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';
import * as _ from 'lodash';
import { EmptyError, Observable } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import * as $ from 'jquery';
import 'owl.carousel';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { UrlService } from '@shared/url.service';
import { toNumber } from 'lodash';

@Component({
    templateUrl: './category-page.component.html',
    animations: [appModuleAnimation()]
})
export class CategoryPageComponent extends AppComponentBase implements OnInit {
    @HostListener('window:resize', ['$event'])
    public isUserLoggedIn: boolean = false;
    public userHasAccount: boolean = false;
    public strGoToGlobalSite: string = "";
    public strGoToCountrySite: string = "";
    public strGoToAreaSite: string = "";
    public strGoToSubAreaSite: string = "";
    public strGoToCategorySite: string = "";
    public strGoToAboutSite: string = "";
    public strGoToRegistrationSite: string = "";
    public strSelectCountry: string = "";
    public strSelectRegion: string = "";
    public strSelectCategory: string = "";
    public strSelectCountryAreaDef: string = "";
    public showOnlyUnansweredDateStorageValue = "showOnlyUnanswered";

    countrySites$: Observable<SiteOutput[]>;
    areaSites$: Observable<SiteOutput[]>;
    areaSites: SiteOutput[] = [];
    subAreaSites$: Observable<SiteOutput[]>;
    subAreaSites: SiteOutput[] = [];
    surveys: PollListDto[] = [];
    politicalPolls: PollListDto[] = [];
    surveysFiltered: PollListDto[] = [];
    petitions: PollListDto[] = [];
    petitionsFiltered: PollListDto[] = [];
    answeredPollIds$: Observable<string[]>;
    answeredPollIds: string[] = [];
    subCategories: any[] = [];

    userSite: UserSitesDto;
    site: CategoryPageDto;
    category: CategoryChartDto;
    cookieLangValue: string;
    areaType: string;
    sitePath: string = "";
    countryName: string;
    countryTranslation: string;
    areaName: string;
    subAreaName: string;
    categoryName: string;
    categoryTranslation: string;
    containerHeight: string;
    previousUrl: Observable<string> = this.urlService.previousUrl$;
    previousUrlStr: string = "";
    pagePath: string = "";
    topArticleId: string;

    categoryId: number;
    siteTypeId: number;   
    tempSurveyCount: number;
    surveyTypeFilterId: number;
    filterSubCategoryId: number;
    pollsStorageLimit: number;

    hasSameSubAreaName: boolean;
    isGlobal: boolean;
    isCountry: boolean;
    isArea: boolean;
    isSubArea: boolean;
    allAnswered: boolean;
    active: boolean = false;
    screenWidth: any;
    showOnlyUnanswered: boolean;
    showPoliticalPage: boolean;
    today: Date;
    lastPosision: number;

    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private urlService: UrlService,
        private _voteServiceProxy: VotechaVoteServiceProxy,
        private _mySiteService: MySiteServiceProxy
    ) {
        super(injector);
        this.getScreenSize();
    }

    ngOnInit() {
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.urlService.previousUrl$.subscribe((previousUrl: string) => {
            if (previousUrl) {
                this.previousUrlStr = previousUrl;
            }
        });
        this.urlService.lastPosision$.subscribe((lastPosision: string) => {
            if (lastPosision) {
                this.lastPosision = toNumber(lastPosision);
            }

        });
        this.today = new Date();
        this.surveyTypeFilterId = -1;
        this.filterSubCategoryId = 0;
        this.pollsStorageLimit = 2;
        this.pagePath = this._router.url;
        this.strGoToGlobalSite = this.l('GoToGlobalSite');
        this.strGoToCountrySite = this.l('GoToCountrySite');
        this.strGoToAreaSite = this.l('GoToArealSite');
        this.strGoToSubAreaSite = this.l('GoToSubAreaSite');
        this.strGoToCategorySite = this.l('GoToCategorySite');
        this.strGoToAboutSite = this.l('GoToAboutSite');
        this.strGoToRegistrationSite = this.l('GoToRegistrationSite');
        this.strSelectCategory = this.l('Select') + ' ' + this.l('Category');
        this.strSelectCountry = this.l('Select') + ' ' + this.l('Country');
        this.strSelectRegion = this.l('Select') + ' ' + this.l('Area');
        this.strSelectCountryAreaDef = this.l('Select') + ' ' + this.l('CountryAreaDef');
        this.strSelectCountryAreaDef = this.l('Select') + ' ' + this.l('CountrySubAreaDef');
        this.sitePath = "global";
        this.countryName = "global";
        this.areaName = "";
        this.subAreaName = "";
        this.tempSurveyCount = 0;
        this.userSite = new UserSitesDto();
        this.userSite.countrySiteId = 0;
        this.surveys = [];
        this.petitions = [];
        this.site = new CategoryPageDto();
        this.site.countryId = 0;
        this.site.areaId = 0;
        this.site.subAreaId = 0;
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
        //if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.cookieLangValue = "nb-NO";            
        }
        if (!this.userHasAccount) {
            this.userHasAccount = CookieHelper.getUserSignedUpValue();
        }
        this.sitePath = "";
        this.isGlobal = false;
        this.isCountry = false;
        this.isArea = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'];
            this.categoryName = params['categoryName'];
            if (this.areaType === null || this.areaType === undefined || this.areaType === 'global') {
                this.areaType = 'global';
                this.siteTypeId = 1;
                this.sitePath = 'global';
                this.isGlobal = true;                
                this.getGlobalCategorySurveys();
            }
            else {
                this.sitePath = params['country'];
                this.countryName = params['country'];
                if (this.areaType === 'country') {
                    this.siteTypeId = 2;
                    this.isCountry = true;
                    this.getCountryCategoryPageData();
                }
                else if (this.areaType === 'area') {
                    this.sitePath = params['country'] + "/" + params['area'];
                    this.areaName = params['area'];
                    this.siteTypeId = 3;
                    this.isArea = true;
                    this.getAreaCategoryPageData();
                }
                else if (this.areaType === 'subarea') {
                    this.sitePath = params['country'] + "/" + params['area'] + "/" + params['subArea'];
                    this.areaName = params['area'];
                    this.subAreaName = params['subArea'];
                    this.siteTypeId = 4;
                    this.isSubArea = true;
                    this.getSubAreaCategoryPageData();
                }
            }

            if (!this.showOnlyUnanswered && this.previousUrlStr.includes("surveyboard")) {
                var jsPosision = this.lastPosision;
                setTimeout(function () { window.scrollTo(0, jsPosision); }, 1000);
            }
        });

        this.showPoliticalPage = this.areaType === 'country' && this.categoryName === 'Governments' ? true : false;

        if (this.showPoliticalPage) {
            this.getPoliticalPolls();
        }
        
        if (this.isUserLoggedIn) {
            this.getUserSites();
            this.getFilterSettings();
        }

        window.scrollTo(0, 0);
    }

    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }


    filterAnsweredPolls(): void {
        if (this.isUserLoggedIn && this.showOnlyUnanswered) {
            this.surveysFiltered = [];
            this.petitionsFiltered = [];
            var tempPolls: PollListDto[] = [];
            for (var j = 0; j < this.surveys.length; j++) {
                if (!this.surveys[j].hasVoted) {
                    var unansweredPoll= this.surveys[j];
                    tempPolls.push(unansweredPoll);
                }
            }
            this.surveysFiltered = tempPolls;

            var tempPetitions: PollListDto[] = [];
            for (var j = 0; j < this.petitions.length; j++) {
                if (!this.petitions[j].hasVoted) {
                    var unsignedPetition = this.petitions[j];
                    tempPetitions.push(unsignedPetition);
                }
            }
            this.petitionsFiltered= tempPetitions;

            if (this.petitionsFiltered.length === 0 && this.surveysFiltered.length === 0 && (this.surveys.length > 0 || this.petitions.length > 0)) {
                this.allAnswered = true;
            }
        }
    }

    getFilterSettings(): void {
        
        let dateStorage = localStorage.getItem(this.showOnlyUnansweredDateStorageValue);
        if (dateStorage) {
            this.showOnlyUnanswered = JSON.parse(dateStorage);
        }
        else {
            this._pollStatisticsService.getFilterAnsweredPollsSettings().subscribe((result) => {
                this.showOnlyUnanswered = result;
            });
        }        
    }

    getGlobalCategorySurveys(): void {
        const categoryDateStorageValue = `categoryGlobalData_date_${this.sitePath}_${this.cookieLangValue}`;
        const categoryStorageValue = `categoryGlobalData_${this.categoryName}_${this.sitePath}_${this.cookieLangValue}`;
        let storageDays = 5;
        let categoryGlobalDataStorage = null;
        let dateStorage = localStorage.getItem(categoryDateStorageValue);
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < 2) {
                categoryGlobalDataStorage = localStorage.getItem(categoryStorageValue);
            }
        }

        if (storageDays < 2 && categoryGlobalDataStorage) {
            this.surveys = JSON.parse(categoryGlobalDataStorage);
            this.surveysFiltered = this.surveys;
            this.active = true;
        }
        else {
            this._pollStatisticsService.getCategoryGlobalPolls(this.categoryName, this.cookieLangValue).subscribe((result) => {
                this.surveys = result.items;
                this.surveysFiltered = this.surveys;
                this.active = true;
                localStorage.setItem(categoryStorageValue, JSON.stringify(this.surveys));
                localStorage.setItem(categoryDateStorageValue, JSON.stringify(JSON.stringify(this.today)));                
                if (this.isUserLoggedIn) {
                    this.getAnswered();
                }
            });
        }        
    }
    getCountryCategoryPageData(): void {
        const categoryDateStorageValue = `categoryCountryData_date_${this.sitePath}_${this.cookieLangValue}`;
        const categoryStorageValue = `categoryCountryData_${this.categoryName}_${this.sitePath}_${this.cookieLangValue}`;
        let storageDays = 5;
        let categoryCountryDataStorage = null;
        let dateStorage = localStorage.getItem(categoryDateStorageValue);
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < 2) {
                categoryCountryDataStorage = localStorage.getItem(categoryStorageValue);
            }
        }
        
        if (storageDays < 2 && categoryCountryDataStorage) {
            this.site = JSON.parse(categoryCountryDataStorage);
            this.initSiteDate();
        }
        else {
            this._pollStatisticsService.getCategoryCountryData(this.categoryName, this.sitePath, this.cookieLangValue).subscribe((result) => {
                this.site = result;
                localStorage.setItem(categoryStorageValue, JSON.stringify(this.site));
                localStorage.setItem(categoryDateStorageValue, JSON.stringify(JSON.stringify(this.today)));
                this.initSiteDate();
            });            
        }
    }
   
    initSiteDate(): void {
        this.surveys = this.site.polls.items;
        this.surveysFiltered = this.surveys;
        this.active = true;
        this.categoryName = this.site.categoryName;
        this.categoryTranslation = this.site.categoryTranslation;
        this.category = new CategoryChartDto();
        this.category.categoryName = this.site.categoryName;
        this.category.colorCode = this.site.polls?.items[0]?.colorCode;
        this.category.categoryId = this.site.categoryId;
        this.countryName = this.site.countryName;
        this.countryTranslation = this.site.countryTranslation;
        this.fillSubCategoryList();
        if (this.isUserLoggedIn) {
            this.getAnswered();            
        }
    }

    //Only in Norway
    getPoliticalPolls(): void {
        let storageDays = 5;
        let politicalPollsStorage = null;
        let dateStorage = localStorage.getItem("politicalPolls_date");
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < this.pollsStorageLimit) {
                politicalPollsStorage = localStorage.getItem("politicalPolls");
            }
        }

        if (storageDays < this.pollsStorageLimit && politicalPollsStorage) {
            this.politicalPolls = JSON.parse(politicalPollsStorage);
        }
        else {
            this._pollStatisticsService.getPoliticalChartPolls("Norway", "nb-NO").subscribe((result) => {
                this.politicalPolls = result;
                localStorage.setItem("politicalPolls", JSON.stringify(this.politicalPolls));
                localStorage.setItem("politicalPolls_date", JSON.stringify(this.today));
            });
        }
    }

    getAnswered(): void {
        this.answeredPollIds = [];
        this._voteServiceProxy.getUsersVotedPollIds().subscribe((result) => {
            this.answeredPollIds = result;
            for (var i = 0; i < this.surveys.length; i++) {
                for (var k = 0; k < this.answeredPollIds.length; k++) {
                    if (this.surveys[i].id === this.answeredPollIds[k]) {
                        this.surveys[i].hasVoted = true;
                    }
                }
            }
            this.surveysFiltered = this.surveys;
            this.filterAnsweredPolls();
        });
    }

    fillSubCategoryList(): void {
        for (var i = 0; i < this.surveys.length; i++) {
            const subCategoryId = this.surveys[i].subCategoryId;
            const subCategoryName = this.surveys[i].subCategoryName;
            const subCat = { "subCategoryId": subCategoryId, "subCategoryName": subCategoryName }
            if (!(this.itemExistInArray(subCat.subCategoryId))) {
                this.subCategories.push(subCat);
            }
        }

        this.subCategories =  _.sortBy(this.subCategories, "subCategoryName");        
    }

    itemExistInArray(subCategoryId: number): boolean {
        var itemInArray = false;
        for (var i = 0; i < this.subCategories.length; i++) {
            if (this.subCategories[i].subCategoryId === subCategoryId) {
                itemInArray = true;
                break;
            }
        }
        return itemInArray;
    }

    fillPetitions(): void {
        this.petitions = [];
        const tempPolls: PollListDto[] = [];
        if (this.surveys.length > 0) {
            for (var i = 0; i < this.surveys.length; i++) {
                if (this.surveys[i].surveyTypeId === 0) {
                    var petition = this.surveys[i];
                    this.petitions.push(petition);
                }
                else {
                    var poll = this.surveys[i];
                    tempPolls.push(poll);
                }
            }
        }
        this.containerHeight = this.getContainerHeight(this.surveys.length);
        this.surveysFiltered = tempPolls;
        this.petitionsFiltered = this.petitions;
        this.filterAnsweredPolls();
    }
    getAreaCategoryPageData(): void {
        this._pollStatisticsService.getCategoryAreaData(this.categoryName, this.sitePath, this.cookieLangValue).subscribe((result) => {
            this.initSiteDate();           
            this.fillPetitions();
        });
    }

    getSubAreaCategoryPageData(): void {
        this._pollStatisticsService.getCategorySubAreaData(this.categoryName, this.sitePath, this.cookieLangValue).subscribe((result) => {
            this.site = result;
            this.initSiteDate();

            if (this.site?.areaName !== null && this.site?.areaName !== '') {
                this.hasSameSubAreaName = this.sameAsSubAreaName(this.site.areaName);
            }
            this.fillPetitions();
        });
    }

    getUserSites(): void {
        this._mySiteService.getUserSites().subscribe((result) => {
            this.userSite = result;
        });
    }


    goToGlobalSite(): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'global', 1]);
        }
        else {
            this._router.navigate(['public/site', 'global', 1]);
        }
    }

    goToCountrySite(countryName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'country', this.userSite.countryName]);
        }
        else {
            this._router.navigate(['public/site/', 'country', countryName]);
        }
    }

    goToAreaSite(areaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'area', this.userSite.countryName, this.userSite.areaName]);
        }
        else {
            this._router.navigate(['public/site/', 'area', this.countryName, areaName]);
        }
    }

    goToSubAreaSite(subAreaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'subarea', this.userSite.countryName, this.userSite.areaName, this.userSite.subAreaName]);
        }
        else {
            this._router.navigate(['public/site/', 'subarea', this.countryName, this.areaName, subAreaName]);
        }
    }


    goToAboutPage(): void {
        this._router.navigate(['public/about']);
    }

    goToCategorySite(categoryName: string): void {
        if (this.isUserLoggedIn) {
            if (this.siteTypeId === 1) {
                this._router.navigate(['app/main/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['app/main/category', categoryName, 'country', this.userSite.countrySitePath]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['app/main/category', categoryName, 'area', this.countryName, this.userSite.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['app/main/category', categoryName, 'subarea', this.countryName, this.userSite.areaName, this.userSite.subAreaName]);
            }
        }
        else {
            if (this.siteTypeId === 1) {
                this._router.navigate(['public/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['public/category', categoryName, 'country', this.countryName]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['public/category', categoryName, 'area', this.countryName, this.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['public/category', categoryName, 'subarea', this.countryName, this.areaName, this.subAreaName]);
            }

        }
    }

    GoToRegistration(): void {
        this._router.navigate(['account/register']);
    }

    sameAsSubAreaName(areaName: string): boolean {
        var hasSameName: boolean = false;
        for (var i = 0; i < this.subAreaSites.length; i++) {
            if (this.subAreaSites[i].name === areaName) {
                hasSameName = true;
                break;
            }
        }
        return hasSameName;
    }


    getContainerHeight(surveyCount: number): string {
        var height: number = 520;
        if (surveyCount > 3) {
            height = Math.ceil(surveyCount / 3) * 520;
        }
        return height.toString() + "px";
    }

    chbOnlyUnansweredChanged(): void {
        localStorage.setItem(this.showOnlyUnansweredDateStorageValue, JSON.stringify(this.showOnlyUnanswered));
        this._pollStatisticsService.setFilterAnsweredPollsSettings(this.showOnlyUnanswered).subscribe(() => {
            if (this.showOnlyUnanswered) {
                this.filterAnsweredPolls();
            }
            else {
                if (this.areaType === null || this.areaType === undefined || this.areaType === 'global') {
                    this.getGlobalCategorySurveys();
                }
                else if (this.areaType === 'country') {
                    this.getCountryCategoryPageData();
                }
                else if (this.areaType === 'area') {
                    this.getAreaCategoryPageData();
                }
                else {
                    this.getSubAreaCategoryPageData();
                }
            }
        });
    }

}
