import { Component,ViewChild, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PollStatisticsServiceProxy, BlogArticleOutput } from '@shared/service-proxies/service-proxies';
import { MySiteServiceProxy, UserSitesDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { Meta } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';

@Component({
    templateUrl: './article.component.html',
  animations: [appModuleAnimation()]
})
export class ArticleComponent extends AppComponentBase implements OnInit {
    isUserLoggedIn: boolean = false;
    articleId: string = "";
    pollId: string = "";
    countryId: number = 0;
    article: BlogArticleOutput;
    cookieLangValue: string;


    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private meta: Meta

    )
    {
        super(injector);

    }

    ngOnInit() {
        registerLocaleData(localeNorwegian);
        this.article = new BlogArticleOutput();
        this.activatedRoute.params.subscribe((params: Params) => {
            this.articleId = params['id'];
            this.pollId = params['pollId'];
            this.countryId = params['countryId'];
            this.getArticle();
        });
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");        
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        if (this.isUserLoggedIn) {

        }

        window.scrollTo(0, 0);
    }

    getArticle(): void {
        this._pollStatisticsService.getVotechaArticle(this.articleId, this.pollId, this.countryId, this.cookieLangValue).subscribe((result) => {
            this.article = result;           
            this.setFaceBookMetaTags();
            //this.setTwitterMetaTags();
        });
    }


    goToCountrySite(countrySiteId: number): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/country/' + countrySiteId]);
        }
        else {
            this._router.navigate(['public/country/' + countrySiteId]);
        }
    }

    goToVoteForm(surveyid: number, voted: boolean): void {
        this._router.navigate(['app/main/voteform/', surveyid, voted]);
    }

    close(): void {
        window.history.back();
    }

    //setTwitterMetaTags(): void {
    //    this.meta.addTag({ name: 'twitter:card', content: this.article.imageUrl });
    //    this.meta.addTag({ name: 'twitter:site', content: '@votecha' });
    //    this.meta.addTag({ name: 'twitter:title', content: this.article.displayTitle  });
    //    this.meta.addTag({ name: 'twitter:description', content: this.article.displayPreamble });
    //    this.meta.addTag({ name: 'twitter:image', content: this.article.imageUrl });
    //}

    setFaceBookMetaTags(): void {
        this.meta.addTag({ property: 'og:url', content: 'https://opinions24.net/public/article/' + this.article.id });
        this.meta.addTag({ property: 'og:title', content: this.article.displayTitle });
        this.meta.addTag({ property: 'og:description', content: this.article.displayPreamble });
        this.meta.addTag({ property: 'og:image', content: this.article.imageUrl});
        this.meta.addTag({ property: 'og:type', content: 'article' });
    }
}
