<div class="portlet light" *ngIf="isUserLoggedIn">
    <div class="portlet-title">
        <i class="fa fa-id-card-o" [ngStyle]="{'color': categoryColor}"></i>
        <span class="caption-subject bold uppercase" [ngStyle]="{'color': categoryColor}"> {{l("Metadata")}}</span>
    </div>
    <div class="portlet-body">
        <table class="table table-no-border" style="line-height:1.8em" *ngIf="screenWidth > 620">
            <tr class="margin-bottom-10" *ngIf="survey.pollCreator !== undefined">
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Creator')}}</span></td>

                <td>{{survey.pollCreator?.name}} <span class="famfamfam-flags {{survey.pollCreator?.iso | lowercase}}"></span> </td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Category')}}</span></td>

                <td> {{survey.categoryName}} </td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('SubCategory')}}</span></td>

                <td> {{survey.subCategoryName}} </td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Area')}}</span></td>

                <td> <span *ngIf="survey.areaType === 1">{{l('AllCountries')}}</span><span *ngIf="survey.areaType !== 1">{{survey.location}} </span></td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('StartDate')}}</span></td>

                <td>{{survey.startDate | date}} </td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('EndDate')}}</span></td>

                <td><span *ngIf="survey.endDate !== null">{{survey.endDate | date}} </span></td>
            </tr>
            <tr>
                <td align="right" class="col-lg-1 col-md-2 col-sm-3 col-xs-4"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('ImageProvider')}}</span></td>

                <td><span *ngIf="survey.imageProviderType !== 3">{{survey.imageProvider }} </span><span *ngIf="survey.imageProviderType === 3">{{l('OwnPicture')}}</span><span *ngIf="survey.photographer !== null && survey.photographer !==''"> - {{survey.photographer}} </span></td>
            </tr>

        </table>
        <table class="table table-no-border" style="line-height:1.8em" *ngIf="screenWidth < 621">
            <tr *ngIf="survey.pollCreator !== undefined">
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('Creator')}}</div></th>

                <td style="border:none">{{survey.pollCreator?.name}} <span class="famfamfam-flags {{survey.pollCreator?.iso | lowercase}}"></span> </td>
            </tr>
            <tr>
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('Category')}}</div></th>

                <td style="border:none"> {{survey.categoryName}} </td>
            </tr>
            <tr>
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('SubCategory')}}</div></th>

                <td style="border:none"> {{survey.subCategoryName}} </td>
            </tr>
            <tr>
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('Area')}}</div></th>

                <td style="border:none"> {{survey.location}} </td>
            </tr>
            <tr>
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('StartDate')}}</div></th>

                <td style="border:none">{{survey.startDate | date}} </td>
            </tr>
            <tr>
                <th style="border:none" class="col-xs-3"><div class="text-right bold" [ngStyle]="{'color': categoryColor}">{{l('EndDate')}}</div></th>

                <td style="border:none"><span *ngIf="survey.endDate !== null">{{survey.endDate | date}} </span></td>
            </tr>
        </table>
    </div>
</div>

