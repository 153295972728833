<!-- Page Content -->
<header class="page-header navbar navbar-sticky-top navbar-inverse" [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12 sticky-header':screenWidth > 770,'container': screenWidth < 771 }" *ngIf="isRoot">
    <div class="page-header-inner">
        <div class="page-logo">
            <a href="javascript:;">
                <img src="/assets/common/images/logo.jpg" alt="logo" class="logo-default" />
                <!--<img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-on-light.png" alt="logo" class="logo-default" width="168" height="33" />
                <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetLogo?id=' + appSession.tenant.logoId" alt="logo" class="logo-default" width="168" height="33" />-->
            </a>
            <div class="menu-toggler sidebar-toggler" *ngIf="isUserLoggedIn">
                <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
            </div>
        </div>
        <div class="page-top">
            <div class="top-menu">
                <ul class="nav navbar-nav pull-right">
                    <li class="dropdown dropdown-user dropdown-dark" *ngIf="!isUserLoggedIn">
                        <a (click)="GoToLogin()" class="dropdown-toggle dropdown-mobile-site">
                            <i class="glyphicon
                    glyphicon-log-in"></i> {{l("LogIn")}}
                        </a>
                    </li>
                    <li class="dropdown dropdown-user dropdown-dark" *ngIf="!isUserLoggedIn">
                        <a (click)="GoToRegistration()" class="dropdown-toggle dropdown-mobile-site">
                            <i class="glyphicon
                    glyphicon-user"></i> {{l("CreateAnAccount")}}
                        </a>
                    </li>
                    <li class="dropdown dropdown-language" *ngIf="!isNorwaySite">
                        <a href="javascript:;" class="dropdown-toggle dropdown-mobile-site" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                            <i [class]="currentLanguage.icon"></i>
                            <span class="langname" *ngIf="screenWidth > 767">
                                {{currentLanguage.displayName}}
                            </span>
                        </a>
                        <ul class="dropdown-menu" *ngIf="languages.length > 0">
                            <li *ngFor="let language of languages">
                                <a href="javascript:;" (click)="changeLanguage(language.name)">
                                    <i [class]="language.icon"></i> {{language.displayName}}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container">
        <nav [ngClass]="{'margin-top-20':screenWidth > 770,'margin-top-5': screenWidth > 480 && screenWidth < 771 }">
            <div class="page-bar" [ngClass]="{'front-survey-group-box':isUserLoggedIn, 'page-bar-mobile': screenWidth < 481, 'margin-bottom-20': screenWidth > 480 }">
                <div [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12': screenWidth > 480 }">
                    <h3 class="page-breadcrumb font-white" [ngClass]="{'margin-bottom-10': screenWidth > 480 && screenWidth < 771, 'margin-bottom-5': screenWidth < 481  }">
                        <span [ngClass]="{'font-media-h2':screenWidth > 770,'margin-bottom-30 font-media-h3': screenWidth < 771 }">
                            <span [ngClass]="{'bold':isGlobal}">
                                <a href="javascript:;" (click)="goToGlobalSite()" [attr.aria-label]="strGoToGlobalSite" *ngIf="!isGlobal"><span style="margin-right:6px" class="font-white"><i class="icon-globe"></i> {{l("Globally")}}</span></a>
                                <span *ngIf="isGlobal"><i class="icon-globe"></i> {{l("Globally")}}</span>
                                <i *ngIf="!isGlobal" class="fa fa-angle-right"></i>
                            </span>
                            <span [ngClass]="{'bold':isCountry}" *ngIf="isCountry || isArea || isSubArea">
                                <a href="javascript:;" (click)="goToCountrySite(countrySitePath)" [attr.aria-label]="strGoToCountrySite" *ngIf="!isCountry" class="font-white" style="margin-left:7px;margin-right:6px">{{site.countryNameTranslation}}</a>
                                <span *ngIf="isCountry" style="margin-left:7px;margin-right:6px"> {{site.countryNameTranslation}} </span>
                                <i *ngIf="isArea || isSubArea" class="fa fa-angle-right"></i>
                            </span>
                            <span [ngClass]="{'bold':isArea}" *ngIf="isArea || isSubArea">
                                <a href="javascript:;" (click)="goToAreaSite(areaName)" *ngIf="!isArea" [attr.aria-label]="strGoToAreaSite" class="font-white" style="margin-left:7px;margin-right:6px"> {{site.areaName}}<span *ngIf="hasSameSubAreaName && !site.isSubAreaInParent">{{l("CountryAreaDef") | lowercase}}</span></a>
                                <span *ngIf="isArea"> {{site.areaName}} <span *ngIf="hasSameSubAreaName && !site.isSubAreaInParent">{{l("CountryAreaDef") | lowercase}}</span></span>
                                <i *ngIf="isSubArea" class="fa fa-angle-right"></i>
                            </span>
                            <span class="bold" *ngIf="isSubArea">
                                {{site.subAreaName}} <span *ngIf="site.areaName === site.subAreaName">{{l("CountrySubAreaDef") | lowercase}}</span>
                            </span>
                        </span>
                    </h3>
                    <div [ngClass]="{'font-media-h2 pull-right':screenWidth > 767,'font-media-h3 margin-bottom-5': screenWidth < 768 }">

                        <div class="btn-group" *ngIf="!isUserLoggedIn && isGlobal && countrySites !== null">
                            <button [attr.aria-label]="strSelectCountry" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                {{l("Countries")}}
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu" *ngIf="countrySites && countrySites.length > 0">
                                <li *ngFor="let site of countrySites; index as c">
                                    <a href="javascript:;" [attr.aria-label]="strGoToCountrySite" (click)="goToCountrySite(site.sitePath)">
                                        <span class="famfamfam-flags {{site.iso| lowercase}}"></span> {{site.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-group" *ngIf="!isUserLoggedIn && isCountry && areaSites !== null">
                            <button [attr.aria-label]="strSelectCountryAreaDef" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                {{l("CountryAreasDef")}}
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu" *ngIf="areaSites$ | async as areaSites">
                                <li *ngFor="let areaSite of areaSites; let i = index">
                                    <a (click)="goToAreaSite(areaSite.name)" [attr.aria-label]="strGoToAreaSite">{{areaSite.name}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-group" *ngIf="!isUserLoggedIn && isArea && subAreaSites !== null">
                            <button [attr.aria-label]="strSelectCountryAreaDef" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle dropdown-mobile-site" data-toggle="dropdown" aria-expanded="false">
                                {{l("CountrySubAreasDef")}}
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu" *ngIf="subAreaSites$ | async as subAreaSites">
                                <li *ngFor="let subAreaSite of subAreaSites; let i = index">
                                    <a (click)="goToSubAreaSite(subAreaSite.name)" [attr.aria-label]="strGoToSubAreaSite">{{subAreaSite.name}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-group">
                            <button [attr.aria-label]="strSelectCategory" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-circle-right btn-public-dropdown  dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                {{l("Categories")}}
                                <i class="fa fa-angle-down"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li *ngFor="let category of categoryList;"><a [attr.aria-label]="strGoToCategorySite" (click)="goToCategorySite(category.categoryName)">{{category.categoryTranslation}}({{category.pollAndPetitionCount}})</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>
<div id="top"></div>
<div [ngClass]="{'container':screenWidth > 767, 'col-xs-12':screenWidth < 768}" class="margin-bottom2pr" *ngIf="!isUserLoggedIn">
    <welcome-section [screenWidth]="screenWidth" [isUserLoggedIn]="isUserLoggedIn"></welcome-section>
</div>

<div [ngClass]="{'container':screenWidth > 767 }" style="max-width:1400px">
    <div [ngClass]="{'col-lg-12 col-md-12  col-sm-12 col-xs-12':!isUserLoggedIn, 'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn }">
        <front-carousel [screenWidth]="screenWidth" [siteId]="siteId" [siteTypeId]="siteTypeId" [inputCountry]="countryName"></front-carousel>
    </div>
</div>

<div class="margin-top2pr margin-bottom2pr" [ngClass]="{'container':screenWidth > 767, 'mobile-container':screenWidth < 768}" *ngIf="isNorwaySite">
    <div class="pointer margin-bottom2pr" [ngClass]="{'col-lg-12 col-md-12  col-sm-12 col-xs-12':!isUserLoggedIn, 'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn }" data-toggle="modal" data-target="#lockBarInfoModal" (click)="lockBarInfoModal.show()">
        <div class="portlet blackboard">
            <div class="portlet-title">
                <h3 class="font-white bold">{{l("LastFeaturesAdded")}} </h3>
            </div>
            <div class="portlet-body">
                <div class="font-white bold" [ngClass]="{'c-font-16':screenWidth > 991, 'c-font-14':screenWidth < 992}">
                    <div class="margin-top-10">
                        <i class="fa fa-user-secret margin-right-5"></i>{{l('SecretParticipation')}}
                    </div>
                    <div class="margin-top-10">
                        <i class="fa fa-unlock margin-right-5"></i>{{l('UnlockPadlock')}}
                    </div>
                    <div class="margin-top-10 link-underline">
                        {{l('ReadMore')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-lg-12 col-md-12  col-sm-12 col-xs-12':!isUserLoggedIn, 'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn }">
        <div class="portlet light">
            <div class="portlet-title">
                <div class="caption">
                    <span class="caption-subject font-dark bold uppercase">Første avstemning er låst opp</span>
                </div>
            </div>
            <div class="portlet-body" [ngStyle]="{'margin-left': screenWidth < 370  ? '20%' : '1px'}">

                <div class="font-media-h3 bold">Første avstemning som ble låst opp er "Elektrifisering av sokkelen".</div>
                <img src="https://votechacdn.blob.core.windows.net/votechacdnimages/first-unlocked-poll.jpg" alt="" class="meninger24-article-image">
                <p>
                    Dette ble den første avstemningen som har skapt så mye engasjement blant våre registrerte brukere at hengelåsen ble åpnet.
                </p>

                <p class="bold">
                    Frem til nå viser statistikken at 92% av folket er imot elektrifisering av sokkelen.
                </p>
                <p>
                    93% av menn er i mot, mens 85 % av kvinner er i mot.<br />
                    Det er aldersgruppen over 65 som er aller mest engasjert, mens aldersgruppen 40 til 65 også er bra engasjerte.
                </p>
                <p>
                    Logg inn og registrer din mening hvis du ikke allerede har gjort det.
                </p>
                <p>
                    Hvis du er sterkt i mot dette, kan du nå også signere et opprop <br /> <a (click)="goToVoteForm('cded0b97-273e-41da-8b6e-ccf1bbac5e34')" class="bold link-underline" style="cursor: pointer">Nei til å elektrifisere sokkelen med energi fra fastlandsnettet</a>.
                </p>
            </div>
        </div>
    </div>
</div>

<section *ngIf="lastPetitions && lastPetitions.length > 0" class="margin-bottom4pr margin-top-4pr" [ngClass]="{'container':screenWidth > 767 }" style="max-width:1400px">
    <div [ngClass]="{'row':screenWidth > 500 }">
        <div [ngClass]="{'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}">
            <h2 class="font-white font-media-h2 bold">{{l("LastPetitions")}}</h2>
            <hr class="categoryLine" style="border-color: white">
        </div>
        <div [ngClass]="{'container':isUserLoggedIn || screenWidth < 501, 'categoryContainer':!isUserLoggedIn && screenWidth > 500 }">
            <div *ngFor="let petition of lastPetitions; let i = index" (deferLoad)="petition.show = true">
                <div class="template-margin" [ngClass]="{'col-lg-3 col-md-4 col-sm-5 col-xs-12':isUserLoggedIn, 'col-lg-4 col-md-4 col-sm-6 col-xs-12':!isUserLoggedIn}" *ngIf="petition.show">
                    <survey-template [inputSurvey]='petition' [inputCountry]="countryName" [inputIsUserLoggedIn]='isUserLoggedIn' [inputPageUrl]="pagePath"></survey-template>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="col-sm-12 col-xs-12 margin-top2pr" *ngIf="isUserLoggedIn">
    <div class="container">
        <div class="form-group form-md-line-input">
            <div class="btn btn-circle btn-outline" [ngClass]="{'green':showOnlyUnanswered,'white':!showOnlyUnanswered }">
                <input id="showOnlyUnanswered" class="md-checkbox" type="checkbox" (change)="chbOnlyUnansweredChanged()" name="showOnlyUnanswered" [ngClass]="{'edited':showOnlyUnanswered}"
                       [(ngModel)]="showOnlyUnanswered">
                <label for="showOnlyUnanswered" [ngClass]="{'font-sm':screenWidth > 767,'font-8':screenWidth < 768}">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    <span style="margin-left:10px">{{l("ShowOnlyUnanswered")}}</span>

                </label>
            </div>
        </div>
    </div>
</div>

<!--<div class="margin-top4pr margin-bottom4pr" [ngClass]="{'container':screenWidth > 767 }" style="max-width:1400px">
    <div [ngClass]="{'col-lg-12 col-md-12  col-sm-12 col-xs-12':!isUserLoggedIn, 'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn }">
        <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                    [adSlot]="8634290723"
                    [adFormat]="'auto'"
                    [display]="'block'"
                    [fullWidthResponsive]="true">

        </ng-adsense>
    </div>
</div>-->
<div class="margin-top4pr margin-bottom4pr" [ngClass]="{'container':screenWidth > 767 }" style="max-width:1400px" *ngIf="!isNorwaySite">
    <div [ngClass]="{'col-lg-12 col-md-12  col-sm-12 col-xs-12':!isUserLoggedIn, 'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn }">
        <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                    [adSlot]="3041696429"
                    [adFormat]="'auto'"
                    [display]="'block'"
                    [fullWidthResponsive]="true">

        </ng-adsense>
    </div>
</div>

<section class="margin-bottom2pr margin-top-6pr" [ngClass]="{'container':screenWidth > 767, 'mobile-container':screenWidth < 768}" *ngIf="currentlyNowFrontPollsFiltered.length > 0">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 class="font-media-h2 font-white bold uppercase">{{l("Currently")}} </h2>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-5 col-xs-12 margin-top2pr margin-bottom-20">
        <div *ngFor="let currentPoll of currentlyNowFrontPollsFiltered; index as p">
            <div class="current-poll shadow font-white" [ngClass]="{'c-font-16':screenWidth > 991, 'c-font-13':screenWidth < 992}" style="padding: 8px 1px" [ngStyle]="{'background-color': currentPoll.colorCode}" *ngIf="!showOnlyUnanswered || !currentPoll.hasVoted">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div (click)="goToCategoryName(currentPoll.categoryId)" style="cursor: pointer" class="margin-top-5">
                        <i class="glyphicon glyphicon-bullhorn margin-right-5" title="{{l('Petition')}}" *ngIf="currentPoll.surveyTypeId === 0 && !currentPoll.hasVoted"></i>
                        <i class="fa fa-check margin-right-5" title="{{l('Petition')}}" *ngIf="currentPoll.surveyTypeId === 0 && currentPoll.hasVoted"></i>
                        <i class="glyphicon glyphicon-stats margin-right-5" title="{{l('Poll')}}" *ngIf="currentPoll.surveyTypeId > 0 && !currentPoll.hasVoted"></i>
                        <i class="fa fa-check margin-right-5" title="{{l('Poll')}}" *ngIf="currentPoll.surveyTypeId > 0 && currentPoll.hasVoted"></i>
                        {{currentPoll.categoryName}}
                    </div>
                </div>
                <div (click)="goToVoteForm(currentPoll.id)" style="cursor: pointer" class="margin-left-10 padding-bottom-10 padding-top-10">
                    <span [ngClass]="{'bold':screenWidth > 991,'margin-left-5':screenWidth < 992}" class="margin-top-15"> {{currentPoll.displayName}}</span>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{'col-lg-offset-2 col-lg-4 col-md-offset-1 col-md-6 col-sm-6':!isUserLoggedIn, 'col-lg-offset-1 col-lg-4 col-md-4 col-sm-6':isUserLoggedIn }" class="col-xs-12 margin-top2pr" *ngIf="siteTypeId === 2 || siteTypeId === 3">
        <area-teaser [inputSite]='site' [inputCountryName]="countryName" [inputIsUserLoggedIn]="isUserLoggedIn"></area-teaser>
    </div>
</section>

<div class="container">
    <section class="margin-bottom2pr margin-top4pr" [ngClass]="{'container':screenWidth > 767}">
        <div *ngIf="pollCategoryListFiltered.length > 0 && totalPolls > 0" class="margin-top-40">
            <div *ngFor="let category of pollCategoryListFiltered; let k=index">
                <div class="row">
                    <div [ngClass]="{'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}">
                        <h3 class="font-media-h3 font-white link-underline" id="category_{{k}}">
                            <a href="javascript:;" (click)="goToCategorySite(category.categoryName)" class="font-white" [ngStyle]="{'font-size': screenWidth > 600 ? 'xx-large' : 'large'}"> <span *ngIf="category.categoryTranslation !== null && category.categoryTranslation !== ''" class="bold">{{category.categoryTranslation}}</span><span *ngIf="category.categoryTranslation === null || category.categoryTranslation === ''">{{category.categoryName}}</span></a>
                            <span class="pull-right">
                                <button type="button" *ngIf="(k + 1) < pollCategoryListFiltered.length" (click)="goToCategory(k+1)" title="{{strGoToNextCategory}}" class="btn-emty font-white margin-left2pr margin-right-5"><i class="glyphicon glyphicon-forward"></i></button>
                            </span>
                            <span class="pull-right">
                                <button *ngIf="k > 0" type="button" (click)="goToCategory(k-1)" title="{{strGoToPreviosCategory}}" class="btn-emty font-white margin-left-5 margin-right-5"><i class="glyphicon glyphicon-backward"></i></button>
                            </span>
                            <span class="pull-right">
                                <button type="button" *ngIf="pollCategoryListFiltered.length === (k + 1)" title="{{strGoToTop}}" (click)="goToTop()" class="btn-emty font-white margin-right-5"><i class="glyphicon glyphicon-circle-arrow-up"></i></button>
                            </span><span class="pull-right">
                                <button *ngIf="k === 0" type="button" title="{{strGoToFooter}}" (click)="goToFooter()" class="btn-emty font-white"><i class="glyphicon glyphicon-circle-arrow-down"></i></button>
                            </span>
                        </h3>
                        <hr class="categoryLine" [ngStyle]="{'border-color': category.colorCode ? category.colorCode : '#4CAF50'}">
                    </div>
                    <div *ngIf="siteTypeId === 2 && category.categoryId === 57 && politicalPolls !== null && politicalPolls.length > 0" class="container margin-top2pr">
                        <political-page [politicalPolls]='politicalPolls' [screenWidth]="screenWidth" [inputIsUserLoggedIn]="isUserLoggedIn" [countryName]="countryName" [pagePath]="pagePath"></political-page>
                    </div>
                    <div [ngClass]="{'container':isUserLoggedIn && screenWidth > 500, 'categoryContainer':!isUserLoggedIn && screenWidth > 500 }">
                        <div *ngFor="let output of category.frontPagePolls; let i = index" (deferLoad)="output.poll.show = true">
                            <div *ngIf="category.categoryId === output.poll.categoryId">
                                <div class="row hidden-lg hidden-md hidden-sm" *ngIf="screenWidth < 768 && output.poll.pollArticleEnabled"></div>
                                <div class="template-margin" [ngClass]="{'col-lg-3 col-md-4 col-sm-5 col-xs-12':isUserLoggedIn, 'col-lg-4 col-md-4 col-sm-6 col-xs-12':!isUserLoggedIn}" *ngIf="output.poll.show">
                                    <survey-template [inputSurvey]='output.poll' [inputCountry]="countryName" [inputIsUserLoggedIn]='isUserLoggedIn' [inputPageUrl]="pagePath"></survey-template>
                                </div>
                                <div [ngClass]="{'row hidden-md hidden-sm hidden-xs':isUserLoggedIn && ((i+1) % 3 === 0)}" *ngIf="screenWidth >= 1170 && i > 0 && isUserLoggedIn"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center margin-bottom-20 link-underline" style="padding: 40px;">
                    <a href="javascript:;" (click)="goToCategorySite(category.categoryName)" class="font-media-h2 font-white"> {{l("GoToTheSite")}} <span *ngIf="category.categoryTranslation !== null && category.categoryTranslation !== ''" class="bold">{{category.categoryTranslation}}</span><span *ngIf="category.categoryTranslation === null || category.categoryTranslation === ''">{{category.categoryName}}</span></a>
                </div>
            </div>
        </div>
    </section>
    <div [ngClass]="{'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}" *ngIf="!active" style="margin-top:25%;margin-bottom:15%">
        <div class="col-sm-offset-5 col-xs-offset-5" [ngClass]="{'col-lg-offset-4 col-md-offset-4':isUserLoggedIn, 'col-lg-offset-5 col-md-offset-5':!isUserLoggedIn}">
            <i class="fa fa-spinner fa-spin font-white" style="font-size:94px;"></i>
        </div>
    </div>
    <div class="container" *ngIf="active && ((totalPolls === 0) || allAnswered)">
        <div class="note note-default shadow rounded-corners col-md-6 font-media-h3" *ngIf="!allAnswered">
            {{l("NoPollsPublished")}}
        </div>
        <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12">
            <div class="well shadow page-message font-white font-media-h3" style="height:90px;" *ngIf="showOnlyUnanswered && (answeredPollIds.length > 0 || allAnswered)">
                <div class="text-center margin-top-10">{{l("YouHaveAnsweredAllPollsOnPage")}}</div>
            </div>
        </div>
    </div>
</div>

<div id="footer">
    <footer-public *ngIf="(isRootNorway || isRootNet) && !isUserLoggedIn"></footer-public>
</div>
<lockBarInfoModal #lockBarInfoModal></lockBarInfoModal>
