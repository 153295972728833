<div bsModal #modal="bs-modal"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #blogForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">
                        <span>{{l("AddArticleLink")}}</span>
                        <span *ngIf="poll.surveyTypeId > 0">{{l("ForSurvey")}}</span><span *ngIf="poll.surveyTypeId === 0">{{l("ForPetition")}}</span><br />
                        <span class="bold" *ngIf="poll.surveyTypeId > 0">{{poll.displayName}}</span><span class="bold" *ngIf="poll.surveyTypeId === 0">{{poll.displayQuestion}}</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="label label-primary" name="categoryName">
                            {{categoryName}}
                        </label>
                        <span style="margin-left:7px">
                            <label class="label label-info" name="subCategoryName">
                                {{subCategoryName}}
                            </label>
                        </span>
                    </div>
                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input class="form-control" required min="10" name="title" [ngClass]="{'edited':newLink}" [(ngModel)]="newLink" maxlength="250" />
                        <label>{{l("Link")}}</label>
                    </div>
                    <div class="font-media-h3 margin-top4pr">
                        <label>#{{l("Tags")}}</label>
                    </div>
                    <div class="form-group">
                        <label class="hint">{{l("ArticleMessageIndicator")}}</label>
                        <div class="md-radio-inline">
                            <div class="md-radio font-green-meadow">
                                <input id="article_indicator_for" name="article_indicator_for" class="md-radiobtn" [(ngModel)]="newArticle.articleIndicator" [value]=1 type="radio">
                                <label for="article_indicator_for">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span>
                                    {{l("Posistive")}}
                                </label>
                            </div>
                            <div class="md-radio font-dark">
                                <input id="article_indicator_neutral" name="article_indicator_neutral" class="md-radiobtn" [(ngModel)]="newArticle.articleIndicator" [value]=0 type="radio">
                                <label for="article_indicator_neutral">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span>
                                    {{l("Neutral")}}
                                </label>
                            </div>
                            <div class="md-radio font-red">
                                <input id="article_indicator_against" name="article_indicator_against" class="md-radiobtn" [(ngModel)]="newArticle.articleIndicator" [value]=3 type="radio">
                                <label for="article_indicator_against">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span>
                                    {{l("Negative")}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="poll.categoryId === 1">
                        
                       
                        <div class="panel-group accordion scrollable" id="accordion2">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_1" aria-expanded="false"> {{l("Politicians")}}, {{l("GovernmentAndParliament")}}</a>
                                    </h4>
                                </div>
                                <div id="collapse_2_1" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                                    <div class="panel-body">
                                        <div class="margin-top-20">
                                            <label class="hint">{{l("TagInfo")}}<span class="lowercase">{{l("TheGoverment")}}</span></label>
                                            <div class="form-group form-md-line-input">
                                                <div class="btn btn-circle" [ngClass]="{'btn-success': newArticle.isGovermentRelated}">
                                                    <input id="isGovermentRelated" class="md-check" type="checkbox" name="isGovermentRelated" [ngClass]="{'edited': newArticle.isGovermentRelated}"
                                                           [(ngModel)]="newArticle.isGovermentRelated">
                                                    <label for="isGovermentRelated">
                                                        <span class="inc"></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span>
                                                        <span style="margin-left:10px">#{{l("TheGoverment")}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="margin-top-20">
                                            <label class="hint">{{l("TagInfo")}}<span class="lowercase">{{l("Politicians")}}</span></label>
                                            <div class="form-group form-md-line-input">
                                                <div class="btn btn-circle" [ngClass]="{'btn-success': newArticle.isPoliticiansRelated}">
                                                    <input id="isGovermentRelated" class="md-check" type="checkbox" name="isPoliticiansRelated" [ngClass]="{'edited': newArticle.isPoliticiansRelated}"
                                                           [(ngModel)]="newArticle.isPoliticiansRelated">
                                                    <label for="isPoliticiansRelated">
                                                        <span class="inc"></span>
                                                        <span class="check"></span>
                                                        <span class="box"></span>
                                                        <span style="margin-left:10px" class="uppercase">#{{l("Politicians")}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse_2_2" aria-expanded="false"> {{l("PoliticalParties")}}</a>
                                    </h4>
                                </div>
                                <div id="collapse_2_2" class="panel-collapse collapse" aria-expanded="false">
                                    <div class="panel-body">
                                        <div class="margin-top-20" *ngIf="politicalParties && politicalParties.length > 0">
                                            <label class="hint">{{l("PoliticalTagsInfo")}}</label>
                                            <div class="margin-left2pr">
                                                <div class="md-checkbox-list">
                                                    <div class="md-checkbox" *ngFor="let c of politicalParties; let i = index">
                                                        <input id="countrySelected{{c.id}}" class="md-check" type="checkbox" name="isSelected{{c.id}}" [ngClass]="{'edited':c.isSelected}"
                                                               [(ngModel)]="c.isSelected">
                                                        <label for="countrySelected{{c.id}}">
                                                            <span class="inc"></span>
                                                            <span class="check"></span>
                                                            <span class="box"></span>
                                                            # <img id="imgitem" alt="{{c.name }}" class="chart-logo-item" style="width:40px;max-height:40px" [src]="c.imgUrl" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                      
                        </div>
                        
                    </div>
                    <div *ngIf="poll.categoryId === 5">
                        <div class="container">
                            <label class="hint">{{l("TagInfo")}}{{l("Environment")}}</label>
                            <div class="form-group form-md-line-input">
                                <div class="btn btn-circle" [ngClass]="{'btn-success': newArticle.isEnvironmentRelated}">
                                    <input id="isEnvironmentRelated" class="md-check" type="checkbox" name="isEnvironmentRelated" [ngClass]="{'edited': newArticle.isEnvironmentRelated}"
                                           [(ngModel)]="newArticle.isEnvironmentRelated">
                                    <label for="isEnvironmentRelated">
                                        <span class="inc"></span>
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        <span style="margin-left:10px">#{{l("Environment")}}</span>

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-danger" *ngIf="invalidArticle">

                        <h2 class="font-media-h3">{{l("InvalidArticle")}}</h2>
                        <p *ngIf="savedArticle.status !== 3">{{l("InsertMediaArticleInfo")}}</p>
                        <p *ngIf="savedArticle.status === 3">{{l("ArticleExist")}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button  type="button" [ngClass]="{'btn-default':!invalidArticle, 'btn-primary blue':invalidArticle}" class="btn" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" [ngClass]="{'btn-default':invalidArticle, 'btn-primary blue':!invalidArticle}" class="btn" [disabled]="invalidArticle" ><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
