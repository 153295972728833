import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';


@NgModule({
    imports: [
        CommonModule
    ]
})
export class AbpZeroTemplateCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [
                AppSessionService,
                AppUrlService
            ]
        }
    }
}