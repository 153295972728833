import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'footer-public',
    templateUrl: './footer-public.component.html',
    styleUrls: ['./footer-public.component.css']
})
export class FooterPublicComponent extends AppComponentBase implements OnInit {
    cookieLangValue: string;
    siteName: string;
    isUserLoggedIn: boolean = false;
    currentYear: number;
    screenWidth: any;
    isVotechaNorway: boolean = false;
    isVotechaCom: boolean = false;
    constructor(
        injector: Injector,
        private _router: Router
    ) {
        super(injector);

    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.currentYear = new Date().getFullYear();
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.siteName = "Votecha";
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        //if (this.isUserLoggedIn) {
        //    this._router.navigate(['app/main/mysite']);
        //}
        if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
        //if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.isVotechaNorway = true;
            this.siteName = "Meninger24";
        }
        else if (AppConsts.appBaseUrl.toLowerCase().includes(".com") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.isVotechaCom = true;
        }

    }

    GoToAbout(): void {
        this._router.navigate(['/public/about']);
    }

    GoToContact(): void {
        this._router.navigate(['/public/contact']);        
    }

    GoToHelp(): void {
        this._router.navigate(['/public/help']);      
    }

    GoToTerms(): void {
        this._router.navigate(['/public/terms']);      
    }

    GoToPrivacy(): void {
        this._router.navigate(['/public/privacy']);      
    }

    GoToCookies(): void {
        this._router.navigate(['/public/cookies']);       
    }

    GoToPossibilities(): void {
        this._router.navigate(['/public/possibilities']);
    }
}
