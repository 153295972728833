
import {finalize} from 'rxjs/operators';
import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollServiceProxy, UserPollInput,PollInput, PollImageDto} from '@shared/service-proxies/service-proxies';
import { PhotoServiceProxy, ImageProviderOutput, PhotoOutput, PhotoInput, TagDto } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import { FileUploader, FileUploaderOptions, Headers } from '@node_modules/ng2-file-upload';
import { IAjaxResponse } from 'abp-ng2-module';
import { TokenService } from 'abp-ng2-module';
import * as _ from 'lodash';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'pollImageModal',
    templateUrl: './poll-image-modal.component.html'
})
export class PollImageModalComponent extends AppComponentBase {

   @ViewChild('modal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    imageProviders: ImageProviderOutput[] = [];
    image: PollImageDto;
    imageInput: PollImageDto;
    fileName: string;
    imgUrl: string;
    active: boolean = false;
    saving: boolean = false;
    newImage: boolean = false;
    isDefault: boolean = true;



    public uploader: FileUploader;
    public temporaryUrl: string;
    private temporaryFileName: string;
    private _uploaderOptions: FileUploaderOptions = {};
    private _$imageResize: JQuery;
    private _$jcropApi: any;
    private _router: Router;


    constructor(
        injector: Injector,
        private _pollService: PollServiceProxy,
        private _photoService: PhotoServiceProxy,
        private _tokenService: TokenService
    ) {
        super(injector);
    }



    initializeModal(): void {
        this.active = true;
        this.temporaryUrl = '';
        this.temporaryFileName = '';
        this.fileName = '';
        this.imgUrl = '';
        this._$imageResize = null;
        this._$jcropApi = null;
        this.initFileUploader();
    }


    initFileUploader(): void {
        let self = this;
        self.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + "/Profile/UploadAzureImage" });
        self._uploaderOptions.autoUpload = true;
        self._uploaderOptions.authToken = 'Bearer ' + self._tokenService.getToken();
        self._uploaderOptions.removeAfterUpload = true;
        self.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        self.uploader.onSuccessItem = (item, response, status) => {
            let resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                self.temporaryFileName = resp.result.fileName;
                this.fileName = item.file.name;
                self.temporaryUrl = AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + resp.result.fileName + '?v=' + new Date().valueOf();

                var newCanvasHeight = resp.result.height * self._$imageResize.width() / resp.result.width;
                self._$imageResize.height(newCanvasHeight + 'px');

                if (self._$jcropApi) {
                    self._$jcropApi.destroy();
                }

                self._$imageResize.attr('src', self.temporaryUrl);
                self._$imageResize.attr('originalWidth', resp.result.width * 2);
                self._$imageResize.attr('originalHeight', resp.result.height);

                self._$imageResize.Jcrop({
                    setSelect: [0, 0, 100, 100],
                    aspectRatio: 2,
                    boxWidth: 300,
                    boxHeight: 200
                }, function () {
                    self._$jcropApi = this;
                });

            } else {
                this.message.error(resp.error.message);
            }
        };

        self.uploader.setOptions(self._uploaderOptions);
    }

    show(userPoll: UserPollInput,poll: PollInput): void {        
        this.getImageProviders();
        this.active = true;
        this.imageInput = new PollImageDto();
        this.imageInput.userPoll = userPoll;
        this.imageInput.userPoll.poll = poll;
        this.initializeModal();
        this.imageInput.alternateText = poll.alternateText;
        this.imageInput.azureUri = poll.imgUrl;
        this.imageInput.providerTypeId = poll.imageProviderType;
        this.imageInput.imageProviderId = poll.imageProviderId;
        this.imageInput.imageProvider = poll.imageProvider;
        this.imageInput.imageUrlToProvider = poll.imageUrlToProvider;
        this.imageInput.photographer = poll.photographer;
        this.newImage = true;
        this.imgUrl = poll.imgUrl;
        this.modal.show();


    }

    onModalShown() {
        this._$imageResize = $("#ImageResize");
    }

    save(): void {
        const self = this;
        if (!self.temporaryFileName) {
            return;
        }

        let resizeParams = { x: 0, y: 0, w: 0, h: 0 };
        if (self._$jcropApi) {
            resizeParams = self._$jcropApi.getSelection();
        }

        const containerWidth = parseInt(self._$jcropApi.getContainerSize()[0]);
        const containerHeight = self._$jcropApi.getContainerSize()[1];

        let originalWidth = containerWidth;
        let originalHeight = containerHeight;

        if (self._$imageResize) {
            originalWidth = parseInt(self._$imageResize.attr('originalWidth'));
            originalHeight = parseInt(self._$imageResize.attr('originalHeight'));
        }

        const widthRatio = originalWidth / containerWidth;
        const heightRatio = originalHeight / containerHeight;

        this.imageInput.tempFileName = self.temporaryFileName;
        this.imageInput.fileName = this.fileName;
        this.imageInput.alternateText = this.imageInput.alternateText;
        this.imageInput.x = Math.floor(resizeParams.x * widthRatio);
        this.imageInput.y = Math.floor(resizeParams.y * heightRatio);
        this.imageInput.width = Math.floor(resizeParams.w * widthRatio);
        this.imageInput.height = Math.floor(resizeParams.h * heightRatio);
        this.saving = true;
        if (this.imageInput.providerTypeId < 3 && this.imageInput.imageProvider === null || this.imageInput.imageProvider === '') {
            this.imageInput.imageProvider = this.getImageProviderName(this.imageInput.providerTypeId);
        }
        self._pollService.savePollImage(this.imageInput).pipe(
            finalize(() => { this.saving = false; }))
            .subscribe((result) => {
                var pollDto = result;                
                const self = this;
                self._$jcropApi.destroy();
                self._$jcropApi = null;
                this.notify.info(this.l('SavedSuccessfully'));
                self.close();
                this._router.navigate(['app/main/user-survey/create-poll-wizard/', pollDto.poll.surveyType, pollDto.id]);
            });
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }

    getImageProviders(): void {
        this._photoService.getImagesProviders().subscribe((result) => {
            this.imageProviders = result.items;
        });
    }

    getImageProviderName(id: number): string {
        var provider = '';
        for (var i = 0; i < this.imageProviders.length; i++) {
            if (this.imageProviders[i].id === id) provider = this.imageProviders[i].name;
        }
        return provider;
    }
}
