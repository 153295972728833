<div bsModal #changePasswordModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog">
    <div class="modal-content">
      <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            <span>{{l("ChangePassword")}}</span>
          </h4>
        </div>

        <div class="modal-body">
          <div class="form-group form-md-line-input form-md-floating-label">
            <input #currentPasswordInput type="password" name="CurrentPassword" class="form-control" [(ngModel)]="currentPassword" required>
            <label>{{l("CurrentPassword")}}</label>
          </div>
          <div class="form-group form-md-line-input form-md-floating-label no-hint">
            <input type="password" name="NewPassword" class="form-control" [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
                   reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                   [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                   required>
            <label>{{l("NewPassword")}}</label>
          </div>
          <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
            <ul class="help-block text-danger" *ngIf="NewPassword.errors">
              <li [hidden]="!NewPassword.errors.requireDigit">{{l("PasswordComplexity_RequireDigit_Hint")}}</li>
              <li [hidden]="!NewPassword.errors.requireLowercase">{{l("PasswordComplexity_RequireLowercase_Hint")}}</li>
              <li [hidden]="!NewPassword.errors.requireUppercase">{{l("PasswordComplexity_RequireUppercase_Hint")}}</li>
              <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">{{l("PasswordComplexity_RequireNonAlphanumeric_Hint")}}</li>
              <li [hidden]="!NewPassword.errors.requiredLength">{{l("PasswordComplexity_RequiredLength_Hint", passwordComplexitySetting.requiredLength)}}</li>
            </ul>
          </div>
          <div class="form-group form-md-line-input form-md-floating-label no-hint">
            <input type="password" name="NewPasswordRepeat" class="form-control" [ngModel]="confirmPassword" #NewPasswordRepeat="ngModel" validateEqual="NewPassword"
                   [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                   [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                   reverse="false" required>
            <label>{{l("NewPasswordRepeat")}}</label>
          </div>
          <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
            <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
              <li [hidden]="!NewPasswordRepeat.errors.requireDigit">{{l("PasswordComplexity_RequireDigit_Hint")}}</li>
              <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">{{l("PasswordComplexity_RequireLowercase_Hint")}}</li>
              <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">{{l("PasswordComplexity_RequireUppercase_Hint")}}</li>
              <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">{{l("PasswordComplexity_RequireNonAlphanumeric_Hint")}}</li>
              <li [hidden]="!NewPasswordRepeat.errors.requiredLength">{{l("PasswordComplexity_RequiredLength_Hint", passwordComplexitySetting.requiredLength)}}</li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn default close-button" (click)="close()" [disabled]="saving">{{l("Cancel")}}</button>
          <button type="submit" class="btn blue save-button" [disabled]="!changePasswordModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
