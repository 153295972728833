import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollStatisticsServiceProxy, CategoryChartDto, SiteDto, SiteOutput } from '@shared/service-proxies/service-proxies';
import { MySiteServiceProxy, UserSitesDto } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { CookieHelper } from '@shared/helpers/CookieHelper';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';

@Component({
    selector: 'top-menu-template',
    templateUrl: './top-menu-template.component.html',
  animations: [appModuleAnimation()]
})
export class TopMenuTemplateComponent extends AppComponentBase implements OnInit, OnChanges {

    countrySites$: Observable<SiteOutput[]>;
    areaSites$: Observable<SiteOutput[]>;
    areaSites: SiteOutput[] = [];
    subAreaSites$: Observable<SiteOutput[]>;
    subAreaSites: SiteOutput[] = [];
    userSite: UserSitesDto;
    site: SiteDto;
    categoryList$: Observable<CategoryChartDto[]>;
    categoryList: CategoryChartDto[] = [];

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;

    public subSiteName: string = "";
    public goToDashboard: string = "";
    public strGoToGlobalSite: string = "";
    public strGoToCountrySite: string = "";
    public strGoToAreaSite: string = "";
    public strGoToSubAreaSite: string = "";
    public strGoToCategorySite: string = "";
    public strGoToAboutSite: string = "";
    public strGoToRegistrationSite: string = "";
    public strSelectCountry: string = "";
    public strSelectRegion: string = "";
    public strSelectCategory: string = "";
    public strSelectCountryAreaDef: string = "";
    public strSelectSubAreaDef: string = "";

    pagePath: string = "";
    sitePath: string = "";
    countrySitePath: string;
    areaSitePath: string;
    subAreaSitePath: string;

    countryName: string;
    areaName: string;
    subAreaName: string;
    categoryName: string;
    categoryTranslation: string;

    cookieLangValue: string;
    areaType: string;

    siteTypeId: number;
    siteId: number;

    public active: boolean = false;
    public saving: boolean = false;
    isUserLoggedIn: boolean = false;
    isGlobal: boolean;
    isCountry: boolean;
    isArea: boolean;
    isSubArea: boolean;
    isCategory: boolean;
    isRootNorway: boolean;
    isVotechaNo: boolean;
    hasSameSubAreaName: boolean;
    showSiteMenu: boolean;

    screenWidth: any;
    today: Date;

    constructor(
        injector: Injector,
        private _router: Router,
        private _mySiteService: MySiteServiceProxy,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
        _router.events.subscribe((val) => {
            this.showSiteMenu = this._router.url.includes("/site/") || this._router.url.includes("public/category") || this._router.url.includes("main/category") ? true : false;
            if (this.showSiteMenu) {
                this.ngOnInit();
            }
        })
    }

    ngOnInit() {
        this.areaType = "";
        this.sitePath = "global";
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.currentLanguage = this.localization.currentLanguage;
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.strGoToGlobalSite = this.l('GoToGlobalSite');
        this.strGoToCountrySite = this.l('GoToCountrySite');
        this.strGoToAreaSite = this.l('GoToArealSite');
        this.strGoToSubAreaSite = this.l('GoToSubAreaSite');
        this.strGoToCategorySite = this.l('GoToCategorySite');

        this.userSite = new UserSitesDto();
        this.userSite.countrySiteId = 0;
        this.userSite.countrySitePath = "";
        this.userSite.areaSitePath = "";
        this.userSite.subAreaSitePath = "";

        this.subSiteName = "";
        this.sitePath = "global";
        this.countryName = "global";
        this.areaName = "";
        this.subAreaName = "";
        this.categoryTranslation = "";

        this.isGlobal = false;
        this.isCountry = false;
        this.isArea = false;
        this.isSubArea = false;
        this.isCategory = false;
        
        this.site = new SiteDto();
        this.pagePath = this._router.url;
        this.showSiteMenu = this.setSiteMenuVisibility();
        registerLocaleData(localeNorwegian);
        this.screenWidth = window.innerWidth;
        this.today = new Date();
        this.activatedRoute.params.subscribe((params: Params) => {

            if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
            //if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
                this.cookieLangValue = "nb-NO";
                this.isVotechaNo = true;
                if (!window.location.toString().includes("global")) {
                    this.sitePath = "norway";
                    if ((params['areaDef'] === undefined || params['areaDef'] === null) && !this._router.url.includes("country") && !this._router.url.includes("area") && !this._router.url.includes("subarea")) {
                        this.areaType = "country";
                        this.countryName = "norway";
                        this.siteTypeId = 2;
                        this.sitePath = this.countryName;
                        this.isCountry = true;
                        if (this._router.url === "/") {
                            this.isRootNorway = true;
                        }
                        if (this.currentLanguage.name !== "nb-NO")
                            this.currentLanguage = VotechaHelper.getNorwegianLang(this.languages);
                        abp.utils.setCookieValue(
                            "Abp.Localization.CultureName",
                            this.currentLanguage.name,
                            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                            abp.appPath
                        );
                        if (this.isUserLoggedIn) {
                            this._router.navigate(['app/main/site/country/norway']);
                        }
                        this.getAreaSites();
                    }
                }

            }
            if (!this.isRootNorway) {
                if (this.areaType === '') {
                    this.areaType = params['areaDef'] || this.getAreaTypeFromUrl();
                }
                if (this.areaType === null || this.areaType === undefined || this.areaType === 'global') {
                    this.areaType = 'global';
                    this.siteTypeId = 1;
                    this.sitePath = 'global';
                    this.isGlobal = true;
                    this.getCountrySites();
                }
                else {
                    this.countrySitePath = params['country'] ? params['country'] : this.getAreaFromUrl('country');
                    this.sitePath = this.countrySitePath;
                    this.countryName = params['country'] ? params['country'] : this.getAreaFromUrl('country');

                    if (this.areaType === 'country') {
                        this.siteTypeId = 2;
                        this.isCountry = true;
                        this.getAreaSites();
                    }
                    else if (this.areaType === 'area') {
                        this.areaSitePath = params['area'] ? params['country'] + "/" + params['area'] : this.countryName + "/" + this.getAreaFromUrl('area');
                        this.sitePath = this.areaSitePath;
                        this.areaName = params['area'] ? params['area'] : this.getAreaFromUrl('area');
                        this.siteTypeId = 3;
                        this.isArea = true;
                        if (!this.site.isSubAreaInParent) {
                            this.getSubAreaSites();
                        }
                    }
                    else if (this.areaType === 'subarea') {
                        this.subAreaSitePath = params['subArea'] ? params['country'] + "/" + params['area'] + "/" + params['subArea'] : this.countryName + "/" + this.getAreaFromUrl('area') + "/" + this.getAreaFromUrl('subarea');
                        this.sitePath = this.subAreaSitePath;
                        this.areaName = params['area'] ? params['area'] : this.getAreaFromUrl('area');
                        this.subAreaName = this.getAreaFromUrl('subarea');
                        this.siteTypeId = 4;
                        this.isSubArea = true;
                    }
                }

            }

            if (this.sitePath !== undefined && this.sitePath !== null) {
                this.getCategories();

                if (!this.isGlobal) {
                    this.getSiteData();
                }
            }

            if ((params['category'] !== undefined && params['category'] !== null && params['category'] !== '') || this._router.url.includes("public/category") || this._router.url.includes("main/category")) {
                this.isCategory = true;
                this.categoryName = params['categoryName'] ?? this.getCategoryFromUrl();
                this.getCategory();
            }
        });

        if (this.isUserLoggedIn) {
            this.getUserSites();
        }
    }

    setSiteMenuVisibility(): boolean {
        return this.pagePath.includes("/site/") ? true : false;
    }

    getAreaTypeFromUrl(): string {
        if (this._router.url.includes("subarea")) {
            return "subarea"
        }
        else if (this._router.url.includes("area")) {
            return "area"
        }
        else if (this._router.url.includes("country")) {
            return "country"
        }
        else if (this._router.url.includes("global")) {
            return "global"
        }
    }

    getAreaFromUrl(areaType: string): string {
        let locationArray: string[];
        let subAreaItems: number = 7;
        let areaItems: number = 6;
        let countryItems: number = 5;
        let globalItems: number = 4;

        if (this.isUserLoggedIn) {
            subAreaItems++;
            areaItems++;
            countryItems++;
            globalItems++;
        }

        if (this._router.url.includes("public/category") || this._router.url.includes("main/category")) {
            subAreaItems++;
            areaItems++;
            countryItems++;
            globalItems++;
        }

        if (this._router.url.includes("subarea")) {
            locationArray = decodeURI(this._router.url).split("/", subAreaItems);           
        }
        else if (this._router.url.includes("area")) {
            locationArray = this._router.url.split("/", areaItems); 
        }
        else if (this._router.url.includes("country")) {
            locationArray = this._router.url.split("/", countryItems); 
        }
        else if (this._router.url.includes("global")) {
            locationArray = this._router.url.split("/", globalItems);
        }


        if (areaType === 'global' && locationArray.length > (globalItems - 1)) {
            return locationArray[(globalItems - 1)];
        }
        else if (areaType === 'country' && locationArray.length > (countryItems - 1)) {
            return locationArray[(countryItems - 1)];
        }
        else if (areaType === 'area' && locationArray.length > (areaItems - 1)) {
            return locationArray[(areaItems - 1)];
        }
        else if (areaType === 'subarea') {
            return locationArray[(subAreaItems - 1)];
        }
        else {
            return '';
        }
    }

    getCategoryFromUrl(): string {
        let locationArray: string[];
        let catUrlItems: number = 4;

        if (this.isUserLoggedIn) {
            catUrlItems++;
        }        

        locationArray = decodeURI(this._router.url).split("/", catUrlItems);
        return locationArray[(catUrlItems - 1)];
    }

    ngOnChanges() {


    }

    getCategory(): void {
        if (this.categoryName) {
            for (var i = 0; i < this.categoryList.length; i++) {
                if (this.categoryList[i].categoryName === this.categoryName) {
                    this.categoryTranslation = this.categoryList[i].categoryTranslation;
                }
            }
        }        
    }

    async getCategories(): Promise<void> {
        let storageDays = 5;
        let categoryStorage = null;
        const categoryDateStorage = `categories_date_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`;
        const categoryListStorage = `categories_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`;
        let dateStorage = localStorage.getItem(categoryDateStorage);
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < 4) {
                categoryStorage = localStorage.getItem(categoryListStorage);
            }
        }
        
        if (storageDays < 4 && categoryStorage) {
            this.categoryList = JSON.parse(categoryStorage);
        }
        else {
            this.categoryList$ = this._pollStatisticsService.getCategoryChart(this.sitePath, this.siteTypeId, this.currentLanguage.name || this.cookieLangValue)
                .pipe(
                    shareReplay(1)
                );
            this.categoryList = await VotechaHelper.lastValueFrom(this.categoryList$);
            localStorage.setItem(categoryListStorage, JSON.stringify(this.categoryList));            
            localStorage.setItem(categoryDateStorage, JSON.stringify(this.today));
        }
            
    }

    getCountrySites(): void {
        this.countrySites$ = this._pollStatisticsService.getCountrySites(this.cookieLangValue)
            .pipe(
                shareReplay(1)
            );
    }

    getAreaSites(): void {
        if (this.sitePath !== null && this.sitePath !== '') {
            this.areaSites$ = this._pollStatisticsService.getAreaSites(this.sitePath, this.cookieLangValue)
                .pipe(
                    tap(data => this.areaSites = data),
                    shareReplay(1)
                );
        }

    }

    getSubAreaSites(): void {
        this.subAreaSites$ = this._pollStatisticsService.getSubAreaSites(this.sitePath, this.cookieLangValue)
            .pipe(
                tap(data => this.subAreaSites = data),
                shareReplay(1)
            );
    }

    getSiteData(): void {
        if (this.sitePath !== undefined && this.sitePath !== "" && (this.site.sitePath !== this.sitePath && ((this.sitePath === 'global' && this.siteTypeId === 1) || (this.sitePath !== 'global' && this.siteTypeId === 2 || this.siteTypeId === 3 || this.siteTypeId === 4)))) {
            this._pollStatisticsService.getSiteData(this.sitePath, this.siteTypeId, "nb-NO").subscribe((result) => {
                this.site = result;
                this.siteId = this.site.siteId;
                this.countrySitePath = result.countryName.toLocaleLowerCase();
                if (this.siteTypeId >= 3) {
                    this.areaSitePath = this.countrySitePath + "/" + result.areaName?.toLowerCase();
                }
                if (this.siteTypeId === 4) {
                    this.subAreaSitePath = this.sitePath;
                }
                if (this.site?.areaName !== null && this.site?.areaName !== '') {
                    this.hasSameSubAreaName = this.sameAsSubAreaName(this.site.areaName);
                }
            });
        }
    }

    getGlobalSiteData(): void {
        this.isGlobal = true;
        this.isCountry = false;
        this.isArea = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'] || this.getAreaTypeFromUrl();
            this.siteTypeId = 1;
            this.sitePath = "global";
            this.getCountrySites();
        });
    }

    getCountrySiteData(sitePath: string): void {
        this.isCountry = true;
        this.isGlobal = false;
        this.isArea = false;
        this.isSubArea = false;

        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'] || this.getAreaTypeFromUrl();
            this.siteTypeId = 2;
            this.sitePath = sitePath;
            this.getAreaSites();

        });
    }

    getAreaSiteData(sitePath: string): void {
        this.isArea = true;
        this.isGlobal = false;
        this.isCountry = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'] || this.getAreaTypeFromUrl();
            this.siteTypeId = 3;
            this.sitePath = sitePath;
            this.getSubAreaSites();

        });
    }

    getSubAreaSiteData(sitePath: string): void {
        this.isSubArea = true;
        this.isGlobal = false;
        this.isCountry = false;
        this.isArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'] || this.getAreaTypeFromUrl();
            this.siteTypeId = 4;
            this.sitePath = sitePath;
        });
    }

    goToGlobalSite(): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/global']);
        }
        else {
            this._router.navigate(['public/site/global']);
        }

        this.getGlobalSiteData();
    }

    goToCountrySite(countryName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'country', this.userSite.countryName]);
        }
        else {
            this._router.navigate(['public/site/', 'country', countryName]);
        }

        this.getCountrySiteData(countryName);
    }

    goToAreaSite(areaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'area', this.userSite.countryName, this.userSite.areaName]);
        }
        else {
            this._router.navigate(['public/site/', 'area', this.countryName, areaName]);
        }

        this.getAreaSiteData(this.countryName + "/" + areaName);
    }

    goToSubAreaSite(subAreaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'subarea', this.userSite.countryName, this.userSite.areaName, this.userSite.subAreaName]);
        }
        else {
            this._router.navigate(['public/site/', 'subarea', this.countryName, this.areaName, subAreaName]);
        }

        this.getSubAreaSiteData(this.countryName + "/" + this.areaName + "/" + subAreaName);
    }

    getUserSites(): void {
        this._mySiteService.getUserSites().subscribe((result) => {
            this.userSite = result;
        });
    }

    goToCategorySite(categoryName: string): void {
        if (this.isUserLoggedIn) {
            if (this.siteTypeId === 1) {
                this._router.navigate(['app/main/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['app/main/category', categoryName, 'country', this.userSite.countrySitePath]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['app/main/category', categoryName, 'area', this.countryName, this.userSite.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['app/main/category', categoryName, 'subarea', this.countryName, this.userSite.areaName, this.userSite.subAreaName]);
            }
        }
        else {
            if (this.siteTypeId === 1) {
                this._router.navigate(['public/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['public/category', categoryName, 'country', this.countryName]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['public/category', categoryName, 'area', this.countryName, this.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['public/category', categoryName, 'subarea', this.countryName, this.areaName, this.subAreaName]);
            }

        }
    }

    sameAsSubAreaName(areaName: string): boolean {
        var hasSameName: boolean = false;
        for (var i = 0; i < this.subAreaSites.length; i++) {
            if (this.subAreaSites[i].name === areaName) {
                hasSameName = true;
                break;
            }
        }
        return hasSameName;
    }
  }
