<div bsModal #linkedAccountsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true" [config]="{keyboard: false}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{l("LinkedAccounts")}}</span>
                    <button class="btn btn-primary blue pull-right" (click)="manageLinkedAccounts()"><i class="fa fa-plus"></i>{{l("LinkNewAccount")}}</button>
                </h4>
            </div>
            <div class="modal-body">
                <div id="LinkedAccountsTable"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn default pull-right" (click)="close()">{{l("Close")}}</button>
            </div>
        </div>
    </div>
</div>
<linkAccountModal #linkAccountModal (modalSave)="getLinkedUsers()"></linkAccountModal>