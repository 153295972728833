<div [ngClass]="{'container':screenWidth > 767, 'col-xs-12':screenWidth < 768}">
    <div class="margin-bottom-10 flex-container-content-left">
        <div class="ring-container" style=" margin-bottom: 10px">
            <div class="ringring"></div>
            <div class="circle"></div>
        </div>
        <div class="poll-label">
            <a class="font-white link-underline font-media-h2 bold margin-bottom-40" (click)="goToVoteForm(politicalPartiesPoll.id)">
                {{politicalPartiesPoll.name}}
            </a>
        </div>
    </div>
    <div [ngClass]="{'margin-left-40':screenWidth > 767}">
        <table class="table">
            <tr *ngFor="let item of politicalItems; let i=index;let even = even; let odd = odd">
                <td class="chart-bar" style="width:60px;height:60px">
                    <img id="imgitem" alt="{{item.itemText}}" class="chart-logo-item" style="width:60px;max-height:60px" [src]="item.imageUrl" />
                </td>
                <td class="chart-bar">
                    <div title="{{item.itemText}}" class="chart-score-bar" style="filter: brightness(130%); height: 100%; display: flex;" [ngStyle]="{'width': item.totalPercent !== '0 %' ? item.totalPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (politicalItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && politicalItems.length < 4 ), 'bg-grey-mint': item.colorCode === '' && i === 2 && politicalItems.length < 4, 'bg-green': item.colorCode === '' && politicalItems.length > 3 && odd}">
                        <span class="score" *ngIf="item.totalPercent !== '0 %'">{{item.totalPercent}}</span>
                    </div>
                </td>

            </tr>
        </table>
    </div>
</div>
