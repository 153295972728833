import { Component, Injector, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import { UserSettingsServiceProxy, CategorySettingsInput, ExcludedUserCategoryInput, ExcludedUserCategoryOutput } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as moment from 'moment';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'terms-template',
    templateUrl: './terms-template.component.html',
    styleUrls: ['./terms-template.component.css'],
    animations: [appModuleAnimation()]
})
export class TermsTemplateComponent extends AppComponentBase implements OnInit {
    page: PageOutput;
    cookieLangValue: string;
    
    public active: boolean = false;


    constructor(
        injector: Injector       
    ) {
        super(injector);
    }

    
    ngOnInit() {                
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.cookieLangValue = "nb-NO";
        }
    }

    goToGDPR(): void {
        var element = "gdprSection";
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

    goToAccountSection(): void {
        var element = "termsAccountSection";
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

    goToPublishSection(): void {
        var element = "termsPublishingSection";
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

    goToTerminateAccountSection(): void {
        var element = "termsTerminateAccountSection";
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

    goToStatisticsSection(): void {
        var element = "termsStatisticsSection";
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

}
