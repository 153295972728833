import { Component, ViewChild, Injector } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';


@Component({
    selector: 'petitionPrivacyModal',
    templateUrl: './petition-privacy-modal.component.html',
    animations: [appModuleAnimation()]
})
export class PetitionPrivacyModalComponent extends AppComponentBase  {
    @ViewChild('modal', { static: true }) modal: ModalDirective;   

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    show(): void {
        this.modal.show();
       
    }

    close(): void {
        this.modal.hide();
    }
}
