import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Observable } from 'rxjs';
import { UrlService } from '@shared/url.service';

@Component({
    selector: 'front-carousel',
    templateUrl: './front-carousel.component.html',
    styleUrls: ['./front-carousel.component.css'],
    animations: [appModuleAnimation()]
})
export class FrontCarouselComponent extends AppComponentBase implements OnInit {

    @Input() screenWidth: number = 0;
    @Input() siteId: number = 0;
    @Input() siteTypeId: number = 0;
    @Input() inputCountry: string;
    


    constructor(
        injector: Injector,
        private _router: Router,
        private urlService: UrlService,
        private activatedRoute: ActivatedRoute
    ) {
        super(injector);
    }

    previousUrl: Observable<string> = this.urlService.previousUrl$;
    lastPosision: Observable<string> = this.urlService.lastPosision$;
    lastPosisionStr: string = "";

    ngOnInit() {        
    }

    goToVoteForm(id: string): void {
     
        let country = this.inputCountry
        if (country.toLowerCase() === 'norge') {
            country = "norway";
        }

        this._router.navigate(['public/surveyboard', country, id]);
    }

    goToCategorySite(categoryName: string, siteTypeId: number, area: string): void {
       
            if (siteTypeId === 1) {
                this._router.navigate(['public/category/', categoryName, 'global']);
            }
            else if (siteTypeId=== 2) {
                this._router.navigate(['public/category', categoryName, 'country', this.inputCountry]);
            }
            else if (siteTypeId === 3) {
                this._router.navigate(['public/category', categoryName, 'area', this.inputCountry, area]);
            }          
        
    }
}
