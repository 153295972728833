import { Component, ViewChild, Injector, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as _ from 'lodash';

@Component({
    selector: 'shareModal',
    templateUrl: './share-modal.component.html'
})
export class ShareModalComponent extends AppComponentBase {
    @ViewChild('modal', { static: true }) modal: ModalDirective;
    
    public active: boolean = false;
    url: string;
    fbUrl: string;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    show(url: string): void {
        this.active = true;
        this.url = "https://votecha.no/public/surveyboard/norway/7c36de2b-882d-41ef-ac9e-a323859688ba";
        this.fbUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.url);
        this.modal.show();
    }

    public copyUrl() {        
        document.execCommand('copy', true, this.url);
        alert(this.url);
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }

}
