export class CookieHelper {
    
    static getUsersCookieApprovedValue(): boolean {
        var isAccepted = false;
        var cookieValue = abp.utils.getCookieValue("AcceptCookies");
        if (cookieValue !== undefined && cookieValue !== null) {
            if (cookieValue === 'True') {
                isAccepted = true;
            }
        }
        return isAccepted;
    }

    static userApproveCookies(): void {
        abp.utils.setCookieValue(
            "AcceptCookies",
            "True",
            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath
        );        
    }

    static getUserSignedUpValue(): boolean {
        var hasAccount = false;
        var cookieValue = abp.utils.getCookieValue("HasAccount");
        if (cookieValue !== undefined && cookieValue !== null) {
            if (cookieValue === 'True') {
                hasAccount = true;
            }
        }
        return hasAccount;
    }
    static userHasSignedUp(): void {
        abp.utils.setCookieValue(
            "HasAccount",
            "True",
            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath
        );
    }
}
