import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
//import { FileUploadModule } from '@node_modules/ng2-file-upload';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from 'shared/header.module';
import { AppComponent } from './app.component';
//import { HeaderComponent } from './shared/layout/header.component';
//import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
//import { SideBarComponent } from './shared/layout/side-bar.component';
import { FooterComponent } from './shared/layout/footer.component';
//import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
//import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
//import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
//import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
//import { MyInterestsModalComponent } from '@app/shared/layout/profile/my-interests-modal.component';

//import { UtilsModule } from '@shared/utils/utils.module';
import { AppCommonModule } from './shared/common/app-common.module';
import { CommonSitesModule } from 'shared/common-sites.module'
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { ImpersonationService } from './admin/users/impersonation.service';
import { LinkedAccountService } from './shared/layout/linked-account.service';
//import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
//import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
//import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
//import { NotificationSettingsModalCompoent } from './shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ChatFriendListItem } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { QuickSideBarChat } from '@app/shared/layout/chat/QuickSideBarChat';




@NgModule({
    declarations: [
        AppComponent,
        //HeaderComponent,
        //HeaderNotificationsComponent,
        //SideBarComponent,
        FooterComponent,
        //LoginAttemptsModalComponent,
        //LinkedAccountsModalComponent,
        //LinkAccountModalComponent,
        //ChangePasswordModalComponent,
        //ChangeProfilePictureModalComponent,
        //MySettingsModalComponent,
        //MyInterestsModalComponent,
        //NotificationsComponent,
        ChatBarComponent,
        ChatFriendListItem


        //NotificationSettingsModalCompoent
    ],
    imports: [
        CommonModule,
        //FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        HeaderModule,
        CommonSitesModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        ////FileUploadModule,
        //AbpModule,
        AppRoutingModule,
        //UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        QuickSideBarChat
    ]
})
export class AppModule { }
