<nav class="navbar">
    <div class="container-fluid">
        <div class="col-lg-3 col-md-3 col-sm-3 margin-bottom-15" *ngIf="isUserLoggedIn">
            <a href="javascript;" class="btn btn-circle btn-md dark" data-toggle="modal" data-target="#addArticleLinkModal" (click)="addArticleLinkModal.show(survey)">
                <i class="fa fa-link" title="{{l('AddLink')}}"></i>
            </a>
            <a href="javascript;" class="btn btn-circle btn-md grey-mint" (click)="articleForm()">
                <i class="fa fa-pencil-square-o" title="{{l('WriteAPost')}}"></i>
            </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 margin-bottom-15">
            <div class="form-group" *ngIf="mediaList.length > 0">
                <select id="ddlFilterArticles" class="form-control" [(ngModel)]="filterMediaTypeId" (change)="filterArticles()" name="filterMediaTypeId" [ngClass]="{'edited':filterMediaTypeId}">
                    <option *ngFor="let media of mediaList" [ngValue]="media.value">{{media.text | titlecase}}</option>
                </select>
            </div>
        </div>
    </div>
</nav>
<div *ngIf="articles !== undefined && filteredArticles.length > 0" class="row">
    <div *ngFor="let article of filteredArticles; let i=index" (deferLoad)="article.show = true">

        <div class="col-lg-5 col-lg-offset-1 col-md-6 col-sm-6 col-xs-12" *ngIf="article.show && article.title !== null && article.title !== '' && article.mediaName.toLowerCase() !== 'youtube'">
            <div class="portlet light" style="margin: 5px 5px 5px 5px;">
                <div class="row">
                    <a href="{{article.articleUrl}}" target="_blank" *ngIf="!article.isVotecha">
                        <div class="col-md-12 col-xs-12">
                            <div class="image-container">
                                <img id="articlePhoto{{article.id}}" alt="{{article.alternateText}}" class="img-responsive" [src]="article.imageUrl">
                            </div>
                            <div class="article-logo transboxArticle" *ngIf="article.mediaLogo !== null && article.mediaLogo !== ''">
                                <img alt="logo" [src]="article.mediaLogo" class="responsive media-article-logo" [ngStyle]="{'max-height': screenWidth > 1190 || screenWidth < 767 ? '70px' : '50px'}">
                            </div>

                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h3 class="font-media-h3 c-font-bold c-font-uppercase" [innerHTML]="article.title" [ngStyle]="{'margin-top': article.imageUrl === undefined || article.imageUrl === null || article.imageUrl === '' ? '100px' : '12px'}">
                            </h3>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 font-md">
                            <p class="margin-top-10 font-dark" [innerHTML]="article.description"></p>
                        </div>
                    </a>
                    <div (click)="goToArticle(article)" *ngIf="article.isVotecha">
                        <div class="col-md-12 col-xs-12">
                            <div class="image-container">
                                <img id="articlePhoto{{article.id}}" alt="{{article.alternateText}}" class="img-responsive" [src]="article.imageUrl">
                            </div>
                            <div class="article-logo transboxArticle" *ngIf="article.mediaLogo !== null && article.mediaLogo !== ''">
                                <img alt="logo" [src]="article.mediaLogo" class="responsive media-article-logo" [ngStyle]="{'max-height': screenWidth > 1190 || screenWidth < 767 ? '70px' : '50px'}">
                            </div>

                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h3 class="font-media-h3 c-font-bold c-font-uppercase" [innerHTML]="article.title" [ngStyle]="{'margin-top': article.imageUrl === undefined || article.imageUrl === null || article.imageUrl === '' ? '100px' : '2px'}">
                            </h3>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 font-media-h3">
                            <p class="margin-top-20 font-dark" [innerHTML]="article.description"></p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="list-datetime font-md font-italic">
                            {{article.published?.format("DD.MM.YYYY")}}
                        </div>
                        <div class="c-about">
                            <div class="font-md bold font-italic">
                                {{article.mediaUrl}}
                            </div>
                        </div>
                    </div>
                    <div class="pull-right" *ngIf="article.iso !== undefined && article.iso !== null && article.iso !==''">
                        <span class="c-about margin-right-10">
                            <i class="famfamfam-flags {{article.iso | lowercase}}"></i>
                        </span>
                    </div>
                </div>
                <div class="row margin-top-10">

                    <div class="col-md-5 col-sm-4 col-xs-4" *ngIf="article.status !== 'Online'">
                        <div class="text-center">
                            <div class="label" [ngClass]="{'label-warning': article.status ==='ForApproval', 'label-success': article.status === 'Online', 'label-danger': article.status === 'Dismissed' || article.status === 'Offline'}">
                                <span *ngIf="article.status ==='ForApproval'"><i class="fa fa-gavel"></i> <span class="hidden-xs" style="margin-left:4px">{{l("ForApproval")}}</span> </span><span *ngIf="article.status === 'Online'">{{l("BlogApprovedNotificationMessage")}}</span>
                                <span *ngIf="article.status === 'Dismissed'">{{l("Dismissed")}}</span><span *ngIf="article.status === 'Offline'">{{l("Offline")}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pull-right">
                        <button class="btn btn-circle red-mint" type="button" *ngIf="article.isOwner || survey.isAdm" (click)="deleteArticle(article)" id="delete{{article.id}}">
                            <i class="fa fa-trash"></i> <span class="hidden-xs">{{l("Remove")}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="article.show && article.mediaName.toLowerCase() === 'youtube'">
            <div class="portlet light" style="margin: 5px 5px 5px 5px;">
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="container">
                            <div class="row">
                                <div class="article-logo" *ngIf="article.mediaLogo !== null && article.mediaLogo !== ''">
                                    <img alt="logo" [src]="article.mediaLogo" class="responsive media-article-logo" [ngStyle]="{'max-height': screenWidth > 1190 || screenWidth < 767 ? '30px' : '20px'}">
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <iframe [ngStyle]="{'width': iFrameWidth,'height': iFrameHeight}" [src]="getSafeUrl(article.articleUrl)" allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <h3 class="font-media-h3 c-font-bold c-font-uppercase margin-top-20" [innerHTML]="article.title">
                        </h3>
                    </div>

                    <div class="col-md-12 col-sm-12 col-xs-12 font-md">
                        <p class="margin-top-10 font-dark" [innerHTML]="article.description"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="list-datetime font-md font-italic">
                            {{article.published?.format("DD.MM.YYYY")}}
                        </div>
                        <div class="c-about">
                            <div class="font-md bold font-italic">
                                {{article.mediaUrl}}
                            </div>
                        </div>
                    </div>
                    <div class="pull-right" *ngIf="article.iso !== undefined && article.iso !== null && article.iso !==''">
                        <span class="c-about margin-right-10">
                            <i class="famfamfam-flags {{article.iso | lowercase}}"></i>
                        </span>
                    </div>
                </div>
                <div class="row margin-top-10">

                    <div class="col-md-5 col-sm-4 col-xs-4" *ngIf="article.status !== 'Online'">
                        <div class="text-center">
                            <div class="label" [ngClass]="{'label-warning': article.status ==='ForApproval', 'label-success': article.status === 'Online', 'label-danger': article.status === 'Dismissed' || article.status === 'Offline'}">
                                <span *ngIf="article.status ==='ForApproval'"><i class="fa fa-gavel"></i> <span class="hidden-xs" style="margin-left:4px">{{l("ForApproval")}}</span> </span><span *ngIf="article.status === 'Online'">{{l("BlogApprovedNotificationMessage")}}</span>
                                <span *ngIf="article.status === 'Dismissed'">{{l("Dismissed")}}</span><span *ngIf="article.status === 'Offline'">{{l("Offline")}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pull-right">
                        <button class="btn btn-circle red-mint" type="button" *ngIf="article.isOwner || survey.isAdm" (click)="deleteArticle(article)" id="delete{{article.id}}">
                            <i class="fa fa-trash"></i> <span class="hidden-xs">{{l("Remove")}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'row hidden-xs': ((i+1) % 2 === 0)}" *ngIf="screenWidth >= 768 && i > 0"></div>
    </div>
</div>
<div class="container font-dark font-italic font-media-h4" *ngIf="!isUserLoggedIn || articles.length === 0">
    <span *ngIf="articles === undefined || articles.length === 0">{{l('NoArticlesOnTopic')}}<br /></span>
    <span *ngIf="isUserLoggedIn">{{l('BeTheFirstToBlog')}}</span>
    <span *ngIf="!isUserLoggedIn"><br />{{l('LoginToBlog')}}</span>
</div>


<addArticleLinkModal #addArticleLinkModal (modalSave)="getArticles()"></addArticleLinkModal>
