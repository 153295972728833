<div [@routerTransition] style="margin-bottom: 3%;" *ngIf="active" [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">    
    <section [ngClass]="{'margin-top4pr margin-bottom4pr margin-left-26':screenWidth > 500,'margin-top4pr margin-bottom6pr':screenWidth < 501 }">
        <div  [ngClass]="{'container':screenWidth > 500}">
            <div class="c-content-title-1" [ngClass]="{'container':screenWidth < 501}">
                <h1 class="c-font-uppercase c-font-bold font-white font-media-h1">{{l('AboutUs')}}</h1>
                <div class="c-line-left c-theme-bg"></div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div [ngClass]="{'welcomeContainer':screenWidth > 500, 'margin-bottom6pr':screenWidth < 501 }">
                    <div class="wow  fadeInLeft animated c-bg-white well-front-page">
                        <div class="well c-bg-white" [ngClass]="{'well-lg':screenWidth > 500, 'padding-5': screenWidth < 351}" style="border-color:#ffffff;border-top-left-radius:12px;border-top-right-radius:12px;box-shadow:none;">
                            <div class="c-content-accordion-1 c-theme wow animate fadeInRight" style="visibility: visible; animation-name: fadeInRight; opacity: 1;">
                                <div class="panel-group" id="accordion" role="tablist">
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingOne">
                                            <h2 class="panel-title">
                                                <a class="c-font-bold c-font-uppercase font-media-h2" [ngClass]="{'collapsed': !pnlExpandWorldOfOpinions}"  data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="togglePanelWorldOfOpinions()">
                                                    {{l("WorldOfOpinionsHeader")}} <i *ngIf="!pnlExpandWorldOfOpinions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandWorldOfOpinions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" class="panel-collapse" [ngClass]="{'in': pnlExpandWorldOfOpinions, 'collapse': !pnlExpandWorldOfOpinions}" role="tabpanel" aria-labelledby="headingOne">
                                            <div class="panel-body">
                                                <div class="col-md-8 col-lg-8" [ngClass]="{'col-md-8 col-lg-8':screenWidth > 500}">
                                                    <div class="font-white" [ngClass]="{'c-font-19':screenWidth > 500, 'c-font-14':screenWidth > 350 && screenWidth < 501,'c-font-12': screenWidth < 351}" *ngIf="page.preamble !== null" [innerHTML]="page.preamble">

                                                    </div>
                                                </div>
                                                <div class="margin-top-10" [ngClass]="{'col-md-4 col-lg-4':screenWidth > 500}">
                                                    <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/misc/group_648864376.png" style="visibility: visible; animation-name: fadeInUp; opacity: 1;">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingEigth">
                                            <h2 class="panel-title">
                                                <a class="c-font-uppercase c-font-bold font-media-h2" [ngClass]="{'collapsed': !pnlExpandHowToUse}" data-toggle="collapse" data-parent="#accordion" href="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth" (click)="togglePanelExpandHowToUse()">
                                                    <span *ngIf="isMeninger24">{{l("HowToUseMeninger24")}}</span><span *ngIf="!isMeninger24">{{l("HowToUseVotecha")}}</span>  <i *ngIf="!pnlExpandHowToUse" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandHowToUse" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapseEigth" class="panel-collapse" [ngClass]="{'in': pnlExpandHowToUse, 'collapse': !pnlExpandHowToUse}" role="tabpanel" aria-labelledby="headingEigth">
                                            <div class="panel-body">
                                                <div>
                                                    <h3 class="font-media-h2 bold font-white">{{l("AnonymousUsers")}}</h3>
                                                    <p class="font-media-h3">{{l("AnonymousUsersInfo1")}}</p>
                                                    <p class="font-media-h3">{{l("AnonymousUsersInfo2")}}</p>
                                                </div>
                                                <div class="margin-top-30" id="RegisteredUsers">
                                                    <h3 class="font-media-h2 bold font-white">{{l("RegisteredUsers")}}</h3>
                                                    <p class="font-media-h3">{{l("RegisteredUsersInfo1")}}</p>
                                                    <p class="font-media-h3">{{l("RegisteredUsersInfo2")}}</p>
                                                    <p class="font-media-h3">{{l("RegisteredUsersInfo3")}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingNine">
                                            <h2 class="panel-title">
                                                <a class="c-font-uppercase c-font-bold font-media-h2" [ngClass]="{'collapsed': !pnlExpandGDPR}" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine" (click)="togglePanelExpandGDPR()">
                                                    {{l("GDPRandPersonalData")}} <i *ngIf="!pnlExpandGDPR" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandGDPR" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapseNine" class="panel-collapse" [ngClass]="{'in': pnlExpandGDPR, 'collapse': !pnlExpandGDPR}" role="tabpanel" aria-labelledby="headingNine">
                                            <div class="panel-body">
                                                <div id="gdpr">
                                                    <p class="font-media-h3">{{l("GDPRandPersonalDataInfo1")}}</p>
                                                    <p class="font-media-h3">{{l("GDPRandPersonalDataInfo2")}}</p>
                                                    <p class="font-media-h3">{{l("GDPRandPersonalDataInfo3")}}</p>
                                                    <p class="font-media-h3">{{l("GDPRandPersonalDataInfo4")}}</p>
                                                    <p class="font-media-h3">{{l("GDPRandPersonalDataInfo5")}}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingSix">
                                            <h2 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" (click)="togglePanelPollsAndPetitions()">
                                                    {{l("PollsAndPetitions")}} <i *ngIf="!pnlExpandPollsAndPetitions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandPollsAndPetitions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h2>
                                        </div>
                                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                                            <div class="panel-body">
                                                <div>
                                                    <h3 class="font-media-h2 bold font-white">{{l("PollHelpHeader")}}</h3>
                                                    <p class="font-media-h3">{{l("PollHelp1")}}</p>
                                                    <p class="font-media-h3">{{l("PollHelp2")}}</p>
                                                </div>
                                                <div class="margin-top-30">
                                                    <h3 class="font-media-h2 bold font-white">{{l("PetitionHelpHeader")}}</h3>
                                                    <p class="font-media-h3">{{l("PetitionHelp1")}}</p>
                                                    <p class="font-media-h3">{{l("PetitionHelp2")}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingThree">
                                            <h3 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" (click)="togglePanelArticlesAndBlogs()">
                                                    {{l("ArticlesAndBlog")}} <i *ngIf="!pnlExpandArticlesAndBlogs" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandArticlesAndBlogs" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h3>
                                        </div>
                                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                            <div class="panel-body">
                                                <div class="font-media-h3">{{l("ArticlesAndBlogContent")}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingFour">
                                            <h3 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" (click)="togglePanelStatistics()">
                                                    {{l("Statistics")}} <i *ngIf="!pnlExpandStatistics" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandStatistics" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h3>
                                        </div>
                                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                            <div class="panel-body">
                                                <div class="font-media-h3">{{l("StatisticsContent")}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingCompetitions">
                                            <h3 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseCompetitions" aria-expanded="false" aria-controls="collapseCompetitions" (click)="togglePanelCompetitions()">
                                                    {{l("CompetitionsAndPoint")}} <i *ngIf="!pnlExpandCompetitions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandCompetitions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h3>
                                        </div>
                                        <div id="collapseCompetitions" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingCompetitions">
                                            <div class="panel-body">
                                                <p class="font-media-h4">{{l("CompetitionsInfo1")}}</p>
                                                <p class="font-media-h4">{{l("CompetitionsInfo2")}}.</p>
                                                <p class="font-media-h4">{{l("CompetitionsInfo3")}}.</p>
                                                <div class="font-media-h3 margin-top-10">{{l("HowToCollectPoints")}}</div>
                                                <ol>
                                                    <li class="font-media-h4">{{l("WelcomeBonus10Points")}}</li>
                                                    <li class="font-media-h4">{{l("AnswerPoints1Point")}}</li>
                                                    <li class="font-media-h4">{{l("CreatePoll10Point")}}</li>
                                                    <li class="font-media-h4">{{l("CreatePetition10Point")}}</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingTwo">
                                            <h3 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" (click)="togglePanelGoalsAndVisions()">
                                                    {{l("GoalsAndVisions")}} <i *ngIf="!pnlExpandGoalsAndVisions" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandGoalsAndVisions" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h3>
                                        </div>
                                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                            <div class="panel-body">
                                                <p class="font-media-h3">{{l("VotechaVision1")}}</p>
                                                <p class="font-media-h3">{{l("VotechaVision2")}}</p>
                                                <p class="font-media-h3">{{l("VotechaVision3")}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <div class="panel-heading rounded-corners" role="tab" id="headingFive">
                                            <h3 class="panel-title">
                                                <a class="collapsed c-font-uppercase c-font-bold font-media-h2" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" (click)="togglePanelReasonsToUseVotecha()">
                                                    {{l("ReasonsToUseVotecha")}} <i *ngIf="!pnlExpandReasonsToUseVotecha" class="glyphicon glyphicon-chevron-up pull-right disable-click"></i><i *ngIf="pnlExpandReasonsToUseVotecha" class="glyphicon glyphicon-chevron-down pull-right disable-click"></i>
                                                </a>
                                            </h3>
                                        </div>
                                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                                            <div class="panel-body">
                                                <div [innerHTML]="page.content" class="lead">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

