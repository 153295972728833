import { Component, OnInit, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PetitionSigningOutput} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';


@Component({
    selector: 'signing-template',
    styleUrls: ['./signing-template.component.css'],
    templateUrl: './signing-template.component.html',
  animations: [appModuleAnimation()]
})
export class SigningTemplateComponent extends AppComponentBase implements OnInit {
    @Input() signing: PetitionSigningOutput;
    profilePicture: string = "/assets/common/images/profile-woman_s.jpg";
    profileManPicture: string = "/assets/common/images/profile-man_s.jpg";
   
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.signing.gender.toString() === '1') {
            this.profilePicture = this.profileManPicture;
        }
    }

    getAge(birthYear: number): number {
        return (new Date().getFullYear() - birthYear);
    }

  }
