import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EditorModule } from 'primeng/editor';
import { UtilsModule } from '@shared/utils/utils.module';
import { AboutComponent } from 'public/about/about.component';
import { ContactComponent } from 'public/contact/contact.component';
import { HelpComponent } from 'public/help/help.component';
import { TermsComponent } from 'public/terms/terms.component';
import { TermsContentComponent } from 'public/terms/terms-content.component';
import { TermsTemplateComponent } from 'public/terms/terms-template.component';
import { TermsModalComponent } from 'public/terms/terms-modal/terms-modal.component';
import { LockBarInfoModalComponent } from 'public/site/survey-template/lock-bar-info-modal/lock-bar-info-modal.component';
import { PrivacyComponent } from 'public/privacy/privacy.component';
import { CookiesComponent } from 'public/cookies/cookies.component';
import { PossibilitiesComponent } from 'public/possibilities/possibilities.component';
import { SiteComponent } from 'public/site/site.component';
import { WelcomeSectionComponent } from 'public/site/welcome-section/welcome-section.component';
import { TourWizardComponent } from 'public/site/tour-wizard/tour-wizard.component';
import { FrontCarouselComponent } from 'public/site/front-carousel/front-carousel.component';
import { SurveyTemplateComponent } from 'public/site/survey-template/survey-template.component';
import { SigningTemplateComponent } from 'public/survey-dashboard/petition-registration/signing-template/signing-template.component';
import { PetitionTemplateComponent } from 'public/site/petition-template/petition-template.component';
import { ArticleComponent } from "public/article/article.component";
import { AddArticleLinkModalComponent } from "public/article/add-article-link/add-article-link-modal.component";
import { SurveyDashboardComponent } from "public/survey-dashboard/survey-dashboard.component";
import { PoliticalPosisionsComponent } from "public/survey-dashboard/political-posisions/political-posisions.component";
import { RelatedArticlesComponent } from "public/survey-dashboard/related-articles/related-articles.component";
import { ProsConsComponent } from "public/survey-dashboard/pros-cons/pros-cons.component";
import { DashboardRatingBarsComponent } from "public/survey-dashboard/voting/dashboard-rating-bars.component";
import { AnonymousVotingChartsComponent } from "public/survey-dashboard/voting/anonymous-voting-charts.component";
import { PetitionRegistrationComponent } from "public/survey-dashboard/petition-registration/petition-registration.component";
import { CategoryPageComponent } from "public/category/category-page.component";
import { PoliticalPageComponent } from "public/category/political-page/political-page.component";
import { PoliticalPartyChartComponent } from "public/category/political-page/political-charts/political-party-chart.component";
import { GaugeChartComponent } from "public/category/political-page/gauge-chart/gauge-chart.component";
import { FooterPublicComponent } from 'public/shared/layout/footer-public/footer-public.component';
import { VotechaAdvantagesModalComponent } from "public/survey-dashboard/anonymous-vote-banner/votecha-advantages-modal.component";
import { PetitionPrivacyModalComponent } from "public/survey-dashboard/petition-registration/privacy/petition-privacy-modal.component";
import { AccountAdvantagesModalComponent } from "public/survey-dashboard/petition-registration/account-advantages/account-advantages-modal.component";
import { AreaTeaserComponent } from 'public/site/area-teaser/area-teaser.component';
import { ShareModalComponent } from 'public/shared/share-modal/share-modal.component';
import { GaugeChartModule } from 'angular-gauge-chart'
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MetaDataComponent } from "public/survey-dashboard/meta-data/meta-data.component";
import { PollVotingComponent } from "public/survey-dashboard/voting/poll-voting.component";
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  imports: [
      CommonModule,
      TabsModule,
      ModalModule,
      EditorModule,
      UtilsModule,
        FormsModule,
        GaugeChartModule,
        IvyCarouselModule,
        AdsenseModule.forRoot({
            adClient: 'ca-pub-5111104741767483',
            adSlot: 8634290723,
        }),
        DeferLoadModule
  ],
  declarations: [
      AboutComponent,
      ContactComponent,
      HelpComponent,
      TermsComponent,
      TermsContentComponent,
      TermsTemplateComponent,
      TermsModalComponent,
      LockBarInfoModalComponent,
      PrivacyComponent,
      CookiesComponent,
      PossibilitiesComponent,
      SiteComponent,
      ArticleComponent,
      SurveyDashboardComponent,
      CategoryPageComponent,
      PoliticalPageComponent,
      PoliticalPartyChartComponent,
      GaugeChartComponent,
      WelcomeSectionComponent,
      TourWizardComponent,
      FrontCarouselComponent,
      SurveyTemplateComponent,
      SigningTemplateComponent,
      PoliticalPosisionsComponent,
      RelatedArticlesComponent,
      ProsConsComponent,
      DashboardRatingBarsComponent,
      AnonymousVotingChartsComponent,
      AddArticleLinkModalComponent,
      FooterPublicComponent,
      PetitionTemplateComponent,
      VotechaAdvantagesModalComponent,
      PetitionPrivacyModalComponent,
      AccountAdvantagesModalComponent,
      AreaTeaserComponent,
      ShareModalComponent,
      PetitionRegistrationComponent,
      MetaDataComponent,
      PollVotingComponent
  ],
  exports: [
      AboutComponent,
      ContactComponent,
      HelpComponent,
      TermsComponent,
      TermsContentComponent,
      TermsTemplateComponent,
      PrivacyComponent,
      CookiesComponent,
      PossibilitiesComponent,
      SiteComponent,
      ArticleComponent,
      SurveyDashboardComponent,
      PoliticalPosisionsComponent,
      RelatedArticlesComponent,
      ProsConsComponent,
      CategoryPageComponent,
      PoliticalPageComponent,
      PoliticalPartyChartComponent,
      GaugeChartComponent,
      WelcomeSectionComponent,
      TourWizardComponent,
      FrontCarouselComponent,
      SurveyTemplateComponent,
      SigningTemplateComponent,
      DashboardRatingBarsComponent,
      AnonymousVotingChartsComponent,
      AddArticleLinkModalComponent,
      FooterPublicComponent,
      PetitionTemplateComponent,
      VotechaAdvantagesModalComponent,
      PetitionPrivacyModalComponent,
      AccountAdvantagesModalComponent,
      AreaTeaserComponent,
      ShareModalComponent,
      PetitionRegistrationComponent,
      MetaDataComponent,
      PollVotingComponent,
      LockBarInfoModalComponent
  ]
})
export class CommonSitesModule { }
