<div *ngIf="active">
    <div class="card card-vote-frame-online">
        <div class="survey-card-header" [ngStyle]="{'background-color': survey.colorCode ? survey.colorCode : '#4b77be'}">
            <div style="height:10px;"></div>
            <span [ngClass] ="{'h3': survey.subCategoryName.length < 26,  'h4': survey.subCategoryName.length > 25}" class="font-white margin-left10p">{{survey.subCategoryName}}</span>            
        </div>
        <div class="survey-card-content" [ngStyle]="{'background-color': survey.colorCode ? survey.colorCode : '#fff'}">
            <div style="width:100%;">
                <img id="img" alt="{{survey.alternateText}}" class="img-responsive" [src]="survey.imgUrl" />
            </div>
            <div class="survey-vote-container">
                <div class="survey-question text-left margin-top-15">
                    <span *ngIf="survey.displayQuestion !== null" [ngClass]="{'uppercase': survey.surveyTypeId === 0}" [ngStyle]="{'color': survey.colorCode ? '#fff' : '#000'}">
                        {{survey.displayQuestion}}
                    </span> <span *ngIf="survey.displayQuestion === null">{{survey.question}}</span>
                </div>
                <div class="col-sm-offset-1 margin-top2pr margin-bottom2pr">
                    <div class="container" *ngIf="survey.surveyType.toString() === 'Score_1_to_10'">
                        <div class="uppercase font-white margin-bottom-10">
                            {{l('Score_1_to_10_Descr')}}
                        </div>
                    </div>
                    <div *ngIf="survey.surveyTypeId < 21">
                        <div class="actions" *ngFor="let optionItem of survey.surveyItems; let i=index">
                            <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() !== 'ImageItemList'">

                                <label class="btn white btn-outline btn-circle btn-sm" [ngClass]="{'active': optionItem.itemValue === vote.answerId}">
                                    <input type="radio"
                                           name="answer"
                                           (change)="onVoteChange(optionItem.displayText)"
                                           [(ngModel)]="vote.answerId"
                                           [value]="optionItem.itemValue">
                                    {{optionItem.displayText}}
                                </label>

                            </div>
                            <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() === 'ImageItemList'">

                                <label class="btn blue-steel btn-outline btn-circle btn-sm" [ngClass]="{'active': optionItem.itemValue === vote.answerId}">
                                    <input type="radio"
                                           name="answer"
                                           (change)="onVoteChange(optionItem.displayText)"
                                           [(ngModel)]="vote.answerId"
                                           [value]="optionItem.itemValue">
                                    {{optionItem.displayText}}
                                    <img id="imgitem" alt="" class="img-responsive" style="max-width:100px;max-height:100px" [src]="optionItem.azureUri" />
                                </label>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="survey.surveyTypeId === 32">
                        <div class="actions" *ngFor="let optionItem of survey.surveyItems; let p=index">
                            <div class="flex-container-content-left margin-bottom-10" style="max-width:600px; margin-top:1%;margin-bottom:1%; background-color:white; padding:6px 20px; border-radius: 6px">
                                <div class="margin-right-20">
                                    <label class="btn blue btn-outline btn-circle btn-sm" [ngClass]="'active'">
                                        <input type="radio"
                                               name="answer"
                                               (change)="onVoteChange(optionItem.displayText)"
                                               [(ngModel)]="vote.answerId"
                                               [value]="optionItem.itemValue">
                                    </label>
                                </div>
                                <div>
                                    <img id="imgitem" alt="" class="img-responsive" style="max-width:50px;max-height:50px" [src]="optionItem.imgUrl" />
                                </div>
                                <div class="font-dark font-media-h4 bold margin-left-10">
                                    {{ optionItem.itemText }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="survey.surveyType.toString() === 'EntityItemList'">
                        <template-entity-selection (answerClicked)='onChildAnswerClicked($event)' (multiAnswerClicked)='onChildMultiAnswerClicked($event)' [answer]="vote.answerId" [surveyInput]='survey'></template-entity-selection>
                    </div>

                </div>
     
            </div>
            <!--<div class="survey-vote-footer">
                <span class="text-left">
                    <button class="btn white btn-circle shadow" [ngStyle]="{'color': survey.colorCode ? survey.colorCode : '#4b77be'}" (click)="goToVoteForm(survey.id)">
                        <i class="fa fa-dashboard"></i>
                    </button>
                </span>
                <span class="pull-right" >
                    <button class="btn white btn-circle shadow" [ngStyle]="{'color': survey.colorCode ? survey.colorCode : '#4b77be'}" (click)="goToVoteForm(survey.id)">
                        <i class="fa fa-arrow-right"></i>
                    </button>
                </span>
            </div>-->
        </div>
    </div>
    <div class="note note-success col-lg-4 col-md-8 col-sm-12 margin-top-20" style="max-width:700px" *ngIf="!survey.name">
        {{l('ThisSurveyIsNotAvailable')}}
    </div>
  
</div>
