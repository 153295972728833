import * as moment from 'moment';
import { EmptyError, Observable } from 'rxjs';
import { AreaSelectionDto, SubAreaSelectionDto } from '@shared/service-proxies/service-proxies';
import { Data } from '@angular/router';
export class VotechaHelper {
    /**
     * Static helper funtions for Votecha
     */

    static lastValueFrom<T>(source: Observable<T>) {
        return new Promise<T>((resolve, reject) => {
            let _hasValue = false;
            let _value: T;
            source.subscribe({
                next: value => {
                    _value = value;
                    _hasValue = true;
                },
                error: reject,
                complete: () => {
                    if (_hasValue) {
                        resolve(_value);
                    } else {
                        reject(new EmptyError());
                    }
                },
            });
        });
    }

    static loadCarousel(carouselId: string, itemCount: number, getItemSizeFromDeviceWidth: number): void {

        var auto: boolean = itemCount > 3;
        var items: number = 3;
        if (itemCount < 3 || getItemSizeFromDeviceWidth < 3)
            if (getItemSizeFromDeviceWidth < itemCount) {
                items = getItemSizeFromDeviceWidth;
            }
            else {
                items = itemCount;
            }

        $(document).ready(function () {
            (function ($) {
                var owl = $(carouselId);
                owl.owlCarousel({
                    items: items,
                    loop: true,
                    margin: 10,
                    autoplay: auto,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true
                });
            })(jQuery);
        });
    }

    static getItemSizeFromDeviceWidth(screenWidth:number): number {
        var items: number = 3;

        if (screenWidth < 768) {
            items = 1;
        }
        else if (screenWidth < 1200) {
            items = 2;
        }
        return items;
    }

    static getDaysFromDate(storedDate: Date): number {
        const ONE_DAY = 1000 * 60 * 60 * 24;
        const todayDate: Date = new Date();
        const today = Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
        const lastDate: Date = new Date(storedDate);
        const last = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());        
        return Math.round((today - last) / ONE_DAY);
    }

    static handleDate(myDate: moment.Moment) {
        let dateString: string = myDate.toString();
        var dateObj = new Date(dateString);
        var momentObj = moment(dateObj);
        var momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        let days: number = parseInt(dateString.substring(8, 10));
        let months: number = parseInt(momentString.substring(5, 7));
        let years: number = parseInt(momentString.substring(0, 5));
        let goodDate: Date = new Date(years + "/" + months + "/" + days);
        return momentString;
        //this.date = goodDate.toISOString().substring(0, 10);
    }

    static getNorwegianLang(languages: abp.localization.ILanguageInfo[]): abp.localization.ILanguageInfo {
        for (var i = 0; i < languages.length; i++) {
            if (languages[i].name === "nb-NO") {
                var norLang = languages[i];
                return norLang;
            }
        }

    }

    static shadeColor(color: string, percent: number): string {

        var R: number = parseInt(color.substring(1, 3), 16);
        var G: number = parseInt(color.substring(3, 5), 16);
        var B: number = parseInt(color.substring(5, 7), 16);

        R = Math.round(R * (100 + percent) / 100);
        G = Math.round(G * (100 + percent) / 100);
        B = Math.round(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    static getAreaName(areaId: number, areas: AreaSelectionDto[]): string {
        var name = "";
        for (var i = 0; i < areas.length; i++) {
            if (areas[i].id === areaId) {
                name = areas[i].name;
            }
        }
        return name;
    }

    static getSubAreaName(subAreaId: number, subAreas: SubAreaSelectionDto[]): string {
        var name = "";
        for (var i = 0; i < subAreas.length; i++) {
            if (subAreas[i].id === subAreaId) {
                name = subAreas[i].name;
            }
        }
        return name;
    }
}
