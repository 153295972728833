import { Component, ViewChild, Injector, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollServiceProxy, GetPollForEditOutput, EntitySchemaForVotingOutput, EntityItemDto, PollInput } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'previewSurveyModal',
    templateUrl: './preview-survey-modal.component.html'
})
export class PreviewSurveyModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

   @ViewChild('modal', { static: true }) modal: ModalDirective;
   @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

    survey: PollInput;
    schema: EntitySchemaForVotingOutput;
    entityItems: EntityItemDto[] = [];
    active: boolean = false;


    constructor(
        injector: Injector,
        private _pollService: PollServiceProxy
    ) {
        super(injector);
    }

    show(poll: PollInput): void {
        this.survey = poll;
        this.active = true;
        this.modal.show();
        //this._pollService.getPollForEditOutput(pollId, areaType, countryId).subscribe((result) => {             
        //    this.survey = result;            
        //    this.active = true;
        //    this.modal.show();
        //});
                       
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }
}
