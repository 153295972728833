<div bsModal #modal="bs-modal" class="modal fade bs-modal-lg" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    <div class="caption">
                        <h4 class="modal-title">
                            <i class="icon-bar-chart"></i>
                            <span class="caption-subject bold uppercase font-green">{{l("PetitionPrivacyAgreement")}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ol class="bold font-media-h4">
                        <li>{{l("PetitionPrivacyAgreement1")}}</li>
                        <li>{{l("PetitionPrivacyAgreement2")}}</li>
                        <li>{{l("PetitionPrivacyAgreement3")}}</li>
                        <li>{{l("PetitionPrivacyAgreement4")}}</li>
                        <li>{{l("PetitionPrivacyAgreement5")}}</li>
                        <li>{{l("PetitionPrivacyAgreement6")}}</li>
                        <li>{{l("PetitionPrivacyAgreement7")}}</li>
                        <li>{{l("PetitionPrivacyAgreement8")}}</li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
            </div>

        </div>
    </div>
</div>
