
<div [@routerTransition]   [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">
    <div class="container">
        <div class="wow fadeInLeft c-bg-white well-front-page animated" [ngClass]="{'margin-top-90 margin-bottom-20':screenWidth < 660}">
            <div class="well well-lg c-bg-white" style="border-color:#ffffff;border-top-left-radius:15px;border-top-right-radius:15px;box-shadow:none">
                <div class="row" style="padding-top:3%">
                    <div class="c-content-title-1">
                        <h1 class="c-font-uppercase c-font-bold font-dark font-media-h1">{{l('Cookies')}}</h1>
                        <div class="c-line-left c-theme-bg"></div>
                    </div>
                    <div [ngClass]="{'col-md-6 col-lg-6':isUserLoggedIn, 'col-md-8 col-lg-8':!isUserLoggedIn}">
                        <h2 class="font-dark" *ngIf="page.preamble !== null" [ngStyle]="{'font-size': screenWidth < 500 ? 'medium' : 'x-large'}">
                            {{page.preamble}}
                        </h2>
                    </div>
                    <div [ngClass]="{'col-md-3 col-lg-3':isUserLoggedIn, 'col-md-4 col-lg-4':!isUserLoggedIn}">
                        <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/misc/privacy_729008815.png" style="visibility: visible; animation-name: fadeInUp; opacity: 1;">
                    </div>
                    <div [innerHTML]="page.content" class="lead" [ngClass]="{'col-md-9 col-lg-9':isUserLoggedIn, 'col-md-12 col-lg-12':!isUserLoggedIn}">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
