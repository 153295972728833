<!-- Page Content -->
<div [@routerTransition]  >
    <div class="container">
        <div [ngClass]="{'col-lg-9 col-md-9 col-xs-12':isUserLoggedIn, 'col-lg-10 col-md-12 col-xs-12':!isUserLoggedIn}">
            <div class="portlet light">
                <div class="container">
                    <div class="col-lg-7 col-md-7 col-sm-10 col-xs-12 margin-left4pr" *ngIf="article !== null && article !== undefined">
                        <div class="image-container margin-top2pr">
                            <img id="photo" alt="{{article.alternateText}}" class="img-responsive" [src]="article.imageUrl" />
                        </div>
                        <div class="portlet-title">
                            <h1 class="c-title bold uppercase font-media-h1">
                                {{article.title}}
                            </h1>
                        </div>

                        <div class="portlet-body">
                            <div class="blog-post-content">
                                <div class="font-media-h2" [innerHTML]="article.preamble">

                                </div>

                                <div class="c-content-blog-post-1">
                                    <div class="c-panel">
                                        <div class="c-author font-italic">{{l("By")}} <span class="text-capitalize margin-right-10">{{article.authorName}}</span><i class="famfamfam-flags {{article.authorCountryIso | lowercase}}"></i></div>
                                        <div class="c-date"><span class="font-italic margin-right-10">{{article.creationTime | date: 'dd/MM/yyyy'}}</span></div>
                                        <!--<div class="c-author font-italic"><i class="fa fa-eye"></i> {{article.read}}</div>-->
                                        <div  *ngIf="article.tags !== undefined">
                                            <ul class="c-tags c-theme-ul-bg" *ngFor="let tag of article.tags">
                                                <li>{{tag.name}}</li>
                                            </ul>
                                        </div>

                                    </div>
                                    
                                </div>
                                <p [innerHTML]="article.content">

                                </p>
                                <div class="margin-top6pr" style="height:60px">
                                    <button type="button" class="btn btn-circle dark btn-outline" (click)="close()"><i class="fa fa-close"></i> {{l("Close")}}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
