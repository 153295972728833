<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <h3 class="font-media-h3 bold">{{l("Content")}}</h3>
    <div class="container font-media-h4">
        <div class="row margin-top-20">
            <a href="javascript:;" (click)="goToGDPR()">
                {{l("GDPRandPersonalData")}}
            </a>
        </div>
        <div class="row margin-top-20">
            <a href="javascript:;" (click)="goToPublishSection()">
                {{l("TermsPublishing1")}}
            </a>
        </div>
        <div class="row margin-top-20">
            <a href="javascript:;" (click)="goToAccountSection()">
                {{l("TermsUserAccount")}}
            </a>
        </div>
        <div class="row margin-top-20">
            <a href="javascript:;" (click)="goToStatisticsSection()">
                {{l("TermsUserAccountRule11")}}
            </a>
        </div>
    </div>
</div>
<div class="col-lg-6 col-md-5 col-sm-5 col-xs-12">
    <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/misc/terms_272058131.png" style="visibility: visible; animation-name: fadeInUp; opacity: 1;">
</div>
<div class="col-lg-10 col-md-10 col-sm-10 lead margin-right-10 margin-top2pr">
    <h3 class="font-media-h3 bold" id="gdprSection">{{l("GDPRandPersonalData")}}</h3>
    <div id="gdprSection" class="font-media-h4">
        {{l("TermsGdpr1")}} <br />
        {{l("TermsGdpr2")}}
    </div>

    <div class="margin-top2pr">
        <h3 class="font-media-h3 bold" id="termsPublishingSection">{{l("TermsPublishing1")}}</h3>
        <div class="font-media-h4">
            {{l("TermsPublishing2")}} <br />
            {{l("TermsPublishing3")}} <br />
            {{l("TermsPublishing4")}} <br />
            <ul class="item-list">
                <li>{{l("TermsPublishing5")}} </li>
                <li>{{l("TermsPublishing6")}} </li>
                <li>{{l("TermsPublishing7")}} </li>
                <li>{{l("TermsPublishing8")}} </li>
                <li>{{l("TermsPublishing9")}} </li>
            </ul>

            {{l("TermsPublishing10")}} <br />
            {{l("TermsPublishing11")}} <br />
            {{l("TermsPublishing12")}} <br />
            {{l("TermsPublishing13")}} <br />
            {{l("TermsPublishing14")}} <br />
        </div>
    </div>
    <div class="margin-top2pr">
        <h3 class="font-media-h3 bold" id="termsAccountSection">{{l("TermsUserAccount")}}</h3>
        <div class="bold font-media-h4">{{l("TermsResponsibility")}}</div>
        <div class="font-media-h4">
            <ul class="item-list">
                <li>
                    {{l("TermsUserAccountRule1")}}
                </li>
                <li>
                    {{l("TermsUserAccountRule2")}}
                </li>
                <li>
                    {{l("TermsUserAccountRule3")}}
                </li>
                <li>
                    {{l("TermsUserAccountRule4")}}
                </li>
                <li>
                    {{l("TermsUserAccountRule5")}}
                </li>
            </ul>

            <h3 class="font-media-h3 bold" id="termsTerminateAccountSection">
                {{l("TermsUserAccountRule6")}}
            </h3>
            <div>
                {{l("TermsUserAccountRule7")}}
                <br />
                {{l("TermsUserAccountRule8")}}
                <br />
                {{l("TermsUserAccountRule9")}}
                <br />
                {{l("TermsUserAccountRule10")}}
            </div>
        </div>
    </div>
    <div class="margin-top2pr">
        <h3 class="font-media-h3 bold" id="termsStatisticsSection">{{l("TermsUserAccountRule11")}}</h3>
        <div class="font-media-h3">
            {{l("TermsUserAccountRule12")}} <br />
            {{l("TermsUserAccountRule13")}}
        </div>
    </div>
</div>
