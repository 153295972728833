import { Component, Injector } from '@angular/core';
import { SideBarMenu } from './side-bar-menu';
import { SideBarMenuItem } from './side-bar-menu-item';

import { PermissionCheckerService } from 'abp-ng2-module';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    templateUrl: './side-bar.component.html',
    selector: 'side-bar'
})
export class SideBarComponent extends AppComponentBase {
isUserLoggedIn:boolean = false;
    constructor(
        injector: Injector,
        public permission: PermissionCheckerService,
        private _appSessionService: AppSessionService) {
        super(injector);
    }

    menu: SideBarMenu = new SideBarMenu("MainMenu", "MainMenu", [
        //new SideBarMenuItem("MyPage", "Pages.Tenant.Dashboard", "icon-home", "/app/main/dashboard"),
        new SideBarMenuItem("MyPage", "Pages.User.MyOpinions", "icon-home", "/app/main/mysite"),        
        new SideBarMenuItem("MyOpinions", "Pages.User.MyOpinions", "icon-like", "/app/main/myopinions"),
        new SideBarMenuItem("MyVotechas", "Pages.User.Survey", "icon-graph", "/app/main/usersurvey"),
        new SideBarMenuItem("MyArticles", "Pages.User.Articles", " icon-pencil", "/app/main/userarticles"),
        new SideBarMenuItem("GoVotecha", "Pages.User.Vote", "icon-check", "/app/main/vote"),

        //new SideBarMenuItem("Surveys", "Pages.User.Vote", "icon-graph", "", [
        //    new SideBarMenuItem("Current", "Pages.User.Vote", "fa fa-globe", "/app/main/site"),
        //    new SideBarMenuItem("TopLists", "Pages.User.Vote", "icon-list", "/app/main/charts"),
        //    new SideBarMenuItem("Search", "Pages.User.Vote", "icon-magnifier", "/app/main/search")]),


        //new SideBarMenuItem("MarketResearch", "Pages.User.MarketResearch", "icon-pie-chart", "/app/main/marketresearch"),
        //new SideBarMenuItem("Competitions", "Pages.User.MyOpinions", "icon-trophy", "/app/main/userrating"),
        new SideBarMenuItem("MyCompany", "Pages.Customer", "icon-briefcase", "", [
            new SideBarMenuItem("CompanyProfile", "Pages.Customer.Company", "fa fa-address-card", "/app/main/customer/company"),
            new SideBarMenuItem("MarketResearch", "Pages.Customer.Company", "fa fa-pie-chart", "/app/main/customer/research"),
            new SideBarMenuItem("CompanyRewards", "Pages.Customer.Company", "icon-trophy", "/app/main/customer/rewards")]),
        //new SideBarMenuItem("Generally", "", "icon-bulb", "", [
        //    new SideBarMenuItem("AboutUs", "", "icon-info", "/public/about"),
        //    new SideBarMenuItem("ContactUs", "", " icon-call-in", "/public/contact"),
        //    new SideBarMenuItem("Help", "", "icon-question", "/public/help"),
        //    new SideBarMenuItem("UserAgreement", "", "icon-bag", "/public/terms"),
        //    new SideBarMenuItem("Privacy", "", "icon-lock", "/public/privacy"),
        //    new SideBarMenuItem("Cookies", "", "icon-grid", "/public/cookies")]),

        new SideBarMenuItem("Tenants", "Pages.Tenants", "icon-globe", "/app/admin/tenants"),
        new SideBarMenuItem("Editions", "Pages.Editions", "icon-grid", "/app/admin/editions"),
        new SideBarMenuItem("Administration", "", "icon-wrench", "", [
            new SideBarMenuItem("Dashboard", "Pages.Administration.Host.Dashboard", "icon-home", "/app/admin/hostDashboard"),
            new SideBarMenuItem("OrganizationUnits", "Pages.Administration.OrganizationUnits", "icon-layers", "/app/admin/organization-units"),
            new SideBarMenuItem("Roles", "Pages.Administration.Roles", "icon-briefcase", "/app/admin/roles"),
            new SideBarMenuItem("Users", "Pages.Administration.Users", "icon-people", "/app/admin/users"),
            new SideBarMenuItem("Languages", "Pages.Administration.Languages", "icon-flag", "/app/admin/languages"),
            new SideBarMenuItem("AuditLogs", "Pages.Administration.AuditLogs", "icon-lock", "/app/admin/auditLogs"),
            new SideBarMenuItem("Maintenance", "Pages.Administration.Host.Maintenance", "icon-wrench", "/app/admin/maintenance"),
            new SideBarMenuItem("Subscription", "Pages.Administration.Tenant.SubscriptionManagement", "icon-refresh", "/app/admin/subscription-management"),
            new SideBarMenuItem("Settings", "Pages.Administration.Host.Settings", "icon-settings", "/app/admin/hostSettings"),
            new SideBarMenuItem("Settings", "Pages.Administration.Tenant.Settings", "icon-settings", "/app/admin/tenantSettings"),

            new SideBarMenuItem("MediaAdmin", "Pages.Administration.AppData.Articles", "fa fa-medium", "", [
                new SideBarMenuItem("Articles", "Pages.Administration.AppData.Articles", "fa fa-pencil-square", "/app/admin/articles"),
                new SideBarMenuItem("Media", "Pages.Administration.AppData.Articles", "fa fa-link", "/app/admin/media"),
            ]),

            
            //new SideBarMenuItem("ImageLibrary", "Pages.Administration.AppData.Surveys", "fa fa-picture-o", "/app/admin/photos"),
            new SideBarMenuItem("Customers", "Pages.Administration.AppData.Companies", "fa fa-building", "/app/admin/customers"),
            new SideBarMenuItem("MarketResearch", "Pages.Administration.AppData.Companies", "icon-pie-chart", "/app/admin/market-research"),            

            new SideBarMenuItem("SurveyAdmin", "Pages.Administration.AppData.Surveys", "fa fa-gavel", "", [
                new SideBarMenuItem("Votechas", "Pages.Administration.AppData.Surveys", "glyphicon glyphicon-stats", "/app/admin/surveys"),
                new SideBarMenuItem("SurveyGroups", "Pages.Administration.AppData.Surveys", "glyphicon glyphicon-th-list", "/app/admin/survey-groups"),
                new SideBarMenuItem("FactorControl", "Pages.Administration.AppData.Surveys", "fa fa-thumbs-up", "/app/admin/survey-factors"),
            ]),

            new SideBarMenuItem("SiteData", "Pages.Administration.AppData.SiteData", "icon-share", "", [
                new SideBarMenuItem("Sites", "Pages.Administration.AppData.Sites", "icon-docs", "/app/admin/sites"),
                new SideBarMenuItem("Frontpages", "Pages.Administration.AppData.FrontPages", "glyphicon glyphicon-th-large", "/app/admin/frontpages"),
                new SideBarMenuItem("SiteManagers", "Pages.Administration.AppData.SiteManagers", "icon-user-female", "/app/admin/sitemanagers"),
                new SideBarMenuItem("InfoPages", "Pages.Administration.AppData.InfoPages", "fa fa-info-circle", "/app/admin/pages"),
            ]),

            //new SideBarMenuItem("Gaming", "Pages.Administration.AppData.Gaming", "icon-game-controller", "", [
            //    new SideBarMenuItem("VotechaGames", "Pages.Administration.AppData.Gaming", "fa fa-gamepad", "/app/admin/votecha-games"),
            //    new SideBarMenuItem("Rewards", "Pages.Administration.AppData.Gaming", "icon-present", "/app/admin/votecha-rewards")
            //]),
            new SideBarMenuItem("BasicData", "Pages.Administration.AppData.BasicData", "icon-basket-loaded", "", [
                new SideBarMenuItem("Entities", "Pages.Administration.AppData.BasicData", "icon-puzzle", "/app/admin/entities"),
                new SideBarMenuItem("Countries", "Pages.Administration.AppData.Countries", "icon-globe", "/app/admin/countries"),
                new SideBarMenuItem("Categories", "Pages.Administration.AppData.Categories", "icon-notebook", "/app/admin/categories"),
                new SideBarMenuItem("Industries", "Pages.Administration.AppData.Categories", "icon-badge", "/app/admin/industries"),
                new SideBarMenuItem("Tags", "Pages.Administration.AppData.Tags", "icon-tag", "/app/admin/tags")
            ])
        ])
    ]);

    checkChildMenuItemPermission(menuItem): boolean {

        for (var i = 0; i < menuItem.items.length; i++) {
            var subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this.permission.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        if (menuItem.permissionName) {
            return this.permission.isGranted(menuItem.permissionName);
        }

        if (menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return true;
    }

}
