import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/app/main/mysite', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then(m => m.AccountModule), //Lazy load account module
        data: { preload: true }
    },
    {
        path: 'public',
        loadChildren: () => import('public/public.module').then(m => m.PublicModule), //Lazy load public module
        data: { preload: true }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule {
    constructor(private router: Router) {
        router.events.subscribe((event: NavigationEnd) => {
            setTimeout(() => {
                this.toggleBodyCssClass(event.url);
            }, 0);
        });
    }

    toggleBodyCssClass(url: string): void {
        if (url) {

            if (url === '/') {
                if (abp.session.userId > 0) {
                    $('body').attr('class', 'page-md page-header-fixed page-sidebar-closed-hide-logo');
                } else {
                    $('body').attr('class', 'page-md login');
                }
            }

            if (url.indexOf("/account/") >= 0) {
                $('body').attr('class', 'page-md login');
            }
            else if (url.indexOf("/public") >= 0) {
                if (abp.session.userId > 0) {
                    $('body').attr('class', 'page-md page-header-fixed page-sidebar-closed-hide-logo');
                } else {
                    $('body').attr('class', 'page-md login');
                }
            }
            else {
                $('body').attr('class', 'page-md page-header-fixed page-sidebar-closed-hide-logo');
            }
        }
    }
}
