<form #registerForm="ngForm" class="login-form" method="post" novalidate (ngSubmit)="save()">

    <div class="form-group" *ngIf="!emailSigning.acceptPrivacyAgreement">
        <div class="font-media-h3">
            {{l('GDPRandPersonalData')}}
        </div>
        <div class="control-label font-media-h4">
            {{l("PetitionInfo1")}}<br />
            <a href="javascript;" class="help-links" data-toggle="modal" data-target="#accountAdvantagesModal" (click)="accountAdvantagesModal.show()"> {{l("PetitionInfo2")}}</a>
        </div>
        <div class="control-label font-media-h4 margin-top-20">
            {{l("PetitionInfo3")}}<br />
            {{l("AccountAdvantage11")}}<br />
            <a href="javascript;" class="help-links" data-toggle="modal" data-target="#petitionPrivacyModal" (click)="petitionPrivacyModal.show()">{{l("PetitionInfo4")}}</a>
        </div>
    </div>
    <div class="form-group margin-top2pr margin-bottom4pr">
        <div class="md-checkbox-list">
            <div class="md-checkbox">
                <input id="PrivacyAgreement" class="md-check" type="checkbox" name="PrivacyAgreement" required
                       [(ngModel)]="emailSigning.acceptPrivacyAgreement">
                <label for="PrivacyAgreement" [ngClass]="{'font-8 bold': screenWidth < 391}">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    {{l("IAcceptPrivacyAgreement")}}
                </label>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label visible-ie8 visible-ie9">{{l("FirstName")}}</label>
        <input class="form-control placeholder-no-fix" autoFocus type="text" placeholder="{{l('FirstName')}}" [(ngModel)]="emailSigning.name" name="FirstName" required maxlength="32" />
    </div>

    <div class="form-group">
        <label class="control-label visible-ie8 visible-ie9">{{l("Surname")}}</label>
        <input class="form-control placeholder-no-fix" type="text" placeholder="{{l('Surname')}}" [(ngModel)]="emailSigning.surName" name="Surname" required maxlength="32" />
    </div>
    <div class="form-group">
        <label class="control-label visible-ie8 visible-ie9">{{l("BirthYear")}}</label>
        <input class="form-control placeholder-no-fix" type="text" placeholder="{{l('BirthYear')}}" [(ngModel)]="emailSigning.birthYear" min="1900" max="2020" name="BirthYear" required minlength="4" maxlength="4" />
    </div>
    <div class="form-group">
        <label class="hint control-label">{{l("Gender")}}</label>
        <div class="md-radio-inline">
            <div class="md-radio">
                <input id="Gender_Man" name="Gender" class="md-radiobtn" [(ngModel)]="emailSigning.gender" [value]="1" type="radio">
                <label for="Gender_Man">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    {{l("Man")}}
                </label>
            </div>
            <div class="md-radio">
                <input id="Gender_Woman" name="Gender" class="md-radiobtn" [(ngModel)]="emailSigning.gender" [value]="2" type="radio">
                <label for="Gender_Woman">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    {{l("Woman")}}
                </label>
            </div>
        </div>
    </div>

    <div *ngIf="emailSigning.gender > 0">
        <div class="form-group">
            <label class="control-label visible-ie8 visible-ie9">{{l("EmailAddress")}}</label>
            <input class="form-control placeholder-no-fix input-ltr" type="email" placeholder="{{l('EmailAddress')}}" [(ngModel)]="emailSigning.email" name="EmailAddress" required maxlength="100" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$" />
        </div>
        <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="emailSigning.countryId === 0">
            <select class="form-control" [(ngModel)]="emailSigning.countryId" name="country" (change)="onCountryDDLChange()" required>
                <option value="0" disabled selected>{{l("Country")}}</option>
                <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
            </select>
            <label class="control-label visible-ie8 visible-ie9">{{l("Country")}}</label>
        </div>
        <div class="margin-top2pr" *ngIf="emailSigning.subAreaId === 0">
            <label class="font-media-h4 font-italic">{{l("SelectYourPlaceOfResidence")}}</label>
        </div>
        <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="(emailSigning.countryId > 0 && areas.length > 0)" (change)="onAreaDDLChange()">
            <select class="form-control" [(ngModel)]="emailSigning.areaId" name="area" [ngClass]="{'edited':emailSigning.areaId}">
                <option value="0" disabled selected>{{l("SelectCountryAreaDef")}}</option>
                <option *ngFor="let a of areas" [ngValue]="a.id">{{a.name}}</option>
            </select>
            <label class="control-label visible-ie8 visible-ie9">{{l("CountryAreaDef")}}</label>
        </div>
        <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="(emailSigning.areaId > 0 && subAreas.length > 0)">
            <select class="form-control" [(ngModel)]="emailSigning.subAreaId" name="subArea" [ngClass]="{'edited':emailSigning.subAreaId}" required>
                <option value="0" disabled selected>{{l("SelectSubAreaDef")}}</option>
                <option *ngFor="let sa of subAreas" [ngValue]="sa.id">{{sa.name}}</option>
            </select>
            <label class="control-label visible-ie8 visible-ie9">{{l("SubArea")}}</label>
        </div>
        <div class="form-group" *ngIf="emailSigning.subAreaId > 0">
            <label class="font-media-h4 font-italic font-purple">{{l("DisplayName")}}</label>
            <div class="md-radio-list">
                <div class="md-radio font-dark">
                    <input id="display_fullname" name="display_fullname" class="md-radiobtn" [(ngModel)]="displayCode" [value]=1 type="radio">
                    <label for="display_fullname">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("DisplayFullName")}}
                    </label>
                </div>
                <div class="md-radio font-dark">
                    <input id="display_forname" name="display_forname" class="md-radiobtn" [(ngModel)]="displayCode" [value]=2 type="radio">
                    <label for="display_forname">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("DisplayForName")}}
                    </label>
                </div>
                <div class="md-radio font-dark">
                    <input id="display_anonymous" name="display_anonymous" class="md-radiobtn" [(ngModel)]="displayCode" [value]=3 type="radio">
                    <label for="display_anonymous">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("DisplayAnonymous")}}
                    </label>
                </div>
            </div>
        </div>
   
        <div class="margin-top2pr" *ngIf="emailSigning.subAreaId > 0 && displayCode < 3">
            <label class="font-media-h4 font-italic font-purple">{{l("PersonalPetitionMessageInfo")}}</label>
            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                <input class="form-control" type="text" name="petitionMessage" [ngClass]="{'edited':emailSigning.petitionMessage}" [(ngModel)]="emailSigning.petitionMessage" maxlength="150">
                <label>{{l("PersonalPetitionMessage")}}</label>
            </div>
        </div>
    </div>
    <div class="margin-top2pr form-actions">
        <button type="submit" [ngClass]="{'btn-default':!registerForm.form.valid || emailSigning.subAreaId === 0 || emailSigning.gender === 0 || displayCode === 0, 'btn-success':registerForm.form.valid && emailSigning.subAreaId > 0 && emailSigning.gender > 0 && displayCode !== 0}" class="btn btn-lg uppercase" [disabled]="!registerForm.form.valid || emailSigning.subAreaId === 0 || emailSigning.gender === 0 || displayCode === 0" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-check"></i> {{l("SignPetition")}}</button>
    </div>

</form>
<petitionPrivacyModal #petitionPrivacyModal></petitionPrivacyModal>
<accountAdvantagesModal #accountAdvantagesModal></accountAdvantagesModal>
