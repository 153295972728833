<div [@routerTransition] [ngClass]="{'c-bg-white': screenWidth < 660}" *ngIf="active" [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">
    <div [ngClass]="{'container margin-bottom-20': screenWidth > 659, 'col-lg-12 col-md-12': screenWidth < 660 }">
        <div class="c-bg-white" [ngClass]="{'wow fadeInLeft  well-front-page animated': screenWidth > 659}">
            <div [ngClass]="{'well well-lg well-sm c-bg-white': screenWidth > 659,'padding-top-20': screenWidth < 660 }" [ngStyle]="{'border-color:#ffffff;border-top-left-radius:15px;border-top-right-radius:15px;box-shadow:none': screenWidth > 659}">
                <div [ngClass]="{'row': screenWidth > 659}">
                    <div class="container c-content-title-1">
                        <h1 class="uppercase bold font-dark font-media-h1">{{l('UserAgreement')}}</h1>
                        <div class="c-line-left c-theme-bg"></div>
                    </div>
                    <div>
                        <terms-content [isRegistering]=false [showApproveButton]=true [hideTermsContent]=false [hideHeader]=true></terms-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>


