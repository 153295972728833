<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="active">
            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title font-blue-steel">

                    <span>{{l("Translations")}}</span>
                </h4>
            </div>
            <div class="modal-body">

                <!-- BEGIN Portlet PORTLET-->
                <div class="portlet light">
                    <div class="portlet-title">
                        <div class="caption">
                            <span class="caption-subject bold uppercase">{{l('CreateTranslation')}}</span>
                        </div>
                    </div>
                    <div class="portlet-body">
                        <div class="">
                            <div class="margin-bottom-30">
                                <select *ngIf="newTranslation" class="form-control" [(ngModel)]="newPollTranslation.applicationLanguageID" name="selectedLanguageId" id="selectedLanguageId">
                                    <option *ngFor="let l of translationLanguageList" [ngValue]="l.id">{{l.displayName}}</option>
                                    <option value="0">{{l('SelectLanguage')}}</option>
                                </select>
                                <label class="label label-primary" *ngIf="!newTranslation">{{l('Edit')}} {{newPollTranslation.languageName}} </label>
                            </div>
                        </div>
                        <div class="panel-group accordion" id="accordionTranslations">
                            <div class="panel panel-primary">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionTranslations" href="#collapse_survey">{{l('TheSurvey')}}</a>
                                    </h4>
                                </div>
                                <div id="collapse_survey" class="panel-collapse in">
                                    <div class="panel-body">
                                        <table class="table table-no-border">
                                            <tr>
                                                <td style="border:none">
                                                    {{l('Title')}}: <span class="bold">{{poll.name}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:none">
                                                    <input class="form-control" id="newTitleTranslation" type="text" name="newTitleTranslation" [(ngModel)]="newPollTranslation.titleTranslation" maxlength="199">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:none">
                                                    {{l('Question')}}: <span class="bold">{{poll.question}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="border:none">
                                                    <input class="form-control" id="newQuestionTranslation" type="text" name="newQuestionTranslation" [(ngModel)]="newPollTranslation.questionTranslation">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:none">
                                                    {{l('Description')}}: <span>{{poll.description}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="border:none">
                                                    <textarea maxlength="999" class="form-control" rows="6" id="newDescriptionTranslation" name="newDescriptionTranslation" [(ngModel)]="newPollTranslation.descriptionTranslation"></textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border:none">
                                                    {{l('AlternateText')}}: <span class="bold">{{poll.alternateText}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style="border:none">
                                                    <input class="form-control" id="alternateImageText" type="text" name="alternateImageText" [(ngModel)]="newPollTranslation.alternateImageText">
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-primary" *ngIf="hasSurveyFactors">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionTranslations" href="#collapse_factors">{{l('ProsAndCons')}}</a>
                                    </h4>
                                </div>
                                <div id="collapse_factors" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <!--<tabset class="tab-container tabbable-line" id="tabFactors">
                                            <tab heading="{{l('Pros')}}" [active]="activeTabFactorIndex === 0">
                                                <table class="table table-no-border">
                                                    <tr>
                                                        <th colspan="2">
                                                            <label class="control-label">{{l('Pros')}}</label>
                                                        </th>
                                                        <th>
                                                            <label class="control-label">{{l('Translation')}}</label>
                                                        </th>
                                                    </tr>

                                                    <tr *ngFor="let item of prosItems; let i=index">
                                                        <td>{{ i+1 }}</td>
                                                        <td>{{ item.factorText }}</td>
                                                        <td>
                                                            <input class="form-control col-md-2" name="translatedfactor{{ i+1 }}" type="text" [(ngModel)]="item.displayText" />
                                                        </td>
                                                    </tr>
                                                </table>

                                            </tab>
                                            <tab heading="{{l('Cons')}}" [active]="activeTabFactorIndex === 1">
                                                <table class="table table-no-border">
                                                    <tr>
                                                        <th colspan="2">
                                                            <label class="control-label">{{l('Cons')}}</label>
                                                        </th>
                                                        <th>
                                                            <label class="control-label">{{l('Translation')}}</label>
                                                        </th>
                                                    </tr>

                                                    <tr *ngFor="let item of consItems; let i=index">
                                                        <td>{{ i+1 }}</td>
                                                        <td>{{ item.factorText }}</td>
                                                        <td>
                                                            <input class="form-control col-md-2" name="translatedfactor{{ i+1 }}" type="text" [(ngModel)]="item.displayText" />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </tab>
                                        </tabset>-->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="margin-bottom-20 margin-top-20">
                            <span class="input-group-btn">
                                <button [ngClass]="{'btn-default': invalidForm(),'btn-primary green':!invalidForm() }" class="btn" [disabled]="invalidForm()" (click)="saveTranslation(newPollTranslation)"><span *ngIf="newTranslation"><i class="fa fa-plus" aria-hidden="true"></i> {{l('AddTranslation')}}</span><span *ngIf="!newTranslation"><i class="fa fa-save" aria-hidden="true"></i> {{l('Update')}}</span></button>
                                <button class="btn" [ngClass]="{'btn-default': newTranslation,'btn-primary blue':!newTranslation }" type="button" id="btnEdit" (click)="clearTranslationFields()" [disabled]="newTranslation"><i class="fa fa-eraser" aria-hidden="true"></i> {{l('NewTranlation')}}</button>
                            </span>
                        </div>
                        <table class="table table-no-border table-hover table-striped">
                            <thead>
                                <tr style="border:none">
                                    <td class="col-md-1" style="border:none">
                                        {{l("Language")}}
                                    </td>
                                    <td style="border:none">
                                        {{l('Title')}}
                                    </td>
                                    <td style="border:none">
                                        {{l('Question')}}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody *ngFor="let t of pollTranslations; let i = index" style="border:none">
                                <tr>
                                    <td style="border:none">
                                        {{t.languageName }}
                                    </td>
                                    <td style="border:none">
                                        {{t.titleTranslation }}
                                    </td>
                                    <td style="border:none">
                                        {{t.questionTranslation }}
                                    </td>
                                    <td>
                                        <button class="btn btn-primary" type="button" id="btnEdit" (click)="editTranslation(t.id)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>{{l('Edit')}}</button>
                                    </td>
                                    <td>
                                        <button (click)="deleteTranslation(t)" title="{{l('Delete')}}" class="btn btn-danger" href="javascript:;">
                                            <i class="icon-trash"></i>
                                            {{l('Delete')}}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- END Portlet PORTLET-->


            </div>
            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-default" (click)="save()">{{l("Close")}}</button>

            </div>

        </div>
    </div>
</div>

