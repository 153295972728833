
<div id="poll_{{inputSurvey.id}}" class="card" [ngClass]="{'card-frame-online':isUserLoggedIn, 'card-frame-offline':!isUserLoggedIn}" (click)="goToVoteForm(inputSurvey.id)">
    <div class="survey-card-header" [ngStyle]="{'background-color': inputSurvey.colorCode ? inputSurvey.colorCode : '#4b77be'}">
        <div style="height:10px;"></div>
        <h3 [ngStyle]="{'font-size': inputSurvey.subCategoryName.length < 24 ? 'x-large' : 'medium'}">{{inputSurvey.subCategoryName}}</h3>
        <div class="pull-right" *ngIf="inputSurvey.surveyTypeId === 0 || (inputSurvey.subCategoryIso !== undefined && inputSurvey.subCategoryIso !== '')">
            <i class="fa fa-bullhorn margin-right-10 font-white" *ngIf="inputSurvey.surveyTypeId === 0"></i>
            <span *ngIf="inputSurvey.subCategoryIso !== undefined && inputSurvey.subCategoryIso !== ''" class="margin-right-10 famfamfam-flags {{inputSurvey.subCategoryIso | lowercase}}"></span>
        </div>

    </div>
    <div class="bar-lock-container" *ngIf="inputSurvey.participants > 0">
        <div *ngIf="!inputSurvey.unlocked" style="filter: brightness(130%)"><span class="key-bar" [ngStyle]="{'width': inputSurvey.unlockPercentage !== '0 %'? inputSurvey.unlockPercentage : '0px'}"><i class="fa fa-key pull-right key-icon"></i></span><span class="pull-right"><i class="fa fa-lock"></i></span></div>
        <div *ngIf="inputSurvey.unlocked" style="filter: brightness(130%)"><div class="key-bar-full"><span class="pull-right"><i class="fa fa-unlock"></i></span></div></div>
    </div>
    <div class="survey-card-content" [ngStyle]="{'background-color': inputSurvey.surveyTypeId > 0 && inputSurvey.colorCode ? inputSurvey.colorCode : '#fff'}">
        <div>
            <img id="img_{{inputSurvey.id}}" alt="{{inputSurvey.alternateText}}" class="img-responsive" [src]="inputSurvey.imgUrl" />
        </div>
        <div class="survey-container">
            <div class="survey-question text-left margin-top2pr">
                <span [ngClass]="{'uppercase bold': inputSurvey.surveyTypeId === 0}" [ngStyle]="{'color': inputSurvey.surveyTypeId > 0 && inputSurvey.colorCode ? '#fff' : '#000'}">
                    <span *ngIf="inputSurvey.displayQuestion !== null">
                        {{inputSurvey.displayQuestion}}
                    </span>
                    <span *ngIf="inputSurvey.displayQuestion === null">{{inputSurvey.question}}</span>
                </span>
            </div>
        </div>
        <div class="survey-footer">
            <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Online'">
                <button [ngStyle]="{'background-color': inputSurvey.hasVoted ? inputSurvey.colorCode : 'white'}" [ngClass]="{'btn-outline btn-icon-only': inputSurvey.hasVoted, 'shadow':!inputSurvey.hasVoted}" class="btn btn-circle" [attr.aria-label]="goToDashboard" *ngIf="isUserLoggedIn && inputSurvey.surveyTypeId > 0" (click)="goToVoteForm(inputSurvey.id)">
                    <span *ngIf="inputSurvey.hasVoted"><i class="fa fa-check fa-3x font-white"></i></span><span *ngIf="!inputSurvey.hasVoted" [ngStyle]="{'color': inputSurvey.colorCode ? inputSurvey.colorCode : '#4b77be'}">{{l("Vote")}}</span>
                </button>
                <button class="btn white btn-circle survey-button shadow" [attr.aria-label]="goToDashboard" *ngIf="!isUserLoggedIn && inputSurvey.surveyTypeId > 0" [ngStyle]="{'color': inputSurvey.colorCode ? inputSurvey.colorCode : '#4b77be'}" (click)="goToVoteForm(inputSurvey.id)">
                    <i class="fa fa-arrow-right"></i>
                </button>
                <button class="btn btn-circle btn-outline shadow font-white" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && (!isUserLoggedIn || !inputSurvey.hasVoted)" [ngStyle]="{'background-color': inputSurvey.colorCode ? inputSurvey.colorCode : '#4b77be'}">
                    <span *ngIf="isUserLoggedIn">{{l("SignPetition")}}</span> <i class="fa fa-arrow-right"></i>
                </button>
                <button class="btn btn-circle btn-outline shadow btn-success" [attr.aria-label]="goToDashboard" *ngIf="inputSurvey.surveyTypeId === 0 && isUserLoggedIn && inputSurvey.hasVoted">
                    <i class="fa fa-check-circle"></i> {{l("Signed")}}
                </button>
            </div>
            <div class="text-center" *ngIf="inputSurvey.status.toString() === 'Offline'">
                <label class="label label-danger">{{l("Offline")}}</label>
            </div>
        </div>
    </div>
</div>



