<div class="col-lg-12 col-xs-12 margin-bottom-40">
    <div class="col-lg-1 col-md-2 col-sm-2 col-xs-3 posision-label-container">
        <span style="padding: 20px 0"><img alt="green-traffic-light" height="85" src="/assets/common/images/traffic-light-green.jpg"></span>
    </div>
    <div style="padding: 10px; margin-right:20px">
        <div *ngFor="let partyItem of survey.politicalPosisionItems; let p=index">
            <div *ngIf="partyItem.opinionId === 1" class="col-lg-2 col-md-2 col-sm-2 col-xs-3 padding-5">
                <img id="imgitem" alt="Political party" class="item-logo-img" [src]="partyItem.imgUrl" />
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xs-12" style="border-top: solid 3px #26ad38">
        <div style="color: #26ad38" class="font-media-h3 bold text-center">{{l('For')}}</div>
    </div>
</div>
<div class="col-lg-12 col-xs-12 margin-bottom-30">
    <div class="col-lg-1 col-md-2 col-sm-2 col-xs-3 posision-label-container">
        <span style="padding: 20px 0"><img alt="red-traffic-light" height="85" src="/assets/common/images/traffic-light-red.jpg"></span>
    </div>
    <div *ngFor="let partyItem of survey.politicalPosisionItems; let p=index">
        <div *ngIf="partyItem.opinionId === 2" class="col-lg-2 col-md-2 col-sm-2 col-xs-3">
            <img id="imgitem" alt="Political party" class="item-logo-img" [src]="partyItem.imgUrl" />
        </div>
    </div>
    <div class="col-lg-12 col-xs-12" style="border-top: solid 3px #ff0000">
        <div class="font-red font-media-h3 bold text-center"> {{l('Against')}}</div>
    </div>
</div>
