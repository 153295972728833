import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { VotechaVoteServiceProxy, PollServiceProxy,  FactorOutput, FactorInput, FactorRatingInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'meta-data',
    templateUrl: './meta-data.component.html',
  animations: [appModuleAnimation()]
})
export class MetaDataComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;    
    @Input() isUserLoggedIn: boolean = false;
    @Input() screenWidth: number = 0;


    public categoryColor: string = "#4b77be";
    public active: boolean = false;
    public saving: boolean = false;


    private cookieLangValue: string;

    constructor(
        injector: Injector,
        private _pollService: PollServiceProxy,
        private _router: Router,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {                   
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.categoryColor = this.survey.colorCode;
    }

  }
