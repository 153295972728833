<div bsModal #modal="bs-modal" class="modal fade bs-modal-lg" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #previewForm="ngForm">
                <div class="modal-header">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div class="caption">
                            <h4 class="modal-title">
                                <i class="icon-bar-chart"></i>
                                <span class="caption-subject bold uppercase">{{l("Preview")}}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body" *ngIf="active">                   
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="margin-bottom-5 margin-top-30">
                                <div class="portlet box" [ngStyle]="{'background-color': categoryColor}">
                                    <div class="portlet-title margin-left10p">
                                        <div class="caption">
                                            <h1><span class="caption-subject bold uppercase font-white font-media-h1"> <span *ngIf="survey.surveyType > 0">{{survey.name}}</span><span *ngIf="survey.surveyType === 0">{{l('Petition')}}</span></span></h1>
                                            <span class="font-white font-media-h3">{{ survey.creationTimeString}}</span>
                                        </div>
                                        <div class="actions margin-top-20">
                                            <span class="btn" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.categoryName}}</span>
                                            <span *ngIf="survey.subCategoryName !== null" class="btn margin-left-20" [ngStyle]="{'background-color': tagColor}" style="pointer-events:none">{{survey.subCategoryName}}</span>
                                        </div>
                                    </div>
                                    <div class="portlet-body">                                      
                                        <div class="margin-top-30">
                                            <div>
                                                <div class="panel-group accordion" id="accordionDashboard">
                                                    <div class="panel panel-default" *ngIf="survey.surveyType > 0">
                                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                                            <h2 class="panel-title">
                                                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Background" aria-expanded="true"><i class="fa fa-info-circle" aria-hidden="true"></i> {{l('SurveyBackground')}} </a>
                                                            </h2>
                                                        </div>
                                                        <div id="collapse_Background" class="panel-collapse collapse" aria-expanded="false" style="">
                                                            <div class="panel-body bg-grey-steel">
                                                                <div class="portlet light ">
                                                                    <div class="portlet-body">
                                                                        <div class="margin-left2pr margin-bottom-20">
                                                                            <p [innerHTML]="survey.description" class="article-text">
                                                                            </p>
                                                                        </div>
                                                                        <hr />
                                                                        <div class="container margin-top4pr">
                                                                            <span class="font-media-h3" [ngStyle]="{'color': categoryColor}">{{l('Sources')}}</span>
                                                                            <ul>
                                                                                <li *ngFor="let source of survey.descriptionSources; let i=index">
                                                                                    <a *ngIf="source.sourceUrl !== null && source.sourceUrl !== ''" title="{{source.sourceName}}" href="{{source.sourceUrl}}" target="_blank">
                                                                                        {{source.sourceName}}
                                                                                    </a>
                                                                                    <span *ngIf="source.sourceUrl === null || source.sourceUrl === ''">{{source.sourceName}}</span>
                                                                                </li>
                                                                            </ul>
                                                                            <span *ngIf="survey.descriptionSources.length === 0">
                                                                                {{l('NoSources')}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel panel-default">
                                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                                            <h2 class="panel-title">
                                                                <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_Articles" aria-expanded="false"><i class="fa fa-newspaper-o" aria-hidden="true"></i> {{l('RelatedArticles')}} </a>
                                                            </h2>
                                                        </div>
                                                        <div id="collapse_Articles" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                                                            <div class="panel-body bg-grey-steel">
                                                                <nav *ngIf="isUserLoggedIn" class="navbar">
                                                                    <div class="container-fluid">
                                                                        <div class="col-lg-6 col-md-12 margin-bottom-15">
                                                                            <a href="javascript;" class="btn btn-circle btn-md dark">
                                                                                <i class="fa fa-link"></i><span class="hidden-xs">{{l('AddLink')}}</span>
                                                                            </a>
                                                                            <a href="javascript;" class="btn btn-circle btn-md grey-mint">
                                                                                <i class="fa fa-pencil-square-o"></i> <span class="hidden-xs">{{l('WriteAPost')}}</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-12">
                                                                            <div class="form-group">
                                                                                <label class="font-sm margin-right-10">{{l('Sort')}}</label>
                                                                                <div class="btn-group btn-group-circle" data-toggle="buttons">
                                                                                    <label class="btn default" [ngClass]="{'active': sortingOrder === 1}">
                                                                                        <input type="radio" name="sortingOrder1" [ngModel]="sortingOrder" [value]="1" class="toggle"> {{l("Date")}}
                                                                                    </label>
                                                                                    <label class="btn default" [ngClass]="{'active': sortingOrder === 2}">
                                                                                        <input type="radio" name="sortingOrder2" [ngModel]="sortingOrder" [value]="2" class="toggle"> {{l("Media")}}
                                                                                    </label>
                                                                                    <label class="btn default" [ngClass]="{'active': sortingOrder === 3}">
                                                                                        <input type="radio" name="sortingOrder3" [ngModel]="sortingOrder" [value]="3" class="toggle"> {{l("Rating")}}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </nav>
                                                                <div class="mt-element-list">
                                                                    <div class="mt-list-container list-news ext-1" *ngIf="articles !== undefined && articles.length > 0">
                                                                        <ul *ngFor="let article of articles; let i=index">
                                                                            <li class="mt-list-item" *ngIf="article.title !== null && article.title !== ''">
                                                                                <div class="portlet light">
                                                                                    <div class="row">
                                                                                        <div class="col-md-4">
                                                                                            <div class="article-logo" *ngIf="article.mediaLogo !== null && article.mediaLogo !== ''">
                                                                                                <a title="{{article.mediaName}}" href="https://{{article.mediaUrl}}" target="_blank">
                                                                                                    <img alt="logo" [src]="article.mediaLogo" class="responsive" style="max-height:60px">
                                                                                                </a>
                                                                                            </div>
                                                                                            <span class="uppercase margin-left10p">
                                                                                                {{article.mediaUrl}}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="col-md-8">
                                                                                            <div class="list-datetime font-md font-italic pull-right">
                                                                                                {{article.published.format("DD.MM.YYYY")}}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <a href="{{article.articleUrl}}" target="_blank">
                                                                                            <div class="col-md-4">
                                                                                                <div class="c-image">

                                                                                                    <img id="articlePhoto{{article.id}}" alt="{{article.alternateText}}" class="img-responsive" [src]="article.imageUrl">

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-8">
                                                                                                <h3 class="font-media-h1 c-font-bold c-font-uppercase">
                                                                                                    {{article.title}}
                                                                                                </h3>
                                                                                                <div class="font-media-h3">
                                                                                                    <p>{{article.description}}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="row margin-top-20">
                                                                                        <div class="pull-right">
                                                                                            <span class="margin-right-20"><button class="btn-icon-only btn-emty" [disabled]="article.userRating === -1 || !isUserLoggedIn"><i *ngIf="isUserLoggedIn" [ngClass]="{'font-grey-mint': article.userRating < 1, 'font-green': article.userRating === 1}" class="fa fa-long-arrow-up fa-2x"></i><i *ngIf="!isUserLoggedIn" class="fa fa-long-arrow-up fa-2x font-disabled"></i> </button><span class="label label-sm label-success">{{article.positiveScore}}</span></span>
                                                                                            <span class="margin-right-20"><button class="btn-icon-only btn-emty" [disabled]="article.userRating === 1 || !isUserLoggedIn"><i *ngIf="isUserLoggedIn" [ngClass]="{'font-grey-mint': article.userRating > -1, 'font-red': article.userRating === -1}" class="fa fa-long-arrow-down fa-2x"></i><i *ngIf="!isUserLoggedIn" class="fa fa-long-arrow-down fa-2x font-disabled"></i></button><span class="label label-sm label-danger">{{article.negativeScore}}</span></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row margin-top-20">

                                                                                        <div class="col-md-5 col-sm-4 col-xs-4" *ngIf="article.status !== 'Online'">
                                                                                            <div class="text-center">
                                                                                                <div class="label" [ngClass]="{'label-warning': article.status ==='ForApproval', 'label-success': article.status === 'Online', 'label-danger': article.status === 'Dismissed' || article.status === 'Offline'}">
                                                                                                    <span *ngIf="article.status ==='ForApproval'"><i class="fa fa-gavel"></i> <span class="hidden-xs" style="margin-left:4px">{{l("ForApproval")}}</span> </span><span *ngIf="article.status === 'Online'">{{l("BlogApprovedNotificationMessage")}}</span>
                                                                                                    <span *ngIf="article.status === 'Dismissed'">{{l("Dismissed")}}</span><span *ngIf="article.status === 'Offline'">{{l("Offline")}}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-2 col-sm-4 col-xs-2" *ngIf="article.status !== 'Online' || survey.isAdm">
                                                                                            <div class="pull-right">
                                                                                                <button class="btn btn-circle red-mint" type="button" *ngIf="article.status ==='ForApproval' || survey.isAdm" id="delete{{article.id}}">
                                                                                                    <i class="fa fa-trash"></i> <span class="hidden-xs">{{l("Delete")}}</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="alert alert-info font-dark" *ngIf="!isUserLoggedIn || articles.length === 0">
                                                                        <span *ngIf="articles === undefined || articles.length === 0" class="font-media-h3 bold">{{l('NoArticlesOnTopic')}}<br /></span>
                                                                        <span *ngIf="isUserLoggedIn">{{l('BeTheFirstToBlog')}}</span>
                                                                        <span *ngIf="!isUserLoggedIn">{{l('LoginToBlog')}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel panel-default" *ngIf="survey.useProsAndCons">
                                                        <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                                            <h2 class="panel-title">
                                                                <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_3" aria-expanded="false"><i class="fa fa-search-plus" aria-hidden="true"></i> {{l('ProsAndCons')}} </a>
                                                            </h2>
                                                        </div>
                                                        <div id="collapse_3" class="panel-collapse collapse" aria-expanded="false">
                                                            <div class="panel-body bg-grey-steel">
                                                                <div class="portlet light ">
                                                                    <div class="portlet-title">
                                                                        <div class="caption">
                                                                            <i [ngStyle]="{'color': categoryColor}" class="fa fa-plus-circle"></i>
                                                                            <span [ngStyle]="{'color': categoryColor}" class="caption-subject bold uppercase"> {{l("Pros")}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="portlet-body">
                                                                        <div *ngIf="survey.prosFactors.length > 0">
                                                                            <div *ngFor="let factor of survey.prosFactors; let k=index">
                                                                                <div class="row">
                                                                                    <div class="factor-frame">
                                                                                        <div class="pull-left bold font-media-h3" style="margin-right:3px">{{k+1}}.</div>
                                                                                        <div class="margin-bottom-10 bold font-media-h3" style="width:100%;"><span *ngIf="factor.displayText !== null && factor.displayText !== ''">{{factor.displayText}}</span><span *ngIf="factor.displayText === null || factor.displayText === ''">{{factor.factorText}}</span></div>
                                                                                        <div class="nowrap" *ngIf="isUserLoggedIn && factor.factorStatus !== 'ForApproval'">
                                                                                            <fieldset class="rating">
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star5-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=5 [(ngModel)]="factor.userRanking" /><label class="full" for="star5-conFactor{{factor.id}}" title="Excellent - 5 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star4-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=4 [(ngModel)]="factor.userRanking" /><label class="full" for="star4-conFactor{{factor.id}}" title="Very good - 4 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star3-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=3 [(ngModel)]="factor.userRanking" /><label class="full" for="star3-conFactor{{factor.id}}" title="Good - 3 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star2-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=2 [(ngModel)]="factor.userRanking" /><label class="full" for="star2-conFactor{{factor.id}}" title="Fair - 2 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star1-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=1 [(ngModel)]="factor.userRanking" /><label class="full" for="star1-conFactor{{factor.id}}" title="Poor - 1 star"></label>
                                                                                            </fieldset>
                                                                                        </div>
                                                                                        <div class="pull-right" *ngIf="factor.factorStatus === 'Approved'">
                                                                                            <span class="badge" [ngClass]="{'badge-primary': factor.factorStatus === 'Approved', 'badge-default':factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed'}">{{factor.rating | number : '1.1-1'}}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="row" *ngIf="isUserLoggedIn && ((survey.hasProFactorForApproval && (factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed')) || survey.isAdm)">
                                                                                    <div class="container">
                                                                                        <span><label *ngIf="factor.factorStatus === 'ForApproval'" class="label label-default">{{l("ForApproval")}}</label><label *ngIf="factor.factorStatus === 'Dismissed'" class="label label-danger">{{l("Dismissed")}}</label></span>
                                                                                        <div class="col-sm-5 col-xs-8">
                                                                                            <span class="margin-right-10"><button class="btn btn-circle btn-info"><i class="fa fa-edit"></i></button></span>
                                                                                            <span><button class="btn btn-circle red-pink"><i class="fa fa-trash"></i></button></span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="isUserLoggedIn">
                                                                            <input class="form-control" type="text" name="question" [ngClass]="{'edited':newProsFactor.factorText}" [(ngModel)]="newProsFactor.factorText" required maxlength="290" minlength="10">
                                                                            <label><span *ngIf="newProsFactor.factorId === ''" class="font-media-h3">{{l("AddProsArgument")}}</span><span class="font-media-h3" *ngIf="newProsFactor.factorId !== ''">{{l("Edit")}}</span></label>
                                                                        </div>
                                                                        <div class="row" *ngIf="isUserLoggedIn">
                                                                            <div style="margin-left:15px">
                                                                                <span *ngIf="newProsFactor.factorId !== ''"><button class="btn btn default"><i class="fa fa-close"></i> {{l("Cancel")}}</button></span>
                                                                                <button [ngStyle]="{'background-color': categoryColor}" class="btn btn-primary"><span *ngIf="newProsFactor.factorId === ''"><i class="fa fa-plus"></i> {{l("Add")}}</span><span *ngIf="newProsFactor.factorId !== ''"><i class="fa fa-save"></i> {{l("Save")}}</span></button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="alert alert-info font-dark margin-top-20" *ngIf="!isUserLoggedIn || survey.prosFactors.length === 0">
                                                                            <span *ngIf="survey.prosFactors.length === 0" class="bold font-media-h3">{{l('NoPros')}}<br /></span>
                                                                            <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreatePros')}}</span>
                                                                            <span *ngIf="!isUserLoggedIn" class="bold">{{l('LoginCreateAPros')}}</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="portlet light ">
                                                                    <div class="portlet-title">
                                                                        <div class="caption">
                                                                            <i class="fa fa-minus-circle font-red-soft"></i>
                                                                            <span class="caption-subject font-red-soft bold uppercase"> {{l("Cons")}}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div class="portlet-body">
                                                                        <div *ngIf="survey.consFactors.length > 0">
                                                                            <div *ngFor="let factor of survey.consFactors; let k=index">
                                                                                <div class="row">
                                                                                    <div class="factor-frame">
                                                                                        <div class="pull-left bold font-media-h3" style="margin-right:3px">{{k+1}}.</div>
                                                                                        <div class="margin-bottom-10 bold font-media-h3">{{factor.factorText}}</div>
                                                                                        <div class="nowrap" *ngIf="isUserLoggedIn && factor.factorStatus !== 'ForApproval'">
                                                                                            <fieldset class="rating">
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star5-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=5 [(ngModel)]="factor.userRanking" /><label class="full" for="star5-conFactor{{factor.id}}" title="Excellent - 5 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star4-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=4 [(ngModel)]="factor.userRanking" /><label class="full" for="star4-conFactor{{factor.id}}" title="Very good - 4 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star3-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=3 [(ngModel)]="factor.userRanking" /><label class="full" for="star3-conFactor{{factor.id}}" title="Good - 3 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star2-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=2 [(ngModel)]="factor.userRanking" /><label class="full" for="star2-conFactor{{factor.id}}" title="Fair - 2 stars"></label>
                                                                                                <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star1-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=1 [(ngModel)]="factor.userRanking" /><label class="full" for="star1-conFactor{{factor.id}}" title="Poor - 1 star"></label>
                                                                                            </fieldset>
                                                                                        </div>
                                                                                        <div class="pull-right">
                                                                                            <span class="badge" [ngClass]="{'badge-danger': factor.factorStatus === 'Approved', 'badge-default':factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed'}">{{factor.rating | number : '1.1-1'}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row" *ngIf="isUserLoggedIn && ((survey.hasConFactorForApproval && (factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed')) || survey.isAdm)">
                                                                                    <div class="container">
                                                                                        <span><label *ngIf="factor.factorStatus === 'ForApproval'" class="label label-default">{{l("ForApproval")}}</label><label *ngIf="factor.factorStatus === 'Dismissed'" class="label label-danger">{{l("Dismissed")}}</label></span>
                                                                                        <div class="col-sm-5 col-xs-8">
                                                                                            <span class="margin-right-10"><button class="btn btn-circle btn-info"><i class="fa fa-edit"></i></button></span>
                                                                                            <span><button class="btn btn-circle red-pink"><i class="fa fa-trash"></i></button></span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="isUserLoggedIn">
                                                                        <input class="form-control" type="text" name="newConsFactor" [ngClass]="{'edited':newConsFactor.factorText}" [(ngModel)]="newConsFactor.factorText" required maxlength="290" minlength="10">
                                                                        <label><span *ngIf="newConsFactor.factorId === ''" class="font-media-h3">{{l("AddConsArgument")}}</span><span class="font-media-h3" *ngIf="newConsFactor.factorId !== ''">{{l("Edit")}}</span></label>
                                                                    </div>
                                                                    <div class="row" *ngIf="isUserLoggedIn">
                                                                        <div style="margin-left:15px">
                                                                            <span *ngIf="newConsFactor.factorId !== ''"><button class="btn btn default"><i class="fa fa-close"></i> {{l("Cancel")}}</button></span>
                                                                            <button class="btn btn-primary red-soft"><span *ngIf="newConsFactor.factorId === ''"><i class="fa fa-plus"></i> {{l("Add")}}</span><span *ngIf="newConsFactor.factorId !== ''"><i class="fa fa-save"></i> {{l("Save")}}</span></button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="alert alert-info font-dark margin-top-20" *ngIf="!isUserLoggedIn || survey.consFactors.length === 0">
                                                                        <span *ngIf="survey.consFactors.length === 0" class="font-media-h3 bold">{{l('NoCons')}}<br /></span>
                                                                        <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreateCons')}}</span>
                                                                        <span *ngIf="!isUserLoggedIn" class="bold">{{l('LoginCreateACons')}}</span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default">
                                                    <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                                        <h2 class="panel-title">
                                                            <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_survey" aria-expanded="false"><i class="fa fa-line-chart" aria-hidden="true"></i> <span *ngIf="survey.surveyType > 0"> {{l('TheSurvey')}}</span><span *ngIf="survey.surveyType === 0"> {{l('ThePetition')}}</span> </a>
                                                        </h2>
                                                    </div>
                                                    <div id="collapse_survey" class="panel-collapse collapse in" aria-expanded="true">
                                                        <div class="panel-body bg-grey-steel">
                                                            <div class="portlet light" *ngIf="survey.surveyType === 0">
                                                                <div class="portlet-body">
                                                                    <div class="margin-left-20 margin-bottom-20">
                                                                        <p [innerHTML]="survey.description" class="article-text">

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="portlet light">
                                                                <div  style="padding:2%">
                                                                    <div class="row">
                                                                        <div class="col-lg-5">
                                                                            <img *ngIf="survey.imgUrl !== null" id="photo" alt="{{survey.alternateText}}" class="img-responsive" [src]="survey.imgUrl" />
                                                                        </div>
                                                                        <div class="col-lg-7">
                                                                            <div class="caption" style="margin-top:2%;margin-bottom:2%">
                                                                                <h3><span class="caption-subject bold uppercase" [ngStyle]="{'color': survey.colorCode}">{{survey.question}}</span></h3>
                                                                            </div>
                                                                            <div style="margin-top:2%;margin-bottom:2%">
                                                                                <div class="container" *ngIf="survey.surveyType === 4">
                                                                                    <div class="uppercase margin-bottom-10" [ngStyle]="{'color': categoryColor}">
                                                                                        {{l('Score_1_to_10_Descr')}}
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="survey.surveyType > 0 && survey.surveyType < 21">
                                                                                    <div class="actions" *ngFor="let optionItem of survey.surveyItems; let i=index">
                                                                                        <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType < 20">

                                                                                            <label [ngStyle]="{'color': survey.colorCode, 'border-color': survey.colorCode }" class="btn btn-outline btn-circle btn-sm" >
                                                                                                <input type="radio"
                                                                                                       name="answer"
                                                                                                       [disabled]="!isUserLoggedIn"
                                                                                                       [value]="optionItem.itemValue">
                                                                                                <span class="margin-left-5"><span *ngIf="optionItem.displayText !== ''">{{optionItem.displayText}}</span><span *ngIf="optionItem.displayText === ''">{{optionItem.itemText}}</span></span>
                                                                                            </label>

                                                                                        </div>
                                                                                        <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType === 20">

                                                                                            <label class="btn votecha-purple btn-outline btn-circle btn-sm">
                                                                                                <input type="radio"
                                                                                                       name="answer"
                                                                                                       [disabled]="!isUserLoggedIn"
                                                                                                       [value]="optionItem.itemValue">
                                                                                                <span class="margin-left-5">{{optionItem.displayText}}</span>
                                                                                                <img id="imgitem" class="img-responsive" alt="{{optionItem.alternateText}}" style="max-width:100px;max-height:100px" [src]="optionItem.azureUri" />
                                                                                            </label>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </div>
                                                                            <div class="container" style="margin-top:4%">
                                                                                <div class="text-left">
                                                                                    <em>{{l("HowImportantIsThisTopicForYou")}}</em>
                                                                                    <div class="container">
                                                                                        <fieldset class="rating">
                                                                                            <input type="radio" id="star5" name="rating" [value]=5 /><label class="full" for="star5" title="Excellent - 5 stars"></label>

                                                                                            <input type="radio" id="star4" name="rating" [value]=4 /><label class="full" for="star4" title="Very good - 4 stars"></label>

                                                                                            <input type="radio" id="star3" name="rating" [value]=3 /><label class="full" for="star3" title="Good - 3 stars"></label>

                                                                                            <input type="radio" id="star2" name="rating" [value]=2 /><label class="full" for="star2" title="Fair - 2 stars"></label>

                                                                                            <input type="radio" id="star1" name="rating" [value]=1 /><label class="full" for="star1" title="Poor - 1 star"></label>

                                                                                        </fieldset>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="alert alert-info survey-login-message" *ngIf="!isUserLoggedIn">
                                                                                <span class="bold uppercase font-dark">{{l("LoginToVote")}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                         
                                                            </div>
         
                                                            <!--<div class="portlet light">
                                                                <div class="portlet-title">
                                                                    <i class="fa fa-id-card-o" [ngStyle]="{'color': categoryColor}"></i>
                                                                    <span class="caption-subject bold uppercase" [ngStyle]="{'color': categoryColor}"> {{l("Metadata")}}</span>
                                                                </div>
                                                                <div class="portlet-body">
                                                                    <table class="table table-no-border" style="line-height:1.8em">
                                                                        <tr class="margin-bottom-10">
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Creator')}}</span></td>

                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Category')}}</span></td>

                                                                            <td> {{survey.categoryName}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('SubCategory')}}</span></td>

                                                                            <td> {{survey.subCategoryName}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('Area')}}</span></td>

                                                                            <td> <span *ngIf="survey.areaType === 1">{{l('AllCountries')}}</span><span *ngIf="survey.areaType !== 1">{{survey.areaName}} </span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('StartDate')}}</span></td>

                                                                            <td>{{survey.startDate | date}} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('EndDate')}}</span></td>

                                                                            <td><span *ngIf="survey.endDate !== null">{{survey.endDate | date}} </span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="right" class="col-lg-1"><span class="margin-right-10 bold" [ngStyle]="{'color': categoryColor}">{{l('ImageProvider')}}</span></td>

                                                                            <td><span *ngIf="survey.imageProviderType !== 3">{{survey.imageProvider }} </span><span *ngIf="survey.imageProviderType === 3">{{l('OwnPicture')}}</span><span *ngIf="survey.photographer !== null && survey.photographer !==''"> - {{survey.photographer}} </span></td>
                                                                        </tr>

                                                                    </table>
                                                                </div>
                                                            </div>-->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default">
                                                    <div class="panel-heading" [ngStyle]="{'background-color': categoryColor, 'color': '#fff'}">
                                                        <h2 class="panel-title">
                                                            <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordionDashboard" href="#collapse_RelatedSurveys" aria-expanded="false"><i class="fa fa-files-o" aria-hidden="true"></i> {{l('RelatedSurveys')}} </a>
                                                        </h2>
                                                    </div>
                                                    <div id="collapse_RelatedSurveys" class="panel-collapse collapse" aria-expanded="false">
                                                        <div class="portlet-body bg-grey-steel">
                                                            <div class="portlet-body">
                                                                <nav class="navbar">
                                                                    <div class="container-fluid">
                                                                        <div class="col-lg-6 col-md-12 margin-top-15 margin-bottom-15">
                                                                            <a href="javascript:;" class="btn btn-circle btn-md grey-mint">
                                                                                <i class="fa fa-pencil-square-o"></i> {{l('CreateARelatedSurvey')}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </nav>


                                                                <div class="alert font-dark" *ngIf="!isUserLoggedIn || relatedSurveys.length === 0">
                                                                    <span *ngIf="relatedSurveys.length === 0" class="font-media-h3 bold">{{l('NoRelatedSurveys')}}<br /></span>
                                                                    <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreateSurvey')}}</span>
                                                                    <span *ngIf="!isUserLoggedIn">{{l('LoginCreateARelatedSurvey')}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    
                   
                    <div class="note note-success col-lg-7 col-md-12 col-sm-12 margin-top-20" style="max-width:700px" *ngIf="!survey.name">
                        {{l('ThisSurveyIsNotAvailable')}}
                    </div>



                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
