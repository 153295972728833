<div [@routerTransition] style="margin-top: 70px; margin-bottom: 3%;" [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">
    <div class="container">
        <div class="wow fadeInLeft c-bg-white well-front-page animated">
            <div class="well well-lg c-bg-white" style="border-color:#ffffff;border-top-left-radius:15px;border-top-right-radius:15px;box-shadow:none">
                <div class="container">
                    <div class="c-content-title-1">
                        <h1 class="c-font-uppercase c-font-bold font-dark font-media-h1">{{l('ContactUs')}}</h1>
                        <div class="c-line-left c-theme-bg"></div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="font-dark margin-bottom4pr" [ngClass]="{'container':screenWidth > 600}" *ngIf="page.preamble !== null">
                            {{page.preamble}}
                        </div>
                        <div  [ngClass]="{'container':screenWidth > 600}">
                            <div class="row margin-top2pr">
                                <div class="bold col-lg-2 col-md-3 col-sm-3 col-xs-12">{{l('ForSupport')}}</div>
                                <div class="votecha-blue col-lg-2 col-md-3 col-sm-3 col-xs-12"><span *ngIf="!isMeninger24">support@opinions24.net</span><span *ngIf="isMeninger24">support@meninger24.no</span></div>
                            </div>
                            <div class="row margin-top2pr">
                                <div class="bold col-lg-2 col-md-3 col-sm-3 col-col-xs-12">{{l('ForSales')}}</div>
                                <div class="votecha-blue col-lg-2 col-md-3 col-sm-3 col-xs-12"><span *ngIf="!isMeninger24">sales@opinions24.net</span><span *ngIf="isMeninger24">salg@meninger24.no</span></div>
                            </div>
                            <div class="row margin-top2pr">
                                <div class="bold col-lg-2 col-md-3 col-sm-3 col-col-xs-12">{{l('ForInfo')}}</div>
                                <div class="votecha-blue col-lg-2 col-md-3 col-sm-3 col-xs-12"><span *ngIf="!isMeninger24">info@opinions24.net</span><span *ngIf="isMeninger24">info@meninger24.no</span></div>
                            </div>
                            <div class="row margin-top2pr margin-bottom4pr">
                                <div class="bold col-lg-2 col-md-3 col-sm-3 col-xs-12">{{l('ForFaktura')}}</div>
                                <div class="votecha-blue col-lg-2 col-md-3 col-sm-3 col-xs-12"><span *ngIf="!isMeninger24">invoice@opinions24.net</span><span *ngIf="isMeninger24">faktura@meninger24.no</span></div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'col-md-3 col-lg-3':isUserLoggedIn, 'col-md-4 col-lg-4':!isUserLoggedIn}">
                        <img class="c-photo img-responsive thumbnail" alt="" src="/assets/common/images/misc/contact_742783930.png" style="visibility: visible; animation-name: fadeInUp; opacity: 1;">
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</div>
