import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PollStatisticsServiceProxy, VotechaVoteServiceProxy, PollServiceProxy,  FactorOutput, FactorInput, FactorRatingInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { forEach } from 'lodash';

@Component({
    selector: 'pros-cons',
    styleUrls: ['./pros-cons.component.css'],
    templateUrl: './pros-cons.component.html',
  animations: [appModuleAnimation()]
})
export class ProsConsComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;    
    @Input() isUserLoggedIn: boolean = false;
    @Input() screenWidth: number = 0;
    @Output() prosConsCountEmitter = new EventEmitter<number>();

    prosConsCount: number;


    public active: boolean = false;
    public saving: boolean = false;
    public newProsFactor: FactorInput;
    public newConsFactor: FactorInput;
    public pollFactors: FactorOutput[] = [];
    public prosFactors: FactorOutput[] = [];
    public consFactors: FactorOutput[] = [];
    public sortingOrder: number;


    private cookieLangValue: string;

    constructor(
        injector: Injector,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private _userVoteService: VotechaVoteServiceProxy,
        private _pollService: PollServiceProxy,
        private _router: Router,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.getFactors();
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.newProsFactor = new FactorInput();
        this.newConsFactor = new FactorInput();
        this.sortingOrder = 1;
        this.newProsFactor.factorId = "";
        this.newConsFactor.factorId = "";
        this.newProsFactor.languageId = this.survey.userLanguageId;
        this.newConsFactor.languageId = this.survey.userLanguageId;
        this.newProsFactor.pollId = this.survey.id;
        this.newConsFactor.pollId = this.survey.id;
        this.newProsFactor.factorType = 1;
        this.newConsFactor.factorType = 2;        
    }

    getFactors(): void {
        this._pollStatisticsService.getPollFactors(this.survey.id, this.survey.areaTypeId, this.survey.countryId, this.cookieLangValue).subscribe((result) => {
            this.pollFactors = result;
            this.prosFactors = this.pollFactors.filter((factor) => factor.factorType === 1);
            this.consFactors = this.pollFactors.filter((factor) => factor.factorType === 2);
            this.setRating(this.prosFactors);
            this.setRating(this.consFactors);
            this.prosConsCount = this.prosFactors.length + this.consFactors.length;
            this.prosConsCountEmitter.emit(this.prosConsCount);
        });
    }

    setRating(factors: FactorOutput[]): void {
        for (var i = 0; i < factors.length; i++) {
            factors[i].rating = factors[i].rating / factors[i].voteCount;
        }
    }

    onFactorRankingChange(factor: FactorOutput, score: number): void {
        this.saving = true;
        var factorRating = new FactorRatingInput();
        factorRating.factorId = factor.id;
        factorRating.rating = score;
        factorRating.countryId = factor.countryId;
        factorRating.pollId = factor.pollId;
        factorRating.userFactorRatingId = factor.userFactorRatingId;
        this._userVoteService.submitPollFactorRating(factorRating).pipe(
            finalize(() => this.saving = false))
            .subscribe((result) => {
                if (factor.factorType === 1) {
                    for (var i = 0; i < this.prosFactors.length; i++) {
                        if (this.prosFactors[i].id === factor.id) {
                            const rating = this.prosFactors[i].userRating > 0 ? (this.prosFactors[i].rating + score) / (this.prosFactors[i].voteCount) : (this.prosFactors[i].rating + score) / (this.prosFactors[i].voteCount + 1);
                            this.prosFactors[i].rating = rating;
                            this.prosFactors[i].userFactorRatingId = result.userFactorRatingId;
                        }
                    }
                }
                else {
                    for (var i = 0; i < this.consFactors.length; i++) {
                        if (this.consFactors[i].id === factor.id) {
                            const rating = this.consFactors[i].userRating > 0 ? (this.consFactors[i].rating + score) / (this.consFactors[i].voteCount) : (this.consFactors[i].rating + score) / (this.consFactors[i].voteCount + 1);
                            this.consFactors[i].rating = rating;
                            this.consFactors[i].userFactorRatingId = result.userFactorRatingId;
                        }
                    }
                }

            });
    }

    saveFactor(isPros: boolean): void {
        var factor = new FactorInput();
        var isNewFactor: boolean = true;
        factor.factorId = "00000000-0000-0000-0000-000000000000";
        if (isPros) {
            if (this.newProsFactor.factorId !== "") factor.factorId = this.newProsFactor.factorId;
            factor.factorText = this.newProsFactor.factorText;
            factor.factorType = this.newProsFactor.factorType;
        }
        else {
            if (this.newConsFactor.factorId !== "") factor.factorId = this.newConsFactor.factorId;
            factor.factorText = this.newConsFactor.factorText;
            factor.factorType = this.newConsFactor.factorType;
        }

        if (!factor.factorId.startsWith("00000000-0000-0000")) {
            isNewFactor = false;
        }
        this.saving = true;
        factor.pollId = this.survey.id;
        factor.areaType = this.survey.areaType;
        factor.countryId = this.survey.countryId;
        factor.languageId = this.survey.userLanguageId;
        this._pollService.savePollFactor(factor)
            .subscribe((result) => {
                var savedFactor = result;
                if (factor.factorType == 1) {
                    if (isNewFactor) {
                        this.prosFactors.push(savedFactor);
                        this.notify.info(this.l('ThanksForContributing'));
                    }
                    else {
                        for (var i = 0; i < this.prosFactors.length; i++) {
                            if (this.prosFactors[i].id === savedFactor.id) {
                                this.prosFactors[i] = savedFactor;
                            }
                        }
                        this.notify.info(this.l('SavedSuccessfully'));
                    }
                    this.newProsFactor = new FactorInput();
                    this.newProsFactor.factorId = "";
                    this.newProsFactor.factorType = 1;
                    this.newProsFactor.languageId = this.survey.userLanguageId;
                    this.newProsFactor.pollId = this.survey.id;
                    this.newProsFactor.areaType = this.survey.areaType;
                    this.newProsFactor.countryId = this.survey.countryId;
                    this.newProsFactor.factorText = ""

                } else {
                    if (isNewFactor) {
                        this.consFactors.push(savedFactor);
                        this.notify.info(this.l('ThanksForContributing'));
                    } else {
                        for (var i = 0; i < this.consFactors.length; i++) {
                            if (this.consFactors[i].id === savedFactor.id) {
                                this.consFactors[i] = savedFactor;
                            }
                            this.notify.info(this.l('SavedSuccessfully'));
                        }
                    }
                    this.newConsFactor = new FactorInput();
                    this.newConsFactor.factorId = "";
                    this.newConsFactor.factorType = 2;
                    this.newConsFactor.pollId = this.survey.id;
                    this.newConsFactor.languageId = this.survey.userLanguageId;
                    this.newConsFactor.factorText = "";
                }

            });
    }

    getPollFactors(factorTypeId: number): void {
        this._pollService.getPollFactors(this.survey.id, this.survey.areaTypeId, this.survey.countryId).subscribe((result) => {
            if (factorTypeId === 1) {
                this.prosFactors = result.items;
            } else {
                this.consFactors = result.items;
            }
        });
    }


    editFactor(pollFactor: FactorOutput): void {
        if (pollFactor.factorType == 1) {
            this.newProsFactor.factorId = pollFactor.id;
            this.newProsFactor.languageId = pollFactor.languageId;
            this.newProsFactor.areaType = this.survey.areaTypeId;
            this.newProsFactor.countryId = this.survey.countryId;
            this.newProsFactor.factorText = pollFactor.factorText;
            this.newProsFactor.factorType = pollFactor.factorType;
            this.newProsFactor.pollId = this.survey.id;
        }
        else {
            this.newConsFactor.factorId = pollFactor.id;
            this.newConsFactor.languageId = pollFactor.languageId;
            this.newConsFactor.areaType = this.survey.areaTypeId;
            this.newConsFactor.countryId = this.survey.countryId;
            this.newConsFactor.factorText = pollFactor.factorText;
            this.newConsFactor.factorType = pollFactor.factorType;
            this.newConsFactor.pollId = this.survey.id;
        }
    }


    deleteFactor(pollFactor: FactorOutput): void {
        this.message.confirm(
            this.l('AreYouSureToDeleteTheArgument', pollFactor.factorText), this.l('AreYouSure'),
            isConfirmed => {
                if (isConfirmed) {
                    this._pollService.deleteFactor(pollFactor.id, pollFactor.pollId, this.survey.areaType, this.survey.countryId)
                        .subscribe(() => {
                            this.notify.info(this.l('SuccessfullyDeleted'));
                            if (pollFactor.factorType === 1) {
                                _.remove(this.prosFactors, pollFactor);
                            }
                            else {
                                _.remove(this.consFactors, pollFactor);
                            }
                        });

                }
            }
        );
    }
    cancelEditFactor(isPros: boolean): void {
        if (isPros) {
            this.newProsFactor = new FactorInput();
            this.newProsFactor.factorId = "";
            this.newProsFactor.factorType = 1;
            this.newProsFactor.languageId = this.survey.userLanguageId;
            this.newProsFactor.pollId = this.survey.id;
            this.newProsFactor.factorText = ""

        } else {
            this.newConsFactor = new FactorInput();
            this.newConsFactor.factorId = "";
            this.newConsFactor.factorType = 2;
            this.newConsFactor.pollId = this.survey.id;
            this.newConsFactor.languageId = this.survey.userLanguageId;
            this.newConsFactor.factorText = "";
        }
    }
  }
