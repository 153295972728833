
<div [@routerTransition] [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}" class="margin-bottom2pr">
    <!--<div class="margin-top2pr margin-bottom2pr" [ngClass]="{'container':screenWidth > 767 }">
        <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                    [adSlot]="3133248694"
                    [adFormat]="'auto'"
                    [display]="'block'"
                    [fullWidthResponsive]="true">

        </ng-adsense>
    </div>-->
    <div [ngClass]="{'row': isUserLoggedIn, 'container': !isUserLoggedIn}" *ngIf="category !== undefined && category !== null">
        <div [ngClass]="{'col-lg-9 col-md-9 col-sm-12 col-xs-12':isUserLoggedIn, 'col-lg-12 col-md-12 col-sm-12 col-xs-12':!isUserLoggedIn}">
            <h1 class="font-white font-media-h1">{{categoryTranslation}}</h1>
            <hr class="categoryLine" [ngStyle]="{'border-color': category.colorCode ? category.colorCode : '#4CAF50'}">
        </div>
    </div>
    <div *ngIf="showPoliticalPage && politicalPolls !== null && politicalPolls.length > 0" class="container margin-top2pr">
        <political-page [politicalPolls]='politicalPolls' [screenWidth]="screenWidth" [inputIsUserLoggedIn]="isUserLoggedIn" [countryName]="countryName" [pagePath]="pagePath"></political-page>
    </div>

    <div class="container margin-top2pr">
        <div class="form-group form-md-line-input" *ngIf="isUserLoggedIn">
            <div class="btn btn-circle btn-outline" [ngClass]="{'green':showOnlyUnanswered,'white':!showOnlyUnanswered }">
                <input id="showOnlyUnanswered" class="md-check" type="checkbox" (change)="chbOnlyUnansweredChanged()" name="showOnlyUnanswered" [ngClass]="{'edited':showOnlyUnanswered}"
                       [(ngModel)]="showOnlyUnanswered">
                <label for="showOnlyUnanswered">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>
                    <span style="margin-left:10px">{{l("ShowOnlyUnanswered")}}</span>

                </label>
            </div>
        </div>
        <div class="form-group font-white col-xs-12  col-sm-8 col-md-6 col-lg-6">
            <div class="md-radio-inline">
                <div class="md-radio">
                    <input id="surveyTypeFilter_all" name="surveyTypeFilter_all" class="md-radiobtn" [(ngModel)]="surveyTypeFilterId" [value]=-1 type="radio">
                    <label for="surveyTypeFilter_all">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("All")}}
                    </label>
                </div>
                <div class="md-radio">
                    <input id="surveyTypeFilter_petitions" name="surveyTypeFilter_petitions" class="md-radiobtn" [(ngModel)]="surveyTypeFilterId" [value]=0 type="radio">
                    <label for="surveyTypeFilter_petitions">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("Petitions")}}
                    </label>
                </div>
                <div class="md-radio">
                    <input id="surveyTypeFilter_polls" name="surveyTypeFilter_polls" class="md-radiobtn" [(ngModel)]="surveyTypeFilterId" [value]=1 type="radio">
                    <label for="surveyTypeFilter_polls">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("Polls")}}
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group col-xs-6  col-sm-4 col-md-3 col-lg-2" *ngIf="surveys.length > 0">
            <select class="form-control" [(ngModel)]="filterSubCategoryId" name="filterSubCategoryId" [ngClass]="{'edited':filterSubCategoryId}">
                <option value=0 selected="selected">{{l("All")}}</option>
                <option *ngFor="let sc of subCategories" [ngValue]="sc.subCategoryId">{{sc.subCategoryName}}</option>
            </select>
        </div>
    </div>
    <main>
        <div *ngIf="!allAnswered" class="container">
            <div class="row" *ngIf="surveys.length > 0">
                <div *ngFor="let output of surveysFiltered; let i = index" (deferLoad)="output.show = true">
                    <div *ngIf="output.subCategoryId !== 23 && (surveyTypeFilterId === -1 || (surveyTypeFilterId === 0 && output.surveyTypeId === surveyTypeFilterId) || (surveyTypeFilterId === 1 && output.surveyTypeId > 0)) && (filterSubCategoryId.toString() === '0' || output.subCategoryId  === filterSubCategoryId)">
                        <div class="container hidden-lg hidden-md hidden-sm" *ngIf="output.showHeaderOnCatPage && output.HeaderTitle !== null && output.HeaderTitle !== ''">

                            <div style="height:auto;">
                                <h4>
                                    <span class="bold survey-header" [ngStyle]="{'color': output.colorCode ? output.colorCode : '#4b77be'}">{{output.headerTitle}}</span>
                                </h4>
                                <span class="survey-header-preamble preamble-color" style="margin-top:15%;">
                                    {{output.headerPreamble}}
                                </span>

                            </div>
                        </div>
                        <div *ngIf="output.show" class="template-margin" [ngClass]="{'col-lg-3 col-md-4 col-sm-5 col-xs-12':isUserLoggedIn, 'col-lg-4 col-md-4 col-sm-6 col-xs-12':!isUserLoggedIn}">
                            <survey-template [inputSurvey]='output' [inputIsUserLoggedIn]='isUserLoggedIn' [inputCountry]="countryName" [inputPageUrl]="pagePath"></survey-template>
                        </div>
                        <div class="hidden-xs" [ngClass]="{'col-lg-6 col-md-4 col-sm-5':isUserLoggedIn, 'col-lg-8 col-md-8 col-sm-6':!isUserLoggedIn}" *ngIf="output.showHeaderOnCatPage && output.HeaderTitle !== null && output.HeaderTitle !== ''">

                            <div style="height:auto;">
                                <span class="bold survey-header" [ngStyle]="{'color': output.colorCode ? output.colorCode : '#4b77be'}">{{output.headerTitle}}</span>
                                <div class="margin-top-30" [ngStyle]="{'color': output.colorCode ? output.colorCode : '#4b77be'}">
                                    <h1 class="hidden-sm">{{output.headerPreamble}}</h1>
                                    <h3 class="hidden-lg hidden-md">{{output.headerPreamble}}</h3>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'row hidden-md hidden-sm hidden-xs':isUserLoggedIn && ((i + 1) % 3 === 0)}"></div>
                    </div>
                </div>
            </div>
            <div style="margin-top:15%;margin-left:35%;" *ngIf="!active">
                <i class="fa fa-spinner font-white fa-spin" style="font-size:94px"></i>
            </div>
        </div>
        <div class="well shadow page-message font-white font-media-h3" *ngIf="showOnlyUnanswered && allAnswered">
            <i class="fa fa-thumbs-o-up margin-right-10 c-font-purple"></i>{{l("YouHaveAnsweredAllPollsOnPage")}}<i class="fa fa-smile-o margin-left-10 c-font-purple"></i>
        </div>
    </main>

</div>

