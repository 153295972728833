import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { VotechaVoteServiceProxy, PollServiceProxy,  FactorOutput, FactorInput, FactorRatingInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'political-posisions',
    templateUrl: './political-posisions.component.html',
  animations: [appModuleAnimation()]
})
export class PoliticalPosisionsComponent extends AppComponentBase implements OnInit {
    @Input() survey: PollListDto;    
    @Input() screenWidth: number = 0;

    public active: boolean = false;


    constructor(
        injector: Injector,

    ) {
        super(injector);
    }

    ngOnInit() {                   
        this.active = true;
    }

  }
