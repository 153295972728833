import { Injectable } from '@angular/core';
import { PermissionCheckerService, RefreshTokenService  } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild
} from '@angular/router';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._sessionService.user && state.url.includes("surveyboard")) {
            var newUrl = state.url.replace("app/main", "public");
            this._router.navigate([newUrl]);
            return false;
        }
        if (!this._sessionService.user) {
            this._router.navigate(['/public/site']);
            return false;
        }
        if (!route.data || !route.data["permission"]) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data["permission"])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/public/site';
        }
       
        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        if (this._permissionChecker.isGranted('Pages.User.MyOpinions')) {
            return '/app/main/mysite';
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }
      
        return '/app/main/mysite';
    }
}
