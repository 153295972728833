<footer class="c-layout-footer c-layout-footer-3 votecha-bg-black">
    <div class="c-prefooter">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12" *ngIf="screenWidth < 768 && isVotechaNorway">
                    <div class="font-white font-media-h3">
                        {{l("FollowUs")}}
                    </div>
                    <div class="c-container">
                        <ul class="c-links votechaFooterLinks" [ngClass]="{'margin-left-10':screenWidth < 768}">
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a *ngIf="isVotechaNorway" href="https://www.facebook.com/Meninger24" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-facebook votecha-blue"></i>
                                    <span class="font-green margin-left-5">Facebook</span>
                                </a>
                                <a *ngIf="isVotechaCom" href="https://www.facebook.com/Votecha" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-facebook votecha-blue"></i>
                                    <span class="font-green margin-left-5">Facebook</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a *ngIf="isVotechaNorway" href="https://www.instagram.com/meninger24/" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-instagram votecha-blue"></i>
                                    <span class="font-green margin-left-5">Instagram</span>
                                </a>
                                <a *ngIf="isVotechaCom" href="https://www.instagram.com/votecha/" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-instagram votecha-blue"></i>
                                    <span class="font-green margin-left-5">Instagram</span>
                                </a>
                            </li>
                            <!--<li class="dropdown dropdown-user dropdown-dark" *ngIf="isVotechaNorway">
                                <a href="https://twitter.com/Meninger24" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-twitter votecha-blue"></i>
                                    <span class="font-green margin-left-5">Twitter</span>
                                </a>
                            </li>-->
                        </ul>
                    </div>
                    <div class="font-white font-media-h3 margin-top-20">
                        {{l("Possibilities")}}
                    </div>
                    <div class="c-container">
                        <ul class="c-links votechaFooterLinks" [ngClass]="{'margin-left-10':screenWidth < 768}">
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToPossibilities()" class="dropdown-toggle">
                                    <i class="icon-user votecha-blue"></i>
                                    <span class="font-white margin-left-5">{{l("Recruitment")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToPossibilities()" class="dropdown-toggle">
                                    <i class="icon-briefcase votecha-blue"></i>
                                    <span class="font-white margin-left-5">{{l("Investors")}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-offset-1 col-lg-5 col-md-offset-1 col-md-5 col-sm-offset-1 col-sm-5 col-xs-12">
                    <div class="c-container c-first">
                        <ul class="c-links votechaFooterLinks" [ngClass]="{'margin-left-10':screenWidth < 768}">
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToAbout()" class="dropdown-toggle">
                                    <i class="fa fa-info-circle votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("AboutUs")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToContact()" class="dropdown-toggle">
                                    <i class="fa fa-phone votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("ContactUs")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark margin-bottom-20">
                                <a (click)="GoToHelp()" class="dropdown-toggle">
                                    <i class="fa fa-question-circle votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("Help")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToTerms()" class="dropdown-toggle">
                                    <i class="fa fa-file-text-o votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("UserAgreement")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToPrivacy()" class="dropdown-toggle">
                                    <i class="fa fa-lock votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("Privacy")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToCookies()" class="dropdown-toggle">
                                    <i class="fa fa-qrcode votecha-blue"></i>
                                    <span class="font-grey-salt margin-left-5">{{l("Cookies")}}</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="col-lg-offset-1 col-lg-5 col-md-offset-1 col-md-5 col-sm-offset-1 col-sm-5 col-xs-12" *ngIf="screenWidth > 767 && isVotechaNorway">
                    <div class="font-white font-media-h3">
                        {{l("FollowUs")}}
                    </div>
                    <div class="c-container">
                        <ul class="c-links votechaFooterLinks" [ngClass]="{'margin-left-10':screenWidth < 768}">
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a *ngIf="isVotechaNorway" href="https://www.facebook.com/Meninger24" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-facebook votecha-blue"></i>
                                    <span class="font-green margin-left-5">Facebook</span>
                                </a>
                                <a *ngIf="isVotechaCom" href="https://www.facebook.com/Votecha" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-facebook votecha-blue"></i>
                                    <span class="font-green margin-left-5">Facebook</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a *ngIf="isVotechaNorway" href="https://www.instagram.com/meninger24/" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-instagram votecha-blue"></i>
                                    <span class="font-green margin-left-5">Instagram</span>
                                </a>
                                <a *ngIf="isVotechaCom" href="https://www.instagram.com/votecha/" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-instagram votecha-blue"></i>
                                    <span class="font-green margin-left-5">Instagram</span>
                                </a>
                            </li>
                            <!--<li class="dropdown dropdown-user dropdown-dark" *ngIf="isVotechaNorway">
                                <a href="https://twitter.com/Meninger24" target="_blank" class="dropdown-toggle">
                                    <i class="fa fa-twitter votecha-blue"></i>
                                    <span class="font-green margin-left-5">Twitter</span>
                                </a>
                            </li>-->
                        </ul>
                    </div>
                    <div class="font-white font-media-h3 margin-top-20">
                        {{l("Possibilities")}}
                    </div>
                    <div class="c-container">
                        <ul class="c-links votechaFooterLinks" [ngClass]="{'margin-left-10':screenWidth < 768}">
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToPossibilities()"  class="dropdown-toggle">
                                    <i class="icon-user votecha-blue"></i>
                                    <span class="font-white margin-left-5">{{l("Recruitment")}}</span>
                                </a>
                            </li>
                            <li class="dropdown dropdown-user dropdown-dark">
                                <a (click)="GoToPossibilities()" class="dropdown-toggle">
                                    <i class="icon-briefcase votecha-blue"></i>
                                    <span class="font-white margin-left-5">{{l("Investors")}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="c-postfooter">
        <div class="container">
            <div class="row">
                <div class="col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-9 col-xs-offset-1 col-xs-10">
                    <p class="c-copyright c-font-grey">
                        {{currentYear}} © {{siteName}}
                    </p>
                </div>
            </div>
        </div>
    </div>

</footer>
