import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto, VotechaChartDto, ChartItemDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'political-party-chart',
    templateUrl: './political-party-chart.component.html',
    styleUrls: ['./political-party-chart.component.css'],
    animations: [appModuleAnimation()]
})
export class PoliticalPartyChartComponent extends AppComponentBase implements OnInit {

    @Input() politicalPartiesPoll: PollListDto; 
    @Input() inputIsUserLoggedIn: boolean = false;
    @Input() screenWidth: number = 0;

    public chartActive: boolean = false;
    public saving: boolean = false;


    chartData: VotechaChartDto;
    politicalItems: ChartItemDto[] = [];
    answerId: number;
    activeRatingTabIndex: number = 0;
    public thisYear: number = 0;

    constructor(
        injector: Injector,
        private _router: Router,

    ) {
        super(injector);
    }

    ngOnInit() {
        this.chartActive = false;
        this.politicalItems = [];
        this.activeRatingTabIndex = 0;
        this.politicalItems = this.politicalPartiesPoll.chartData.chartDataList;
        if (this.politicalItems !== null && this.politicalItems.length > 0) {
            this.chartActive = true;
        }        
    }

    goToVoteForm(id: string): void {        
        var country = "norway";
        if (this.inputIsUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country, id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }
}
