<div bsModal #mySettingsModal="bs-modal"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
	<div class="modal-dialog">
		<div class="modal-content">
			<form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
				<div class="modal-header">
					<button type="button" class="close" aria-label="Close" (click)="close()">
          				<span aria-hidden="true">&times;</span>
        			</button>
					<h4 class="modal-title">
						<span>{{l("MySettings")}}</span>
					</h4>
				</div>

                <div class="modal-body" *ngIf="user">
                    <tabset class="tab-container tabbable-line" id="tabPage">
                        <tab heading="{{l('Personal')}}" tabindex=0 (select)="setActiveTab(0)" [active]="activeTabIndex === 0">
                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <input #nameInput class="form-control edited" type="text" name="FirstName" [(ngModel)]="user.name" required maxlength="32">
                                <label>{{l("FirstName")}}</label>
                            </div>

                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <input type="text" name="Surname" class="form-control edited" [(ngModel)]="user.surname" required maxlength="32">
                                <label>{{l("Surname")}}</label>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <input class="form-control placeholder-no-fix" type="number" [(ngModel)]="user.birthYear" name="Birthyear" disabled [ngClass]="{'edited':user.birthYear}" />
                                <label>{{l("BirthYear")}}</label>
                            </div>
                            <div class="form-group">
                                <label class="no-hint font-md font-grey-silver">{{l("Gender")}}</label>
                                <div class="md-radio-inline">
                                    <div class="md-radio" *ngIf="userGender==1">
                                        <input id="Gender_Man" name="Gender" class="md-radiobtn" [(ngModel)]="user.gender" [value]="1" type="radio" disabled>
                                        <label for="Gender_Man">
                                            <span class="inc"></span>
                                            <span class="check"></span>
                                            <span class="box"></span>
                                            {{l("Man")}}
                                        </label>
                                    </div>
                                    <div class="md-radio" *ngIf="userGender==2">
                                        <input id="Gender_Woman" name="Gender" class="md-radiobtn" [(ngModel)]="user.gender" [value]="2" type="radio" disabled>
                                        <label for="Gender_Woman">
                                            <span class="inc"></span>
                                            <span class="check"></span>
                                            <span class="box"></span>
                                            {{l("Woman")}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <input type="email" name="EmailAddress" class="form-control edited" [(ngModel)]="user.emailAddress" required maxlength="100">
                                <label>{{l("EmailAddress")}} ({{l("UserName")}})</label>
                                <span class="help-block" *ngIf="!canChangeUserName">{{l("CanNotChangeAdminUserName")}}</span>
                            </div>

                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <input type="text" name="PhoneNumber" class="form-control edited" [(ngModel)]="user.phoneNumber" maxlength="24">
                                <label>{{l("PhoneNumber")}}</label>
                            </div>


                            <div class="form-group form-md-line-input form-md-floating-label no-hint">
                                <select class="form-control" [(ngModel)]="user.countryId" name="country" (change)="onCountryDDLChange()" required [ngClass]="{'edited':user.countryId}">
                                    <option value="0" disabled selected>{{l("Country")}}</option>
                                    <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label>{{l("Country")}}</label>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="(user.countryId > 0 && areas.length > 0)" (change)="onAreaDDLChange()">
                                <select class="form-control" [(ngModel)]="user.areaId" name="area" [ngClass]="{'edited':user.areaId}">
                                    <option value="0" disabled selected>{{l("Area")}}</option>
                                    <option *ngFor="let a of areas" [ngValue]="a.id">{{a.name}}</option>
                                </select>
                                <label>{{l("Area")}}</label>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="(user.countryId > 0 && cities.length > 0)">
                                <select class="form-control" [(ngModel)]="user.cityId" name="city" [ngClass]="{'edited':user.cityId}">
                                    <option value="0" selected>{{l("DontLiveInCity")}}</option>
                                    <option *ngFor="let ci of cities" [ngValue]="ci.id">{{ci.name}}</option>
                                </select>
                                <label>{{l("City")}}</label>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="(user.areaId > 0 && subAreas.length > 0)">
                                <select class="form-control" [(ngModel)]="user.subAreaId" name="subArea" [ngClass]="{'edited':user.subAreaId}">
                                    <option value="0" disabled selected>{{l("SubArea")}}</option>
                                    <option *ngFor="let sa of subAreas" [ngValue]="sa.id">{{sa.name}}</option>
                                </select>
                                <label>{{l("SubArea")}}</label>
                            </div>
                            <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="showTimezoneSelection">
                                <label>{{l("Timezone")}}</label>
                                <timezone-combo [(selectedTimeZone)]="user.timezone" defaultTimezoneScope="{{defaultTimezoneScope}}"></timezone-combo>
                            </div>
                        </tab>
                        <tab heading="{{l('Participation')}}" tabindex=1 (select)="setActiveTab(1)" [active]="activeTabIndex === 1">
                            <div class="md-checkbox-list">                                
                                <div class="md-checkbox">
                                    <input id="VotechaGame_IsEnabled" class="md-check" type="checkbox" name="VotechaGameIsEnabled"
                                           [(ngModel)]="user.isParticipatingVotechaGame">
                                    <label for="VotechaGame_IsEnabled">
                                        <span class="inc"></span>
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        {{l("ParticipatingVotechaGame")}}
                                    </label>
                                </div>
                            </div>                            
                        </tab>
                        <tab heading="{{l('DeleteProfile')}}" tabindex=2 (select)="setActiveTab(1)" [active]="activeTabIndex === 1">
                            <div class="alert alert-danger font-dark" style="max-width:700px;">
                                <p class="margin-bottom-10">{{l('DeleteProfileInfo1')}}</p>
                                <p class="bold">{{l('DeleteProfileInfo2')}}</p>
                                <p class="bold">{{l('DeleteProfileInfo3')}}</p>
                                <p class="margin-top-10">{{l('DeleteProfileInfo4')}}</p>
                                <br />
                                <div class="form-group form-md-line-input">
                                    <div class="btn btn-circle" [ngClass]="{'btn-danger':forDeleting, 'btn-default':!forDeleting}">
                                        <input id="forDeleting" class="md-check" type="checkbox" name="forDeleting" [ngClass]="{'edited':forDeleting}"
                                               [(ngModel)]="forDeleting">
                                        <label for="forDeleting">
                                            <span class="inc"></span>
                                            <span class="check"></span>
                                            <span class="box"></span>
                                            <span style="margin-left:10px">{{l("DeleteMe")}}</span>

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </tab>

                    </tabset>
                    

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn default close-button" (click)="close()" [disabled]="saving"><i class="fa fa-close"></i> {{l("Cancel")}}</button>
                    <button type="submit" class="btn blue save-button" *ngIf="activeTabIndex !== 2 && !forDeleting" [disabled]="!mySettingsModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                    <button type="button" *ngIf="forDeleting" class="btn btn-danger" (click)="deleteProfile()"><i class="fa fa-trash"></i> {{l("DeleteMe")}} </button>
                </div>
			</form>
		</div>
	</div>
</div>
