import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VotechaChartDto, ChartItemDto, EntityItemDto, PetitionSigningOutput } from '@shared/service-proxies/service-proxies';


import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'dashboard-rating-bars',
    templateUrl: './dashboard-rating-bars.component.html',
    styleUrls: ['./dashboard-rating-bars.component.css'],
  animations: [appModuleAnimation()]
})
export class DashboardRatingBarsComponent extends AppComponentBase implements OnInit,OnChanges {

    @Input() chartDataFromParent: VotechaChartDto;
    @Input() surveyTypeId: number;
    

    public chartActive: boolean = false;    
    public saving: boolean = false;
    public signings: PetitionSigningOutput[] = [];
 
    chartData: VotechaChartDto;
    totalItems: ChartItemDto[] = [];
    womenItems: ChartItemDto[] = [];
    menItems: ChartItemDto[] = [];
    youthItems: ChartItemDto[] = [];
    youngAdultItems: ChartItemDto[] = [];
    middleAgedItems: ChartItemDto[] = [];
    elderlyItems: ChartItemDto[] = [];

    public bottomTotalLabel: string = '0';
    public bottomWomenLabel: string = '0';
    public bottomMenLabel: string = '0';
    public canvasWidth = 300;
    public totalNeedleValue = 50;
    public totalScore: number = 0;
    public menNeedleValue = 50;
    public menScore: number = 0;
    public womenNeedleValue = 50;
    public womenScore: number = 0;
    public centralLabel = '';
    public name = '';
  
    public options = {
        hasNeedle: true,
        needleColor: 'gray',
        needleStartValue: 0,
        arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)"],
        arcDelimiters: [30, 70],
    };

    answerId: number;
    activeRatingTabIndex: number = 0;
    totalRatingScore: number = 0;
    public thisYear: number = 0;

    constructor(
        injector: Injector       
       
    ) {
        super(injector);
    }

    ngOnInit() {
        this.chartActive = false;
        this.totalItems = [];
        this.womenItems = [];
        this.menItems = [];
        this.youthItems = [];
        this.youngAdultItems = [];
        this.middleAgedItems = [];
        this.elderlyItems = [];
        this.activeRatingTabIndex = 0;

        if (this.surveyTypeId > 0 && this.chartDataFromParent !== null && this.chartDataFromParent !== undefined) {           
           
            this.chartData = this.chartDataFromParent;                         
            if (this.surveyTypeId === 4 && this.chartDataFromParent.totalAverage > 0) {
                this.totalScore = (this.chartDataFromParent.totalAverage * 10);
                this.totalNeedleValue = this.totalScore;
                this.bottomTotalLabel = (Math.round(this.totalScore) / 10).toString();

                this.womenScore = (this.chartDataFromParent.womenAverage * 10);
                this.womenNeedleValue = this.womenScore;
                this.bottomWomenLabel = (Math.round(this.womenScore) / 10).toString();

                this.menScore = (this.chartDataFromParent.menAverage * 10);
                this.menNeedleValue = this.menScore;
                this.bottomMenLabel = (Math.round(this.menScore) / 10).toString();
            }
            else if (this.chartDataFromParent.chartDataList !== undefined && this.chartData.chartDataList.length > 0) {
                this.totalItems = this.fillItemArrays('total');
                this.womenItems = this.fillItemArrays('women');
                this.menItems = this.fillItemArrays('men');
                this.youthItems = this.fillItemArrays('youth');
                this.youngAdultItems = this.fillItemArrays('youngAdult');
                this.middleAgedItems = this.fillItemArrays('middleAged');
                this.elderlyItems = this.fillItemArrays('elderly');                
            }

            this.chartActive = true;
        }
        else if (this.surveyTypeId == 0 && this.chartDataFromParent !== null && this.chartDataFromParent !== undefined && this.chartDataFromParent.petitionSigningList !== undefined) {
            var d = new Date();
            this.thisYear = d.getFullYear();
            this.chartData = this.chartDataFromParent;
            this.signings = this.chartDataFromParent.petitionSigningList.items;
            this.chartActive = true;
        }
    }
    
    ngOnChanges() {
        this.ngOnInit();
    }

    fillItemArrays(arrayType: string): ChartItemDto[] {
        var items: ChartItemDto[] = [];
        for (var i = 0; i < this.chartData.chartDataList.length; i++) {
            if (arrayType === 'total' && this.chartData.chartDataList[i].totalVotes > 0) {
                var item = this.chartData.chartDataList[i];                
                items.push(item);
            }
            else if (arrayType === 'women' && this.chartData.chartDataList[i].womenVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'men' && this.chartData.chartDataList[i].menVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'youth' && this.chartData.chartDataList[i].youthVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'youngAdult' && this.chartData.chartDataList[i].youngAdultVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'middleAged' && this.chartData.chartDataList[i].middleAgedVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
            else if (arrayType === 'elderly' && this.chartData.chartDataList[i].elderlyVotes > 0) {
                var item = this.chartData.chartDataList[i];
                items.push(item);
            }
        }

        return items;
    }
  }
