<div bsModal #modal="bs-modal" class="modal fade bs-modal-lg" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #previewForm="ngForm">
                <div class="modal-header c-bg-blue-2">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <div class="caption">
                            <h4 class="modal-title">
                                <i class="icon-bar-chart font-white"></i>
                                <span class="caption-subject bold uppercase font-white"><span *ngIf="survey.surveyType > 0">{{survey.name}}</span><span *ngIf="survey.surveyType === 0">{{l('Petition')}}</span></span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="caption" >
                        <img id="surveyImage" alt="" class="img-responsive"  [src]="survey.imgUrl" />
                    </div>
                    <div class="caption">

                        <span class="caption-subject bold uppercase font-blue">{{survey.question}}</span>
                    </div>

                    <div style="margin-top:5%;margin-bottom:5%">
                        <div class="c-about" *ngIf="survey.surveyType.toString() === 'Petition'">
                            <div class="margin-bottom-10">
                                {{survey.description}}
                            </div>
                        </div>
                        <div class="container" *ngIf="survey.surveyType.toString() === 'Score_1_to_10'">
                            <div class="uppercase font-blue margin-bottom-10">
                                {{l('Score_1_to_10_Descr')}}
                            </div>
                        </div>
                        <div *ngIf="survey.surveyType < 31">
                            <div class="actions" *ngFor="let optionItem of survey.optionItems; let i=index">
                                <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%">

                                    <label class="btn blue btn-outline btn-circle btn-sm">
                                        <input type="radio"
                                               name="answer"                                           
                                               [value]="optionItem.itemValue">
                                        {{optionItem.displayText}}
                                    </label>

                                </div>
                                <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() === 'ImageItemList'">

                                    <label class="btn blue-steel btn-outline btn-circle btn-sm">
                                        <input type="radio"
                                               name="answer"                                        
                                               [value]="optionItem.itemValue">
                                        {{optionItem.displayText}}
                                        <img id="imgitem" alt="" class="img-responsive" style="max-width:100px;max-height:100px" [src]="optionItem.azureUri" />
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="survey.surveyType.toString() === 'EntityItemList'">
                            <template-entity-selection [surveyInput]='survey'></template-entity-selection>
                        </div>
                    </div>
                    <div class="btn-group" *ngIf="survey.surveyType.toString() === 'Petition'">
                        <button [disabled]="true" class="btn btn-success">{{l("SignPetition")}}</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button  type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
