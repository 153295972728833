<section>
    <div class="margin-top6pr margin-bottom6pr" [ngClass]="{'margin-top6pr col-lg-12 col-md-12 col-sm-12 col-xs-12': screenWidth > 450, 'margin-top4pr': screenWidth < 451}">
        <div class="flex-container">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <div class="text-center"><i class="glyphicon glyphicon-ok font-green" *ngIf="screenWidth < 586"></i></div>
                <div class="font-white padding-bottom-10" [ngClass]="{'font-media-h2 bold':screenWidth > 900,'font-media-h3':screenWidth > 630 && screenWidth < 901, 'font-media-h4 bold':screenWidth > 370 && screenWidth < 631,'font-media-h5': screenWidth < 371  }">
                    <i class="glyphicon glyphicon-ok font-green" *ngIf="screenWidth > 585"></i> {{l("OpinionPolls")}}
                </div>
            </div>
            <div class="col-lg-4 col-md-5 col-sm-5 col-xs-5">
                <div class="text-center"><i class="glyphicon glyphicon-heart font-green" *ngIf="screenWidth < 586"></i></div>
                <div class="text-center font-white padding-bottom-10" [ngClass]="{'font-media-h2 bold':screenWidth > 900,'font-media-h3':screenWidth > 630 && screenWidth < 901, 'font-media-h4 bold':screenWidth > 370 && screenWidth < 631,'font-media-h5': screenWidth < 371  }">
                    <i class="glyphicon glyphicon-heart font-green" *ngIf="screenWidth > 585"></i> {{l("SignatureCampaigns")}}
                </div>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-3 col-xs-3" *ngIf="screenWidth > 320">
                <div class="text-center"><i class="glyphicon glyphicon-stats font-green" *ngIf="screenWidth < 586"></i></div>
                <div class="pull-right font-white padding-bottom-10" [ngClass]="{'font-media-h2 bold':screenWidth > 900,'font-media-h3':screenWidth > 630 && screenWidth < 901, 'font-media-h4 bold':screenWidth > 370 && screenWidth < 631,'font-media-h5': screenWidth < 371  }">
                    <i class="glyphicon glyphicon-stats font-green" *ngIf="screenWidth > 585"></i> {{l("Statistics")}}
                </div>
            </div>
        </div>
        <div class="margin-top2pr margin-bottom2pr">
            <div *ngIf="screenWidth < 768">
                <img style="width: 100%" class="rounded-corners shadow border-white" alt="" src="https://votechacdn.blob.core.windows.net/votechacdnimages/tablet_1065196496.webp">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5" *ngIf="screenWidth > 767">
                <img class="img-responsive rounded-corners shadow border-white margin-top-20 margin-left-20" alt="" src="https://votechacdn.blob.core.windows.net/votechacdnimages/tablet_1065196496.webp">
            </div>
            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 font-white" [ngClass]="{'col-xs-7': screenWidth > 567, 'col-xs-12': screenWidth < 568}">
                <h1 class="margin-top-20 margin-bottom2pr bold font-white uppercase font-media-h1"> {{l("ADemocraticTool")}}</h1>
                <div class="content-slider font-media-h2">
                    <div class="slider">
                        <div class="mask">
                            <ul class="bold">
                                <li class="anim1 margin-bottom2pr"> {{l("ASolutionToPromoteCommunityInvolvementAndDemocracy")}}</li>
                                <li class="anim2 margin-bottom2pr"> {{l("BasedOnPetitionPolls")}}</li>
                                <li class="anim3 margin-bottom2pr"> {{l("UserContent")}}</li>
                                <li class="anim4 margin-bottom2pr">{{l("WorldOfOpinionsContent4")}} </li>
                                <li class="anim5 margin-bottom2pr">{{l("VotechaWelcomeInfo")}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="margin-bottom-5">
                    <button type="button" (click)="goToAboutPage()" [ngClass]="{'btn-lg':screenWidth > 600, 'btn-md':screenWidth > 350 && screenWidth < 601,'btn-xs': screenWidth < 351 }" class="btn uppercase btn-circle default margin-right-10">{{l("ReadMore")}}</button>
                    <button type="button" (click)="goToTourPage()" [ngClass]="{'btn-lg':screenWidth > 600, 'btn-md':screenWidth > 350 && screenWidth < 601,'btn-xs': screenWidth < 351 }" class="btn uppercase btn-circle green margin-right-10">{{l("Tour")}}</button>
                </div>
            </div>
        </div>
    </div>
</section>

