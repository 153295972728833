import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';

@Component({
  selector: 'petition-template',
    templateUrl: './petition-template.component.html',
  animations: [appModuleAnimation()]
})
export class PetitionTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() inputSurvey: PollListDto;
    @Input() inputCountry: string;
    @Input() inputIsUserLoggedIn: boolean = false;
    @Input() screenWidth: number;
    public active: boolean = false;
    public saving: boolean = false;
    public goToDashboard: string = "";
    isUserLoggedIn: boolean = false;
    activeTabIndex: number = 0;
    tabIndex: number = 0;

    public categoryColor: string = "#4b77be";
    public categoryColorName: string = "blue";
    public tagColor: string = "#4b77be";   
    
    public surveyContainerHeight: string;
    public lightCategoryColor: string;
    public gradientExpr: string = "";

    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.surveyContainerHeight = this.getContainerHeight();
        this.categoryColor = this.inputSurvey.colorCode;    
        if (this.categoryColor !== null) {
            this.tagColor = VotechaHelper.shadeColor(this.categoryColor, -40);
            this.lightCategoryColor = VotechaHelper.shadeColor(this.categoryColor, 30);
        }
        this.gradientExpr = "linear-gradient(" + this.lightCategoryColor + ", " + this.categoryColor + ")";
        this.activeTabIndex = 0;
        this.isUserLoggedIn = this.inputIsUserLoggedIn;
        this.goToDashboard = this.l('GoToDashboardSite');
    }

    ngOnChanges() {

        this.isUserLoggedIn = this.inputIsUserLoggedIn;

    }

    getContainerHeight(): string {
        var height: string = "350px";
        if (this.screenWidth < 1200) height = "280px";        
        if (this.screenWidth < 769) height = "250px";
        if (this.screenWidth < 682) height = "230px";
        if (this.screenWidth < 503) height = "450px";  
        return height;
    }

    goToVoteForm(id: string): void {
        var country = this.inputCountry
        if (country.toLowerCase() === 'norge') country = "norway";
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country , id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }

  }
