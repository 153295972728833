<div bsModal #myInterestsModal="bs-modal"  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
	<div class="modal-dialog">
		<div class="modal-content">
			<form *ngIf="active" #myInterestsModalForm="ngForm" (ngSubmit)="save()">
				<div class="modal-header">
					<button type="button" class="close" aria-label="Close" (click)="close()">
          				<span aria-hidden="true">&times;</span>
        			</button>
					<h1 class="modal-title">
                        <span>{{l("MyCategories")}}</span>
					</h1>
				</div>
                <div class="modal-body">
                    <div class="form-group">                                            
                        <div class="margin-top4pr">
                            <span class="font-italic">{{l("ExcludeCategoriesCheckInfo")}}</span>
                        </div>
                    </div>
                    <div class="col-lg-offset-2 margin-top4pr">
                        <div class="md-checkbox-list" *ngFor="let cat of interests; let i = index">
                            <div class="md-checkbox font-dark">
                                <input id="interestChb{{cat.categoryId}}" class="md-check" type="checkbox" name="selected{{cat.categoryId}}" [ngClass]="{'edited':cat.selected}"
                                       [(ngModel)]="cat.selected">
                                <label for="interestChb{{cat.categoryId}}">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span>
                                    {{ cat.categoryName }}
                                </label>
                            </div>
                            <div [ngClass]="{'row margin-top4pr':((i+1) % 4 === 0)}"></div>
                        </div>
                    </div>
                </div>
				<div class="modal-footer">
					<button type="button" class="btn default close-button" (click)="close()" [disabled]="saving">{{l("Cancel")}}</button>
					<button type="submit" class="btn blue save-button" [disabled]="!myInterestsModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
				</div>
			</form>
		</div>
	</div>
</div>
