import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollListDto} from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { data } from 'jquery';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { UrlService } from '@shared/url.service';
import { PollStatisticsServiceProxy, SiteDto, CurrentLocalPollDto } from '@shared/service-proxies/service-proxies';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';
import * as _ from 'lodash';
import { log } from 'console';

@Component({
    selector: 'area-teaser',
    templateUrl: './area-teaser.component.html',
  animations: [appModuleAnimation()]
})
export class AreaTeaserComponent extends AppComponentBase implements OnInit {
    @Input() inputSite: SiteDto;
    @Input() inputCountryName: string;
    @Input() inputIsUserLoggedIn: boolean = false;
    public active: boolean = false;
    localPolls$: Observable<CurrentLocalPollDto[]>;
    localPolls: CurrentLocalPollDto[] = [];
    subAreaPolls: SubAreaPoll[] = [];
    areaIdsFiltered: number[] = [];
    subAreaIdsFiltered: number[] = [];
    public goToDashboard: string = "";
    public strGoToAreaSite: string = "";
    public strGoToSubAreaSite: string = "";
    inputSiteId: number = 0;

    constructor(
        injector: Injector,
        private _router: Router,
        private urlService: UrlService,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private activatedRoute: ActivatedRoute

    ) {
        super(injector);
    }

    ngOnInit() {
        this.inputSiteId = this.inputSite.siteId;
        this.goToDashboard = this.l('GoToDashboardSite');
        this.strGoToAreaSite = this.l('GoToArealSite');
        this.strGoToSubAreaSite = this.l('GoToSubAreaSite');
        this.getLocalPolls();
    }

    ngOnChanges() {
        this.getLocalPolls();
    }

   async getLocalPolls(): Promise<void> {
        this.localPolls$ = this._pollStatisticsService.getCurrentAreaPolls(this.inputSiteId)
            .pipe(
                tap(data => this.localPolls = data),
                shareReplay(1)
        );

       this.localPolls = await VotechaHelper.lastValueFrom(this.localPolls$);
       if (this.localPolls) {
           this.subAreaIdsFiltered = _.uniq(this.localPolls.map(poll => poll.subAreaId));
           this.mapAreaPolls();
       }
       
    }

    mapAreaPolls(): void {
        for (var i = 0; i < this.subAreaIdsFiltered.length; i++) {
            for (var k = 0; k < this.localPolls.length; k++) {
                if (this.subAreaIdsFiltered[i] === this.localPolls[k].subAreaId) {
                    let subAreaPoll = new SubAreaPoll();
                    subAreaPoll.areaType = this.localPolls[k].subAreaId > 0 ? 7 : 5;
                    subAreaPoll.areaName = this.localPolls[k].areaName;
                    subAreaPoll.areaId = this.localPolls[k].areaId;
                    subAreaPoll.subAreaId = this.localPolls[k].subAreaId;
                    subAreaPoll.subAreaName = this.localPolls[k].subAreaId > 0 ? this.localPolls[k].subAreaName : this.localPolls[k].areaName;
                    subAreaPoll.localPolls = this.localPolls.filter(poll => poll.subAreaId === this.subAreaIdsFiltered[i]);
                    this.subAreaPolls.push(subAreaPoll);
                    break;
                }
            }

        }
    }

    goToSite(areaType: number, areaName: string, subAreaName: string) {
        if (areaType === 7 && subAreaName !== null && subAreaName !== '') {
            this.goToSubAreaSite(areaName, subAreaName);
        }
        else {
            this.goToAreaSite(areaName);
        }
    }

    goToAreaSite(areaName: string): void {
        if (this.inputIsUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'area', this.inputCountryName, areaName]);
        }
        else {
            this._router.navigate(['public/site/', 'area', this.inputCountryName, areaName]);
        }
    }

    goToSubAreaSite(areaName: string, subAreaName: string): void {
        if (this.inputIsUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'subarea', this.inputCountryName, areaName, subAreaName]);
        }
        else {
            this._router.navigate(['public/site/', 'subarea', this.inputCountryName, areaName, subAreaName]);
        }
    }

    goToVoteForm(id: string): void {      
        if (this.inputIsUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', this.inputCountryName, id]);
        }
        else {
            this._router.navigate(['public/surveyboard', this.inputCountryName, id]);
        }
    }
  }

export class SubAreaPoll {
    areaType: number;
    areaName: string;
    areaId: number;
    subAreaName: string;
    subAreaId: number;
    localPolls: CurrentLocalPollDto[];
}
