<div bsModal #modal="bs-modal" class="modal fade bs-modal-lg" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    <div class="caption">
                        <h4 class="modal-title">
                            <i class="icon-bar-chart"></i>
                            <span class="caption-subject bold uppercase font-green">{{l("ThanksForYourVote")}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p class="font-italic">{{l("AnonymousVoteInfo1")}}</p>
                    <p class="font-italic">{{l("AnonymousVoteInfo2")}}</p>
                    <p class="bold">{{l("AnonymousVoteInfo3")}}</p>
                    <ol>
                        <li>{{l("AccountAdvantage1")}}</li>
                        <li>{{l("AccountAdvantage2")}}</li>
                        <li>{{l("AccountAdvantage3")}}</li>
                        <li>{{l("AccountAdvantage4")}}</li>
                        <li>
                            <p>{{l("AccountAdvantage5")}}</p>
                            <p>{{l("AccountAdvantage6")}}</p>
                        </li>
                        <li>{{l("AccountAdvantage7")}}</li>
                        <li>{{l("AccountAdvantage8")}}</li>
                        <li>{{l("AccountAdvantage9")}}</li>
                        <li>{{l("AccountAdvantage10")}}</li>
                        <li>
                            <p> {{l("AccountAdvantage11")}}</p>
                            <p> {{l("AccountAdvantage12")}}</p>
                            <p>{{l("AccountAdvantage13")}}</p>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
            </div>

        </div>
    </div>
</div>
