<div class="portlet light poll-box" [ngClass]="{'no-border-radius': screenWidth < 768 }" *ngIf="survey.status?.toString() === 'Online'" [ngStyle]="{'height': surveyContainerHeight, 'background-image': gradientExpr}">    
    <div *ngIf="screenWidth >= 768" style="padding:2%;">        
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="caption margin-top2pr margin-bottom4pr flex-container-content-center">
                    <h2 [ngClass]="{'font-media-h1-petition':!isUserLoggedIn && survey.surveyTypeId === 0, 'font-media-h1': isUserLoggedIn && survey.surveyTypeId === 0, 'font-media-h2': survey.surveyTypeId > 0}">
                        <span class="caption-subject bold uppercase font-white">{{survey.displayQuestion}}</span>
                    </h2>
                </div>
                <div class="caption margin-top2pr container" *ngIf="survey.surveyType.toString() === 'Score_1_to_10'">
                    <div class="uppercase margin-bottom-10 font-white">
                        {{l('Score_1_to_10_Descr')}}
                    </div>
                </div>
                <div class="margin-top-40 font-white font-media-h1 text-center" *ngIf="isUserLoggedIn && survey.surveyTypeId === 0 && hasVoted && survey.petitionSignings > 0">
                    {{survey.petitionSignings}} <i class="glyphicon glyphicon-heart-empty margin-left-5"></i>
                </div>
                <div class="caption margin-top2pr margin-bottom2pr flex-container-content-center">
                    <div *ngIf="survey.surveyTypeId > 0 && survey.surveyTypeId < 21">
                        <div class="actions" *ngFor="let optionItem of survey.surveyItems; let i=index">
                            <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() !== 'ImageItemList'">

                                <label [ngStyle]="{'color': optionItem.itemValue !== vote.answerId ? survey.colorCode : 'white', 'background-color': optionItem.itemValue === vote.answerId ? survey.colorCode : 'white', 'border-color': survey.colorCode }" class="btn btn-outline btn-circle btn-sm" [ngClass]="{'active active-bg': optionItem.itemValue === vote.answerId}">
                                    <input type="radio"
                                           name="answer"
                                           [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                           (change)="onVoteChange()"
                                           [(ngModel)]="vote.answerId"
                                           [value]="optionItem.itemValue">
                                    <span class="margin-left-5">{{optionItem.displayText}}</span>
                                </label>

                            </div>
                            <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() === 'ImageItemList'">

                                <label class="btn votecha-purple btn-outline btn-circle btn-sm" [ngClass]="{'active': optionItem.itemValue === vote.answerId}">
                                    <input type="radio"
                                           name="answer"
                                           [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                           (change)="onVoteChange()"
                                           [(ngModel)]="vote.answerId"
                                           [value]="optionItem.itemValue">
                                    <span class="margin-left-5">{{optionItem.displayText}}</span>
                                    <img id="imgitem" class="img-responsive" alt="{{optionItem.alternateText}}" style="max-width:100px;max-height:100px" [src]="optionItem.azureUri" />
                                </label>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="survey.surveyTypeId === 32">
                        <div class="actions" *ngFor="let optionItem of survey.surveyItems; let p=index">
                            <div class="flex-container-content-left margin-bottom-10" style="max-width:600px; margin-top:1%;margin-bottom:1%; background-color:white; padding:6px 20px; border-radius: 6px">
                                <div class="margin-right-20">
                                    <label class="btn blue btn-outline btn-circle btn-sm" [ngClass]="'active'">
                                        <input type="radio"
                                               name="answer"
                                               [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                               (change)="onVoteChange()"
                                               [(ngModel)]="vote.answerId"
                                               [value]="optionItem.itemValue">
                                    </label>
                                </div>
                                <div>
                                    <img id="imgitem" alt="" class="img-responsive" style="max-width:50px;max-height:50px" [src]="optionItem.imgUrl" />
                                </div>
                                <div class="font-dark font-media-h4 bold margin-left-10">
                                    {{ optionItem.itemText }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center margin-top-40" *ngIf="survey.surveyTypeId === 0 && isUserLoggedIn">
                        <button type="button" *ngIf="!hasVoted" (click)="signPetition()" class="btn btn-lg btn-circle" [ngClass]="{'btn-default':!isUserLoggedIn, 'btn-success margin-top-30':isUserLoggedIn}" [disabled]="!isUserLoggedIn">{{l("SignPetition")}}</button>
                        <div class="font-white  bold margin-bottom-20 font-media-h3" *ngIf="survey.surveyTypeId === 0 && hasVoted">
                            {{l("ThanksForYourSigning")}} <span class="font-red-pink"><i class="fa fa-heart"></i></span>
                            <div class="margin-top-30">
                                <button type="button" (click)="undoSigning()" class="btn btn-circle red btn-outline">{{l("UndoSigning")}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="margin-top-40 font-white font-media-h2 text-center nowrap" *ngIf="!isUserLoggedIn && survey.surveyTypeId === 0 && hasVoted && survey.petitionSignings > 0">
                    {{survey.petitionSignings}} <i class="glyphicon glyphicon-heart-empty margin-left-5"></i>
                </div>
            </div>     
    </div>
    <div *ngIf="screenWidth < 768" style="padding:2%">
        <div class="margin-left2pr">
            <div class="margin-top4pr">
                <h2 [ngClass]="{'h3 margin-top-30': survey.surveyTypeId === 0, 'font-media-h3': survey.surveyTypeId > 0}"><span class="caption-subject bold uppercase font-white">{{survey.displayQuestion}}</span></h2>
            </div>
            <div class="container margin-top2pr" *ngIf="survey.surveyType.toString() === 'Score_1_to_10'">
                <div class="uppercase margin-bottom-10 font-white">
                    {{l('Score_1_to_10_Descr')}}
                </div>
            </div>
            <div class="font-media-h3" style="margin-top:2%;margin-bottom:2%">
                <div *ngIf="survey.surveyTypeId > 0 && survey.surveyTypeId < 21">
                    <div class="actions" *ngFor="let optionItem of survey.surveyItems; let i=index">
                        <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%;" *ngIf="survey.surveyType.toString() !== 'ImageItemList'">

                            <label [ngStyle]="{'color': optionItem.itemValue !== vote.answerId ? survey.colorCode : 'white', 'background-color': optionItem.itemValue === vote.answerId ? survey.colorCode : 'white', 'border-color': survey.colorCode }" class="btn btn-outline btn-circle btn-sm" [ngClass]="{'active': optionItem.itemValue === vote.answerId}">
                                <input type="radio"
                                       name="answer"
                                       [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                       (change)="onVoteChange()"
                                       [(ngModel)]="vote.answerId"
                                       [value]="optionItem.itemValue">
                                <span class="margin-left-5" [ngClass]="{'font-6': useSmallFont}">{{optionItem.displayText}}</span>
                            </label>

                        </div>
                        <div class="btn-group btn-group-devided" style="margin-top:1%;margin-bottom:1%; margin-left:1%" *ngIf="survey.surveyType.toString() === 'ImageItemList'">

                            <label [ngStyle]="{'color': optionItem.itemValue !== vote.answerId ? survey.colorCode : 'white', 'background-color': optionItem.itemValue === vote.answerId ? survey.colorCode : 'white', 'border-color': survey.colorCode }" class="btn btn-outline btn-circle btn-sm" [ngClass]="{'active': optionItem.itemValue === vote.answerId}">
                                <input type="radio"
                                       name="answer"
                                       [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                       (change)="onVoteChange()"
                                       [(ngModel)]="vote.answerId"
                                       [value]="optionItem.itemValue">
                                <span class="margin-left-5" [ngClass]="{'font-6': survey.surveyTypeId === 20}">{{optionItem.displayText}}</span>
                                <img id="imgitem" class="img-responsive" alt="{{optionItem.alternateText}}" style="max-width:100px;max-height:100px" [src]="optionItem.azureUri" />
                            </label>

                        </div>
                    </div>
                </div>
                <div *ngIf="survey.surveyTypeId === 32">
                    <div class="actions" *ngFor="let optionItem of survey.surveyItems; let p=index">
                        <div class="flex-container-content-left margin-bottom-10" style="max-width:600px; margin-top:1%;margin-bottom:1%; background-color:white; padding:6px 20px; border-radius: 6px">
                            <div class="margin-right-20">
                                <label class="btn blue btn-outline btn-circle btn-sm" [ngClass]="'active'">
                                    <input type="radio"
                                           name="answer"
                                           [disabled]="(!isUserLoggedIn && (!survey.anonymousVotesEnabled || survey.unlocked)) || vote.isAnonymous"
                                           (change)="onVoteChange()"
                                           [(ngModel)]="vote.answerId"
                                           [value]="optionItem.itemValue">
                                </label>
                            </div>
                            <div>
                                <img id="imgitem" alt="" class="img-responsive" style="max-width:50px;max-height:50px" [src]="optionItem.imgUrl" />
                            </div>
                            <div class="font-dark font-media-h4 bold margin-left-10">
                                {{ optionItem.itemText }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="margin-top-20 font-white font-media-h1 text-center nowrap" *ngIf="isUserLoggedIn && survey.surveyTypeId === 0 && hasVoted && survey.petitionSignings > 0">
                    {{survey.petitionSignings}}<i class="glyphicon glyphicon-heart-empty margin-left-5"></i>
                </div>
                <div class="text-center margin-top4pr margin-bottom-40" *ngIf="survey.surveyTypeId === 0 && isUserLoggedIn && !hasVoted">
                    <button type="button" (click)="signPetition()" class="btn btn-lg btn-circle" [ngClass]="{'btn-default':!isUserLoggedIn, 'btn-success margin-top-30':isUserLoggedIn}" [disabled]="!isUserLoggedIn">{{l("SignPetition")}}</button>
                </div>
                <div class="margin-top-20 font-white  bold" *ngIf="survey.surveyTypeId === 0 && isUserLoggedIn && hasVoted">
                    {{l("ThanksForYourSigning")}}! <span class="font-red-pink"><i class="fa fa-heart"></i></span>
                    <div class="margin-top-30">
                        <button type="button" (click)="undoSigning()" class="btn btn-circle red btn-outline">{{l("UndoSigning")}}</button>
                    </div>
                </div>
            </div>
            <div class="margin-top-40 margin-bottom-20 font-white font-media-h1 text-center nowrap" *ngIf="!isUserLoggedIn && survey.surveyTypeId === 0 && hasVoted && survey.petitionSignings > 0">
                {{survey.petitionSignings}}<i class="glyphicon glyphicon-heart-empty margin-left-5"></i>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="survey.useRanking && vote.answerId > 0">
        <div class="text-left ">
            <em class="font-white font-media-h3">{{l("HowImportantIsThisTopicForYou")}}</em>
            <div class="container">
                <fieldset class="rating">
                    <input type="radio" id="star5" name="rating" [value]=5 (change)="onImportanceRankingChange()" [(ngModel)]="vote.importanceRanking" /><label class="full" for="star5" title="Excellent - 5 stars"></label>

                    <input type="radio" id="star4" name="rating" [value]=4 (change)="onImportanceRankingChange()" [(ngModel)]="vote.importanceRanking" /><label class="full" for="star4" title="Very good - 4 stars"></label>

                    <input type="radio" id="star3" name="rating" [value]=3 (change)="onImportanceRankingChange()" [(ngModel)]="vote.importanceRanking" /><label class="full" for="star3" title="Good - 3 stars"></label>

                    <input type="radio" id="star2" name="rating" [value]=2 (change)="onImportanceRankingChange()" [(ngModel)]="vote.importanceRanking" /><label class="full" for="star2" title="Fair - 2 stars"></label>

                    <input type="radio" id="star1" name="rating" [value]=1 (change)="onImportanceRankingChange()" [(ngModel)]="vote.importanceRanking" /><label class="full" for="star1" title="Poor - 1 star"></label>

                </fieldset>
            </div>
        </div>
    </div>
    <div class="margin-top-10 alert alert-info" *ngIf="vote.isAnonymous">
        <span class="bold font-media-h3">{{l("AnonymousAnswered")}}</span>
    </div>
    <div class="col-lg-12 col-sm-12 col-xs-12 margin-top2pr margin-bottom-20">
        <span *ngFor="let tag of survey.tags">
            <span class="font-white bold margin-right-10">
                {{tag}}
            </span>
        </span>
        <span class="fb-share-btn pull-right">
            <a class="btn btn-md btn-circle  btn-facebook" [attr.href]="fbUrl" target="_blank">
                <img class="icon-facebook" alt="facebook-logo" height="24" src="/assets/common/images/f_logo_RGB-Blue_58.png"> {{l('Share')}}
            </a>
        </span>
    </div>
</div>

<div class="portlet light" *ngIf="survey.status?.toString() === 'Offline'">
    <div class="alert alert-danger">
        <span *ngIf="survey.surveyTypeId > 0">{{l("ThisSurveyIsOffline")}}</span>
        <span *ngIf="survey.surveyTypeId === 0">{{l("ThisPetitionIsOffline")}}</span>
    </div>
</div>
