import { Component, OnChanges, Input, Output, EventEmitter, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { SourceInput, SourceOutput } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'sources',
    templateUrl: './sources.component.html',
    animations: [appModuleAnimation()]
})
export class SourcesComponent extends AppComponentBase implements OnInit, OnChanges{
    @Input() sourceList: SourceOutput[];
    @Output() sourceAdded: EventEmitter<SourceInput> = new EventEmitter<SourceInput>();
    @Output() sourceRemoved: EventEmitter<SourceOutput> = new EventEmitter<SourceOutput>();
    public newSource: SourceInput;

    constructor(
        injector: Injector

    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.newSource = new SourceInput();
        this.newSource.sourceName= "";
        this.newSource.sourceUrl = "";
    }

    ngOnChanges(): void {
     
    }

    addSource(): void {
        var newSourceOutput: SourceOutput = new SourceOutput();
        newSourceOutput.sourceName = this.newSource.sourceName;
        newSourceOutput.sourceUrl = this.newSource.sourceUrl;
        this.newSource.sourceName = this.newSource.sourceName;
        this.newSource.sourceUrl = this.newSource.sourceUrl;
        this.newSource.createArticle = this.newSource.createArticle;
        this.sourceAdded.emit(this.newSource);
        this.sourceList.push(newSourceOutput);
        this.newSource.sourceName = '';
        this.newSource.sourceUrl = '';
        this.newSource.createArticle = false;
    }

    deleteSource(itemDeleted: SourceOutput): void {
        var newSourceOutput: SourceOutput = new SourceOutput();
        newSourceOutput.sourceName = itemDeleted.sourceName;
        newSourceOutput.sourceUrl = itemDeleted.sourceUrl;

        this.sourceRemoved.emit(newSourceOutput);

        for (var i = 0; i < this.sourceList.length; i++) {
            if (this.sourceList[i].sourceName === itemDeleted.sourceName) {
                var delIndex = i;
                this.sourceList.splice(i, 1);
            }
        }
    }

    messageIndicator_click(selectedValue: number): void {
        this.newSource.messageIndicator = selectedValue;
    }
}
