import { Component, Injector, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.css'],
    animations: [appModuleAnimation()]
})
export class PrivacyComponent extends AppComponentBase implements OnInit {
    page: PageOutput;
    cookieLangValue: string;
    isUserLoggedIn: boolean = false;
    public screenWidth: number;

    constructor(
        injector: Injector,
        private _pageService: PageServiceProxy
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.page = new PageOutput();
        this.getContent();
    }

    getContent(): void {
        this._pageService.getPage("Privacy", this.cookieLangValue).subscribe((result) => {
            this.page.preamble = result.preamble;
            var content = result.content;
            this.page.content = content.replace('/h1/g', 'h1 class=\"font-media-h1\"');
            window.scrollTo(0, 0);
        });
    }
}
