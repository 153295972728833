<div bsModal #linkAccountModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">

        <div class="modal-content">

            <form *ngIf="active" #linkAccountForm="ngForm" novalidate (ngSubmit)="save()">

                <div class="modal-header">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">
                        <span>{{l("LinkNewAccount")}}</span>
                    </h4>
                </div>

                <div class="modal-body">
                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input #tenancyNameInput type="text" name="TenancyName" class="form-control" [ngClass]="{'edited': linkUser.tenancyName}" [(ngModel)]="linkUser.tenancyName" maxlength="64">
                        <label>{{l("TenancyName")}}</label>
                    </div>

                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input class="form-control" type="text" name="UserName" [ngClass]="{'edited': linkUser.usernameOrEmailAddress}" [(ngModel)]="linkUser.usernameOrEmailAddress" required maxlength="256">
                        <label>{{l("UserName")}}</label>
                    </div>

                    <div class="form-group form-md-line-input form-md-floating-label">
                        <input type="password" [ngClass]="{'edited': linkUser.password}" name="Password" class="form-control" [(ngModel)]="linkUser.password" required maxlength="128">
                        <label>{{l("Password")}}</label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="!linkAccountForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>

            </form>

        </div>
    </div>
</div>