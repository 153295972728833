<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">
                    {{options.title}}
                </h4>
            </div>

            <div class="modal-body">

                <form novalidate (ngSubmit)="refreshTable()">
                    <div *ngIf="options.isFilterEnabled" class="input-group margin-bottom-10">
                        <input autoFocus [(ngModel)]="filterText" name="filterText" class="form-control" placeholder="{{l('SearchWithThreeDot')}}" type="text">
                        <span class="input-group-btn">
                            <button class="btn default" type="submit"><i class="icon-magnifier"></i></button>
                        </span>
                    </div>
                    <div #table></div>
                </form>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
            </div>

        </div>
    </div>
</div>