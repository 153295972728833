
<div *ngIf="active">
    <div class="row">

        <div class="col-md-12 col-xs-12">
            <div class="image-container">
                <img id="articlePhoto{{article.id}}" alt="{{article.alternateText}}" class="img-responsive" [src]="article.imageUrl">
            </div>
            <div class="article-logo transboxArticle" *ngIf="article.mediaLogo !== null && article.mediaLogo !== ''">
                <img alt="logo" [src]="article.mediaLogo" class="responsive media-article-logo" style="max-height:50px">
            </div>

        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h2 class="font-media-h2 c-font-bold c-font-uppercase margin-top-10" [innerHTML]="article.title" [ngStyle]="{'margin-top': article.imageUrl === undefined || article.imageUrl === null || article.imageUrl === '' ? '100px' : '2px'}">
            </h2>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 font-media-h3">
            <p class="margin-top-20 font-dark" [innerHTML]="article.description"></p>
        </div>

    </div>
    <div class="row margin-top-20">
        <div class="col-md-6 col-sm-6 col-xs-6">
            <div class="c-about font-dark">
                {{ article.creationTime | date: 'dd/MM/yyyy'}}
            </div>
            <div class="list-datetime font-md font-italic">
                {{article.published?.format("DD.MM.YYYY")}}
            </div>
            <div class="c-about">
                <div class="font-md bold font-italic">
                    {{article.mediaUrl}}
                </div>
            </div>
            <div class="c-about" *ngIf="article.iso !== undefined && article.iso !== null && article.iso !==''">
                <i class="famfamfam-flags {{article.iso | lowercase}}"></i> <span class="margin-left-5" *ngIf="article.iso ==='US'"><i class="famfamfam-flags gb"></i></span>
            </div>
        </div>
        <div class="pull-right margin-right-20">
            <div class="label" [ngClass]="{'label-info': article.status.toString() ==='1','label-warning': article.status.toString() ==='2', 'label-success': article.status.toString() === '4', 'label-danger': article.status.toString() === '5' ||  article.status.toString() === '6'}">
                <span *ngIf="article.status.toString() === '1'">{{l("Draft")}}</span>
                <span *ngIf="article.status.toString() ==='2'"><i class="fa fa-gavel"></i> <span style="margin-left:4px">{{l("ForApproval")}}</span> </span><span *ngIf="article.status.toString() === '4'">{{l("Online")}}</span>
                <span *ngIf="article.status.toString() === '5'">{{l("Dismissed")}}</span><span *ngIf="article.status.toString() === '6'">{{l("Offline")}}</span>
            </div>
        </div>
    </div>
</div>


