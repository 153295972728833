
import { finalize } from 'rxjs/operators';
import { Component, ViewChild, Injector, Output, EventEmitter, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UserSettingsServiceProxy, CategorySettingsInput,CategorySettingsDto, ExcludedUserCategoryInput, ExcludedUserCategoryOutput } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'myInterestsModal',
    templateUrl: './my-interests-modal.component.html'
})
export class MyInterestsModalComponent extends AppComponentBase {

    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('myInterestsModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active: boolean = false;
    public saving: boolean = false;

    settings:CategorySettingsInput;
    interests: ExcludedUserCategoryInput[] = [];


    constructor(
        injector: Injector,
        private _userSettingsService: UserSettingsServiceProxy
    ) {
        super(injector);
    }

    show(): void {
        this.settings = new CategorySettingsInput();
        this.getInterests();

    }


    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.settings.haveExcludedCategories = this.setHaveExcludedCategories();
        this.settings.excludedCategories = [];

        if (this.settings.haveExcludedCategories) {            
            this.settings.excludedCategories = this.interests;
        }
        
        this._userSettingsService.saveCategorySettings(this.settings).pipe(
            finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

  

    getInterests(): void {
        this._userSettingsService.getCategorySettings().subscribe((result) => {
            this.settings.id = result.id;
            this.settings.haveExcludedCategories = result.haveExcludedCategories;
            this.interests = result.excludedCategories;
          
            this.active = true;
            this.modal.show();
        });
    }

    setHaveExcludedCategories(): boolean {
        var haveExcludedCategories = false;
        for (var i = 0; i < this.interests.length; i++) {
            if (this.interests[i].selected) {
                haveExcludedCategories = true;
                break;
            }
        }
        return haveExcludedCategories;
    }
}
