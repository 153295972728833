import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { PhotoServiceProxy, PhotoOutput, PhotoInput } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, Headers } from '@node_modules/ng2-file-upload';
import { IAjaxResponse } from 'abp-ng2-module';
import { TokenService } from 'abp-ng2-module';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  animations: [appModuleAnimation()]
})
export class FileUploadComponent extends AppComponentBase implements OnInit,OnChanges {
    @Input() enitityId: number;
    @Input() enitityTypeId: number;
    public active: boolean = false;
    public uploader: FileUploader;
    photo: PhotoOutput;
    photoInput: PhotoInput;

    public temporaryImageUrl: string;
    public saving: boolean = false;

    private temporaryImageFileName: string;
    private _uploaderOptions: FileUploaderOptions = {};
    private _$imageResize: JQuery;
    private _$jcropApi: any;


    constructor(
        injector: Injector,       
        private _tokenService: TokenService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.photoInput = new PhotoInput();
        this.active = true;
        this.temporaryImageUrl = '';
        this.temporaryImageFileName = '';
        this._$imageResize = null;
        this._$jcropApi = null;
        this.initFileUploader();
    }
    ngOnChanges() {
        
    }

   

    initFileUploader(): void {
        let self = this;
        self.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + "/Profile/UploadAzureImage" });
        self._uploaderOptions.autoUpload = true;
        self._uploaderOptions.authToken = 'Bearer ' + self._tokenService.getToken();
        self._uploaderOptions.removeAfterUpload = true;
        self.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        self.uploader.onSuccessItem = (item, response, status) => {
            let resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                self.temporaryImageFileName = resp.result.fileName;
                self.temporaryImageUrl = AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + resp.result.fileName + '?v=' + new Date().valueOf();

                var newCanvasHeight = resp.result.height * self._$imageResize.width() / resp.result.width;
                self._$imageResize.height(newCanvasHeight + 'px');

                if (self._$jcropApi) {
                    self._$jcropApi.destroy();
                }

                self._$imageResize.attr('src', self.temporaryImageUrl);
                self._$imageResize.attr('originalWidth', resp.result.width);
                self._$imageResize.attr('originalHeight', resp.result.height);

                self._$imageResize.Jcrop({
                    setSelect: [0, 0, 100, 100],
                    aspectRatio: 1,
                    boxWidth: 400,
                    boxHeight: 400
                }, function () {
                    self._$jcropApi = this;
                });

            } else {
                this.message.error(resp.error.message);
            }
        };

        self.uploader.setOptions(self._uploaderOptions);
    }

    onShown() {
        this._$imageResize = $("#ImageResize");
    }

   

    save(): void {
        let self = this;
        if (!self.temporaryImageFileName) {
            return;
        }

        let resizeParams = { x: 0, y: 0, w: 0, h: 0 };
        if (self._$jcropApi) {
            resizeParams = self._$jcropApi.getSelection();
        }

        let containerWidth = parseInt(self._$jcropApi.getContainerSize()[0]);
        let containerHeight = self._$jcropApi.getContainerSize()[1];

        let originalWidth = containerWidth;
        let originalHeight = containerHeight;

        if (self._$imageResize) {
            originalWidth = parseInt(self._$imageResize.attr("originalWidth"));
            originalHeight = parseInt(self._$imageResize.attr("originalHeight"));
        }

        let widthRatio = originalWidth / containerWidth;
        let heightRatio = originalHeight / containerHeight;

        let input = new PhotoInput();
        input.name = self.temporaryImageFileName;
        input.x = Math.floor(resizeParams.x * widthRatio);
        input.y = Math.floor(resizeParams.y * heightRatio);
        input.width = Math.floor(resizeParams.w * widthRatio);
        input.height = Math.floor(resizeParams.h * heightRatio);            
    }

    cleanUpAfterUpload(): void {
        let self = this;
        self._$jcropApi.destroy();
        self._$jcropApi = null; 
    }

}
