<div class="page-sidebar navbar-collapse collapse">
    <!-- DOC: Apply "page-sidebar-menu-light" class right after "page-sidebar-menu" to enable light sidebar menu style(without borders) -->
    <!-- DOC: Apply "page-sidebar-menu-hover-submenu" class right after "page-sidebar-menu" to enable hoverable(hover vs accordion) sub menu mode -->
    <!-- DOC: Apply "page-sidebar-menu-closed" class right after "page-sidebar-menu" to collapse("page-sidebar-closed" class must be applied to the body element) the sidebar sub menu mode -->
    <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
    <!-- DOC: Set data-keep-expand="true" to keep the submenues expanded -->
    <!-- DOC: Set data-auto-speed="200" to adjust the sub menu slide up/down speed -->
    <div class="menu-toggler sidebar-toggler" *ngIf="isUserLoggedIn">
        <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
    </div>
    <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
    </a>
    <ul class="page-sidebar-menu" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed="200">
        
        <ng-template ngFor let-menuItem [ngForOf]="menu.items" let-mainMenuItemIndex="index">
            <li *ngIf="showMenuItem(menuItem)" routerLinkActive="active" [ngClass]="{'start': mainMenuItemIndex == 0}">
                <a [routerLink]="[menuItem.route]" *ngIf="!menuItem.items.length">
                    <i class="{{menuItem.icon}}"></i>
                    <span class="title">{{l(menuItem.name)}}</span>
                </a>
                <a href="javascript:;" class="auto" *ngIf="menuItem.items.length">
                    <i class="{{menuItem.icon}}"></i>
                    <span class="title">{{l(menuItem.name)}}</span>
                    <span class="arrow "></span>
                </a>
                <ul class="sub-menu" *ngIf="menuItem.items.length">
                    <!-- Menu Level 2 -->
                    <ng-template ngFor let-childMenuItem [ngForOf]="menuItem.items" let-childMenuItemIndex="index">
                        <li *ngIf="showMenuItem(childMenuItem)" routerLinkActive="active" [ngClass]="{'start': childMenuItemIndex == 0}">
                            <a [routerLink]="[childMenuItem.route]" *ngIf="!childMenuItem.items.length">
                                <i class="{{childMenuItem.icon}}"></i>
                                <span class="title">{{l(childMenuItem.name)}}</span>
                            </a>
                            <a href="javascript:;" class="auto" *ngIf="childMenuItem.items.length">
                                <i class="{{childMenuItem.icon}}"></i>
                                <span class="title">{{l(childMenuItem.name)}}</span>
                                <span class="arrow "></span>
                            </a>
                            <ul class="sub-menu" *ngIf="childMenuItem.items.length">
                                <!-- Menu Level 3 -->
                                <ng-template ngFor let-childOfChildMenuItem [ngForOf]="childMenuItem.items" let-childOfChildMenuItemIndex="index">
                                    <li *ngIf="showMenuItem(childOfChildMenuItem)" routerLinkActive="active" [ngClass]="{'start': childOfChildMenuItemIndex == 0}">
                                        <a [routerLink]="[childOfChildMenuItem.route]" *ngIf="!childOfChildMenuItem.items.length">
                                            <i class="{{childOfChildMenuItem.icon}}"></i>
                                            <span class="title">{{l(childOfChildMenuItem.name)}}</span>
                                        </a>
                                        <a href="javascript:;" class="auto" *ngIf="childOfChildMenuItem.items.length">
                                            <i class="{{childOfChildMenuItem.icon}}"></i>
                                            <span class="title">{{l(childOfChildMenuItem.name)}}</span>
                                            <span class="arrow "></span>
                                        </a>
                                        <ul class="sub-menu" *ngIf="childOfChildMenuItem.items.length">
                                            <!-- Menu Level 4 -->
                                            <ng-template ngFor let-childOfChildOfChildMenuItem [ngForOf]="childOfChildMenuItem.items" let-childOfChildMenuItemIndex="index">
                                                <li *ngIf="showMenuItem(childOfChildOfChildMenuItem)" routerLinkActive="active" [ngClass]="{'start': childOfChildMenuItemIndex == 0}">
                                                    <a [routerLink]="[childOfChildOfChildMenuItem.route]">
                                                        <i class="{{childOfChildOfChildMenuItem.icon}}"></i>
                                                        <span class="title">{{l(childOfChildOfChildMenuItem.name)}}</span>
                                                    </a>
                                                </li>
                                            </ng-template>
                                        </ul>
                                    </li>
                                </ng-template>
                            </ul>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ng-template>
    </ul>

</div>
