import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AbpZeroTemplateCommonModule } from '@shared/common/common.module';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FileUploadModule } from '@node_modules/ng2-file-upload';
//import { AppRoutingModule } from 'app/app-routing.module';
import { EditorModule } from 'primeng/editor';
import { UtilsModule } from '@shared/utils/utils.module';
import { AppCommonModule } from 'app/shared/common/app-common.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';

import { HeaderComponent } from 'app/shared/layout/header.component';
import { HeaderNotificationsComponent } from 'app/shared/layout/notifications/header-notifications.component';
import { SideBarComponent } from 'app/shared/layout/side-bar.component';
//import { FooterComponent } from './shared/layout/footer.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { MyInterestsModalComponent } from '@app/shared/layout/profile/my-interests-modal.component';
import { RouterModule } from '@angular/router';
//import { ImpersonationService } from './admin/users/impersonation.service';
import { LinkedAccountService } from 'app/shared/layout/linked-account.service';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
import { NotificationsComponent } from 'app/shared/layout/notifications/notifications.component';
import { NotificationSettingsModalCompoent } from 'app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from 'app/shared/layout/notifications/UserNotificationHelper';
import { CreatePollTranslationModalComponent } from 'app/shared/layout/create-poll-translation/create-poll-translation-modal.component';
import { PreviewSurveyModalComponent } from 'app/shared/layout/preview-survey/preview-survey-modal.component';
import { PreviewPollModalComponent } from 'app/shared/layout/preview-survey/preview-poll-modal.component';
import { PollImageModalComponent } from 'app/shared/layout/polls/poll-image/poll-image-modal.component';
import { FileUploadComponent } from 'app/shared/layout/file-upload/file-upload.component';
import { TemplateVoteSurveyComponent } from 'app/shared/layout/survey-schemas/template-vote-survey/template-vote-survey.component';
import { TemplateEntitySelectionComponent } from 'app/shared/layout/survey-schemas/template-entity-selection/template-entity-selection.component';
import { TemplateRatingBarsComponent } from 'app/shared/layout/charts/template-rating-bars.component';
import { SurveyCarouselComponent } from 'app/shared/layout/surveys/survey-carousel/survey-carousel.component';
import { SourcesComponent } from 'app/shared/layout/create-sources/sources.component';
import { ArticleTemplateComponent } from 'app/shared/layout/article-template/article-template.component';
//import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
//import { ChatFriendListItem } from './shared/layout/chat/chat-friend-list-item.component';
//import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
//import { QuickSideBarChat } from '@app/shared/layout/chat/QuickSideBarChat';
import { TopMenuTemplateComponent } from 'public/site/top-menu/top-menu-template.component';

@NgModule({
  imports: [
      FormsModule,
        CommonModule,
        AbpZeroTemplateCommonModule,
      HttpClientModule,
      HttpClientJsonpModule,
      ModalModule.forRoot(),
      TooltipModule.forRoot(),
      TabsModule.forRoot(),
      FileUploadModule,    
      //AppRoutingModule,
        RouterModule,
      UtilsModule,
      AppCommonModule.forRoot(),
      EditorModule,
      ServiceProxyModule

  ],
  declarations: [
      HeaderComponent,
      LoginAttemptsModalComponent,
      LinkedAccountsModalComponent,
      LinkAccountModalComponent,
      ChangePasswordModalComponent,
      ChangeProfilePictureModalComponent,
      MySettingsModalComponent,
      MyInterestsModalComponent,
      HeaderNotificationsComponent,
      NotificationsComponent,
      NotificationSettingsModalCompoent,
      CreatePollTranslationModalComponent,
      PreviewSurveyModalComponent,
      PreviewPollModalComponent,
      PollImageModalComponent,
      FileUploadComponent,
      TemplateVoteSurveyComponent,
      TemplateEntitySelectionComponent,
      TemplateRatingBarsComponent,
      SurveyCarouselComponent,
      SourcesComponent,
      ArticleTemplateComponent,
      SideBarComponent,
      TopMenuTemplateComponent
  ],
  exports: [
      HeaderComponent,
      FormsModule,
      //AppRoutingModule,
      UtilsModule,
      FileUploadModule,
      MySettingsModalComponent,
      CreatePollTranslationModalComponent,
      PreviewSurveyModalComponent,
      PreviewPollModalComponent,
      PollImageModalComponent,
      FileUploadComponent,
      TemplateVoteSurveyComponent,
      TemplateEntitySelectionComponent,
      TemplateRatingBarsComponent,
      SourcesComponent,
      SurveyCarouselComponent,
      SideBarComponent,
      ArticleTemplateComponent

  ]
})
export class HeaderModule { }
