<div bsModal #modal="bs-modal" (onShown)="onModalShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #imageUploadForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">
                        <span>{{l("UploadNewImage")}}</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input class="form-control" required min="2" name="AlternateText" [ngClass]="{'edited':imageInput.alternateText}" [(ngModel)]="imageInput.alternateText" maxlength="250" />
                        <label>{{l("AlternateText")}}</label>
                    </div>
                    <div class="note note-info">
                        <span>{{l("DefaultImageSizeInfo")}}</span>
                    </div>
                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <select class="form-control" [(ngModel)]="imageInput.providerTypeId" name="providerTypeId" [ngClass]="{'edited':imageInput.providerTypeId}">
                            <option value="1" selected="selected">{{l("LicensedProvider")}}</option>
                            <option value="2">{{l("FreeProvider")}}</option>
                            <option value="3">{{l("MyImage")}}</option>
                        </select>
                        <label>{{l("ProviderType")}}</label>

                    </div>
                    <div *ngIf="imageInput.providerTypeId < 3" class="form-group form-md-line-input form-md-floating-label no-hint">
                        <select class="form-control" [(ngModel)]="imageInput.imageProviderId" name="imageProviderId" [ngClass]="{'edited':imageInput.imageProviderId}">
                            <option *ngFor="let c of imageProviders" [ngValue]="c.id">{{c.name}}</option>
                        </select>
                        <label>{{l("ImageProvider")}}</label>

                    </div>
                    <div *ngIf="imageInput.providerTypeId < 3" class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input class="form-control" required min="12" name="AlternateText" [ngClass]="{'edited':imageInput.imageUrlToProvider}" [(ngModel)]="imageInput.imageUrlToProvider" maxlength="290" />
                        <label>{{l("ImageUrlToProvider")}}</label>
                    </div>
                    <div class="form-group form-md-line-input form-md-floating-label no-hint">
                        <input class="form-control" required min="12" name="photographer" [ngClass]="{'edited':imageInput.photographer}" [(ngModel)]="imageInput.photographer" maxlength="30" />
                        <label>{{l("Photographer")}}</label>
                    </div>
                    <div>
                        <div class="form-group">
                            <input type="file" ng2FileSelect [uploader]="uploader" />
                            <span class="help-block m-b-none">{{l("ProfilePicture_Change_Info")}}</span>
                        </div>
                        <img id="ImageResize" [hidden]="!temporaryUrl" />
                    </div>
                    <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="imgUrl !== undefined && imgUrl !==''">
                        <img id="surveyImage" alt="" class="img-responsive" style="max-width:300px" [src]="imgUrl" />
                    </div>


                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="!imageUploadForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
