
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row">
        <label class="font-media-h3 font-white">
            {{l("PeoplesPoliticalSatisfaction")}}
        </label>
    </div>
</div>

<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin-top-40 margin-bottom-40" *ngIf="politicalTrustPoll !== null">
    <gauge-chart [poll]='politicalTrustPoll' [inputIsUserLoggedIn]="inputIsUserLoggedIn"></gauge-chart>
</div>
<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin-top-40 margin-bottom-40" *ngIf="govermentTrustPoll !== null">
    <gauge-chart [poll]='govermentTrustPoll' [inputIsUserLoggedIn]="inputIsUserLoggedIn"></gauge-chart>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top-40 margin-bottom-40" *ngIf="politicalPartiesPoll !== null">
    <political-party-chart [politicalPartiesPoll]='politicalPartiesPoll' [inputIsUserLoggedIn]="inputIsUserLoggedIn" [screenWidth]="screenWidth"></political-party-chart>
</div>

<!--<div class="margin-top4pr margin-bottom4pr">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <ng-adsense [adClient]="'ca-pub-5111104741767483'"
                    [adSlot]="1275932038"
                    [adFormat]="'auto'"
                    [display]="'block'"
                    [fullWidthResponsive]="true">

        </ng-adsense>
    </div>
</div>-->

