
<div class="card card-frame-area-teaser margin-bottom-70 margin-right-20 " *ngIf="localPolls$ | async as localPolls">
    <div class="area-teaser-header btn-public-dropdown">
        <div style="height:10px;"></div>
        <h3 class="font-media-h2">{{l("SubAreas")}}</h3>
    </div>

    <div class="survey-card-content  bg-white">
        <div style="height:93%; overflow:hidden; overflow-y:scroll;">
            <div *ngFor="let subArea of subAreaPolls; index as p" class="margin-top-10 margin-left-10">
                <div class="font-media-h2 menu-dark-blue margin-left-10">
                    <span *ngIf="subArea.areaType === 5"><a (click)="goToSite(subArea.areaType,subArea.areaName, '')" [attr.aria-label]="strGoToAreaSite" title="{{l('GoToAreaSite')}}"> {{subArea.subAreaName}}</a></span>
                    <span *ngIf="subArea.areaType === 7"><a (click)="goToSite(subArea.areaType,subArea.areaName, subArea.subAreaName)" [attr.aria-label]="strGoToSubAreaSite" title="{{l('GoToSubAreaSite')}}"> {{subArea.subAreaName}}</a></span>
                </div>
                <div *ngFor="let poll of subArea.localPolls; index as p" class="margin-bottom-20">
                    <div class="menu-dark-blue bold margin-left-10 margin-top-10">
                        <a (click)="goToVoteForm(poll.id)" [attr.aria-label]="goToDashboard" title="{{l('GoToDashboardSite')}}"><i class="fa fa-circle font-purple font-media-h5 margin-right-10"></i> <span class="font-media-h3 font-dark">{{poll.name}}</span></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



