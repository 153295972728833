<div [@routerTransition] class="form-group">
    <table class="table table-no-border" *ngIf="sourceList.length > 0">
        <tr class="active">
            <th>
                <label class="control-label">#</label>
            </th>
            <th>
                <label class="control-label">{{l('Sources')}}</label>
            </th>
            <td></td>
        </tr>
        <tr *ngFor="let item of sourceList; let i=index">
            <td>{{ i+1 }}</td>
            <td><a href="{{ item.sourceUrl }}">{{ item.sourceName }}</a></td>
            <td>
                <button (click)="deleteSource(item)" title="{{l('Delete')}}" class="btn btn-danger" href="javascript:;">
                    <i class="icon-trash"></i>
                    {{l('Delete')}}
                </button>
            </td>
        </tr>
    </table>
    <table class="table table-no-border">
        <tr>
            <td style="border:none">
                <label class="control-label">{{l('Name')}}</label>
            </td>
            <td style="border:none">
                <label class="control-label">{{l('Url')}}</label>
            </td>
            <td style="border:none">
            </td>
        </tr>
        <tr>
            <td style="border:none">
                <input class="form-control col-md-2" name="inputSourceName" type="text" placeholder="media - article name" [(ngModel)]="newSource.sourceName" maxlength="120" />
            </td>
            <td style="border:none">
                <input class="form-control col-md-2" name="inputSourceUrl" type="text" placeholder="https://xxx.xx/xx" [(ngModel)]="newSource.sourceUrl" maxlength="150" />
            </td>
        </tr>
        <tr>
            <td>
                <div class="md-checkbox margin-top-10">
                    <input id="chbAddArticle" class="md-check" type="checkbox" name="chbAddArticle" [ngClass]="{'edited':newSource.createArticle}"
                           [(ngModel)]="newSource.createArticle">
                    <label for="chbAddArticle">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span>
                        {{l("AddToArticleList")}}
                    </label>
                </div>
            </td>
            <td>
                <div *ngIf ="newSource.createArticle">
                    <div class="form-group">
                        <label>{{l("MessageIndicator")}}</label>
                    </div>
                    <div class="form-group">
                        <div class="btn-group btn-group-circle" data-toggle="buttons">
                            <label class="btn default" [ngClass]="{'active': newSource.messageIndicator === 1}" (click)="messageIndicator_click(1)">
                                <input type="radio" name="messageIndicator" [ngModel]="newSource.messageIndicator" [value]="1" class="toggle"> {{l("Posistive")}}
                            </label>
                            <label class="btn default" [ngClass]="{'active': newSource.messageIndicator === 0}" (click)="messageIndicator_click(0)">
                                <input type="radio" name="articleIndicator" [ngModel]="newSource.messageIndicator" [value]="2" class="toggle"> {{l("Neutral")}}
                            </label>
                            <label class="btn default" [ngClass]="{'active': newSource.messageIndicator === 2}" (click)="messageIndicator_click(2)">
                                <input type="radio" name="messageIndicator" [ngModel]="newSource.messageIndicator" [value]="3" class="toggle"> {{l("Negative")}}
                            </label>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td style="border:none" colspan="2" align="right">
                <div class="margin-top-10 margin-right-20">
                    <button type="button" (click)="addSource()"
                            class="btn btn-info">
                        {{l('Add')}}
                    </button>
                </div>
            </td>
        </tr>
    </table>

</div>
