<nav  [ngClass]="{'margin-bottom-20 margin-top-30':screenWidth > 480,'margin-bottom-5 margin-top-5': screenWidth < 481 }">
    <div class="page-bar" [ngClass]="{'page-bar-mobile': screenWidth < 481, 'margin-bottom-20': screenWidth > 480 }">
        <div [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-xs-12': screenWidth > 480 }">
            <h3 class="page-breadcrumb font-white" [ngClass]="{'margin-bottom-5': screenWidth < 771 }">
                <span [ngClass]="{'font-media-h2':screenWidth > 770,'margin-bottom-30 font-media-h3': screenWidth < 771 }">
                    <span [ngClass]="{'bold':isGlobal && !isCategory}">
                        <a href="javascript:;" (click)="goToGlobalSite()" [attr.aria-label]="strGoToGlobalSite" *ngIf="!isGlobal || isCategory"><span style="margin-right:6px" class="font-white"><i class="icon-globe"></i> {{l("Globally")}}</span></a>
                        <span *ngIf="isGlobal && !isCategory"><i class="icon-globe"></i> {{l("Globally")}}</span>
                        <i *ngIf="!isGlobal || isCategory" class="fa fa-angle-right"></i>
                    </span>
                    <span [ngClass]="{'bold':isCountry && !isCategory}" *ngIf="isCountry || isArea || isSubArea">
                        <a href="javascript:;" (click)="goToCountrySite(countrySitePath)" [attr.aria-label]="strGoToCountrySite" *ngIf="!isCountry || isCategory" class="font-white" style="margin-left:7px;margin-right:6px">{{site.countryNameTranslation}}</a>
                        <span *ngIf="isCountry && !isCategory" style="margin-left:7px;margin-right:6px"> {{site.countryNameTranslation}} </span>
                        <i *ngIf="isArea || isSubArea || isCategory" class="fa fa-angle-right"></i>
                    </span>
                    <span [ngClass]="{'bold':isArea && !isCategory}" *ngIf="isArea || isSubArea">
                        <a href="javascript:;" (click)="goToAreaSite(areaName)" *ngIf="!isArea || isCategory" [attr.aria-label]="strGoToAreaSite" class="font-white" style="margin-left:7px;margin-right:6px"> {{site.areaName}}<span *ngIf="hasSameSubAreaName && !site.isSubAreaInParent">{{l("CountryAreaDef") | lowercase}}</span></a>
                        <span *ngIf="isArea && !isCategory">
                        {{site.areaName}} <span *ngIf="hasSameSubAreaName && !site.isSubAreaInParent">{{l("CountryAreaDef") | lowercase}}</span>
                        </span>
                        <i *ngIf="isSubArea || isCategory" class="fa fa-angle-right"></i>
                    </span>
                    <span [ngClass]="{'bold':isSubArea && !isCategory}" *ngIf="isSubArea">
                        <a href="javascript:;" (click)="goToSubAreaSite(subAreaName)" *ngIf="isCategory" class="font-grey-light" style="margin-left:7px;margin-right:6px" [attr.aria-label]="strGoToSubAreaSite"> {{site.subAreaName}} <span *ngIf="site.areaName === site.subAreaName">{{l("CountrySubAreaDef") | lowercase}}</span></a>
                        <span *ngIf="!isCategory">
                            {{site.subAreaName}} <span *ngIf="site.areaName === site.subAreaName">{{l("CountrySubAreaDef") | lowercase}}</span>
                        </span>
                        <i class="fa fa-angle-right" *ngIf="isCategory"></i>
                    </span>
                    <span class="bold" *ngIf="isCategory">
                        <span style="margin-left:7px;margin-right:6px"> {{categoryTranslation}} </span>
                    </span>
                </span>
            </h3>
            <div [ngClass]="{'font-media-h2 pull-right':screenWidth > 767,'font-media-h3 margin-bottom-5': screenWidth < 768 }">
                <div class="btn-group" *ngIf="isUserLoggedIn && userSite.countrySiteId > 0">
                    <button [attr.aria-label]="strSelectRegion" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{l("MyRegion")}}
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <a href="javascript:;" [attr.aria-label]="strGoToCountrySite" (click)="goToCountrySite(userSite.countrySitePath)">
                                <span class="famfamfam-flags {{userSite.countryIso| lowercase}}"></span> {{userSite.countryNameTranslation}}
                            </a>
                        </li>
                        <li *ngIf="isUserLoggedIn && userSite.areaSitePath !== ''">
                            <a href="javascript:;" [attr.aria-label]="strGoToAreaSite" (click)="goToAreaSite(userSite.areaSitePath)">
                                <i class="fa fa-university"></i> {{userSite.areaName}} <span *ngIf="hasSameSubAreaName && !site.isSubAreaInParent">{{l("CountryAreaDef")}}</span>
                            </a>
                        </li>
                        <li *ngIf="isUserLoggedIn && userSite.subAreaSitePath !== '' && !site.isSubAreaInParent">
                            <a href="javascript:;" [attr.aria-label]="strGoToSubAreaSite" (click)="goToSubAreaSite(userSite.subAreaSitePath)">
                                {{userSite.subAreaName}} <span *ngIf="userSite.areaName === userSite.subAreaName">{{l("CountrySubAreaDef")}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" *ngIf="!isUserLoggedIn && isGlobal">
                    <button [attr.aria-label]="strSelectCountry" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{l("Countries")}}
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <ul class="dropdown-menu" *ngIf="countrySites$ | async as countrySites">
                        <li *ngFor="let site of countrySites; index as c">
                            <a href="javascript:;" [attr.aria-label]="strGoToCountrySite" (click)="goToCountrySite(site.sitePath)">
                                <span class="famfamfam-flags {{site.iso| lowercase}}"></span> {{site.name}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" *ngIf="!isUserLoggedIn && isCountry && areaSites !== null">
                    <button [attr.aria-label]="strSelectCountryAreaDef" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{l("CountryAreasDef")}}
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <ul class="dropdown-menu" *ngIf="areaSites$ | async as areaSites">
                        <li *ngFor="let areaSite of areaSites; let i = index">
                            <a (click)="goToAreaSite(areaSite.name)" [attr.aria-label]="strGoToAreaSite">{{areaSite.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" *ngIf="!isUserLoggedIn && isArea && subAreaSites !== null">
                    <button [attr.aria-label]="strSelectCountryAreaDef" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-public-dropdown dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{l("CountrySubAreasDef")}}
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <ul class="dropdown-menu" *ngIf="subAreaSites$ | async as subAreaSites">
                        <li *ngFor="let subAreaSite of subAreaSites; let i = index">
                            <a (click)="goToSubAreaSite(subAreaSite.name)" [attr.aria-label]="strGoToSubAreaSite">{{subAreaSite.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group">
                    <button [attr.aria-label]="strSelectCategory" [ngClass]="{'btn-md':screenWidth > 480,'btn-xs': screenWidth < 481}" class="btn btn-circle-right btn-public-dropdown  dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {{l("Categories")}}
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li *ngFor="let category of categoryList;"><a [attr.aria-label]="strGoToCategorySite" (click)="goToCategorySite(category.categoryName)">{{category.categoryTranslation}}({{category.pollAndPetitionCount}})</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>
