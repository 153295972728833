import { Component, ViewChild, Injector, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PollStatisticsServiceProxy, CategoryChartDto, FrontPagePollOutput, PollListDto, SiteDto, SiteOutput, FrontPageData, PollTotalRatingOutput } from '@shared/service-proxies/service-proxies';
import { MySiteServiceProxy, ProfileServiceProxy, UserSitesDto, VotechaVoteServiceProxy, ChangeUserLanguageDto } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import * as _ from 'lodash';
import { CookieHelper } from '@shared/helpers/CookieHelper';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';
import { Meta } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';
import * as $ from 'jquery';
import 'owl.carousel';
import { Observable } from 'rxjs';
import { data } from 'jquery';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { forEach, toNumber } from 'lodash';
import { UrlService } from '@shared/url.service';


@Component({
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.css'],
    animations: [appModuleAnimation()]
})
export class SiteComponent extends AppComponentBase implements OnInit {
    @HostListener('window:resize', ['$event'])
    areaSites$: Observable<SiteOutput[]>;
    countrySites: SiteOutput[] = [];
    areaSites: SiteOutput[] = [];
    subAreaSites$: Observable<SiteOutput[]>;
    subAreaSites: SiteOutput[] = [];
    frontPageData$: Observable<FrontPageData>;
    frontPageData: FrontPageData = new FrontPageData();

    lastPetitions: PollListDto[] = [];

    totalRatingList: PollTotalRatingOutput[] = [];

    currentlyNowFrontPolls: PollListDto[] = [];
    currentlyNowFrontPollsFiltered: PollListDto[] = [];
    //frontPetitions: FrontPagePollOutput[] = [];
    //frontPetitionsFiltered: FrontPagePollOutput[] = [];

    politicalPolls: PollListDto[] = [];
    subSiteSurveys: PollListDto[] = [];
    pollCategoryList: CategoryChartDto[] = [];
    pollCategoryListFiltered: CategoryChartDto[] = [];
    categoryList$: Observable<CategoryChartDto[]>;
    categoryList: CategoryChartDto[] = [];
    answeredPollIds$: Observable<string[]>;
    answeredPollIds: string[] = [];

    userSite: UserSitesDto;
    site: SiteDto;

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;

    cookieLangValue: string;
    areaType: string;

    lblPolitics: string;
    lblEnvironment: string;
    lblTravel: string;
    lblFinance: string;
    lblSoccer: string;
    public subSiteName: string = "";
    public strGoToGlobalSite: string = "";
    public strGoToCountrySite: string = "";
    public strGoToAreaSite: string = "";
    public strGoToSubAreaSite: string = "";
    public strGoToCategorySite: string = "";
    public strGoToAboutSite: string = "";
    public strGoToRegistrationSite: string = "";
    public strSelectCountry: string = "";
    public strSelectRegion: string = "";
    public strSelectCategory: string = "";
    public strSelectCountryAreaDef: string = "";
    public strSelectSubAreaDef: string = "";
    public strGoToNextCategory: string = "";
    strGoToPreviosCategory: string = "";
    public strGoToTop: string = "";
    public strGoToFooter: string = "";
    public showOnlyUnansweredDateStorageValue = "showOnlyUnanswered";

    pagePath: string = "";
    sitePath: string = "";
    countrySitePath: string;
    areaSitePath: string;
    subAreaSitePath: string;

    countryName: string;
    areaName: string;
    subAreaName: string;

    siteTypeId: number;
    siteId: number;
    totalPolls: number;
    pollsStorageLimit: number;
    active: boolean = false;
    isUserLoggedIn: boolean = false;
    userHasAccount: boolean = false;
    hasSameSubAreaName: boolean;
    isGlobal: boolean;
    isCountry: boolean;
    isArea: boolean;
    isSubArea: boolean;
    isRoot: boolean;
    isRootNet: boolean;
    isRootNorway: boolean;
    isNorwaySite: boolean;
    showOnlyUnanswered: boolean;
    allAnswered: boolean;
    allPollsCompleted: boolean;
    screenWidth: any;
    previousUrl: Observable<string> = this.urlService.previousUrl$;
    previousUrlStr: string = "";
    lastPosision: number;
    today: Date;

    constructor(
        injector: Injector,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private _pollStatisticsService: PollStatisticsServiceProxy,
        private _mySiteService: MySiteServiceProxy,
        private _voteServiceProxy: VotechaVoteServiceProxy,
        private _profileServiceProxy: ProfileServiceProxy,
        private meta: Meta,
        private urlService: UrlService
    ) {
        super(injector);
        this.getScreenSize();
    }

    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.areaType = "";
        this.sitePath = "global";
        this.today = new Date();
        this.pollsStorageLimit = 1;

        this.urlService.previousUrl$.subscribe((previousUrl: string) => {
            if (previousUrl) {
                this.previousUrlStr = previousUrl;
            }
        });
        this.urlService.lastPosision$.subscribe((lastPosision: string) => {
            if (lastPosision) {
                this.lastPosision = toNumber(lastPosision);
            }

        });

        if (this._router.url === "/") {
            this.isRoot = true;
        }

        if ((AppConsts.appBaseUrl.toLowerCase().includes(".net"))) {
            this.isRootNet = true;
            this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled == false);
            this.currentLanguage = this.localization.currentLanguage;
            this.getCountrySites();
        }


        this.pagePath = this._router.url;
        registerLocaleData(localeNorwegian);
        this.screenWidth = window.innerWidth;
        this.active = false;
        this.showOnlyUnanswered = false;
        this.strGoToGlobalSite = this.l('GoToGlobalSite');
        this.strGoToCountrySite = this.l('GoToCountrySite');
        this.strGoToAreaSite = this.l('GoToArealSite');
        this.strGoToSubAreaSite = this.l('GoToSubAreaSite');
        this.strGoToCategorySite = this.l('GoToCategorySite');
        this.strGoToAboutSite = this.l('GoToAboutSite');
        this.strGoToTop = this.l('GoToTop');
        this.strGoToFooter = this.l('GoToFooter');
        this.strGoToNextCategory = this.l('GoToNextCategory');
        this.strGoToPreviosCategory = this.l('GoToLastCategory');
        this.strGoToRegistrationSite = this.l('GoToRegistrationSite');
        this.strSelectCategory = this.l('Select') + ' ' + this.l('Category');
        this.strSelectCountry = this.l('Select') + ' ' + this.l('Country');
        this.strSelectRegion = this.l('Select') + ' ' + this.l('Area');
        this.strSelectCountryAreaDef = this.l('Select') + ' ' + this.l('CountryAreaDef');
        this.strSelectCountryAreaDef = this.l('Select') + ' ' + this.l('CountrySubAreaDef');
        this.isUserLoggedIn = false;
        this.userSite = new UserSitesDto();
        this.userSite.countrySiteId = 0;
        this.userSite.countrySitePath = "";
        this.userSite.areaSitePath = "";
        this.userSite.subAreaSitePath = "";

        this.subSiteSurveys = [];
        this.subSiteName = "";
        this.sitePath = "global";
        this.countryName = "global";
        this.areaName = "";
        this.subAreaName = "";
        //this.frontPetitions = [];
        this.site = new SiteDto();
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled == false);
        this.currentLanguage = this.localization.currentLanguage;

        if (!this.userHasAccount) {
            this.userHasAccount = CookieHelper.getUserSignedUpValue();
        }
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;

        this.countrySitePath = "";
        this.isGlobal = false;
        this.isCountry = false;
        this.isArea = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.cookieLangValue = "en";
            if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
            //if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
                this.cookieLangValue = "nb-NO";
                this.isNorwaySite = true;
                this.getPoliticalPolls();
                if (!window.location.toString().includes("global")) {
                    this.sitePath = "norway";
                    if (params['areaDef'] === undefined || params['areaDef'] === null) {
                        this.areaType = "country";
                        this.countryName = "norway";
                        this.siteTypeId = 2;
                        this.sitePath = this.countryName;
                        this.isCountry = true;
                        if (this._router.url === "/") {
                            this.isRootNorway = true;
                        }
                        if (this.currentLanguage.name !== "nb-NO")
                            this.currentLanguage = VotechaHelper.getNorwegianLang(this.languages);
                        abp.utils.setCookieValue(
                            "Abp.Localization.CultureName",
                            this.currentLanguage.name,
                            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                            abp.appPath
                        );
                        if (this.isUserLoggedIn) {
                            this._router.navigate(['app/main/site/country/norway']);
                        }
                        this.getAreaSites();
                    }
                }                
            }
            
            else if ((AppConsts.appBaseUrl.toLowerCase().includes(".net") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) && this._router.url === "/") {
                this.isRootNet = true;
                if (this.isUserLoggedIn) {
                    this._router.navigate(['app/main/site/global']);
                }
            }

            if (!this.isRootNorway) {
                if (this.areaType === '') {
                    this.areaType = params['areaDef'];
                }
                if (this.areaType === null || this.areaType === undefined || this.areaType === 'global') {
                    this.areaType = 'global';
                    this.siteTypeId = 1;
                    this.sitePath = 'global';
                    this.isGlobal = true;
                    this.getCountrySites();
                }
                else {
                    this.countrySitePath = params['country'];
                    this.sitePath = this.countrySitePath;
                    this.countryName = params['country'];

                    if (this.areaType === 'country') {
                        this.siteTypeId = 2;
                        this.isCountry = true;
                        this.getAreaSites();
                    }
                    else if (this.areaType === 'area') {
                        this.areaSitePath = params['country'] + "/" + params['area'];
                        this.sitePath = this.areaSitePath;
                        this.areaName = params['area'];
                        this.siteTypeId = 3;
                        this.isArea = true;
                        if (!this.site.isSubAreaInParent) {
                            this.getSubAreaSites();
                        }
                    }
                    else if (this.areaType === 'subarea') {
                        this.subAreaSitePath = params['country'] + "/" + params['area'] + "/" + params['subArea'];
                        this.sitePath = this.subAreaSitePath;
                        this.areaName = params['area'];
                        this.subAreaName = params['subArea'];
                        this.siteTypeId = 4;
                        this.isSubArea = true;
                    }
                }
            }



            this.getCategories();
            this.getArticlesAndSurveys();


        });

        if (this.isUserLoggedIn) {
            this.getUserSites();
        }
        else {
            if (this.siteTypeId === 1 && this.cookieLangValue === "nb-NO") {
                this.subSiteName = "Norge";
                //this.getSubSiteSurveys();
            }
        }

        if (!this.showOnlyUnanswered && this.previousUrlStr.includes("surveyboard")) {
            var jsPosision = this.lastPosision;
            setTimeout(function () { window.scrollTo(0, jsPosision); }, 1000);
        }
        else {
            window.scrollTo(0, 0);
        }
    }

    //Only in Norway
    getPoliticalPolls(): void {
        let storageDays = 5;
        let politicalPollsStorage = null;
        let dateStorage = localStorage.getItem("politicalPolls_date");
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < this.pollsStorageLimit) {
                politicalPollsStorage = localStorage.getItem("politicalPolls");
            }
        }

        if (!AppConsts.appBaseUrl.toLowerCase().includes("localhost") && storageDays < this.pollsStorageLimit && politicalPollsStorage) {
            this.politicalPolls = JSON.parse(politicalPollsStorage); 
        }
        else {            
            this._pollStatisticsService.getPoliticalChartPolls("Norway", "nb-NO").subscribe((result) => {
                this.politicalPolls = result;
                localStorage.setItem("politicalPolls", JSON.stringify(this.politicalPolls));
                localStorage.setItem("politicalPolls_date", JSON.stringify(this.today));
            });
        }        
    }

    getArticlesAndSurveys(): void {
        if (!this.isGlobal) {
            this.getSiteData();
        }
        this.getLatestPetitions()
        this.getFrontPageSurveys();        
        
        //this.getSubSiteSurveys();         
    }

    async getCategories(): Promise<void> {
        if (this.isRootNorway || this.isRootNet) {
            const categoryStorageLimit = 4;
            let storageDays = 5;
            let categoryStorage = null;
            let dateStorage = localStorage.getItem(`categories_date_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`);
            if (dateStorage) {
                storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
                if (storageDays < categoryStorageLimit) {
                    categoryStorage = localStorage.getItem(`categories_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`);
                }
            }

            if (!AppConsts.appBaseUrl.toLowerCase().includes("localhost") && storageDays < categoryStorageLimit && categoryStorage) {
                this.categoryList = JSON.parse(categoryStorage);
            }
            else {
                this.categoryList$ = this._pollStatisticsService.getCategoryChart(this.sitePath, this.siteTypeId, this.currentLanguage.name || this.cookieLangValue)
                    .pipe(
                        shareReplay(1)
                    );
                this.categoryList = await VotechaHelper.lastValueFrom(this.categoryList$);
                localStorage.setItem(`categories_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`, JSON.stringify(this.categoryList));
                localStorage.setItem(`categories_date_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`, JSON.stringify(this.today));
            }           
        }
    }

    getSiteData(): void {
        if (this.sitePath !== undefined && this.sitePath !== "" && (this.site.sitePath !== this.sitePath && ((this.sitePath === 'global' && this.siteTypeId === 1) || (this.sitePath !== 'global' && this.siteTypeId === 2 || this.siteTypeId === 3 || this.siteTypeId === 4)))) {
            this._pollStatisticsService.getSiteData(this.sitePath, this.siteTypeId, this.currentLanguage.name || this.cookieLangValue).subscribe((result) => {
                this.site = result;
                this.siteId = this.site.siteId;
                this.countrySitePath = result.countryName.toLocaleLowerCase();
                if (this.siteTypeId >= 3) {
                    this.areaSitePath = this.countrySitePath + "/" + result.areaName.toLocaleLowerCase();
                }
                if (this.siteTypeId === 4) {
                    this.subAreaSitePath = this.sitePath;
                }
                if (this.site?.areaName !== null && this.site?.areaName !== '') {
                    this.hasSameSubAreaName = this.sameAsSubAreaName(this.site.areaName);
                }
            });
        }
    }

    getUserSites(): void {
        this._mySiteService.getUserSites().subscribe((result) => {
            this.userSite = result;
            if (this.userSite.isNewSocialNetworkUser) {
                this.goToProfile();
            }
        });
    }


    loadCarousel(carouselId: string, surveyCount: number): void {
        var itemSizeFromDeviceWidth = VotechaHelper.getItemSizeFromDeviceWidth(this.screenWidth);
        VotechaHelper.loadCarousel(carouselId, surveyCount, itemSizeFromDeviceWidth);
    }

    getGetPollTotalRatingList(): void {
        this._pollStatisticsService.getPollTotalRatingList(this.sitePath).subscribe((result) => {
            this.totalRatingList = result.items;
            this.updateTotalRatingVaules();
        });
    }

    getLatestPetitions(): void {
        this._pollStatisticsService.getLatestPetitions(this.sitePath, this.siteTypeId, this.currentLanguage.name || this.cookieLangValue).subscribe((result) => {
            this.lastPetitions = result.items;
        });
    }

    async getFrontPageSurveys(): Promise<void> {
        
        let storageDays = 2;
        let frontPageDataStorage = null;
        const frontPageDateStorage = `frontPageData_date_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`;
        let dateStorage = localStorage.getItem(frontPageDateStorage);
        if (dateStorage) {
            storageDays = VotechaHelper.getDaysFromDate(JSON.parse(dateStorage));
            if (storageDays < this.pollsStorageLimit) {
                frontPageDataStorage = localStorage.getItem(`frontPageData_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`);
            }
        }

        
        if (!AppConsts.appBaseUrl.toLowerCase().includes("localhost") && storageDays < this.pollsStorageLimit && frontPageDataStorage) {
            this.frontPageData = JSON.parse(frontPageDataStorage);
        }
        else {
            this.frontPageData$ = this._pollStatisticsService.getFrontPagePolls(this.sitePath, this.siteTypeId, this.currentLanguage.name || this.cookieLangValue)
                .pipe(
                    shareReplay(1)
                );
            this.frontPageData = await VotechaHelper.lastValueFrom(this.frontPageData$);
            localStorage.setItem(`frontPageData_${this.sitePath}_${this.currentLanguage.name || this.cookieLangValue}`, JSON.stringify(this.frontPageData));
            localStorage.setItem(frontPageDateStorage, JSON.stringify(this.today));            
        }
        
        this.pollCategoryListFiltered = [];
        //this.frontPetitionsFiltered = [];
        this.currentlyNowFrontPollsFiltered = [];
        this.currentlyNowFrontPolls = [];       
        this.pollCategoryList = this.frontPageData.categoryList;
        //this.frontPetitions = this.frontPageData.frontPagePetitions;
        this.totalPolls = this.frontPageData.pollsCount;
        

        if (this.isUserLoggedIn) {            
            this.getShowOnlyUnansweredFlag();
            
            this.allPollsCompleted = this.frontPageData.allPollsCompleted;
            this.allAnswered = this.allPollsCompleted;
            this.answeredPollIds = [];
            this.answeredPollIds$ = this._voteServiceProxy.getUsersVotedPollIds();
            this.answeredPollIds = await VotechaHelper.lastValueFrom(this.answeredPollIds$);

            for (var i = 0; i < this.pollCategoryList.length; i++) {
                for (var j = 0; j < this.pollCategoryList[i].frontPagePolls.length; j++) {
                    if (this.pollCategoryList[i].frontPagePolls[j].isCurrentNow) {
                        var currentPoll = this.pollCategoryList[i].frontPagePolls[j];
                        if (currentPoll.poll.colorCode === null || currentPoll.poll.colorCode === '') {
                            currentPoll.poll.colorCode = '#202e73';
                        }
                        if (currentPoll.poll.surveyTypeId === 0) {
                            currentPoll.poll.displayName = currentPoll.poll.displayQuestion;
                            currentPoll.poll.name = currentPoll.poll.question;
                        }
                        for (var k = 0; k < this.answeredPollIds.length; k++) {
                            if (this.pollCategoryList[i].frontPagePolls[j].poll.id === this.answeredPollIds[k]) {
                                this.pollCategoryList[i].frontPagePolls[j].poll.hasVoted = true;
                                currentPoll.poll.hasVoted = true;
                            }

                        }

                        this.currentlyNowFrontPolls.push(currentPoll.poll);
                    }
                    else {
                        for (var k = 0; k < this.answeredPollIds.length; k++) {
                            if (this.pollCategoryList[i].frontPagePolls[j].poll.id === this.answeredPollIds[k]) {
                                this.pollCategoryList[i].frontPagePolls[j].poll.hasVoted = true;
                            }

                        }
                    }
                }
            }

            //for (var j = 0; j < this.frontPetitions.length; j++) {
            //    if (this.frontPetitions[j].isCurrentNow) {
            //        var currentPetition = this.frontPetitions[j];
            //        if (currentPetition.poll.colorCode === null || currentPetition.poll.colorCode === '') {
            //            currentPetition.poll.colorCode = '#202e73';
            //        }
            //        currentPetition.poll.displayName = currentPetition.poll.displayQuestion;
            //        this.currentlyNowFrontPolls.push(currentPetition.poll);
            //    }
            //    for (var k = 0; k < this.answeredPollIds.length; k++) {
            //        if (this.frontPetitions[j].poll.id === this.answeredPollIds[k]) {
            //            this.frontPetitions[j].poll.hasVoted = true;
            //        }
            //    }
            //}

            this.pollCategoryListFiltered = this.pollCategoryList;
            //this.frontPetitionsFiltered = this.frontPetitions;
            this.currentlyNowFrontPollsFiltered = this.currentlyNowFrontPolls;
            this.filterAnsweredPolls();
        }
        else {
            for (var i = 0; i < this.pollCategoryList.length; i++) {
                for (var j = 0; j < this.pollCategoryList[i].frontPagePolls.length; j++) {
                    if (this.pollCategoryList[i].frontPagePolls[j].isCurrentNow) {
                        var currentPoll = this.pollCategoryList[i].frontPagePolls[j];
                        if (currentPoll.poll.colorCode === null || currentPoll.poll.colorCode === '') {
                            currentPoll.poll.colorCode = '#5c6873';
                        }
                        if (currentPoll.poll.surveyTypeId === 0) {
                            currentPoll.poll.displayName = currentPoll.poll.displayQuestion;
                            currentPoll.poll.name = currentPoll.poll.question;
                        }
                        this.currentlyNowFrontPolls.push(currentPoll.poll);
                    }
                }
            }

            //for (var j = 0; j < this.frontPetitions.length; j++) {
            //    if (this.frontPetitions[j].isCurrentNow) {
            //        var currentPetition = this.frontPetitions[j];
            //        if (currentPetition.poll.colorCode === null || currentPetition.poll.colorCode === '') {
            //            currentPetition.poll.colorCode = '#5c6873';
            //        }
            //        currentPetition.poll.displayName = currentPetition.poll.displayQuestion;
            //        this.currentlyNowFrontPolls.push(currentPetition.poll);
            //    }
            //}

            this.pollCategoryListFiltered = this.pollCategoryList;
            //this.frontPetitionsFiltered = this.frontPetitions;
            this.currentlyNowFrontPollsFiltered = this.currentlyNowFrontPolls;
        }

        this.getGetPollTotalRatingList();
        this.active = true;
    }

    private updateTotalRatingVaules(): void {
        for (var i = 0; i < this.pollCategoryListFiltered.length; i++) {
            for (var j = 0; j < this.pollCategoryListFiltered[i].frontPagePolls.length; j++) {
                for (var k = 0; k < this.totalRatingList.length; k++) {
                    if (this.pollCategoryListFiltered[i].frontPagePolls[j].poll.id === this.totalRatingList[k].pollId) {
                        this.pollCategoryListFiltered[i].frontPagePolls[j].poll.participants = this.totalRatingList[k].participants;
                        this.pollCategoryListFiltered[i].frontPagePolls[j].poll.unlocked = this.totalRatingList[k].unlocked;
                        this.pollCategoryListFiltered[i].frontPagePolls[j].poll.unlockLimit = this.totalRatingList[k].unlockLimit;
                    }
                }
            }
        }
    }

    private getShowOnlyUnansweredFlag() {
        let dateStorage = localStorage.getItem(this.showOnlyUnansweredDateStorageValue);
        if (dateStorage) {
            this.showOnlyUnanswered = JSON.parse(dateStorage);
        }
        else {
            this.showOnlyUnanswered = this.frontPageData.filterPolls;
        }
    }

    chbOnlyUnansweredChanged(): void {
        localStorage.setItem(this.showOnlyUnansweredDateStorageValue, JSON.stringify(this.showOnlyUnanswered));
        this._pollStatisticsService.setFilterAnsweredPollsSettings(this.showOnlyUnanswered).subscribe(() => {
            if (this.showOnlyUnanswered) {
                this.filterAnsweredPolls();
            }
            else {
                this.getFrontPageSurveys();
            }
        });
    }

    filterAnsweredPolls(): void {
        if (this.isUserLoggedIn && this.showOnlyUnanswered) {
            var currentlyNowTempPolls: PollListDto[] = [];
            for (var j = 0; j < this.currentlyNowFrontPolls.length; j++) {
                for (var k = 0; k < this.answeredPollIds.length; k++) {
                    if (this.currentlyNowFrontPolls[j].id === this.answeredPollIds[k]) {
                        this.currentlyNowFrontPolls[j].hasVoted = true;
                    }
                }
                if (!this.currentlyNowFrontPolls[j].hasVoted) {
                    var unAnsweredPoll = this.currentlyNowFrontPolls[j];
                    currentlyNowTempPolls.push(unAnsweredPoll);
                }
            }

            this.currentlyNowFrontPollsFiltered = currentlyNowTempPolls;

            //var tempPetitions: FrontPagePollOutput[] = [];
            //for (var j = 0; j < this.frontPetitions.length; j++) {
            //    if (!this.frontPetitions[j].poll.hasVoted) {
            //        var unsignedFrontPagePetition = this.frontPetitions[j];
            //        tempPetitions.push(unsignedFrontPagePetition);
            //    }
            //}
            //this.frontPetitionsFiltered = tempPetitions;

            var tempCategories: CategoryChartDto[] = [];
            for (var i = 0; i < this.pollCategoryList.length; i++) {
                var tempCategory = this.pollCategoryList[i];
                var tempPolls: FrontPagePollOutput[] = [];
                for (var j = 0; j < this.pollCategoryList[i].frontPagePolls.length; j++) {

                    if (!this.pollCategoryList[i].frontPagePolls[j].poll.hasVoted) {
                        var unAnsweredFrontPagePoll = this.pollCategoryList[i].frontPagePolls[j];
                        tempPolls.push(unAnsweredFrontPagePoll);
                    }
                }
                if (tempPolls.length > 0) {
                    tempCategory.frontPagePolls = tempPolls;
                    tempCategories.push(tempCategory);
                }
            }

            this.pollCategoryListFiltered = tempCategories;
            if (this.allPollsCompleted || (this.totalPolls > 0 && this.pollCategoryListFiltered.length === 0)) {
                this.allAnswered = true;
            }
        }
    }

    getCountrySites(): void {
        if (this.isRootNet) {
            this._pollStatisticsService.getCountrySites(this.cookieLangValue).subscribe((result) => {
                this.countrySites = result;
            });
        }
    }

    getAreaSites(): void {
        if (this.isRootNorway && this.sitePath !== null && this.sitePath !== '') {
            this.areaSites$ = this._pollStatisticsService.getAreaSites(this.sitePath, this.cookieLangValue)
                .pipe(
                    tap(data => this.areaSites = data),
                    shareReplay(1)
                );
        }

    }

    getSubAreaSites(): void {
        if (this.isRootNorway) {
            this.subAreaSites$ = this._pollStatisticsService.getSubAreaSites(this.sitePath, this.cookieLangValue)
                .pipe(
                    tap(data => this.subAreaSites = data),
                    shareReplay(1)
                );
        }
    }

    goToProfile(): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/profile']);
        }
    }

    goToGlobalSite(): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/global']);
        }
        else {
            this._router.navigate(['public/site/global']);
        }

        this.getGlobalSiteData();
    }

    goToCountrySite(countryName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'country', this.userSite.countryName]);
        }
        else {
            this._router.navigate(['public/site/', 'country', countryName]);
        }

        this.getCountrySiteData(countryName);
    }

    goToAreaSite(areaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'area', this.userSite.countryName, this.userSite.areaName]);
        }
        else {
            this._router.navigate(['public/site/', 'area', this.countryName, areaName]);
        }

        this.getAreaSiteData(this.countryName + "/" + areaName);
    }

    goToSubAreaSite(subAreaName: string): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/site/', 'subarea', this.userSite.countryName, this.userSite.areaName, this.userSite.subAreaName]);
        }
        else {
            this._router.navigate(['public/site/', 'subarea', this.countryName, this.areaName, subAreaName]);
        }

        this.getSubAreaSiteData(this.countryName + "/" + this.areaName + "/" + subAreaName);
    }


    GoToRegistration(): void {
        this._router.navigate(['account/register']);
    }



    goToCategorySite(categoryName: string): void {
        if (this.isUserLoggedIn) {
            if (this.siteTypeId === 1) {
                this._router.navigate(['app/main/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['app/main/category', categoryName, 'country', this.userSite.countrySitePath]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['app/main/category', categoryName, 'area', this.countryName, this.userSite.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['app/main/category', categoryName, 'subarea', this.countryName, this.userSite.areaName, this.userSite.subAreaName]);
            }
        }
        else {
            if (this.siteTypeId === 1) {
                this._router.navigate(['public/category/', categoryName, 'global']);
            }
            else if (this.siteTypeId === 2) {
                this._router.navigate(['public/category', categoryName, 'country', this.countryName]);
            }
            else if (this.siteTypeId === 3) {
                this._router.navigate(['public/category', categoryName, 'area', this.countryName, this.areaName]);
            }
            else if (this.siteTypeId === 4) {
                this._router.navigate(['public/category', categoryName, 'subarea', this.countryName, this.areaName, this.subAreaName]);
            }

        }
    }

    sameAsSubAreaName(areaName: string): boolean {
        var hasSameName: boolean = false;
        for (var i = 0; i < this.subAreaSites.length; i++) {
            if (this.subAreaSites[i].name === areaName) {
                hasSameName = true;
                break;
            }
        }
        return hasSameName;
    }

    getGlobalSiteData(): void {
        this.isGlobal = true;
        this.isCountry = false;
        this.isArea = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'];
            this.siteTypeId = 1;
            this.sitePath = "global";
            this.getCountrySites();
        });
    }


    getCountrySiteData(sitePath: string): void {
        this.isCountry = true;
        this.isGlobal = false;
        this.isArea = false;
        this.isSubArea = false;

        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'];
            this.siteTypeId = 2;
            this.sitePath = sitePath;
            this.getAreaSites();

        });
    }

    getAreaSiteData(sitePath: string): void {
        this.isArea = true;
        this.isGlobal = false;
        this.isCountry = false;
        this.isSubArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'];
            this.siteTypeId = 3;
            this.sitePath = sitePath;
            this.getSubAreaSites();

        });
    }

    getSubAreaSiteData(sitePath: string): void {
        this.isSubArea = true;
        this.isGlobal = false;
        this.isCountry = false;
        this.isArea = false;
        this.activatedRoute.params.subscribe((params: Params) => {
            this.areaType = params['areaDef'];
            this.siteTypeId = 4;
            this.sitePath = sitePath;
        });
    }


    GoToLogin(): void {
        this._router.navigate(['account/login']);
    }

    goToVoteForm(id: string): void {
        var country = this.countryName
        if (country.toLowerCase() === 'norge') country = "norway";
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/surveyboard/', country, id]);
        }
        else {
            this._router.navigate(['public/surveyboard', country, id]);
        }
    }

    goToFooter(): void {
        document.getElementById("footer").scrollIntoView({ behavior: 'smooth' });
    }

    goToTop(): void {
        document.getElementById("top").scrollIntoView({ behavior: 'smooth' });
    }

    goToCategory(index: number): void {
        var element = "category_" + index;
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
    }

    goToCategoryName(categoryId: number): void {
        var index = 0;
        for (var i = 0; i < this.pollCategoryListFiltered.length; i++) {
            if (this.pollCategoryListFiltered[i].categoryId === categoryId) {
                index = i;
                break
            }
        }
        this.goToCategory(index);
    }

    goToAboutPage(): void {
        this._router.navigate(['public/about']);
    }

    changeLanguage(languageName: string): void {
        let input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        if (this.isUserLoggedIn) {
            this._profileServiceProxy.changeLanguage(input).subscribe(() => {
                abp.utils.setCookieValue(
                    "Abp.Localization.CultureName",
                    languageName,
                    new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                    abp.appPath
                );

                window.location.reload();
            });
        }
        else {
            abp.utils.setCookieValue(
                "Abp.Localization.CultureName",
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            location.reload();
        }
    }

}
