<div bsModal #modal="bs-modal" class="modal fade bs-modal-sm" style="margin-top: 120px" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="active">
            <div class="modal-header c-bg-white">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div>
                    <div class="caption">
                        <h1 class="modal-title">
                            <i class="icon-bar-chart font-blue-steel"></i>
                            <span class="caption-subject bold uppercase font-blue-steel">{{l("LastFeaturesAdded")}}</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="margin-top-10 font-media-h2 bold">
                        <i class="fa fa-user-secret margin-right-5"></i>{{l('SecretParticipation')}}
                    </h2>
                    <p>
                        {{l("SecretParticipationInfo1")}}<br />
                        {{l("SecretParticipationInfo2")}}
                    </p>
                    <h2 class="margin-top-10 font-media-h2 bold">
                        <i class="fa fa-unlock margin-right-5"></i>{{l('UnlockPadlock')}}
                    </h2>
                    <p>
                        {{l("UnlockPollInfo1")}}<br />
                        {{l("UnlockPollInfo2")}}
                    </p>
                    <p>{{l("UnlockPollInfo3")}}</p>
                    <p class="bold">
                        {{l("UnlockPollInfo4")}}
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
            </div>
        </div>
    </div>
</div>
