<div class="crop" 
    [style.width.px]="starWidth"
    [title]="rating"
    (click)="onClick()">
    <div style="width: 90px">
        <span class="fa fa-star star-ranking"></span>
        <span class="fa fa-star star-ranking" *ngIf="rating > 1"></span>
        <span class="fa fa-star star-ranking" *ngIf="rating > 2"></span>
        <span class="fa fa-star star-ranking" *ngIf="rating > 3"></span>
        <span class="fa fa-star star-ranking" *ngIf="rating > 4"></span>
    </div>
</div>
