<div style="margin-top:1%;margin-bottom:1%; margin-left:1%;" *ngIf="chartActive">    
    <div class="row">
        <tabset class="tab-container tabbable-line" id="tabRatingScore">
            <tab heading="{{l('Total')}}" tabindex="0" [active]="activeRatingTabIndex === 0">
                <div class="panel-body">
                    <div class="margin-right-10">
                        <div class="container font-media-h3 font-grey-mint">
                            <span><i class="fa fa-user" title="{{l('Participants')}}"></i> {{chartData.totalParticipants}}</span>
                        </div>
                    </div>
                    <div class="margin-right-10" *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                        <table class="col-xs-12 col-sm-12" *ngFor="let item of totalItems; let i=index;let even = even; let odd = odd">
                            <tr *ngIf="item.totalVotes > 0">
                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                    <span class="pull-left margin-right-10"><i class="fa fa-user"></i> {{item.totalVotes}}</span>      {{item.displayText}}
                                </td>
                                <td>
                                    <div class="bar-rating-container">
                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalPercent !== '0 %'? item.totalPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (totalItems.length > 3 && even)) && chartData.colorCode !== null && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && totalItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && totalItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && totalItems.length > 3 && odd}" class="score"><span *ngIf="item.totalPercent !== '0 %'">{{item.totalPercent}}</span></div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div *ngIf="chartData.importanceRanking > 0" class="margin-top-40 font-media-h3 pull-right" [ngStyle]="{'color':chartData.colorCode}">
                            {{chartData.importanceRanking}} <i class="fa fa-star"></i>
                        </div>
                    </div>
                    <div *ngIf="surveyTypeId === 4">
                        <rg-gauge-chart [canvasWidth]="canvasWidth"
                                        [needleValue]="totalNeedleValue"
                                        [centralLabel]="centralLabel"
                                        [options]="options"
                                        [name]="name"
                                        [bottomLabel]="bottomTotalLabel">

                        </rg-gauge-chart>
                    </div>
                </div>
            </tab>
            <tab heading="{{l('Gender')}}" tabindex="1" [active]="activeRatingTabIndex === 1">
                <div class="panel-group accordion" id="accordion1">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapse_1">{{l('Women')}}</a>
                            </h4>
                        </div>
                        <div id="collapse_1" class="panel-collapse in">
                            <div class="panel-body">
                                <div class="margin-right-10">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-female"></i> {{chartData.womenParticipants}}</span>
                                    </div>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div class="margin-right-10" *ngIf="womenItems.length> 0">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of womenItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.womenVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-male"></i> {{item.womenVotes}}</span>   {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalWomenPercent !== '0 %'? item.totalWomenPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (womenItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }"
                                                             [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && womenItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && womenItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && womenItems.length > 3 && odd}"
                                                             class="score">
                                                            <span *ngIf="item.totalWomenPercent !== '0 %'">{{item.totalWomenPercent}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="womenItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoWomenAnswered')}}
                                    </div>
                                </div>                               
                                <div *ngIf="surveyTypeId === 4">
                                    <rg-gauge-chart [canvasWidth]="canvasWidth"
                                                    [needleValue]="womenNeedleValue"
                                                    [centralLabel]="centralLabel"
                                                    [options]="options"
                                                    [name]="name"
                                                    [bottomLabel]="bottomWomenLabel">

                                    </rg-gauge-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapse_2">{{l('Men')}}</a>
                            </h4>
                        </div>
                        <div id="collapse_2" class="panel-collapse in">
                            <div class="panel-body">
                                <div class="margin-right-10">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-male"></i> {{chartData.menParticipants}}</span>
                                    </div>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div *ngIf="menItems.length> 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of menItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.menVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-male"></i> {{item.menVotes}}</span>   {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalMenPercent !== '0 %'? item.totalMenPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (menItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && menItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && menItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && menItems.length > 3 && odd}" class="score"><span *ngIf="item.totalMenPercent !== '0 %'">{{item.totalMenPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="menItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoMenAnswered')}}
                                    </div>
                                </div>

                                <div *ngIf="surveyTypeId === 4">
                                    <rg-gauge-chart [canvasWidth]="canvasWidth"
                                                    [needleValue]="menNeedleValue"
                                                    [centralLabel]="centralLabel"
                                                    [options]="options"
                                                    [name]="name"
                                                    [bottomLabel]="bottomMenLabel">

                                    </rg-gauge-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </tab>
            <tab heading="{{l('Age')}}" tabindex="2" [active]="activeRatingTabIndex === 2" *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                <div class="panel-group accordion" id="accordionAge">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_1">{{l('AgeGroup')}}: 13-23</a>
                            </h4>
                        </div>
                        <div id="accordionAge_1" class="panel-collapse in">
                            <div class="panel-body">
                                <div class="margin-right-10">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-user"></i> {{chartData.youthParticipants}}</span>
                                    </div>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div *ngIf="youthItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of youthItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.youthVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-user"></i> {{item.youthVotes}}</span> {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalYouthPercent !== '0 %'? item.totalYouthPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (youthItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && youthItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && youthItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && youthItems.length > 3 && odd}" class="score"><span *ngIf="item.totalYouthPercent !== '0 %'">{{item.totalYouthPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="youthItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                                <div class="margin-right-10" *ngIf="surveyTypeId === 0">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-user-circle"></i> {{chartData.youthParticipants}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_2">{{l('AgeGroup')}}: 24-39</a>
                            </h4>
                        </div>
                        <div id="accordionAge_2" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="container font-media-h3 font-grey-mint">
                                    <span><i class="fa fa-user"></i> {{chartData.youngAdultParticipants}}</span>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div *ngIf="youngAdultItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of youngAdultItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.youngAdultVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-user"></i> {{item.youngAdultVotes}}</span> {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalYoungAdultPercent !== '0 %'? item.totalYoungAdultPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (youngAdultItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && youngAdultItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && youngAdultItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && youngAdultItems.length > 3 && odd}" class="score"><span *ngIf="item.totalYoungAdultPercent !== '0 %'">{{item.totalYoungAdultPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="youngAdultItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                                <div class="margin-right-10" *ngIf="surveyTypeId === 0">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-user-circle"></i> {{chartData.youngAdultParticipants}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_3">{{l('AgeGroup')}}: 40-65</a>
                            </h4>
                        </div>
                        <div id="accordionAge_3" class="panel-collapse in">
                            <div class="panel-body">
                                <div class="container font-media-h3 font-grey-mint">
                                    <span><i class="fa fa-user"></i> {{chartData.middleAgedParticipants}}</span>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div *ngIf="middleAgedItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of middleAgedItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.middleAgedVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-user"></i> {{item.middleAgedVotes}}</span>   {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalMiddleAgedPercent !== '0 %'? item.totalMiddleAgedPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (middleAgedItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && middleAgedItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && middleAgedItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && middleAgedItems.length > 3 && odd}" class="score"><span *ngIf="item.totalMiddleAgedPercent !== '0 %'">{{item.totalMiddleAgedPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="middleAgedItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                                <div class="margin-right-10" *ngIf="surveyTypeId === 0">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-user-circle"></i> {{chartData.middleAgedParticipants}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_4">{{l('AgeGroup')}}: 66+</a>
                            </h4>
                        </div>
                        <div id="accordionAge_4" class="panel-collapse collapse">
                            <div class="panel-body">
                                <div class="container font-media-h3 font-grey-mint">
                                    <span><i class="fa fa-user"></i> {{chartData.elderlyParticipants}}</span>
                                </div>
                                <div *ngIf="surveyTypeId > 0 && surveyTypeId !== 4">
                                    <div *ngIf="elderlyItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of elderlyItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.elderlyVotes > 0">
                                                <td align="right" class="col-xs-6 col-sm-5 font-xs">
                                                    <span class="pull-left"><i class="fa fa-user"></i> {{item.elderlyVotes}}</span>    {{item.displayText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div style="filter: brightness(130%)" [ngStyle]="{'width': item.totalElderlyPercent !== '0 %'? item.totalElderlyPercent : '0px', 'background-color': item.colorCode !== null && item.colorCode !== '' ? item.colorCode : ((i === 0 || (elderlyItems.length > 3 && even)) && chartData.colorCode !== '') ? chartData.colorCode : 'bg-blue'  }" [ngClass]="{'bg-red-soft': ((item.colorCode === null || item.colorCode === '') && i === 1 && elderlyItems.length < 4 ), 'bg-grey-mint': (item.colorCode === null || item.colorCode === '') && i === 2 && elderlyItems.length < 4, 'bg-green': (item.colorCode === null || item.colorCode === '') && elderlyItems.length > 3 && odd}" class="score"><span *ngIf="item.totalElderlyPercent !== '0 %'">{{item.totalElderlyPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="elderlyItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                                <div class="margin-right-10" *ngIf="surveyTypeId === 0">
                                    <div class="container font-media-h3 font-grey-mint">
                                        <span><i class="fa fa-user-circle"></i> {{chartData.elderlyParticipants}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="{{l('Signings')}}" tabindex="3" [active]="activeRatingTabIndex === 3" *ngIf="surveyTypeId === 0">
                <div class="table-scrollable">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th> # </th>
                                <th> {{l("Name")}} </th>
                                <th *ngIf="chartData.areaType.toString() === 'AllCountries' || chartData.areaType.toString() === 'SelectedCountries'"> {{l("Country")}}</th>
                                <th class="hidden-xs"> {{l("Area")}}</th>
                                <th>{{l("SubArea")}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let signing of signings; let i=index">
                                <td>{{ i + 1 }}</td>
                                <td><span *ngIf="signing.displayName !== undefined && signing.displayName !== null && signing.displayName !==''">{{ signing.displayName }}</span><span *ngIf="signing.displayName === undefined || signing.displayName === null || signing.displayName !==''"><span *ngIf="signing.gender === 'Woman'">{{l("Woman")}}</span><span *ngIf="signing.gender !== 'Woman'">{{l("Man")}}</span>, <span>{{thisYear - signing.birthYear}}</span></span></td>
                                <td *ngIf="chartData.areaType.toString() === 'AllCountries' || chartData.areaType.toString() === 'SelectedCountries'"><span class="flag-icon flag-icon-{{signing.iso | lowercase}}"></span> {{ signing.countryName }}</td>
                                <td class="hidden-xs">{{ signing.areaName }}</td>
                                <td>{{ signing.subAreaName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </tab>
        </tabset>
    </div>
</div>


