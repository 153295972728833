import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MultiAnswerOutput,  MediaArticleListOutputStatus,  } from '@shared/service-proxies/service-proxies';
import { VotechaVoteServiceProxy, PollOptionItemOutput, UserVoteInput,  FactorOutput, FactorInput } from '@shared/service-proxies/service-proxies';
import { PollListDto, PollInput, EntitySchemaForVotingOutput, EntityItemDto } from '@shared/service-proxies/service-proxies';
import { PollServiceProxy, VotechaChartDto } from '@shared/service-proxies/service-proxies';
import { PollStatisticsServiceProxy, RelatedSurveyDto, BlogArticleOutput, MediaArticleListOutput } from '@shared/service-proxies/service-proxies';
import { MediaPollArticleServiceProxy,ArticleRatingDto} from '@shared/service-proxies/service-proxies';
import { PetitionSigningServiceProxy, UserSigningInput, PetitionSigningOutput } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CookieHelper } from '@shared/helpers/CookieHelper';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { isPrimitive } from 'util';
import { registerLocaleData } from '@angular/common';
import localeNorwegian from '@angular/common/locales/nb';
import localeSwedish from '@angular/common/locales/sv';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'PreviewPollModal',
    templateUrl: './preview-poll-modal.component.html',
    animations: [appModuleAnimation()]
})
export class PreviewPollModalComponent extends AppComponentBase  {
    @ViewChild('modal', { static: true }) modal: ModalDirective;
    public vote: UserVoteInput;
    public survey: PollListDto;
    public pollFromForm: PollInput;
    public chartData: VotechaChartDto;
    public relatedSurveys: PollListDto[] = [];
    public votechaArticles: BlogArticleOutput[] = [];
    public articles: MediaArticleListOutput[] = [];
    public newProsFactor: FactorInput;
    public newConsFactor: FactorInput;
    private cookieLangValue: string;
    public showArticles: boolean = false;
    public showProsCons: boolean = false;
    public voteSaved: boolean = false;
    public active: boolean = false;
    public saving: boolean = false;
    public dataIsReady: boolean = false;
    public hasVoted: boolean = false;
    public isUserLoggedIn: boolean = false;
    private userHasAccount: boolean = false;
    public tabIndex: number = 0;
    public pollId: string = "";
    public activeTabIndex: number = 0;
    public sortingOrder: number;
    public categoryColor: string = "#4b77be";
    public categoryColorName: string = "blue";
    public tagColor: string = "#4b77be";
    public areaDef: string = "";
    public country: string = "";
    public area: string = "";
    public subArea: string = "";
    public language: string = "";
    public screenWidth: number;

    constructor(
        injector: Injector,
        private activatedRoute: ActivatedRoute,
        private _pollService: PollServiceProxy,
        private _router: Router
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    show(poll: PollInput): void {
        this.pollFromForm = poll;        
        this.chartData = new VotechaChartDto();
        this.activeTabIndex = 0;
        this.sortingOrder = 1;
        
        this.getScreenSize();
        registerLocaleData(localeNorwegian);
        this.isUserLoggedIn = false;
        this.showProsCons = false;
        this.relatedSurveys = [];
        this.articles = [];
      
        this.isUserLoggedIn = false;
        this.newProsFactor = new FactorInput();
        this.newConsFactor = new FactorInput();
        this.vote = new UserVoteInput();
        this.vote.answerId = 0;
        this.getSurvey();
       
    }

   

    getSurvey() {        
        this.survey = new PollListDto();
        this.survey.id = this.pollFromForm.id;
        this.survey.areaTypeId = this.pollFromForm.areaTypeId;
        this.survey.categoryColorName = this.pollFromForm.categoryColorName;
        this.survey.categoryId = this.pollFromForm.categoryId;
        this.survey.categoryName = this.pollFromForm.categoryName;
        this.survey.colorCode = this.pollFromForm.colorCode;
        this.categoryColor = this.pollFromForm.colorCode;
        this.survey.countryId = this.pollFromForm.countryId;
        this.survey.countryName = this.pollFromForm.countryName;
        this.survey.subCategoryId = this.pollFromForm.subCategoryId;
        this.survey.subCategoryName = this.pollFromForm.subCategoryName;
        this.survey.useProsAndCons = this.pollFromForm.useProsAndCons;
        this.survey.description = this.pollFromForm.description;
        this.survey.name = this.pollFromForm.name;
        this.survey.question = this.pollFromForm.question; 
        this.survey.descriptionSources = this.pollFromForm.descriptionSources;
        this.survey.startDate = this.pollFromForm.startDate;
        this.survey.endDate = this.pollFromForm.endDate;
        this.survey.surveyType = this.pollFromForm.surveyType;
        this.survey.surveyTypeId = this.pollFromForm.surveyTypeId;

        this.survey.imgUrl = this.pollFromForm.imgUrl;
        this.survey.imageProvider = this.pollFromForm.imageProvider;
        this.survey.imageProviderType = this.pollFromForm.imageProviderType;
        this.survey.imageProviderId = this.pollFromForm.imageProviderId;
        this.survey.imageUrlToProvider = this.pollFromForm.imageUrlToProvider;
        this.survey.photographer = this.pollFromForm.photographer;
      
        if (this.categoryColor !== null) {
            this.tagColor = this.shadeColor(this.categoryColor, -40);
        }

        if (this.pollFromForm.surveyType < 20) {
            this._pollService.getPollItemList(this.pollFromForm.surveyType, this.pollFromForm.primaryLanguageId).subscribe(result => {
                this.survey.surveyItems = result;
            });
        }
        else if (this.pollFromForm.optionItems !== undefined) {
            for (var i = 0; i < this.pollFromForm.optionItems.length; i++) {
                var item: PollOptionItemOutput = new PollOptionItemOutput();
                item.itemValue = this.pollFromForm.optionItems[i].itemValue;
                item.itemText = this.pollFromForm.optionItems[i].itemText;
                this.survey.surveyItems.push(item);

            };
        }
        
        this.active = true;
        this.modal.show();
    }


    ScaleDownImages(displayDescription: string):  string {
      return  displayDescription.replace("<img src", "<img class=\"img-responsive\" src");
    }

    FixUrl(displayDescription: string): string {
        displayDescription = displayDescription.replace("ref=\"www.", "ref=\"https://www.");
        var linkIndex = displayDescription.indexOf("ref=\"");
        if (!displayDescription.substring(linkIndex, linkIndex + 15).includes("https:") && !displayDescription.substring(linkIndex, linkIndex + 8).includes("http:")){
            displayDescription = displayDescription.replace("ref=\"", "ref=\"https://");
        }
            return displayDescription;
    }


  
    shadeColor(color: string, percent: number): string {

        var R: number = parseInt(color.substring(1, 3), 16);
        var G: number = parseInt(color.substring(3, 5), 16);
        var B: number = parseInt(color.substring(5, 7), 16);

        R = Math.round(R * (100 + percent) / 100);
        G = Math.round(G * (100 + percent) / 100);
        B = Math.round(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    close(): void {
        this.modal.hide();
        this.active = false;
    }
}
