import { Component, OnInit, Injector, ViewEncapsulation, ViewChild } from '@angular/core';
import { AbpSessionService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AbpMultiTenancyService } from 'abp-ng2-module';
import {
    ProfileServiceProxy,
    UserLinkServiceProxy,
    UserServiceProxy,
    LinkedUserDto,
    ChangeUserLanguageDto,
    TenantLoginInfoDto,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

import { LoginAttemptsModalComponent } from './login-attempts-modal.component';
import { LinkedAccountsModalComponent } from './linked-accounts-modal.component';
import { ChangePasswordModalComponent } from './profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from './profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from './profile/my-settings-modal.component';
import { MyInterestsModalComponent } from './profile/my-interests-modal.component';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { NotificationSettingsModalCompoent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { AppConsts } from '@shared/AppConsts';
import { SubscriptionStartType, EditionPaymentType } from "@shared/AppEnums";
import { Router } from '@angular/router';
import { slideFromBottom } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import * as moment from 'moment';
import { VotechaHelper } from '@shared/helpers/VotechaHelper';

@Component({
    templateUrl: './header.component.html',
    selector: 'header',
    animations: [slideFromBottom()],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends AppComponentBase implements OnInit {

    @ViewChild('notificationSettingsModal', { static: true }) notificationSettingsModal: NotificationSettingsModalCompoent;

    @ViewChild('loginAttemptsModal', { static: true }) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal', { static: true }) linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild('myInterestsModal', { static: true }) MyInterestsModal: MyInterestsModalComponent;

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin: boolean = false;
    isUserLoggedIn: boolean = false;
    showSiteMenu: boolean;
    isVotechaNo: boolean;

    shownLoginNameTitle: string = "";
    shownLoginName: string = "";
    profilePicture: string = "/assets/common/images/votecha-profile-picture.jpg";
    cookieLangValue: string;
    pagePath: string = "";

    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    chatConnected = false;
    isNorwaySite = false;
    screenWidth: any;

    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    subscriptionStartType = SubscriptionStartType;
    editionPaymentType: typeof EditionPaymentType = EditionPaymentType;

    constructor(
        injector: Injector,
        private _router: Router,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _userServiceProxy: UserServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private _userNotificationHelper: UserNotificationHelper,
        private _sessionService: SessionServiceProxy,
        private _appSessionService: AppSessionService
    ) {
        super(injector);
        _router.events.subscribe((val) => {
            this.showSiteMenu = this._router.url.includes("/site/") || this._router.url.includes("public/category") || this._router.url.includes("main/category")  ? true : false;
        })
    }

    get multiTenancySideIsTenant(): boolean {
        return this._abpSessionService.tenantId > 0;
    }

    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.isUserLoggedIn = this._abpSessionService.userId > 0;
        this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled == false);
        this.currentLanguage = this.localization.currentLanguage;        
        if (AppConsts.appBaseUrl.toLowerCase().includes(".no")) {
            this.isNorwaySite = true;
            if (this.currentLanguage.name !== "nb-NO")
                this.currentLanguage = VotechaHelper.getNorwegianLang(this.languages);            
        }
        if (this.isUserLoggedIn) {
            this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
            this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
            this.shownLoginNameTitle = this.isImpersonatedLogin ? this.l("YouCanBackToYourAccount") : "";
            this.getCurrentLoginInformations();
            this.getProfilePicture();
            this.getRecentlyLinkedUsers();

            this.registerToEvents();
        }
        else {
            this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
            if (this.cookieLangValue === null || this.cookieLangValue === undefined || this.cookieLangValue ==='') {
                var userLang = navigator.language;
                this.changeLanguage(userLang);
            }
        }

       
    }

    registerToEvents() {
        abp.event.on("profilePictureChanged", () => {
            this.getProfilePicture();
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });
    }

  
    changeLanguage(languageName: string): void {
        let input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        if (this.isUserLoggedIn) {
            this._profileServiceProxy.changeLanguage(input).subscribe(() => {
                abp.utils.setCookieValue(
                    "Abp.Localization.CultureName",
                    languageName,
                    new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                    abp.appPath
                );

                window.location.reload();
            });
        }
        else {
            abp.utils.setCookieValue(
                "Abp.Localization.CultureName",
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            location.reload();
        }
    }


    getCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.user.name;
        this._sessionService.getCurrentLoginInformations()
            .subscribe((result: GetCurrentLoginInformationsOutput) => {
                this.tenant = result.tenant;
            });
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : ".") + "\\" + linkedUser.username;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }

    showLoginAttempts(): void {
        this.loginAttemptsModal.show();
    }

    showLinkedAccounts(): void {
        this.linkedAccountsModal.show();
    }

    changePassword(): void {
        this.changePasswordModal.show();
    }

    changeProfilePicture(): void {
        this.changeProfilePictureModal.show();
    }

    changeMySettings(): void {
        this.mySettingsModal.show();
    }

    changeMyInterests(): void {
        this.MyInterestsModal.show();
    }

    logout(): void {
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.user.name;
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    get chatEnabled(): boolean {
        return false;
    }

    subscriptionStatusBarVisible(): boolean {
        return this._appSessionService.tenantId > 0 && (this._appSessionService.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    getSubscriptionExpiringDayCount(): number {
        if (!this._appSessionService.tenant.subscriptionEndDateUtc) {
            return 0;
        }

        return Math.round(moment(this._appSessionService.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    }

    getTrialSubscriptionNotification(): string {
        return abp.utils.formatString(this.l("TrialSubscriptionNotification"),
            "<strong>" + this._appSessionService.tenant.edition.displayName + "</strong>",
            "<a href='/account/buy?editionId=" + this._appSessionService.tenant.edition.id + "&editionPaymentType=" + this.editionPaymentType.BuyNow + "'>" + this.l("ClickHere") + "</a>");
    }

    getExpireNotification(localizationKey: string): string {
        return abp.utils.formatString(this.l(localizationKey), this.getSubscriptionExpiringDayCount());
    }

    GoToRegistration(): void {
        this._router.navigate(['account/register']);
    }

    GoToLogin(): void {
        this._router.navigate(['account/login']);
    }

    GoToHome(): void {
        if (this.isUserLoggedIn) {
            this._router.navigate(['app/main/mysite/']);
        }
        else {
            this._router.navigate(['']);
        }
    }

}
