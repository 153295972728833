
import { finalize } from 'rxjs/operators';
import { Component, ViewChild, Injector, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PollServiceProxy, PollTranslationServiceProxy, GetPollForEditOutput, PollInput, PollTranslationOutput, PollTranslationInput } from '@shared/service-proxies/service-proxies';
import { LanguageServiceProxy, ApplicationLanguageListDto } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';

@Component({
    selector: 'CreatePollTranslationModal',
    templateUrl: './create-poll-translation-modal.component.html'
})
export class CreatePollTranslationModalComponent extends AppComponentBase {

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

   @ViewChild('modal', { static: true }) modal: ModalDirective;
   @ViewChild('nameInput') nameInput: ElementRef;

    poll: GetPollForEditOutput;
    pollTranslations: PollTranslationOutput[] = [];
    newPollTranslation: PollTranslationInput;
    languages: ApplicationLanguageListDto[];

    translationLanguageList = [];
    active: boolean = false;
    saving: boolean = false;
    newTranslation: boolean = true;
    hasSurveyFactors: boolean = true;
    selectedLanguageId: number = 0;
    activeTabFactorIndex: number = 0;
    constructor(
        injector: Injector,
        private _pollService: PollServiceProxy,
        private _pollTranslationService: PollTranslationServiceProxy,
        private _languageService: LanguageServiceProxy
    ) {
        super(injector);
    }

    show(pollDto: PollInput): void {
        this.mapPoll(pollDto);
        this.newPollTranslation = new PollTranslationInput();
        this.newPollTranslation.descriptionTranslation = '';
        this.newPollTranslation.alternateImageText = '';
        this.newPollTranslation.applicationLanguageID = 0;
        this.activeTabFactorIndex = 0;
        this.pollTranslations = [];
        this.newPollTranslation.userPollId = pollDto.id;
    }

    mapPoll(pollDto: PollInput): void {
        this.poll = new GetPollForEditOutput();
        this.poll.id = pollDto.id;
        this.poll.areaTypeId = pollDto.areaTypeId;
        this.poll.countryId = pollDto.countryId;
        this.poll.name = pollDto.name;
        this.poll.question = pollDto.question;
        this.poll.description = pollDto.description;
        this.poll.categoryId = pollDto.categoryId;
        this.poll.subCategoryId = pollDto.subCategoryId;
        this.hasSurveyFactors = false;
        this.getTranslations();
        //this.hasSurveyFactors = pollDto.h
       

    }

    getTranslations(): void {
        this._pollTranslationService.getPollTranslations(this.poll.id, this.poll.areaTypeId, this.poll.countryId).subscribe(result => {
            this.poll.translations = result;
            this.getLanguages();
        });
    }
    //getPoll(): void {
    //    this._pollService.getPollForEditOutput(this.poll.id, this.poll.areaTypeId, this.poll.countryId).subscribe(result => {
    //        this.poll = result;
    //        if (this.poll.translations != undefined && this.poll.translations.length > 0) {
    //            this.createPollTranslations();
    //        }
    //        this.active = true;
    //        this.newTranslation = true;
    //        this.modal.show();
    //    });
    //}

    onShown(): void {
        $(this.nameInput.nativeElement).focus();
    }

    saveTranslation(pollTranslation: PollTranslationInput): void {
        this.saving = true;
        pollTranslation.pollId = this.poll.id;
        pollTranslation.areaTypeId = this.poll.areaTypeId;
        pollTranslation.countryId = this.poll.countryId;
        this._pollTranslationService.savePollTranslation(pollTranslation).pipe(
            finalize(() => this.saving = false))
            .subscribe((result) => {
                this.newPollTranslation.id = result;
                pollTranslation.id = result;
                this.notify.info(this.l('SavedSuccessfully'));
                this.AddTranslation(pollTranslation);

                //this.getTranslations();
            });
    }


    close(): void {
        this.modal.hide();
        this.active = false;
    }

    save(): void {
        this.saving = true;
        this.close();
        this.modalSave.emit();
    }

    getLanguages(): void {
        this._languageService.getLanguages().subscribe((result) => {
            this.languages = result.items;
            this.fillTranslationLanguageList();
            if (this.poll.translations != undefined && this.poll.translations.length > 0) {
                this.createPollTranslations();
            }
            this.active = true;
            this.newTranslation = true;
            this.modal.show();
        });
    }


    AddTranslation(pollTranslation: PollTranslationInput): void {
        var newTranslation = new PollTranslationOutput();
        newTranslation.id = pollTranslation.id;
        newTranslation.applicationLanguageId = pollTranslation.applicationLanguageID;
        newTranslation.pollId = this.poll.id;
        newTranslation.languageName = this.getLanguageName(newTranslation.applicationLanguageId);
        newTranslation.titleTranslation = pollTranslation.titleTranslation;
        newTranslation.questionTranslation = pollTranslation.questionTranslation;
        newTranslation.descriptionTranslation = pollTranslation.descriptionTranslation;
        newTranslation.alternateImageText = pollTranslation.alternateImageText;
        if (!this.isTranslationInList(newTranslation.applicationLanguageId)) {
            this.pollTranslations.push(newTranslation);
        }
        this.newPollTranslation = new PollTranslationInput();
        this.newPollTranslation.applicationLanguageID = 0;
        this.fillTranslationLanguageList()
    }


    createPollTranslations() {

        for (var i = 0; i < this.poll.translations.length; i++) {
            if (this.poll.translations[i].languageName === undefined || this.poll.translations[i].languageName === null || this.poll.translations[i].languageName === '') {
                var lang = this.getLanguage(this.poll.translations[i].applicationLanguageId);
                this.poll.translations[i].languageName = lang.displayName;
                this.poll.translations[i].icon = lang.icon;
            }
            this.pollTranslations.push(this.poll.translations[i]);
        }
        //var countryLanguageTranslation = new PollTranslationInput();
        //countryLanguageTranslation.applicationLanguageID = this.poll.primaryLanguageId;
        //countryLanguageTranslation.userPollId = this.poll.id;
        //countryLanguageTranslation.title = this.poll.name;
        //countryLanguageTranslation.question = this.poll.question;
        //countryLanguageTranslation.description = this.poll.description;
        //countryLanguageTranslation.alternateImageText = this.poll.alternateText;
        //countryLanguageTranslation.languageName = this.getLanguageName(countryLanguageTranslation.applicationLanguageID);

        //if (!this.isTranslationInList(countryLanguageTranslation.applicationLanguageID)) {
        //    this.saveTranslation(countryLanguageTranslation);
        //}


        this.fillTranslationLanguageList();
    }


    //Fills dropdownlist with languages for adding translation
    fillTranslationLanguageList(): void {
        this.translationLanguageList = [];
        for (var i = 0; i < this.languages.length; i++) {
            if (!this.isTranslationInList(this.languages[i].id)) {
                var languageItem = this.languages[i];
                this.translationLanguageList.push(languageItem);
            }
        }
    }
    getLanguageId(languageName: string): number {
        var languageId = 0;
        for (var i = 0; i < this.languages.length; i++) {
            if (this.languages[i].displayName === languageName) {
                languageId = this.languages[i].id;
                break;
            }
        }
        return languageId;
    }

    getLanguageName(languageId: number): string {
        var language = '';
        for (var i = 0; i < this.languages.length; i++) {
            if (this.languages[i].id === languageId) {
                language = this.languages[i].displayName;
                break;
            }
        }
        return language;
    }

    getLanguage(languageId: number): ApplicationLanguageListDto {
        var language = new ApplicationLanguageListDto();
        for (var i = 0; i < this.languages.length; i++) {
            if (this.languages[i].id === languageId) {
                language = this.languages[i];
                break;
            }
        }
        return language;
    }

    isTranslationInList(languageId: number): boolean {
        var inList = false;
        for (var i = 0; i < this.pollTranslations.length; i++) {
            if (this.pollTranslations[i].applicationLanguageId === languageId) {
                inList = true;
                break;
            }
        }
        return inList;
    }



    SetLanguageNameOnTranslations(): void {
        for (var i = 0; i < this.pollTranslations.length; i++) {
            this.pollTranslations[i].languageName = this.getLanguageName(this.poll.translations[i].applicationLanguageId);
        }
    }

    invalidForm(): boolean {
        var isInvalid: boolean = true;
        if (this.newPollTranslation.titleTranslation === undefined) {
            return isInvalid;
        }
        if (this.newPollTranslation.applicationLanguageID > 0 && this.newPollTranslation.titleTranslation.length > 2 && this.newPollTranslation.questionTranslation.length > 6) {
            isInvalid = false;
        }
        return isInvalid;
    }

    clearTranslationFields(): void {
        this.newPollTranslation = new PollTranslationInput();
        this.newPollTranslation.applicationLanguageID = 0;
        this.newPollTranslation.descriptionTranslation = "";
        this.newPollTranslation.titleTranslation = "";
        this.newPollTranslation.questionTranslation = "";
        this.newPollTranslation.alternateImageText = "";
        this.newTranslation = true;
    }

    editTranslation(pollTranslationId: string): void {
        this._pollTranslationService.getPollTranslationForEdit(this.poll.id, pollTranslationId, this.poll.areaTypeId, this.poll.countryId).pipe(
            finalize(() => this.saving = false))
            .subscribe((result) => {
                this.newPollTranslation.id = result.id;
                this.newPollTranslation.applicationLanguageID = result.applicationLanguageId;
                this.newPollTranslation.titleTranslation = result.titleTranslation;
                this.newPollTranslation.pollId = result.pollId;
                this.newPollTranslation.questionTranslation = result.questionTranslation;
                this.newPollTranslation.descriptionTranslation = result.descriptionTranslation;
                this.newPollTranslation.alternateImageText = result.alternateImageText;
                this.newPollTranslation.languageName = this.getLanguageName(result.applicationLanguageId);
                this.newTranslation = false;
            });
    }


    deleteTranslation(pollTranslation: PollTranslationOutput): void {
        this.message.confirm(
            this.l('AreYouSureToDeleteTheTranslation', pollTranslation.titleTranslation), this.l('AreYouSure'),
            isConfirmed => {
                if (isConfirmed) {
                    this._pollTranslationService.deletePollTranslation(pollTranslation.pollId, pollTranslation.id, this.poll.areaTypeId, this.poll.countryId).subscribe(() => {
                        this.notify.info(this.l('SuccessfullyDeleted'));
                        _.remove(this.pollTranslations, pollTranslation);
                    });
                }
            }
        );
    }
}
