import { Component, Injector, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PageServiceProxy, PageOutput } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',    
    animations: [appModuleAnimation()]
})
export class ContactComponent extends AppComponentBase implements OnInit {
    page: PageOutput;    
    cookieLangValue: string;
    isUserLoggedIn: boolean = false;
    isMeninger24: boolean = false;
    public screenWidth: number;

    constructor(
        injector: Injector,
        private _pageService: PageServiceProxy
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        this.isUserLoggedIn = abp.session.userId ? (abp.session.userId > 0) : false;
        this.page = new PageOutput();
        this.getContent();
        this.isMeninger24 = false;
        if (AppConsts.appBaseUrl.toLowerCase().includes(".no") || AppConsts.appBaseUrl.toLowerCase().includes("localhost")) {
            this.isMeninger24 = true;
        }
    }

    getContent(): void {
        this._pageService.getPage("Contact", this.cookieLangValue).subscribe((result) => {
            this.page.preamble = result.preamble;
            this.page.content = result.content;
            window.scrollTo(0, 0);
        });
    }
}
