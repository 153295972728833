<div class="portlet light ">
    <div class="portlet-title">
        <div class="caption">
            <i class="fa fa-plus-circle prosColor"></i>
            <span class="caption-subject bold uppercase prosColor">
                <span *ngIf="survey.prosText === undefined || survey.prosText === null || survey.prosText === ''">
                    <span *ngIf="survey.forAndAgainstTextId === 0">{{l("Pros")}} -</span>
                    <span *ngIf="survey.forAndAgainstTextId > 0">{{l('ArgumentsFor')}}</span>
                    {{survey.name}}
                </span>
                <span *ngIf="survey.prosText !== undefined && survey.prosText !== null && survey.prosText !== ''">
                    {{ survey.prosText }}
                </span>
            </span>
        </div>
    </div>
    <div class="portlet-body">
        <div *ngIf="prosFactors.length > 0">
            <div *ngFor="let factor of prosFactors; let k=index">
                <div class="margin-bottom2pr">
                    <div class="row">
                        <div class="factor-frame container-max-width">
                            <div class="pull-left bold font-media-h3 prosColor" style="margin-right:3px">{{k+1}}.</div>
                            <div class="margin-bottom-10 bold font-media-h3 prosColor" style="width:100%;">
                                <span *ngIf="factor.displayText !== null && factor.displayText !== ''">{{factor.displayText}}</span>
                                <span *ngIf="factor.displayText === null || factor.displayText === ''">{{factor.factorText}}</span>
                                <span class="pull-right" *ngIf="factor.factorStatus === 'Approved' && factor.rating > 0">
                                    {{ factor.rating }} <i class="fa fa-star prosColor"></i>
                                </span>
                            </div>
                            <div class="nowrap" *ngIf="isUserLoggedIn && factor.factorStatus === 'Approved'">
                                <fieldset class="rating">
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star5-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=5 (change)="onFactorRankingChange(factor, 5)" [(ngModel)]="factor.userRating" /><label class="full" for="star5-conFactor{{factor.id}}" title="Excellent - 5 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star4-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=4 (change)="onFactorRankingChange(factor, 4)" [(ngModel)]="factor.userRating" /><label class="full" for="star4-conFactor{{factor.id}}" title="Very good - 4 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star3-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=3 (change)="onFactorRankingChange(factor, 3)" [(ngModel)]="factor.userRating" /><label class="full" for="star3-conFactor{{factor.id}}" title="Good - 3 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star2-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=2 (change)="onFactorRankingChange(factor, 2)" [(ngModel)]="factor.userRating" /><label class="full" for="star2-conFactor{{factor.id}}" title="Fair - 2 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star1-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=1 (change)="onFactorRankingChange(factor, 1)" [(ngModel)]="factor.userRating" /><label class="full" for="star1-conFactor{{factor.id}}" title="Poor - 1 star"></label>
                                </fieldset>
                            </div>
                            <div class="nowrap font-grey-light" *ngIf="!isUserLoggedIn">                                
                                <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                            </div>

                        </div>
                    </div>
                    <div class="row" *ngIf="isUserLoggedIn && ((survey.hasProFactorForApproval && (factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed')) || survey.isAdm)">
                        <div class="container">
                            <span><label *ngIf="factor.factorStatus === 'ForApproval'" class="label label-default">{{l("ForApproval")}}</label><label *ngIf="factor.factorStatus === 'Dismissed'" class="label label-danger">{{l("Dismissed")}}</label></span>
                            <div class="col-sm-5 col-xs-8">
                                <span class="margin-right-10"><button class="btn btn-circle btn-info" (click)="editFactor(factor)"><i class="fa fa-edit"></i></button></span>
                                <span><button class="btn btn-circle red-pink" (click)="deleteFactor(factor)"><i class="fa fa-trash"></i></button></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
        <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="isUserLoggedIn">
            <input class="form-control" type="text" name="question" [ngClass]="{'edited':newProsFactor.factorText}" [(ngModel)]="newProsFactor.factorText" required maxlength="290" minlength="10">
            <label><span *ngIf="newProsFactor.factorId === ''" class="font-media-h3">{{l("AddProsArgument")}}</span><span class="font-media-h3" *ngIf="newProsFactor.factorId !== ''">{{l("Edit")}}</span></label>
        </div>
        <div class="row" *ngIf="isUserLoggedIn">
            <div style="margin-left:15px">
                <span *ngIf="newProsFactor.factorId !== ''"><button class="btn btn default" (click)="cancelEditFactor(true)"><i class="fa fa-close"></i> {{l("Cancel")}}</button></span>
                <button class="btn prosBackGroundColor font-white" (click)="saveFactor(true)"><span *ngIf="newProsFactor.factorId === ''"><i class="fa fa-plus"></i> {{l("Add")}}</span><span *ngIf="newProsFactor.factorId !== ''"><i class="fa fa-save"></i> {{l("Save")}}</span></button>
            </div>
        </div>
        <div class="font-dark margin-top-20 font-italic font-media-h4" *ngIf="!isUserLoggedIn || prosFactors.length === 0">
            <span *ngIf="prosFactors.length === 0" class="bold">{{l('NoPros')}}<br /></span>
            <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreatePros')}}</span>
            <span *ngIf="!isUserLoggedIn">{{l('LoginCreateAPros')}}</span>

        </div>
    </div>
</div>
<div class="portlet light">
    <div class="portlet-title">
        <div class="caption">
            <i class="fa fa-minus-circle font-red-soft"></i>
            <span class="caption-subject font-red-soft bold uppercase">
                <span *ngIf="survey.consText === undefined || survey.consText === null || survey.consText === ''">
                    <span *ngIf="survey.consText !== null && survey.consText !==''">{{survey.consText}}</span>
                    <span *ngIf="(survey.consText === null || survey.consText ==='') && survey.forAndAgainstTextId === 0">{{l("Cons")}} -</span>
                    <span *ngIf="(survey.consText === null || survey.consText ==='') && survey.forAndAgainstTextId > 0">{{l('ArgumentsAgainst')}}</span>
                    {{survey.name}}
                </span>
                <span *ngIf="survey.consText !== undefined && survey.consText !== null && survey.consText !== ''">
                    {{ survey.consText }}
                </span>
            </span>
        </div>
    </div>
    <div class="portlet-body">
        <div *ngIf="consFactors.length > 0">
            <div *ngFor="let factor of consFactors; let k=index">
                <div class="margin-bottom2pr">
                    <div class="row">
                        <div class="factor-frame container-max-width">
                            <div class="pull-left bold font-media-h3 font-red-soft" style="margin-right:3px">{{k+1}}.</div>
                            <div class="margin-bottom-10 bold font-media-h3 font-red-soft">
                                <span *ngIf="factor.displayText !== null && factor.displayText !== ''">{{factor.displayText}}</span>
                                <span *ngIf="factor.displayText === null || factor.displayText === ''">{{factor.factorText}}</span>
                                <span class="pull-right" *ngIf="factor.factorStatus === 'Approved' && factor.rating > 0">
                                    {{ factor.rating }} <i class="fa fa-star font-red-soft"></i>
                                </span>
                            </div>
                            <div class="nowrap" *ngIf="isUserLoggedIn && factor.factorStatus === 'Approved'">
                                <fieldset class="rating">
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star5-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=5 (change)="onFactorRankingChange(factor, 5)" [(ngModel)]="factor.userRating" /><label class="full" for="star5-conFactor{{factor.id}}" title="Excellent - 5 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star4-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=4 (change)="onFactorRankingChange(factor, 4)" [(ngModel)]="factor.userRating" /><label class="full" for="star4-conFactor{{factor.id}}" title="Very good - 4 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star3-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=3 (change)="onFactorRankingChange(factor, 3)" [(ngModel)]="factor.userRating" /><label class="full" for="star3-conFactor{{factor.id}}" title="Good - 3 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star2-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=2 (change)="onFactorRankingChange(factor, 2)" [(ngModel)]="factor.userRating" /><label class="full" for="star2-conFactor{{factor.id}}" title="Fair - 2 stars"></label>
                                    <input [disabled]="factor.factorStatus !== 'Approved'" type="radio" id="star1-conFactor{{factor.id}}" name="rating-conFactor{{factor.id}}" [value]=1 (change)="onFactorRankingChange(factor, 1)" [(ngModel)]="factor.userRating" /><label class="full" for="star1-conFactor{{factor.id}}" title="Poor - 1 star"></label>
                                </fieldset>
                            </div>
                            <div class="nowrap font-grey-light" *ngIf="!isUserLoggedIn">
                                <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isUserLoggedIn && ((survey.hasConFactorForApproval && (factor.factorStatus === 'ForApproval' || factor.factorStatus === 'Dismissed')) || survey.isAdm)">
                        <div class="container">
                            <span><label *ngIf="factor.factorStatus === 'ForApproval'" class="label label-default">{{l("ForApproval")}}</label><label *ngIf="factor.factorStatus === 'Dismissed'" class="label label-danger">{{l("Dismissed")}}</label></span>
                            <div class="col-sm-5 col-xs-8">
                                <span class="margin-right-10"><button class="btn btn-circle btn-info" (click)="editFactor(factor)"><i class="fa fa-edit"></i></button></span>
                                <span><button class="btn btn-circle red-pink" (click)="deleteFactor(factor)"><i class="fa fa-trash"></i></button></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group form-md-line-input form-md-floating-label no-hint" *ngIf="isUserLoggedIn">
        <input class="form-control" type="text" name="newConsFactor" [ngClass]="{'edited':newConsFactor.factorText}" [(ngModel)]="newConsFactor.factorText" required maxlength="290" minlength="10">
        <label><span *ngIf="newConsFactor.factorId === ''" class="font-media-h3">{{l("AddConsArgument")}}</span><span class="font-media-h3" *ngIf="newConsFactor.factorId !== ''">{{l("Edit")}}</span></label>
    </div>
    <div class="row" *ngIf="isUserLoggedIn">
        <div style="margin-left:15px">
            <span *ngIf="newConsFactor.factorId !== ''"><button class="btn btn default" (click)="cancelEditFactor(false)"><i class="fa fa-close"></i> {{l("Cancel")}}</button></span>
            <button class="btn btn-primary red-soft" (click)="saveFactor(false)"><span *ngIf="newConsFactor.factorId === ''"><i class="fa fa-plus"></i> {{l("Add")}}</span><span *ngIf="newConsFactor.factorId !== ''"><i class="fa fa-save"></i> {{l("Save")}}</span></button>
        </div>
    </div>
    <div class="font-dark margin-top-20 font-italic font-media-h4" *ngIf="!isUserLoggedIn || consFactors.length === 0">
        <span *ngIf="consFactors.length === 0" class="bold">{{l('NoCons')}}<br /></span>
        <span class="margin-top-20" *ngIf="isUserLoggedIn">{{l('BeTheFirstToCreateCons')}}</span>
        <span *ngIf="!isUserLoggedIn">{{l('LoginCreateACons')}}</span>

    </div>
</div>


