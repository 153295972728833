    <div style="margin-top:1%;margin-bottom:1%; margin-left:1%;" *ngIf="chartActive">
        <div class="row">
            <tabset class="tab-container tabbable-line" id="tabRatingScore">
                <tab heading="{{l('Total')}}" tabindex="0" [active]="activeRatingTabIndex === 0">
                    <div class="margin-right-10">
                        <table class="col-xs-12 col-sm-12" *ngFor="let item of totalItems; let i=index;let even = even; let odd = odd">
                            <tr *ngIf="item.totalVotes > 0">
                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                    {{item.itemText}}
                                </td>
                                <td>
                                    <div class="bar-rating-container">
                                        <div [ngStyle]="{'width': item.totalPercent !== '0 %'? item.totalPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalPercent !== '0 %'">{{item.totalPercent}}</span></div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </tab>
                <tab heading="{{l('Gender')}}" tabindex="1" [active]="activeRatingTabIndex === 1">
                    <div class="panel-group accordion" id="accordion1">
                        <div class="panel panel-primary">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapse_1">{{l('Women')}}</a>
                                </h4>
                            </div>
                            <div id="collapse_1" class="panel-collapse in">
                                <div class="panel-body">
                                    <div class="margin-right-10" *ngIf="womenItems.length> 0">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of womenItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.womenVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalWomenPercent !== '0 %'? item.totalWomenPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalWomenPercent !== '0 %'">{{item.totalWomenPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>                                    
                                    <div *ngIf="womenItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoWomenAnswered')}}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-primary">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapse_2">{{l('Men')}}</a>
                                </h4>
                            </div>
                            <div id="collapse_2" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div *ngIf="menItems.length> 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of menItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.menVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalMenPercent !== '0 %'? item.totalMenPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalMenPercent !== '0 %'">{{item.totalMenPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="menItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoMenAnswered')}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </tab>
                <tab heading="{{l('Age')}}" tabindex="2" [active]="activeRatingTabIndex === 2">
                    <div class="panel-group accordion" id="accordionAge">
                        <div class="panel panel-primary" >
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_1">{{l('AgeGroup')}}: 13-23</a>
                                </h4>
                            </div>
                            <div id="accordionAge_1" class="panel-collapse in">
                                <div class="panel-body">
                                    <div *ngIf="youthItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of youthItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.youthVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalYouthPercent !== '0 %'? item.totalYouthPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalYouthPercent !== '0 %'">{{item.totalYouthPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="youthItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-primary" >
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_2">{{l('AgeGroup')}}: 24-39</a>
                                </h4>
                            </div>
                            <div id="accordionAge_2" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div *ngIf="youngAdultItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of youngAdultItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.youngAdultVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalYoungAdultPercent !== '0 %'? item.totalYoungAdultPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalYoungAdultPercent !== '0 %'">{{item.totalYoungAdultPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="youngAdultItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-primary">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_3">{{l('AgeGroup')}}: 40-65</a>
                                </h4>
                            </div>
                            <div id="accordionAge_3" class="panel-collapse in">
                                <div class="panel-body">
                                    <div *ngIf="middleAgedItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of middleAgedItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.middleAgedVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalMiddleAgedPercent !== '0 %'? item.totalMiddleAgedPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalMiddleAgedPercent !== '0 %'">{{item.totalMiddleAgedPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="middleAgedItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-primary">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordionAge" href="#accordionAge_4">{{l('AgeGroup')}}: 66+</a>
                                </h4>
                            </div>
                            <div id="accordionAge_4" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div *ngIf="elderlyItems.length > 0" class="margin-right-10">
                                        <table class="col-xs-12 col-sm-12" *ngFor="let item of elderlyItems; let i=index;let even = even; let odd = odd">
                                            <tr *ngIf="item.elderlyVotes > 0">
                                                <td align="right" class="col-xs-5 col-sm-4 font-xs">
                                                    {{item.itemText}}
                                                </td>
                                                <td>
                                                    <div class="bar-rating-container">
                                                        <div [ngStyle]="{'width': item.totalElderlyPercent !== '0 %'? item.totalElderlyPercent : '0px'  }" [ngClass]="{'bg-blue': even, 'bg-blue-dark': odd}" class="score"><span *ngIf="item.totalElderlyPercent !== '0 %'">{{item.totalElderlyPercent}}</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div *ngIf="elderlyItems.length === 0" class="note note-warning margin-top-30" style="max-width:700px">
                                        {{l('NoOneInGroupAnswered')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>

            </tabset>
        </div>
    </div>


