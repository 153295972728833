import { Component, OnInit, ViewChild, Injector, InjectionToken, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AnonymousPollAnswerDto, AnonymousPollAnswerOutput, ChartItemDto} from '@shared/service-proxies/service-proxies';


import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'anonymous-voting-charts',
    templateUrl: './anonymous-voting-charts.component.html',
  animations: [appModuleAnimation()]
})
export class AnonymousVotingChartsComponent extends AppComponentBase implements OnInit,OnChanges {

    @Input() chartDataFromParent: AnonymousPollAnswerDto;
    @Input() surveyTypeId: number;
    
    public chartActive: boolean = false;    
    public saving: boolean = false;

 
    chartData: AnonymousPollAnswerDto;
    totalItems: AnonymousPollAnswerOutput[] = [];   
    answerId: number;

    constructor(
        injector: Injector       
       
    ) {
        super(injector);
    }

    ngOnInit() {
        this.chartActive = false;
        this.totalItems = [];

        if (this.surveyTypeId > 0 && this.chartDataFromParent !== null && this.chartDataFromParent !== undefined) {                      
            this.chartData = this.chartDataFromParent;
            if (this.chartData.answers.length > 0) {
                this.totalItems = this.chartData.answers;
                this.chartActive = true;
            }
        }      
    }
    
    ngOnChanges() {
        this.ngOnInit();
    }

    formatPercentage(input: string): string {
        const posDot = input.indexOf(".");
        return input.substring(0, posDot) + input.substring(posDot + 1);
    }

  }
