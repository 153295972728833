<div [@routerTransition]  [ngStyle]="{'max-width': isUserLoggedIn ? '1400px' : '100%'}">
    <div class="container font-white fadeInLeft animated">
        <div class="c-content-title-1">
            <h1 class="c-font-uppercase c-font-bold font-white font-media-h1">{{l('Help')}}</h1>
            <div class="c-line-left c-theme-bg"></div>
        </div>
        <div [ngClass]="{'col-md-6 col-lg-6':isUserLoggedIn, 'col-md-8 col-lg-8':!isUserLoggedIn}">
            <h2 class="bold font-white" *ngIf="page.preamble !== null">
                {{l('HelpPreamble')}}
            </h2>
        </div>
        <div [ngClass]="{'col-md-3 col-lg-3':isUserLoggedIn, 'col-md-4 col-lg-4':!isUserLoggedIn}">
           
        </div>
        <div class="lead" [ngClass]="{'col-md-9 col-lg-9':isUserLoggedIn, 'col-md-12 col-lg-12':!isUserLoggedIn}">

            <div class="panel-group accordion scrollable" id="accordionHelp">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"   data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_SignUp"> {{l('SignUp')}}</a>
                        </h4>
                    </div>
                    <div id="collapse_Help_SignUp" class="panel-collapse in">
                        <div class="panel-body font-dark">
                            <p>{{l('ClickTheLink')}} <a href="javascript:;" (click)="GoToRegistration()">{{l('CreateAnAccount')}}</a></p>
                            <p>{{l('FacebookLoginInfo1')}}</p>
                            <p>{{l('FacebookLoginInfo2')}}</p>

                            <p class="bold">{{l('FacebookLoginInfo3')}}</p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_LogIn">{{l('LogIn')}}</a>
                        </h4>
                    </div>
                    <div id="collapse_Help_LogIn" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l('ClickTheLink')}} <a href="javascript:;" (click)="GoToLogin()">{{l('Login')}}</a></p>
                            <p>{{l('LoginHelp1')}}</p>
                            <p>{{l('LoginHelp2')}}</p>
                            <p>{{l('LoginHelp3')}}</p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_ForgotPassword"> {{l('ForgotPassword')}} </a>
                        </h4>
                    </div>
                    <div id="collapse_Help_ForgotPassword" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l('ClickTheLink')}} <a href="javascript:;" (click)="GoToLogin()">{{l('Login')}}</a></p>
                            <br />
                            <p class="bold">{{l('ForgotPasswordHelp1')}}</p>
                            <p>{{l('ForgotPasswordHelp2')}}</p>
                        </div>
                    </div>
                </div>

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_DeleteProfile"> {{l('DeleteProfile')}} </a>
                        </h4>
                    </div>
                    <div id="collapse_Help_DeleteProfile" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p class="bold">{{l('DeleteProfileHelp1')}}</p>
                            <p>{{l('DeleteProfileHelp2')}}</p>
                            <p>{{l('SettingsHelp')}}</p>
                            
                            <br />
                            <p class="bold">{{l('DeleteProfileInfo2')}}</p>
                            <p class="bold">{{l('DeleteProfileInfo3')}}</p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary" *ngIf="isUserLoggedIn">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_CreatePoll">{{l('CreateAPollOrPetition')}}</a>
                        </h4>
                    </div>
                    <div id="collapse_Help_CreatePoll" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l('ClickTheLink')}} <a href="javascript:;" (click)="goToCreateVotecha()">{{l('CreateVotecha')}}</a></p>
                            <br />
                            <p class="bold">{{l('WizardPollOrPetionHelp')}}</p>                            
                            <p>{{l('GlobalPollInfo')}}</p>
                            <p class="bold">{{l("CreatePoll")}}</p>
                            <ol>
                                <li>{{l("WizardPollEnrollmentHelp")}}</li>
                                <li>
                                    {{l("WizardCategory")}}
                                </li>
                                <li>
                                    {{l("WizardSubCategory")}}
                                </li>
                                <li>
                                    {{l("WizardBackground")}}
                                </li>
                                <li>
                                    {{l("AddYourSourcesHelp")}}
                                </li>
                                <li>
                                    {{l("CreateSurveyTitleHelp")}}
                                </li>
                                <li>
                                    {{l("WizardQuestion")}}
                                    {{l("CreateSurveyQuestionHelp")}}
                                </li>


                                <li>
                                    {{l("WizardPollType")}}
                                </li>
                                <li>
                                    {{l("WizardPollLocation")}}
                                </li>
                                <li>
                                    {{l("WizardUploadImage")}}
                                </li>
                                <li>
                                    {{l("WizardPollApprove")}}
                                </li>
                            </ol>


                            <p class="bold"> {{l("CreatePetition")}}</p>
                            <ol>
                                <li>{{l("WizardPetitionEnrollmentHelp")}}</li>
                                <li>
                                    {{l("WizardCategory")}}
                                </li>
                                <li>
                                    {{l("WizardSubCategory")}}
                                </li>
                                <li>
                                    {{l("PetitionNameHelp")}}
                                </li>
                                <li>
                                    {{l("PetitionDescriptionHelp")}}
                                </li>
                                <li>
                                    {{l("AddYourSourcesHelp")}}
                                </li>
                                <li>
                                    {{l("WizardPetitionLocation")}}
                                </li>
                                <li>
                                    {{l("WizardUploadImage")}}
                                </li>
                                <li>
                                    {{l("WizardPollApprove")}}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary" *ngIf="isUserLoggedIn">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold" data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_CreateBlog"> {{l('RelatedArticles')}} </a>
                        </h4>
                    </div>
                    <div id="collapse_Help_CreateBlog" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p class="bold">{{l('CreateArticle')}}</p>
                            <p>{{l("CreateArticleHelp1")}}</p>
                            <p>{{l("CreateArticleHelp2")}}</p>
                            <p>{{l("ArticleApprove")}}</p>
                            <br />
                            <p class="bold">{{l('AddMediaArticle')}}</p>
                            <p>{{l("AddMediaArticleHelp1")}}</p>
                            <p>{{l("AddMediaArticleHelp2")}}</p>
                            <p>{{l("AddMediaArticleApprove")}}</p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary" *ngIf="isUserLoggedIn">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_UserProfile"> {{l('User profile')}} </a>
                        </h4>
                    </div>
                    <div id="collapse_Help_UserProfile" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l('ProfileHelp')}}</p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-primary" *ngIf="isUserLoggedIn">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_Interests">{{l('MyCategories')}} </a>
                        </h4>
                    </div>
                    <div id="collapse_Help_Interests" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l("InterestsHelp1")}}</p>
                            <p>{{l("InterestsHelp2")}}</p>                            
                        </div>
                    </div>
                </div>                            
                <div class="panel panel-primary" *ngIf="isUserLoggedIn">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a class="accordion-toggle font-white bold"  data-toggle="collapse" data-parent="#accordionHelp" href="#collapse_Help_Answered"> {{l('AnsweredPollsAndSignedPetition')}}</a>
                        </h4>
                    </div>
                    <div id="collapse_Help_Answered" class="panel-collapse collapse">
                        <div class="panel-body font-dark">
                            <p>{{l("MyVotechaHelp1")}}</p>
                            <p>{{l("MyVotechaHelp2")}}</p>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>
