<div class="margin-bottom-10 flex-container-content-left">
    <div class="ring-container" style=" margin-bottom: 10px">
        <div class="ringring"></div>
        <div class="circle"></div>
    </div>
    <div class="poll-label">
        <a class="font-white link-underline font-media-h2 bold" (click)="goToVoteForm(poll.id)">
            {{poll.name}}
        </a>
    </div>

</div>
<rg-gauge-chart class="font-white" [canvasWidth]="canvasWidth"
                [needleValue]="needleValue"
                [centralLabel]="centralLabel"
                [options]="options"
                [name]="name"
                [bottomLabel]="bottomLabel">

</rg-gauge-chart>
