
<div  class="signing-card signing-card-frame-outer shadow" [ngStyle]="{'background-color': signing.gender === 1 ? '#4b77be' : '#f546c7'}">   
    <div class="signing-card-frame">
        <div class="profile-userpic">
            <img *ngIf="profilePicture" id="MySiteProfilePicture" alt="" class="img-responsive border-radius-6" [src]="profilePicture" />
        </div>
        <div class="survey-container">
            <div class="person-data text-left margin-top2pr">
                <div class="name">
                    <span *ngIf="!signing.isAnonymous">{{ signing.displayName }}</span>
                    <span *ngIf="signing.isAnonymous && signing.gender === 1">{{l('Man')}}</span>
                    <span *ngIf="signing.isAnonymous && signing.gender === 2">{{l('Woman')}}</span>
                    <span *ngIf="signing.isAnonymous">({{ getAge(signing.birthYear )}})</span> 
                </div>
                <div *ngIf="!signing.isAnonymous">{{l("Age")}}: {{ getAge(signing.birthYear )}}</div>
                <div *ngIf="signing.subAreaName !== null && signing.subAreaName !== ''"> {{ signing.subAreaName }} </div>
                <div *ngIf="signing.petitionMessage  && signing.petitionMessage !== ''" class="margin-top-10">
                    <span>"</span>{{ signing.petitionMessage }}<span>"</span>
                </div>
            </div>
        </div>
    </div>
</div>



